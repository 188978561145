import React, { Component, Fragment } from "react";
import { Row, Col, FormGroup } from "reactstrap";
import styles from "./CampaignDetails.module.css";
import classNames from "classnames";
import CalendarInput from "../shared/CalendarInput/CalendarInput";
import DropdownWrapper from "../shared/DropdownWrapper";
import { PlanOptionsCampaign } from "../Sales/PlanDetails/PlanOptionsCampaign";
import moment from 'moment';
import { SessionContext } from "../../context/SessionContext";

const UFN = "Until Further Notice";

export class CampaignDetails extends Component {
    constructor(props) {
        super(props);

        const { beginDate = "", endDate = "",
            laborReimb, campaignType, campaignTerm, rncExclusion = "",
            viewCreateCampaign } = this.props.selectedRow[0] || {};

        this.state = {
            createCampaign: false,
            beginDate,
            endDate: endDate === UFN ? "" : endDate,
            modalOpen: false,
            tempDate: '',
            showCalendar: null,
            campaignEndDate: endDate === UFN || endDate == '' ? "first" : "second",
            showEndDate: false, //endDate === "first" ? false : true,
            campaignType,
            campaignTerm,
            rncExclusion,


        }
    }

    componentDidMount() {

        console.log(this.props.selectedRow[0])
        if (this.state.endDate === UFN || this.state.endDate == '') {
            this.setState({ showEndDate: false });
        } else {
            this.setState({ showEndDate: true });
        }

    }

    getBeginDate = () => {

        if (this.props.blnPencil === false) {
            return null;
        } else {
            return this.props.selectedRow[0].beginDate;
        }

    }

    getEndDate = () => {

        if (this.props.blnPencil === false) {
            return null
        } else {
            return this.props.selectedRow[0].endDate;
        }

    }

    handleBeginDate = e => {

        let data = [...this.props.selectedRow]
        data[0].beginDate = e
        this.props.updateRow(data)
        this.props.updateErrors("bdate")
        this.setState({ beginDate: e, endDate: null, campaignEndDate: "first", showEndDate: false })

    }

    handleEndDate = e => {

        let data = [...this.props.selectedRow]
        data[0].endDate = e
        this.props.updateRow(data)
        this.setState({ endDate: e })
        this.props.updateErrors("edate")
    }

    onTypeRadioChange = (newData) => {
        this.setState({ ...newData });
    }

    onTermRadioChange = (newData) => {
        this.setState({ ...newData });
    }

    handleStateDropDown = (value, name) => {

        //if(name){
        //  this.setState({[name]: value, showEndDate: value === 'second'});
        // }

        if (value === 'second') {
            if (this.state.beginDate == "") {
                this.props.updateBeginDateErr(true);
                this.setState({ campaignEndDate: "first" })
            } else {
                this.setState({ showEndDate: true, campaignEndDate: "second" })
                this.props.updateEndDateChecker(true)
            }

        } else if (value === 'first') {
            this.setState({ showEndDate: false, campaignEndDate: "first" })

            let data = [...this.props.selectedRow]
            data[0].endDate = null
            this.props.updateRow(data)
            this.props.updateEndDateChecker(false)

        }
    }

    onRNCChange = (rncExclusion) => {

        this.setState({ rncExclusion });

        let data = [...this.props.selectedRow]
        data[0].rncExclusion = rncExclusion
        this.props.updateRow(data)
        this.props.updateErrors("rnc")
    }

    dateFormat = () => {
        return this.props.isCanada == true ? "DD/MM/YYYY" : "MM/DD/YYYY"
    }

    render() {
        var momentDate = this.props.isCanada === true ? moment(this.props.selectedRow[0].beginDate, "DD/MM/YYYY") : moment(this.props.selectedRow[0].beginDate, "MM/DD/YYYY");
        var possibleEndDate = moment(momentDate).add(30, "days").toDate();
        const { beginDate, endDate, campaignEndDate, showEndDate, rncExclusion } = this.state;
        return (
            <Fragment>
                <Row className="multiColWrapper">
                    <Col md="5" lg="4">
                        <FormGroup className={this.props.errBeginDate === true && "form-error"} >
                            <CalendarInput onChange={this.handleBeginDate}
                                label="Campaign Start Date"
                                id="campaignStartDate"
                                name="campaignStartDate"
                                minDate={this.props.possibleBeginDate()}
                                value={this.getBeginDate()}
                                updateRow={this.props.updateRow}
                                selectedRow={this.props.selectedRow}
                                placeHolder={this.dateFormat()} isRequired />
                            {this.props.errBeginDate === true && <div className="errorMsg">Campaign start date is required.</div>}
                        </FormGroup>
                    </Col>
                    <Col lg="8">
                        <label class="form-label" 
                        for="campaignEndDtOption">Campaign End Date</label>
                        <span aria-hidden="true" class="required-indicator">*</span>
                        <Row className="multiColWrapper">
                            <Col lg="6">
                                <DropdownWrapper
                                    options={[{ data: 'first', display: UFN }, { data: 'second', display: 'Select a Date' }]}
                                    className="form-control"
                                    aria-required="true"
                                    onChangedValue={this.handleStateDropDown}
                                    onBlur={this.handleStateDropDown}
                                    placeholder="Please select..."
                                    value={campaignEndDate}
                                    id="campaignEndDtOption"
                                    name="campaignEndDateOption"
                                    defaultValue={'first'}
                                    label=""
                                    isRequired
                                />
                            </Col>
                            {showEndDate && (<Col lg="6">
                                <FormGroup className={this.props.errEndDate === true && "form-error"} >
                                    <CalendarInput
                                        endDate={this.state.endDate}
                                        onChange={this.handleEndDate}
                                        value={endDate} id="campaignEndDate"
                                        name="campaignEndDate"
                                        minDate={possibleEndDate}
                                        placeHolder={this.dateFormat()}
                                        updateRow={this.props.updateRow}
                                        selectedRow={this.props.selectedRow}
                                    />
                                    {this.props.errEndDate === true && <div className="errorMsg">Campaign end date is required.</div>}
                                </FormGroup>
                            </Col>)}
                        </Row>
                    </Col>
                </Row>
                <FormGroup className={this.props.errRnc === true && "form-error"} >
                    <label for="typeOfInst" className="form-label d-inline">Do you want to exclude residential new construction?</label>
                    <span aria-hidden="true" className="required-indicator">*</span>
                    <div className="d-flex" id="typeOfInst">
                        <div className={'squareRadioBtn'} role="radio" tabIndex="0">
                            <input id="yesExclude" type="radio" aria-required="true" aria-labelledby="Exclude Residential new construction" name="servicerOptions"
                                checked={rncExclusion === "Yes"} onChange={() => this.onRNCChange("Yes")} />
                            <label className="radio-controls-label m-0 w-100" for="yesExclude">Yes</label>
                        </div>
                        <div className={"squareRadioBtn"} role="radio" tabIndex="0">
                            <input id="dontExclude"
                                type="radio"
                                aria-required="true"
                                aria-labelledby="Don't exclude Residential new construction"
                                name="servicerOptions"
                                checked={rncExclusion === "No"}
                                onChange={() => this.onRNCChange("No")} />
                            <label className="radio-controls-label m-0 w-100" for="dontExclude">No</label>
                        </div>
                    </div>
                    {this.props.errRnc === true && <div className="errorMsg">Select an option to continue.</div>}
                </FormGroup>
                <PlanOptionsCampaign
                    onTypeChange={this.onTypeRadioChange}
                    onTermChange={this.onTermRadioChange}
                    selectedRow={this.props.selectedRow}
                    tableData={this.props.tableData}
                    updateTableData={this.props.updateTableData}
                    updateRow={this.props.updateRow}
                    updatedRowData={this.props.updatedRowData}
                    blnPencil={this.props.blnPencil}
                    blnCopy={this.props.blnCopy}
                    isCanada={this.props.isCanada}
                    errLabor={this.props.errLabor}
                    errCoverage={this.props.errCoverage}
                    errTerm={this.props.errTerm}
                    updateErrors={this.props.updateErrors}
                    updateShowTermsText={this.props.updateShowTermsText}
                    accountId={this.props.accountId}

                />
                {this.props.showTermsText && (
                    <div
                        className={classNames(
                        styles.threeYrPlan,
                        this.context.accountName.toLowerCase() === "homedepot"
                            ? styles.threeYrPlanHD
                            : ""
                        )}
                    >
                        3 Year Plans Save Up to 25%
                    </div>
                )}
            </Fragment>
        );
    }
}

CampaignDetails.contextType = SessionContext;