import React, { Component,Fragment } from "react";
import { FormGroup, Col } from "reactstrap";
import { DropDownAutoComplete } from '../../components/inputs/DropDownAutoComplete';
import styles from "./ContactUs.module.css";
import classNames from "classnames";
import Cleave from 'cleave.js/react';
import SimpleReactValidator from 'simple-react-validator';

const wordCountLimit = 750;

export class ContactUsInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            wordCount: 0,
            dealerName: '',
            dealerId: '',
            dealerPhoneNum: '',
            dealerEmailAdd: '',
            dealerComments: '',
            notes: '',
            helpingOptions: {},
            helpOption: 0,
            defaultReason: '',
            validationFired: false,
            dealerNameErr: true,
            dealerIdErr: true,
            dealerPhoneNumErr: true,
            dealerCommentsErr: true,
            dealerEmailAddErr: true,
            invalidEmailErr: true,
            dealerEmailAddErrMsg: '',
        }

        this.validator = new SimpleReactValidator({
            element: message => (
                <div className={classNames(styles.contactTypes, "errorMsg")}>
                    {message.charAt(0).toUpperCase() + message.slice(1)}
                </div>
            ),
            messages: {
                required: ':attribute is required.',
                email: 'Please enter valid email.',
                phone: 'Please enter a valid phone number.'
            }
        });
    }

    componentDidMount = () => {
        this.getQueueReasons();
    }

    handleComments = (e) => {
        const count = e.target.value;
        const wordCount = count.split("").length;

        this.handleInputChange(e);

        this.setState({ wordCount });
        this.setState({ notes: e.target.value });
    }

    handleInputChange = (e) => {
        const { id, value } = e.target;
        this.setState({
            [id]: value,
        })
        this.props.updateContactInfo(id, value);
        this.forceUpdate();
    }

    handleDropDownChange = (e) => {
        this.setState({ helpOption: e });
        this.props.updateContactInfo('helpOption', e);
    }

    validateForm = () => {
        if (this.validator.allValid()) {
            let { helpOption } = this.state;

            if (helpOption == 0) {
                helpOption = this.state.helpingOptions.filter(c => c.display == "Other")[0].data;
                this.props.updateContactInfo('helpOption', helpOption);
                this.setState({ helpOption: helpOption });
            }

            this.props.handleSave(helpOption);
        }
        else {
            this.validator.showMessages();
            this.forceUpdate();
        }

        this.props.updateContactInfo('validationFired', false);
    }
    
    getTitleCase = (reason) => {
        return reason
          .split(" ")
          .map((item) => {
            return item === "and"
              ? item.toLowerCase()
              : item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
          })
          .join(" ");
    };

    getQueueReasons = () => {
        fetch('queue/queueReasons/')
            .then(res => res.json())
            .then(data => {
                var contactUsReasons = data.map(s => {
                    return {
                        data: s.queueReasonId,
                        display: this.getTitleCase(s.reason),
                        queueReasonCategoryId: s.queueReasonCategoryId
                    }
                }).sort((a, b) => {
                    if (a.data < b.data) { return -1; }
                    if (a.data > b.data) { return 1; }
                    return 0;
                }).filter((a) => a.queueReasonCategoryId === 2);

                this.setState({
                    helpingOptions: contactUsReasons,
                    defaultReason: contactUsReasons.filter(c => c.display == "Other")[0].data,
                });
                this.forceUpdate();
            });
    }

    render() {
        if (this.props.validationFired) {
            this.validateForm();
        }

        this.validator.purgeFields();


        const errDealerName = this.validator.message("name", this.state.dealerName, "required");
        const errDealerId = this.validator.message("dealerID", this.state.dealerId, "required|min:5", { messages:{ required: "Dealer ID is required.", min:"Please enter a valid Dealer ID"}});
        const errDealerPhone = this.validator.message("phoneNumber", this.state.dealerPhoneNum, "required|phone");
        const errDealerEmail = this.validator.message("email", this.state.dealerEmailAdd, "required|email");
        const errDealerComment = this.validator.message("dealerComments", this.state.dealerComments, "required", { messages: { required: "Please provide a detailed description of your inquiry." } });


        return (
            <Fragment>
                <FormGroup>
                    <label for="helpingOptions" className="form-label">How can we help?</label>
                    <DropDownAutoComplete
                        name="campaignEndDate"
                        id="helpingOptions"
                        className="form-control"
                        placeholder="Please select..."
                        options={this.state.helpingOptions}
                        defaultValue={this.state.defaultReason}
                        onChangedValue={this.handleDropDownChange}
                        isTransform={true}
                    />
                </FormGroup>
                <FormGroup className={errDealerName ? "form-error" : ""}>
                    <label for="dealerName" className="form-label">Name</label>
                    <span aria-hidden="true" className="required-indicator">*</span>
                    <input
                        className="form-control"
                        id="dealerName"
                        type="text"
                        aria-required="true"
                        aria-labelledby="Name"
                        name="name"
                        value={this.state.dealerName}
                        onChange={this.handleInputChange} />
                    {errDealerName}
                </FormGroup>
                <FormGroup className={errDealerId ? "form-error" : ""}>
                    <label for="dealerId" className="form-label">Dealer ID</label>
                    <span aria-hidden="true" className="required-indicator">*</span>
                    <input
                        className="form-control"
                        id="dealerId"
                        type="text"
                        aria-required="true"
                        aria-labelledby="DealerID"
                        name="dealerId"
                        onChange={this.handleInputChange} />
                    {errDealerId}
                </FormGroup>
                <FormGroup className={errDealerPhone ? "form-error" : ""}>
                    <label for="dealerPhoneNum" className="form-label">Phone Number</label>
                    <span aria-hidden="true" className="required-indicator">*</span>
                    <Cleave 
                        placeholder="" 
                        name="contactUsInfo" 
                        className="form-control" 
                        id="dealerPhoneNum" 
                        options={{ 
                            numericOnly: true, 
                            blocks: [0, 3, 3, 4], 
                            delimiters: ['(', ') ', '-'] 
                        }} 
                        onChange={this.handleInputChange} 
                    />
                    {errDealerPhone}
                </FormGroup>
                <FormGroup className={errDealerEmail ? "form-error" : ""}>
                    <label for="dealerEmailAdd" className="form-label">Email Address</label>
                    <span aria-hidden="true" className="required-indicator">*</span>
                    <input className="form-control" id="dealerEmailAdd" type="email" aria-required="true" aria-labelledby="DealerEmailAddress" name="dealerEmailAddress" onChange={this.handleInputChange} />
                    {errDealerEmail}
                </FormGroup>
                <FormGroup className={errDealerComment ? "form-error mb-0" : "mb-0"}>
                    <label for="dealerComments" className="form-label">Comment</label>
                    <span aria-hidden="true" className="required-indicator">*</span>
                    <textarea className="form-control" id="dealerComments" rows="5" maxlength={wordCountLimit} spellcheck="" onChange={this.handleComments} aria-required="true" aria-labelledby="DealerComments" ></textarea>
                    <div className="maxLength">
                        {this.state.wordCount}/{wordCountLimit}
                    </div>
                    {errDealerComment}
                </FormGroup>
            </Fragment >
        );
    }
}