/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component } from "react";
import { CardDetails } from '../components/Sales/PaymentInformation/CardDetails';
import { OrderSummary } from '../components/Sales/PaymentInformation/OrderSummary';
import { Container, Card, CardBody, Row, Col, Label, Input, FormGroup } from "reactstrap";
import { Spinner } from 'react-bootstrap';
import styles from './PaymentContainer.module.css'
import classNames from "classnames";
import PageLoading from '../components/shared/PageLoading/PageLoading';
import ErrorConfirmation from '../components/ErrorConfirmation/ErrorConfirmation';
import { PaymentEntityType, PaymentSource } from '../components/Global/PaymentConstants';
import { SessionContext } from '../context/SessionContext';
import { getBrandContent } from "../components/BrandingWhiteLabel/BrandContent";
import { SendMailByAccount, emailModel, serviceModel } from '../components/Global/helpers'
import moment from "moment";

//import FooterContainer from "../../Footer";
let customPayBtn = styles.customPayBtn;
let dateFormat = "MM/DD/YYYY";

export class PaymentGateway extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mailingAddress: {},
      billingAddress: {},
      partyId: 0,
      dealerId: null,
      subTotalAmount: 0.00,
      totalTax: 0.00,
      dataLoaded: false,
      payInProgress: false,
      viewModel: {},
      gst: 0.00,
      pst: 0.00,
      countryCode: "US",
      purchaser: '',
      states: [],
      tokenError: false,
      loadError: false,
      contractCreationError: false,
      contractIdForPayment: 0,
      returnUrl: "",
      successUrl: "",
      translations: {},
      languageCode: "",
      tps: 0.00,
      tvp: 0.00,
      totalContractPrice: 0.00,

    }
  }

  componentDidMount() {
    this.getLennoxPayment();
  }


  getLennoxPayment = () => {
    fetch('/PaymentGateway/GetPaymentInfo')
      .then(response => {
        if (response.status != 200) {
          this.setState({
            dataLoaded: false,
            loadError: true
          });
        }
        return response.json();
      })
      .then((viewModel) => {
        if (viewModel == null || viewModel?.success == null || !viewModel?.success) {
          this.setState({
            viewModel: viewModel,
            dataLoaded: true,
            tokenError: viewModel !== null && !viewModel?.internalErrorThrown && !viewModel?.tokenIsValid,
            loadError: true
          });
        } else {
          this.setState({
            viewModel: viewModel,
            mailingAddress: viewModel?.mailingAddress,
            billingAddress: viewModel?.mailingAddress,
            totalTax: viewModel?.contractPaymentSummary?.taxTotal,
            pst: viewModel?.contractPaymentSummary?.pst,
            gst: viewModel?.contractPaymentSummary?.gst,
            subTotalAmount: viewModel?.contractPaymentSummary?.subTotal,
            contractIds: viewModel?.contractIds,
            returnUrl: viewModel?.returnUrl,
            successUrl: viewModel?.successUrl,
            countryCode: viewModel?.countryId === 2 ? "CA" : "US",
            languageCode: viewModel?.languageCode,
            tps: viewModel?.contractPaymentSummary?.gst,
            tvp: viewModel?.contractPaymentSummary?.pst,
            totalContractPrice: viewModel?.contractDetails[0]?.contractAmount
          }, () => {
            const { useTranslations, useTranslationsHeader, useTranslationsFooter, setPaymentGatewayStatus } = this.context;
            if(viewModel.languageCode.toLowerCase() !== 'en') {              
              useTranslations("PaymentGateway", viewModel.languageCode.toLowerCase());
              useTranslationsHeader("Header", viewModel.languageCode.toLowerCase());
              useTranslationsFooter("Footer", viewModel.languageCode.toLowerCase());
            }   
            setPaymentGatewayStatus(true);
            this.setState({ dataLoaded: true });
          });
        }
      });
  }

  onPay = () => {
    this.setState({
      payInProgress: true
    }, () => {
      this.handlePayment();
    });
    }

    handlePayment = async () => {
    let hasErrors = await this.refs.creditCard.checkForValidationErrors();
    if (hasErrors) {
      this.setState({
        payInProgress: false
      });
    } else {
      try {
        let res = await this.refs.creditCard.pay();
        if (res.isSuccessful) {
            await this.sendConfirmationEmail();
         } else {
          this.setState({
            payInProgress: false
          });

            fetch('Logging/LogErrorWhenOnPay/', {
                method: 'POST',
                body: JSON.stringify({
                    ErrorMessage: res.errors[0]?.rawErrorMessage,
                    ErrorCode: parseInt(res.errors[0]?.errorCode),
                }),
                headers: { "Content-type": "application/json", },
            })
                .then(response => {
                    return response.json();
                })

        }
      }
      catch (error) {
          this.setState({
          contractCreationError: true,
          tokenError: false
          });
        fetch('Logging/LogErrorWhenOnPay/', {
            method: 'POST',
            body: JSON.stringify({
                ErrorMessage: error.message,
                ErrorCode: 0,
                }),
            headers: { "Content-type": "application/json", },
            })
            .then(response => {
                return response.json();
            })
      }
    }
  }

	formatContracts = (tokenURL, contracts) => {
		let listLimit = 50;
		let newList = `${listLimit}; ${contracts.length}`;

		for (var i = 0; i <= listLimit - 1; i++) {
			if (i + 1 > contracts.length) {
				break;
			}

			newList += `; ${contracts[i].contractId}`;
		}

		return newList;
	}

	sendConfirmationEmail = async () => {
		const { contractIds, mailingAddress, viewModel: { dealerId, accountId } } = this.state;
		let { viewModel: { contractDetails } } = this.state;

		if (contractIds !== undefined) {
			const recipient = mailingAddress.emailAddress;

			if (recipient !== null && recipient !== undefined && recipient !== '') {
				let contractTotal = 0;
				if (Array.isArray(contractDetails) && contractDetails.length > 0) {
					let newContractList = contractDetails.filter((x) => (isNaN(parseFloat(x.contractAmount)) ? 0 : parseFloat(x.contractAmount)) > 0);

					contractDetails = newContractList;
					contractDetails.forEach(contract => {
						contractTotal += isNaN(parseFloat(contract.contractAmount)) ? 0 : parseFloat(contract.contractAmount);
					});
				}

				let contractTotalInitial = parseFloat(contractTotal);

				let email = new emailModel();
				let service = new serviceModel();
				let recipients = [recipient];
				let languageCode = this.state.languageCode.toLowerCase();
				let emailName = languageCode === "fr-ca" ? "PaymentGatewayConfirmationFrCa"
					: languageCode === "fr-us" ? "PaymentGatewayConfirmationFrUs"
						: "PaymentGatewayConfirmation";

				email.recipients = recipients
				email.cc = [];
				email.bcc = [];
				email.isBodyHtml = true;
				email.emailType = 0

				service.contractList = this.formatContracts("tempToken", contractDetails);
				service.totalAmt = contractTotalInitial;
				service.languageCode = languageCode;

				SendMailByAccount(accountId, email, service, accountId, emailName);
			}

			this.redirect(this.state.successUrl);
		}
	}



  redirect = (url) => {
    window.location.replace(url);
  }

  handleBackButton = () => {
    this.redirect(this.state.returnUrl);
  }

  getString = (value) => {
    return value != null && typeof value === "string" && value.length > 0 ? value : "";
  }

  render() {
    const { countryCode } = this.state;
    const { accountName, isTranslationsResolved, translations } = this.context;

    if (!this.state.dataLoaded)
      return (<PageLoading />);

    if (this.state?.viewModel?.languageCode !== "en" && Object.keys(translations)?.length === 0 && !(this.state.loadError || this.state.contractCreationError)) {
      return (<PageLoading />);
    }

    if (this.state.loadError || this.state.contractCreationError) {
      // TODO: Define defaults. (later story)
      var viewModel = this.state.viewModel;
      var clientName = this.getString(viewModel.clientName).length > 0 ? viewModel.clientName : "Lennox";
      var clientSupportNumber = this.getString(viewModel.clientSupportNumber).length > 0 ? viewModel.clientSupportNumber : getBrandContent(clientName).programPhone;
      var internalSupportNumber = this.getString(viewModel.internalSupportNumber).length > 0 ? viewModel.internalSupportNumber : getBrandContent(clientName).programPhone;

      return (
        <ErrorConfirmation
          isTokenError={this.state.tokenError}
          clientSupportNumber={clientSupportNumber}
          internalSupportNumber={internalSupportNumber}
          clientName={clientName}
          isCanadaState={countryCode == "CA" ? true : false}
          countryCode={countryCode}
          languageCode = {(this.state?.viewModel?.languageCode) !== undefined ? this.state?.viewModel?.languageCode : "en"}
        />
      );
    }

    return (
            <Container className="contentWrapper">
              <h1 className={`h4 ${styles.pageHeader}`}>{translations.paymentInfo || 'Payment Information'}</h1>
              <Row>
                <Col lg="8">
                  <Card>
                    <CardBody>
                      <CardDetails ref="creditCard"
                        mailingAddress={this.state.mailingAddress}
                        billingAddress={this.state.billingAddress}
                        hideSaveCard={true}
                        amount={this.state.subTotalAmount + this.state.totalTax}
                        isCanadaState={this.state.countryCode == "CA" ? true : false}
                        chargedEntityType={PaymentEntityType.Contract}
                        chargedEntityIds={this.state.contractIds}
                        partyId={this.state.partyId}
                        accountId={this.state.viewModel.accountId}
                        activateEntityOnCharge={true}
                        paymentSource={PaymentSource.PaymentGateway}
                        countryId={this.state.countryCode == "CA" ? 2 : 1}
                        translations= {this.state?.viewModel?.languageCode.toLowerCase() !== 'en' ? translations : {}}
                      />
                    </CardBody>
                  </Card>
                </Col>
                <OrderSummary 
                  subTotalAmount={this.state.subTotalAmount}
                  paymentAmount={this.state.subTotalAmount}
                  taxAmount={this.state.totalTax}
                  totalTax={this.state.totalTax}
                  pst={this.state.pst}
                  gst={this.state.gst}
                  countryCode={this.state.countryCode}
                  payCallback={this.onPay}
                  payInProgress={this.state.payInProgress}
                  displayPayButton={true} 
                  translations={
                    this.state?.viewModel?.languageCode.toLowerCase() !== "en"
                      ? translations
                      : {}
                  }
                  countryId={this.state?.viewModel?.countryId}                  
                />
              </Row>
              <div className="d-flex justify-content-left">
                <button className={classNames("btn-secondary", customPayBtn)} onClick={this.handleBackButton}>
                  {translations?.back || 'Back'}
                </button>
              </div>
            </Container>
    );
  }
}

PaymentGateway.contextType = SessionContext;