import React, { Component } from 'react';
import { Route, Redirect } from "react-router";
import ErrorConfirmation from '../ErrorConfirmation/ErrorConfirmation';
import DealerTerms from '../DealerTerms/DealerTerms';
import { DealerHome } from '../../Screens/DealerHome/DealerHome';
import CombinedContextProvider, { ContextCombined } from '../../context/CombinedContext';
import ErrorPasswordExceeded from '../ErrorConfirmation/ErrorPasswordExceeded';

class ProtectedRouteCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: props.path,
            component: props.component,
            accountName:''
        }
        this.getAccountName = this.getAccountName.bind(this);
    }
    getAccountName = () => {
        fetch('helper/getaccountbyurl')
            .then(res => res.json())
            .then(data => {
                this.setState({ accountName: data.accountName });
            })
    }

    componentDidMount = async () => {
        await this.getAccountName();
    }
    render() {
        const { component: Component, ...rest } = this.props;
        const authContext = this.context.authContext;
        const sessionContext = this.context.sessionContext;
        var needsToReaccept = sessionContext.needsToReacceptFlag();
        const bypassLoginClients = ["staples"];

        return <Route {...rest} render={(props) => {
            if (bypassLoginClients.includes(authContext.accountName.toLowerCase())) {
                // return <DealerHome />
                return <Component {...props} />;
            }
            else if (authContext.isSessionValid()) {
                if (authContext.token !== null && needsToReaccept) {
                    return <DealerTerms profileModel={sessionContext.getProfile()} />
                } else if (authContext.token !== null && !needsToReaccept) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to="/login" />
                }
            } else {
                if (sessionContext.loginExceeded == false) {
                    return <Redirect to="/login" />
                } else {
                    return <ErrorPasswordExceeded />;
                }
            }
        }} />;
    }
}
ProtectedRouteCheck.contextType = ContextCombined;

const ProtectedRoute = props => {
    return (
        <CombinedContextProvider>
            <ProtectedRouteCheck {...props} />
        </CombinedContextProvider>
    );
};

export default ProtectedRoute;