import React, { Component } from "react";
import { Container, Card, CardBody, Row, Col, Label, Input, FormGroup } from "reactstrap";
import { ReactComponent as Info } from '../../../assets/images/info-icon.svg';
import { ReactComponent as SecCode } from '../../../assets/images/Security Code.svg';
import { ReactComponent as VisaIcon } from '../../../assets/images/cc-visa.svg';
import { ReactComponent as MastercardIcon } from '../../../assets/images/cc-mastercard.svg';
import { ReactComponent as AmexIcon } from '../../../assets/images/cc-amex.svg';
import { ReactComponent as DiscoverIcon } from '../../../assets/images/cc-discover.svg';
import { DropDownAutoComplete } from '../../inputs/DropDownAutoComplete';
import { DropDownInput } from '../../inputs/DropDownInput';
import styles from "./CardDetails.module.css";
import SimpleReactValidator from 'simple-react-validator';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { CreditCardInput } from '../../inputs/CreditCardInput';
import { updatePartnerCase, createRepair, updateRepair, getTUDetailedDecision, addTUCase, getTUSessionId, getTUToken, sendEmail, addTUStatus, createNewAuthorization } from '../../../components/Global/helpers';
import { billingCodeDisplayIds, authorizationStatus } from '../../../components/Global/AdjudicationConstants';
import ReactTooltip from 'react-tooltip';
import { PaymentEntityType, PaymentSource } from '../../Global/PaymentConstants';
import NeutralInfoBadge from "../../shared/NeutralInfoBadge/NeutralInfoBadge";
import classNames from 'classnames';
import { SessionContext } from "../../../context/SessionContext";
import InfoTooltip from "../../shared/InfoTooltip/InfoTooltip";
import { getCurrencySymbol } from '../../../lib/util';


let customPayBtn = styles.customPayBtn;
let amount = styles.amount;
let defaultCustomerInfo = { firstName: '', lastName: '' }
let testContractIds = [579]

export class CardDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customer: this.props.party,
            amount: this.props.amount,
            mailingAddress: this.props.mailingAddress,
            defaultBillingStateId: null,
            visible: this.props.visible,
            states: [],
            ccNumber: '',
            ccType: 'unknown',
            ccTypeId: '',
            cardExpires: '',
            securityCode: '',
            selectedAddress: 'mailingAddress1',
            searching: false,
            verifiedAddress: null,
            addressToVerify: null,
            countryId: props.countryId,
            customerInfo: this.props.customerInfo == null ? [defaultCustomerInfo] : this.props.customerInfo,
            billingAddress: this.props.billingAddress.address1,
            billingAddress2: this.props.billingAddress.address2 && this.props.billingAddress.address2,
            billingCity: this.props.billingAddress.city,
            billingState: this.props.billingAddress.state,
            billingPostal: this.props.billingAddress.zip,
            billingStateAbbrev: this.props.billingAddress.stateAbbrev == null ? this.props.billingAddress.state : this.props.billingAddress.stateAbbrev,
            hideSaveCard: this.props.hideSaveCard == undefined || this.props.hideSaveCard == false ? false : this.props.hideSaveCard,
            errors: {
                ccInvalid: false,
                ccExpired: false,
                ccNumber: false,
                cardExpires: false,
                securityCode: false,
                billingAddress: !this.stringHasValue(this.props.billingAddress.address1),
                billingCity: !this.stringHasValue(this.props.billingAddress.city),
                billingState: !this.stringHasValue(this.props.billingAddress.state),
                billingPostal: !this.stringHasValue(this.props.billingAddress.zip),
                invalidPostal: false
            },
            paid: false,
            clientId: '',
            saveOnly: this.props.saveOnly == undefined ? false : this.props.saveOnly,
            savedCardType: this.props.savedCardType == null ? 1 : this.props.savedCardType,
            validationFired: false,
            chargedEntityType: this.props.chargedEntityType == null ? PaymentEntityType.NA : this.props.chargedEntityType,
            chargedEntityIds: this.props.chargedEntityIds == null ? testContractIds : this.props.chargedEntityIds,
            activateEntityOnCharge: this.props.activateEntityOnCharge == null ? false : this.props.activateEntityOnCharge,
            paymentSource: this.props.paymentSource == null ? PaymentSource.NA : this.props.paymentSource,
            isLennoxDealer: this.props.isLennoxDealer,
            saveCardDetails: []
        }

        this.handleStateDropDown = this.handleStateDropDown.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleAddressInputChange = this.handleAddressInputChange.bind(this);
        this.handleAddressFormat = this.handleAddressFormat.bind(this);
        this.ccTypeChanged = this.ccTypeChanged.bind(this);
        this.onUseMailingAddress = this.onUseMailingAddress.bind(this);
        this.pay = this.pay.bind(this);
        this.onSaveCardDetailsForFutureUse = this.onSaveCardDetailsForFutureUse.bind(this);
        this.getCreditCardTypeId = this.getCreditCardTypeId.bind(this);

        this.validator = new SimpleReactValidator({
            validators: {
                card_expires: {
                    message: ':attribute is invalid.',

                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^(0[1-9]|1[0-2])\/?([0-9]{2})$/) && params.indexOf(val) === -1
                    },

                    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
                    required: true
                },

                cvv: {
                    message: ':attribute is invalid.',

                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^[0-9]{3,4}$/) && params.indexOf(val) === -1
                    },

                    messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
                    required: true
                },
            },

            element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,

            messages: {
                card_num: 'Credit card number is invalid.',
                card_exp: ':attribute is invalid.',
                required: ':attribute is required.'
            },
        });
    }

    checkForValidationErrors = () => {
        var e = this.state.errors;
        let hasError = !this.validator.allValid() || e.billingAddress || e.billingCity || e.billingPostal || e.billingState || e.cardExpires
            || e.ccExpired || e.ccInvalid || e.invalidPostal || e.securityCode || !this.validator.check(this.state.ccNumber, "required") ||
            !this.validator.check(this.state.cardExpires, "required") || !this.validator.check(this.state.securityCode, "required");
        if (hasError) {
            this.setState({
                validationFired: true
            }, () => {
                this.validator.showMessages();
                this.forceUpdate();
            });
        }

        return hasError;
    }    

    componentDidMount() {
    fetch('party/states')
      .then(res => res.json())
      .then(data => {
        this.setState({
          states: data.map(s => {
            return {
              data: s.stateId,
              display: s.name,
              abbreviation: s.abbreviation,
              country: s.countryModel?.iso2,
              countryId: s.countryModel?.countryId
            }
          }).filter(s => s.countryId === this.props.countryId).sort((a, b) => {
            if (a.display < b.display) { return -1; }
            if (a.display > b.display) { return 1; }
            return 0;
          })
        }, () => {
          let billingStateAbbrev = this.state.billingStateAbbrev === null ? null : this.state.billingStateAbbrev.toUpperCase()
          let state = this.state.states.find(x => x.abbreviation.toUpperCase() === billingStateAbbrev);
          let error = this.state.errors;
          if (state != null) {
            this.setState({ billingStateId: state.data, defaultBillingStateId: state.data });
            error.billingState = false;

                        this.setState({
                            errors: error
                        });
                    }
                });
            })

        this.setState({ clientId: 'hvac' });
        this.setState({ useMailingAddress: true });
        this.setState({ SaveCardDetailsForFutureUse: false });
    }

    handleStateDropDown = e => {
        let stateId = e;
        let errors = this.state.errors;

        if (stateId > 0)
            errors.billingState = false;
        else
            errors.billingState = true;

        const stateName = this.state.states.find(({ data }) => data === stateId).display;
        this.setState({
            billingStateId: stateId,
            state: stateName,
            defaultBillingStateId: stateId,
            errors: errors
        });
    }

    handleInputChange = e => {
        let { name, value } = e.target;
        let fieldName = name;
        let errors = this.state.errors;
        let validations = "required";
        let months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        let currentMonth = months[new Date().getMonth()];
        let currentYear = new Date().getFullYear().toString().substring(2, 4);

        if (name === "ccNumber") {
            value = e.target.value;
        }

        if (name === "cardExpires") {
            value = e.target.rawValue;
        }

        switch (name) {
            case "ccNumber":
                errors["ccInvalid"] = false;
                fieldName = "credit card number";
                validations = "required|card_num";

                if ((this.state.ccType === "unknown" && value.trim().length < 15) ||
                    (this.state.ccType === "amex" && value.trim().length !== 15) ||
                    (this.state.ccType !== "amex" && this.state.ccType !== "unknown"
                        && value.trim().length !== 16)) {
                    errors["ccInvalid"] = true;
                }
                break;

            case "cardExpires":
                errors["ccExpired"] = false;
                fieldName = "expiration date";
                validations = "required|card_expires";

                if (value.length >= 4) {
                    let month = value.substring(0, 2);
                    let year = value.substring(2, 4);

                    if (year < currentYear) {
                        errors["ccExpired"] = true;
                    }

                    if (year === currentYear) {
                        if (month < currentMonth) {
                            errors["ccExpired"] = true;
                        }
                    }
                }

                break;

            case "securityCode":
                fieldName = "security code"
                validations = "required|cvv";
                break;

            default:
                break;
        }

        errors[name] = !this.validator.check(value, validations);

        this.setState({
            [name]: value,
            errors: errors
        });

        this.validator.showMessageFor(fieldName);
    }

    stringHasValue = (value) => {
        return typeof value === 'string' && value.trim() !== "";
    }

    handleAddressInputChange = e => {
        let { id, value } = e.target;
        let { errors } = this.state;

        if (id !== "billingAddress2") {
            errors[id] = value.trim() === "" ? true : false;

            if (id === "billingPostal") {
                errors.invalidPostal = errors[id];
            }

            this.setState({ errors: errors });
        }

        this.setState({ [id]: value });
    }

    handleAddressFormat = e => {
        let { id, value } = e.target;
        let fieldName = id;
        const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');
        let tempValue = value.trim();

        switch (fieldName) {
            case "billingAddress":
                tempValue = removeExtraSpace(tempValue);
                document.getElementById('billingAddress').value = tempValue;
                break;

            case "billingAddress2":
                tempValue = removeExtraSpace(tempValue);
                document.getElementById('billingAddress2').value = tempValue;
                break;

            case "billingCity":
                tempValue = removeExtraSpace(tempValue);
                document.getElementById('billingCity').value = tempValue;
                break;

            case "billingPostal":
                tempValue = removeExtraSpace(tempValue);
                document.getElementById('billingPostal').value = tempValue;
                break;

            default:
                break;
        }
    }

    ccTypeChanged(type) {
        switch (type) {
            case "visa":
                break;

            case "mastercard":
                break;

            case "amex":
                break;

            case "discover":
                break;

            default:
                type = "unknown"
                break;
        };

        this.setState(() => ({
            ccType: type
        }));
    }

    onUseMailingAddress = () => {
        const { useMailingAddress } = this.state;
        this.setState({
            useMailingAddress: !useMailingAddress
        }, () => {
            if (this.state.useMailingAddress) {

                let errors = this.state.errors;
                errors.billingState = false;
                errors.billingAddress = false;
                errors.billingCity = false;
                errors.billingState = false;
                errors.billingPostal = false;
                errors.invalidPostal = false;

                this.setState({
                    billingAddress: this.state.mailingAddress.address1,
                    billingAddress2: this.state.mailingAddress.address2 && this.state.mailingAddress.address2,
                    billingCity: this.state.mailingAddress.city,
                    billingState: this.state.mailingAddress.state,
                    billingPostal: this.state.mailingAddress.zip,
                    billingStateAbbrev: this.state.mailingAddress.stateAbbrev == null ? this.state.mailingAddress.state : this.state.mailingAddress.stateAbbrev,
                    billingStateId: this.state.defaultBillingStateId,
                    errors: errors
                });
            }
            else {
                let errors = this.state.errors;
                errors.billingAddress = !this.stringHasValue(this.state.billingAddress);
                errors.billingCity = !this.stringHasValue(this.state.billingCity);
                errors.billingState = !this.stringHasValue(this.state.billingState);
                errors.billingPostal = !this.stringHasValue(this.state.billingPostal);
                this.setState({
                    errors: errors
                });
            }
        });
    };

    onSaveCardDetailsForFutureUse = () => {
        const { SaveCardDetailsForFutureUse } = this.state;
        this.setState({ SaveCardDetailsForFutureUse: !SaveCardDetailsForFutureUse });
    };

    getCreditCardTypeId = (type) => {
        // Sets credit card type
        let ccTypeId = this.state.ccTypeId;
        switch (this.state.ccType) {
            case "visa":
                ccTypeId = "1";
                break;
            case "amex":
                ccTypeId = "2";
                break;
            case "mastercard":
                ccTypeId = "4";
                break;
            case "discover":
                ccTypeId = "3";
                break;
            default:
                break;
        }
        return ccTypeId;
    }

    pay = async e => {
        //e.preventDefault();
        // send payload to payment controller
        let month = this.state.cardExpires.substring(0, 2);
        let year = this.state.cardExpires.substring(2, 4);
        console.log(this.state);
        let response = await fetch(`payment/${this.state.clientId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                CardInfo: {
                    FullNumber: this.state.ccNumber,
                    ExpirationMonth: parseInt(month),
                    ExpirationYear: parseInt('20' + year),
                    CreditCardVerificationNumber: this.state.securityCode,
                    CardType: this.getCreditCardTypeId(this.state.ccType)
                },
                CardHolder: {
                    FirstName: this.state.customerInfo[0].customerFirstName,
                    LastName: this.state.customerInfo[0].customerLastName,
                    BillingAddress: {
                        StreetAddress: this.state.billingAddress,
                        Locality: this.state.billingCity,
                        Region: this.state.states.find(state => { return state.data == this.state.billingStateId }).display,
                        PostalCode: this.state.billingPostal.substring(0, 5),
                        ThreeLetterIsoCode: this.props.isCanadaState ? "CAN" : "USA"
                    },
                    ShippingAddress: {
                        StreetAddress: this.state.billingAddress,
                        Locality: this.state.billingCity,
                        Region: this.state.states.find(state => { return state.data == this.state.billingStateId }).display,
                        PostalCode: this.state.billingPostal.substring(0, 5),
                        ThreeLetterIsoCode: this.props.isCanadaState ? "CAN" : "USA"
                    }
                },
                RelatedTo: this.state.chargedEntityType,
                RelatedToIds: this.state.chargedEntityIds,
                CurrencyCode: this.props.isCanadaState ? "CAD" : "USD",
                CultureName: this.props.isCanadaState ? "en-CA" : "en-US",
                Amount: parseFloat(this.state.amount),
                IsPreAuthCharge: this.state.saveOnly,
                accountId: this.props.accountId,
                activateEntity: this.state.activateEntityOnCharge,
                paymentSource: this.state.paymentSource
            })
        }).then(res => {
            if (res.status === 200) {
                return res.json();
            }
            else {
                return null; //This will trigger the internal error
            }
        });

        if (response != null) {
            if (response.isSuccessful) {
                if (this.state.saveOnly || this.state.SaveCardDetailsForFutureUse) {
                    var optionSaved = await fetch(`party/SavePaymentOption`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            paymentOptionType: this.state.savedCardType,
                            gatewayTransactionId: response.gatewayTransactionId,
                            partyId: this.props.partyId,
                            clientId: this.state.clientId
                        })
                    }).then(res => {
                        return res.ok;
                    });

                    if (optionSaved) {
                        this.setState(prevState => ({
                            errors: {
                                ...prevState.errors,
                                general: false,
                                generalSaveOnly: false,
                                internal: false,
                                internalSaveOnly: false
                            }
                        }));
                    } else {
                        this.setState(prevState => ({
                            errors: {
                                ...prevState.errors,
                                internal: !this.state.saveOnly,
                                internalSaveOnly: this.state.saveOnly
                            }
                        }));
                        response.isSuccessful = false;
                    }
                }
            }
            else {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        internal: response.isInternalError,
                        general: !response.isInternalError && !this.state.saveOnly,
                        generalSaveOnly: !response.isInternalError && this.state.saveOnly
                    }
                }));
            }
        }
        else {
            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    internal: !this.state.saveOnly,
                    internalSaveOnly: this.state.saveOnly
                }
            }));
            return { isSuccessful: false }
        }
        return response;
    }


    render() {
        this.validator.purgeFields();

        let stateOptions = this.state.states;
        const { showPaymentDetails = true, children, headLabel, atAdvantageCampaign = false, showLabel = true, isCanadaState, hideCard = false, translations={} } = this.props;
        const stateLabel = isCanadaState ? 'Province' : 'State'
        const zipLabel = isCanadaState ? 'Postal' : 'Zip/Postal'
        const currency = getCurrencySymbol(this.state?.countryId);

        return (
            <>
                {showLabel && <Row className="d-md-flex justify-content-start m-0">
                <h2 className="h5 subHead">{translations?.cardDetails || (headLabel || "Card Details")}</h2>
                    {showPaymentDetails && (
                        !atAdvantageCampaign && <NeutralInfoBadge currency={currency}  text={translations?.paymentAmount || "Payment Amount"} amount={this.props.amount} />
                    )}
                </Row>}

                {children}
                {!hideCard &&
                    <>
                        <Col md="6" className="p-0">
                            <p>
                                <FormGroup className={"inputContainer " + ((this.state.validationFired && (!this.validator.check(this.state.ccNumber, "required") || this.state.errors.ccNumber || this.state.errors.ccInvalid)) ? "form-error  mb-0" : " ")}>
                                    <Label for="ccNumber" className="form-label">{translations?.ccNumber || "Credit Card Number"}</Label>
                                    <span aria-hidden="true" className="required-indicator">*</span>

                                    <CreditCardInput
                                        onCreditCardTypeChanged={this.ccTypeChanged}
                                        handleInputChange={this.handleInputChange}
                                        arialabelledby="ccNumber" autoComplete="cc-number"
                                        ariarequired="true" className="form-control"
                                        name="ccNumber" id="ccNumber"
                                    />

                                    <ul className={styles.ccImageList}>
                                        {(this.state.ccType === "visa" || this.state.ccType === "unknown") &&
                                            <li aria-hidden="true" className="visa">
                                                <span className={styles.ccImages}><VisaIcon /></span>
                                            </li>
                                        }

                                        {(this.state.ccType === "mastercard" || this.state.ccType === "unknown") &&
                                            <li aria-hidden="true" className="mastercard">
                                                <span className={styles.ccImages}><MastercardIcon /></span>
                                            </li>
                                        }

                                        {(this.state.ccType === "amex" || this.state.ccType === "unknown") &&
                                            <li aria-hidden="true" className="amex">
                                                <span className={styles.ccImages}><AmexIcon /></span>
                                            </li>
                                        }

                                        {(this.state.ccType === "discover" || this.state.ccType === "unknown") &&
                                            <li aria-hidden="true" className="discover">
                                                <span className={classNames("visuallyhidden", styles.ccImages)}><DiscoverIcon /></span>
                                            </li>
                                        }
                                    </ul>

                                    {this.state.validationFired && (!this.validator.check(this.state.ccNumber, "required")) &&
                                        ((Object.keys(translations).length === 0) ? 
                                            this.validator.message("credit card number", this.state.ccNumber, "required") :
                                            <div className="errorMsg">{this.props?.translations?.errors?.ccNumRequired}</div>
                                        )
                                    }
                                    {this.state.validationFired && this.state.errors.ccInvalid && this.state.ccNumber.trim() !== ""  && 
                                        <div className="errorMsg">
                                            {(Object.keys(translations).length !== 0) ? 
                                                this.props?.translations?.errors?.ccNumInvalid : 
                                                'Credit card number is invalid.'}
                                        </div>
                                    }

                                </FormGroup>

                                <div className="row twoColWrapper" >
                                    <Col xs="12" sm="6" className={styles.twoColWrapperXS}>
                                        <FormGroup className={((this.state.validationFired && (!this.validator.check(this.state.cardExpires, "required") || this.state.errors.cardExpires || this.state.errors.ccExpired)) ? "form-error mb-0" : "mb-0")}>
                                            <Label for="cardExpires" className="form-label">{translations?.expiry || "Expiration Date"}</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <Cleave 
                                                options={{ date: true, datePattern: ['m', 'y'] }} 
                                                aria-labelledby="cardExpires" 
                                                className="form-control" 
                                                type="text" 
                                                placeholder="MM/YY" 
                                                id="cardExpires" 
                                                name="cardExpires" 
                                                onChange={this.handleInputChange} 
                                                value={this.state.cardExpires} 
                                                onBlur={() => this.validator.showMessageFor('cardExpires')} 
                                            />
                                            {this.state.validationFired && this.state.errors.ccExpired && 
                                                <div className="errorMsg">
                                                    {(Object.keys(translations).length !== 0) ? 
                                                    translations?.errors?.expiryExpired : 
                                                    'Credit card being used has expired.'}
                                                </div>
                                            }
                                            {(this.state.validationFired && (!this.validator.check(this.state.cardExpires, "required"))) && 
                                                ((Object.keys(translations).length === 0) ? 
                                                    this.validator.message("expiration date", this.state.cardExpires, "required") :                                                     
                                                    <div className="errorMsg">{translations?.errors?.expiryRequired}</div>
                                                )
                                            }
                                            {this.state.validationFired && !this.validator.check(this.state.cardExpires, "card_expires") && this.state.cardExpires.trim() !== "" &&
                                                ((Object.keys(translations).length === 0) ? 
                                                    <div className="errorMsg">Expiration date is invalid.</div> :
                                                    <div className="errorMsg">{translations?.errors?.expiryInvalid}</div>
                                                )                                                
                                            }
                                        </FormGroup>
                                    </Col>

                                    <Col xs="12" sm="6">
                                        <FormGroup className={((this.state.validationFired && (!this.validator.check(this.state.securityCode, "required") || this.state.errors.securityCode || this.state.errors.securityCodeInvalid)) ? "form-error mb-0" : "mb-0")}>
                                            <Label for="securityCode" className="form-label">{translations?.securityCode || "Security Code"}</Label>
                                            <span aria-hidden="true" className="required-indicator">*</span>

                                            <div className={styles.codeHasTooltip}>
                                                <div className={styles.secCodeInfo}>
                                                    <InfoTooltip
                                                        iconType="question"
                                                        message={translations?.securityInfo || " 3 or 4 digit number on back of card (front of American Express)"}
                                                        icon={<SecCode />} />
                                                </div>
                                                <Cleave options={{ numericOnly: true, blocks: [4] }}
                                                    aria-labelledby="securityCode"
                                                    className="form-control"
                                                    id="securityCode"
                                                    name="securityCode"
                                                    maxLength={4}
                                                    onChange={this.handleInputChange}
                                                    value={this.state.securityCode}
                                                    onBlur={() => this.validator.showMessageFor('securityCode')} />
                                            </div>

                                            {(this.state.validationFired && !this.validator.check(this.state.securityCode, "required")) && 
                                                ((Object.keys(translations).length === 0) ? 
                                                    <div className="errorMsg">Security code is required.</div> :
                                                    <div className="errorMsg">{translations?.errors?.cvvRequired}</div>
                                                )                                                
                                            }
                                            {this.state.validationFired && !this.validator.check(this.state.securityCode, "cvv") && this.state.securityCode.trim() !== "" &&
                                                ((Object.keys(translations).length === 0) ? 
                                                    <div className="errorMsg">Security code is invalid.</div> :
                                                    <div className="errorMsg">{translations?.errors?.cvvInvalid}</div>
                                                )                                                
                                            }
                                        </FormGroup>
                                    </Col>
                                </div>
                                {!this.state.hideSaveCard &&
                                    <div className="checkbox mb-0">
                                        <input className="mr-2" type="checkbox" id="saveCardDetails" checked={this.state.SaveCardDetailsForFutureUse} onChange={this.onSaveCardDetailsForFutureUse} />
                                        <label for="saveCardDetails" className={classNames("checkboxLabel", this.context.accountName)}>Save my card details for future purchases</label>
                                    </div>
                                }
                            </p>
                        </Col>

                        <Col md="6"></Col>

                        <p className={styles.formLabel} for="billingAdd">{translations?.billingAddress || "Billing Address"}</p>
                        <span aria-hidden="true" className="required-indicator">*</span>

                        <div className="checkbox mb-2" id="billingAdd">
                            <input className="mr-2" type="checkbox" id="usingMailingAddress" checked={this.state.useMailingAddress} onChange={this.onUseMailingAddress} />
                            <label for="usingMailingAddress" className={classNames("checkboxLabel", this.context.accountName)}>{translations?.useMailing || "Use my Mailing Address"}</label>
                        </div>

                        {
                            this.state.useMailingAddress ? (
                                <p className="mb-0">
                                    {this.state.mailingAddress.address1}
                                    {this.state.mailingAddress.address2 != '' && this.state.mailingAddress.address2}
                                    <br />{this.state.mailingAddress.city + "," + this.state.mailingAddress.state + " " + this.state.mailingAddress.zip}
                                </p>

                            ) : (
                                <Col md="6" className="p-0">
                                    <FormGroup className={this.state.validationFired && this.state.errors.billingAddress ? "form-error" : ""}>
                                        <label htmlFor="billingAddress" className="form-label">
                                            {translations?.address1 || "Street Address"}                                   
                                        </label>
                                        <span aria-hidden="true" className="required-indicator">*</span>

                                        <Input className="form-control"
                                            type="text"
                                            id="billingAddress"
                                            maxLength={150}
                                            onBlur={this.handleAddressFormat}
                                            onChange={this.handleAddressInputChange}
                                            value={this.state.billingAddress}
                                        />

                                        {this.state.validationFired && this.state.errors.billingAddress === true && 
                                            <div className="errorMsg">
                                                {translations?.errors?.address1Required || "Street address is required."}
                                            </div>
                                        }
                                    </FormGroup>

                                    <FormGroup>
                                        <Label className="form-label" for="billingAddress2">
                                            {translations?.address2 || "Apt., Suite, Building"}     
                                        </Label>
                                        <Input className="form-control"
                                            type="text"
                                            id="billingAddress2"
                                            aria-labelledby="Apt Number"
                                            maxLength={50}
                                            onBlur={this.handleAddressFormat}
                                            onChange={this.handleAddressInputChange}
                                            value={this.state.billingAddress2}
                                        />
                                    </FormGroup>

                                    <FormGroup className={this.state.validationFired && this.state.errors.billingCity ? "form-error" : ""}>
                                        <label for="billingCity" className="form-label">
                                            {translations?.city || "City"}
                                        </label>
                                        <span aria-hidden="true" className="required-indicator">*</span>

                                        <Input className="form-control"
                                            type="text"
                                            id="billingCity"
                                            maxLength={50}
                                            onBlur={this.handleAddressFormat}
                                            onChange={this.handleAddressInputChange}
                                            value={this.state.billingCity}
                                        />

                                        {this.state.validationFired && this.state.errors.billingCity === true && 
                                            <div className="errorMsg">
                                                {translations?.errors?.cityRequired || "City is required."}
                                            </div>
                                        }
                                    </FormGroup>

                                    <Row className="twoColWrapper mb-0">
                                        <Col sm="6" className={styles.twoColWrapperXS}>
                                            <FormGroup className={this.state.validationFired && this.state.errors.billingState ? "form-error mb-0" : "mb-0"}>
                                                <label for="billingState" className="form-label"> 
                                                    {translations?.stateProvince || stateLabel}  
                                                </label>
                                                <span aria-hidden="true" className="required-indicator">*</span>

                                                {stateOptions &&
                                                    <DropDownAutoComplete
                                                        name={'billingState'}
                                                        options={stateOptions}
                                                        className="form-control"
                                                        onChangedValue={this.handleStateDropDown}
                                                        onBlur={this.handleStateDropDown}
                                                        placeholder="Please select..."
                                                        value={this.state.billingStateId}
                                                    />}

                                                {this.state.validationFired && this.state.errors.billingState && 
                                                    <div className="errorMsg">
                                                        {translations?.errors?.stateRequired || `${stateLabel} is required.`}
                                                    </div>
                                                }
                                            </FormGroup>
                                        </Col>

                                        <Col sm="6">
                                            <FormGroup 
                                                className={(this.state.validationFired && 
                                                (this.state.errors.billingPostal || 
                                                    this.state.errors.invalidPostal ||
                                                    this.state.billingPostal.trim().length < 5)
                                                ) ? 
                                                "form-error mb-0" : "mb-0"}
                                            >
                                                <label for="billingPostal" className="form-label">
                                                    {translations?.zip || zipLabel}  
                                                </label>
                                                <span aria-hidden="true" className="required-indicator">*</span>

                                                <Cleave className="form-control" options={{
                                                    numericOnly: !isCanadaState
                                                }} id="billingPostal"
                                                    maxLength={10}
                                                    value={this.state.billingPostal}
                                                    onBlur={this.handleAddressFormat}
                                                    onChange={this.handleAddressInputChange} />

                                                {this.state.validationFired && this.state.errors.billingPostal === true && 
                                                    <div className="errorMsg">
                                                        {translations?.errors?.postalRequired || `${zipLabel} is required.`}
                                                    </div>
                                                }
                                                {this.state.validationFired && this.state.billingPostal.trim().length < 5 && this.state.billingPostal.trim() !== "" && 
                                                    <div className="errorMsg">
                                                        {translations?.errors?.postalInvalid || `Please enter a valid postal code.`}
                                                    </div>
                                                }
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        }

                        {
                            this.state.errors.billingAddressMatch &&
                                <div className="validationSummary mt-3">
                                    {translations?.errors?.billingAddressMatch || "Billing address does not match the cards records."}
                                </div>
                        }

                        {
                            this.state.errors.funds &&
                                <div className="validationSummary mt-3">
                                    {translations?.errors?.funds || "Unfortunately there are not enough funds available on this card. Please try paying with a different card."}
                                </div>
                        }

                        {
                            this.state.errors.internal &&
                                <div className="validationSummary mt-3">
                                    {translations?.errors?.internal || "Something went wrong processing your payment. This issue was on our side, not yours. Your card was not charged, please try again later."}
                                </div>
                        }

                        {
                            this.state.errors.declined &&
                                <div className="validationSummary mt-3">
                                    {translations?.errors?.declined || "Credit card entered was declined, please try paying with a different credit card."}
                                </div>
                        }

                        {
                            this.state.errors.general &&
                                <div className="validationSummary mt-3">
                                    {translations?.errors?.tryAgain || "There was an issue processing your payment. Please try again."}
                                </div>
                        }

                        {
                            this.state.errors.generalSaveOnly &&
                                <div className="validationSummary mt-3">
                                    {translations?.errors?.internalSaveError || "Something went wrong saving your card. Please try again using a different card."}
                                </div>
                        }

                        {
                            this.state.errors.internalSaveOnly &&
                                <div className="validationSummary mt-3">
                                    {translations?.errors?.internalSaveError || "Something went wrong saving your card. The issue is on our side, not yours. Please try again later."}
                                </div>
                        }

                        {
                            this.state.paid &&
                                <div className="form-label">Payment was successful.</div>
                        }
                    </>
                }
                </>
            
           
        );
    }
}

CardDetails.contextType = SessionContext