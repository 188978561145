import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { ReactComponent as YesIcon } from '../../../../assets/images/checkmark.svg';
import styles from './PlanOptions.module.css';
import { Row } from "reactstrap";
import classnames from 'classnames';
import { getBrandContent } from '../../../BrandingWhiteLabel/BrandContent';
import { useContext } from "react";
import { SessionContext } from "../../../../context/SessionContext";

const coverageTypeTable = styles.coverageTypeTable;

const YesIconComp = () => {
  return (
    <div className={styles.yesIcon}><YesIcon /></div>
  )
};

const CoverageType = () => {

  const context = useContext(SessionContext)
  const { accountName } = context;
  const accountNameToLower  = accountName.toLowerCase();

  let screenRecords = getBrandContent("coveragetype");  
  let coverageTypeColumnsAll = [];
  let coverageTypeDataAll = [];

  if((screenRecords[accountNameToLower]?.plan1?.length ?? 0) === 3) {
    coverageTypeColumnsAll = [
      {
        dataField: "planBenefits",
        text: "Plan Benefits*"
      },
      {
        dataField: "laborOnly",
        text: "Labor Only"
      },
      {
        dataField: "parts",
        text: "Parts"
      }
    ];  
  
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan1) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan1[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan1[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan1[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan1[1],
        parts: (screenRecords[accountNameToLower]?.plan1[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan1[2],
      });
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan2) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan2[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan2[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan2[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan2[1],
        parts: (screenRecords[accountNameToLower]?.plan2[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan2[2],
      });
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan3) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan3[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan3[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan3[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan3[1],
        parts: (screenRecords[accountNameToLower]?.plan3[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan3[2],
      });
  }  
  
  if(((screenRecords[accountNameToLower]?.plan1?.length ?? 0) > 3) && ((screenRecords[accountNameToLower]?.plan1?.length ?? 0) < 5)) {
    coverageTypeColumnsAll = [
      {
        dataField: "planBenefits",
        text: "Plan Benefits*"
      },
      {
        dataField: "laborOnly",
        text: "Labor Only"
      },
      {
        dataField: "laborPlus",
        text: "Labor PLUS"
      },
      {
        dataField: "parts",
        text: "Parts"
      }
    ];  
  
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan1) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan1[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan1[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan1[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan1[1],
        laborPlus: (screenRecords[accountNameToLower]?.plan1[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan1[2],
        parts: (screenRecords[accountNameToLower]?.plan1[3] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan1[3],
      });
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan2) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan2[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan2[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan2[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan2[1],
        laborPlus: (screenRecords[accountNameToLower]?.plan2[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan2[2],
        parts: (screenRecords[accountNameToLower]?.plan2[3] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan2[3],
      });
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan3) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan3[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan3[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan3[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan3[1],
        laborPlus: (screenRecords[accountNameToLower]?.plan3[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan3[2],
        parts: (screenRecords[accountNameToLower]?.plan3[3] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan3[3],
      });
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan4) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan4[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan4[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan4[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan4[1],
        laborPlus: (screenRecords[accountNameToLower]?.plan4[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan4[2],
        parts: (screenRecords[accountNameToLower]?.plan4[3] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan4[3],
      });
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan5) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan5[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan5[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan5[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan5[1],
        laborPlus: (screenRecords[accountNameToLower]?.plan5[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan5[2],
        parts: (screenRecords[accountNameToLower]?.plan5[3] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan5[3],
      });
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan6) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan6[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan6[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan6[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan6[1],
        laborPlus: (screenRecords[accountNameToLower]?.plan6[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan6[2],
        parts: (screenRecords[accountNameToLower]?.plan6[3] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan6[3],
      });
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan7) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan7[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan7[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan7[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan7[1],
        laborPlus: (screenRecords[accountNameToLower]?.plan7[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan7[2],
        parts: (screenRecords[accountNameToLower]?.plan7[3] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan7[3],
      });
  }  

  if(screenRecords[accountNameToLower]?.plan1.length >= 5) {
    coverageTypeColumnsAll = [
      {
        dataField: "planBenefits",
        text: "Plan Benefits*"
      },
      {
        dataField: "laborOnly",
        text: "Labor Only"
      },
      {
        dataField: "laborPlus",
        text: "Labor PLUS"
      },
      {
        dataField: "partsLabor",
        text: "Parts & Labor PLUS"
      },
      {
        dataField: "parts",
        text: "Parts"
      }
    ];
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan1) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan1[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan1[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan1[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan1[1],
        laborPlus: (screenRecords[accountNameToLower]?.plan1[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan1[2],
        partsLabor: (screenRecords[accountNameToLower]?.plan1[3] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan1[3],
        parts: (screenRecords[accountNameToLower]?.plan1[4] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan1[4],
      });
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan2) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan2[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan2[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan2[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan2[1],
        laborPlus: (screenRecords[accountNameToLower]?.plan2[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan2[2],
        partsLabor: (screenRecords[accountNameToLower]?.plan2[3] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan2[3],
        parts: (screenRecords[accountNameToLower]?.plan2[4] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan2[4],
      });
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan3) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan3[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan3[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan3[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan3[1],
        laborPlus: (screenRecords[accountNameToLower]?.plan3[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan3[2],
        partsLabor: (screenRecords[accountNameToLower]?.plan3[3] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan3[3],
        parts: (screenRecords[accountNameToLower]?.plan3[4] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan3[4],
      });
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan4) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan4[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan4[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan4[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan4[1],
        laborPlus: (screenRecords[accountNameToLower]?.plan4[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan4[2],
        partsLabor: (screenRecords[accountNameToLower]?.plan4[3] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan4[3],
        parts: (screenRecords[accountNameToLower]?.plan4[4] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan4[4],
      });
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan5) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan5[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan5[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan5[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan5[1],
        laborPlus: (screenRecords[accountNameToLower]?.plan5[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan5[2],
        partsLabor: (screenRecords[accountNameToLower]?.plan5[3] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan5[3],
        parts: (screenRecords[accountNameToLower]?.plan5[4] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan5[4],
      });
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan6) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan6[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan6[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan6[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan6[1],
        laborPlus: (screenRecords[accountNameToLower]?.plan6[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan6[2],
        partsLabor: (screenRecords[accountNameToLower]?.plan6[3] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan6[3],
        parts: (screenRecords[accountNameToLower]?.plan6[4] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan6[4],
      });
    (screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.plan7) && 
      coverageTypeDataAll.push({
        planBenefits: (screenRecords[accountNameToLower]?.plan7[0] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan7[0],
        laborOnly: (screenRecords[accountNameToLower]?.plan7[1] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan7[1],
        laborPlus: (screenRecords[accountNameToLower]?.plan7[2] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan7[2],
        partsLabor: (screenRecords[accountNameToLower]?.plan7[3] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan7[3],
        parts: (screenRecords[accountNameToLower]?.plan7[4] === "yes") ? (<YesIconComp />) : screenRecords[accountNameToLower]?.plan7[4],
      });
  }


  return (
    <>
      {(accountNameToLower === "ge") 
        ? <p>Plan options will vary depending on the product and {getBrandContent(accountName).pricingLevelName.toLowerCase()} selected.</p>
        : <p>Plan Options will vary depending on the product and {getBrandContent(accountName).pricingLevelName.toLowerCase()} selected.</p>
      }
      
      <div className={classnames(coverageTypeTable, "m-lg-0")}>
        <BootstrapTable
          keyField="planBenefits"
          data={coverageTypeDataAll}
          columns={coverageTypeColumnsAll}
          bordered={true}
          bootstrap4
          wrapperClasses="table-responsive-lg"
        />
      </div>
      {accountNameToLower === "lennox" ? (
        <p className="mt-3 mb-0">
          *Depending on the plan purchased, benefits will vary. Limitations and
          exclusions apply. Please see the full terms and conditions for further
          details.
        </p>
      ) : (
          <p className="mt-3 mb-0">
            *Depending on the plan purchased, benefits will vary. Limitations and
            exclusions apply. Please see the full terms and conditions for further
            details.
        </p>
        )}
    </>
  );
}

export default CoverageType