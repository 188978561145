import React, { useState, useContext, useEffect } from 'react';
import { Row, Col, FormGroup, Label } from "reactstrap"
import styles from './Claim.module.css'
import classNames from 'classnames';
import StandardInput from '../shared/StandardInput';
import NotesField from '../shared/NotesField/NotesField';
import CalendarInput from "../shared/CalendarInput/CalendarInput";
import DateField from "../shared/DateField/DateField";
import { Controller } from "react-hook-form";
import { useClaimStoreForm } from './useClaimStoreForm';
import moment from 'moment';
import { SessionContext } from '../../context/SessionContext';

const ClaimInvoiceForm = ({
    formProps, contract
}) => {

    const { setInvoiceNumber,
        setInvoiceDate,
        setFailureDate,
        setServiceCompletionDate,
        setFailureDateCheck,
        setServiceCompletionDateCheck,
        setOnsiteTextAreaValue,
        setResolutionTextAreaValue
    } = useClaimStoreForm();

    const [forceUpdate, setForceUpdate] = useState(false);

    const {
        register,
        control,
        setValue,
        trigger,
        watch,
        formState: { errors },
    } = formProps;

    const invoiceNumber = watch('invoiceNumber');
    const invoiceDate = watch('invoiceDate');
    const failureDate = watch('failureDate');
    const serviceCompletionDate = watch('serviceCompletionDate');
    const failureDateCheck = watch('failureDateCheck');
    const onsiteTextAreaValue = watch('onsiteTextAreaValue');
    const resolutionTextAreaValue = watch('resolutionTextAreaValue');
    const context = useContext(SessionContext);
    const { tab, subtab } = context.getCurrentTabAndSubtab();
    const { activeSubtab } = tab
    const { accountName, getCurrentTabAndSubtab } = context;
    const minDate = new Date();

    useEffect(() => {
        const subscription = watch((value) => {
            context.tabDispatch('UPDATE_SUBTAB', {
                subtabId: activeSubtab,
                claimInvoiceFormData: value
            })
        });
        return () => subscription.unsubscribe(); // Cleanup on unmount
    }, [watch])

    useEffect(() => {
        setValue('invoiceNumber', subtab?.claimInvoiceFormData?.invoiceNumber || "");
        setValue('failureDate', subtab?.claimInvoiceFormData?.failureDate || "")
        setValue('serviceCompletionDate', subtab?.claimInvoiceFormData?.serviceCompletionDate || "")
        setValue('failureDateCheck', subtab?.claimInvoiceFormData?.failureDateCheck || "")
        setValue('onsiteTextAreaValue', subtab?.claimInvoiceFormData?.onsiteTextAreaValue || "")
        setValue('resolutionTextAreaValue', subtab?.claimInvoiceFormData?.resolutionTextAreaValue || "")
    }, [tab?.tabId])

    return (
        <Row>
            <Col sm="12" md="10">
                <Row>
                    <Col sm="3" className='pr-0'>
                        <Controller
                            control={control}
                            defaultValue={""}
                            name="invoiceNumber"
                            rules={{
                                required: 'Invoice number is required.',
                            }}
                            ref={register}
                            render={({ field, fieldState, formState }) => (
                                <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                    <StandardInput
                                        hasError={fieldState.error?.message}
                                        label="Invoice Number"
                                        required
                                        fieldName="invoiceNumber"
                                        ariaLabelAndFieldId="invoiceNumber"
                                        value={invoiceNumber}
                                        onChange={(e) => {
                                            setInvoiceNumber(e.target.value)
                                            setValue('invoiceNumber', e.target.value)
                                            trigger('invoiceNumber');
                                        }
                                        }
                                        validator={fieldState.error?.message}
                                    />
                                </FormGroup>
                            )}
                        />
                    </Col>
                    <Col sm="3" className='pr-0'>
                        <Controller
                            control={control}
                            defaultValue={""}
                            name="invoiceDate"
                            ref={register}
                            rules={{
                                required: 'Invoice date is required.',
                            }}
                            render={({ field, fieldState, formState }) => (
                                <FormGroup className={classNames(!!fieldState.error && styles.errorClass)}>
                                    <Label for="phonePreference" className="form-label">Invoice Date</Label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <DateField
                                        id="invoiceDate"
                                        name="invoiceDate"
                                        contract={contract}
                                        placeHolder={contract?.contract?.country === 'CA' ? "DD/MM/YYYY" : "MM/DD/YYYY"}
                                        disabled={true}
                                        onDateChange={(e) => {
                                            setInvoiceDate(e)
                                            setValue('invoiceDate', e)
                                            trigger('invoiceDate');
                                        }
                                        }
                                        value={invoiceDate}
                                    />
                                    <div class="errorMsg">{fieldState.error?.message}</div>
                                </FormGroup>
                            )}
                        />
                    </Col>
                    <Col sm="3" className='pr-0'>
                        <Controller
                            control={control}
                            defaultValue={""}
                            name="failureDate"
                            ref={register}
                            rules={{
                                required: 'Failure date is required.',
                                validate: (val, formVals) => {
                                    if (contract?.contract?.country === 'CA') {
                                        return moment(val, 'DD/MM/YYYY').isAfter(moment(formVals?.serviceCompletionDate, 'DD/MM/YYYY')) ? 'Service completion cannot be prior to failure date.' : null;
                                    }
                                    return moment(val).isAfter(moment(formVals?.serviceCompletionDate)) ? 'Service completion cannot be prior to failure date.' : null;
                                }
                            }}
                            render={({ field, fieldState, formState }) => (
                                <FormGroup className={!!fieldState.error && 'form-error'}>
                                    <Label for="failureDate" className="form-label">Failure Date</Label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <DateField
                                        id="failureDate"
                                        name="failureDate"
                                        contract={contract}
                                        placeHolder={contract?.contract?.country === 'CA' ? "DD/MM/YYYY" : "MM/DD/YYYY"}
                                        onDateChange={(e) => {
                                            setValue('failureDate', e);
                                            trigger('failureDate');
                                            trigger('failureDateCheck');
                                            setFailureDate(e)
                                            if (e !== serviceCompletionDate) {
                                                setServiceCompletionDate(false);
                                                setValue('failureDateCheck', false);
                                            }
                                            if (serviceCompletionDate) {
                                                trigger('serviceCompletionDate');

                                            }
                                        }
                                        }
                                        value={failureDate}
                                        maxDate={minDate}
                                        emptyDate={true}
                                    />
                                    <div class="errorMsg">{fieldState.error?.message}</div>
                                </FormGroup>
                            )}
                        />
                    </Col>
                    <Col sm="3" className='pr-0'>
                        <Controller
                            control={control}
                            defaultValue={""}
                            name="serviceCompletionDate"
                            ref={register}
                            rules={{
                                required: 'Service completion date is required.',
                                validate: (val, formVals) => {
                                    if (contract?.contract?.country === 'CA') {
                                        return moment(val, 'DD/MM/YYYY').isBefore(moment(formVals?.failureDate, 'DD/MM/YYYY')) ? 'Service completion cannot be prior to failure date.' : null;
                                    }
                                    return moment(val).isBefore(moment(formVals?.failureDate)) ? 'Service completion cannot be prior to failure date.' : null;
                                }
                            }}
                            render={({ field, fieldState, formState }) => (
                                <FormGroup className={`mb-2 ${!!fieldState.error && 'form-error'}`}>
                                    <Label for="serviceCompletionDate" className="form-label">Service Completion Date</Label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <DateField
                                        id="serviceCompletionDate"
                                        name="serviceCompletionDate"
                                        contract={contract}
                                        placeHolder={contract?.contract?.country === 'CA' ? "DD/MM/YYYY" : "MM/DD/YYYY"}
                                        onDateChange={(e) => {
                                            setServiceCompletionDate(e);
                                            if (e !== failureDate) {
                                                setFailureDateCheck(false);
                                                setValue('failureDateCheck', false);
                                            }
                                            setValue('serviceCompletionDate', e)
                                            trigger('serviceCompletionDate');
                                            trigger('failureDate');
                                            trigger('failureDateCheck');
                                        }
                                        }
                                        value={field?.value}
                                        maxDate={minDate}
                                    />
                                    <div class="errorMsg">{fieldState.error?.message}</div>
                                </FormGroup>
                            )}
                        />
                        <div className="checkbox mb-2">
                            <Controller
                                control={control}
                                defaultValue={""}
                                name="failureDateCheck"
                                ref={register}
                                render={({ field, fieldState, formState }) => (
                                    <>

                                        <input
                                            className="mr-2"
                                            type="checkbox"
                                            name="failureDateCheck"
                                            id="failureDateCheck"
                                            onChange={(e) => {
                                                setFailureDateCheck(e.target.checked)
                                                setValue('failureDateCheck', e.target.checked)
                                                if (e.target.checked) {
                                                    setServiceCompletionDate(failureDate)
                                                    setValue('serviceCompletionDate', failureDate)
                                                } else {
                                                    setServiceCompletionDate('')
                                                    setValue('serviceCompletionDate', '')
                                                }
                                                trigger('failureDate');
                                                trigger('failureDateCheck');
                                                trigger('serviceCompletionDate');
                                            }
                                            }
                                            checked={failureDateCheck}
                                        />
                                        <Label for="failureDateCheck" className={classNames("checkboxLabel", accountName)}>
                                            Same as failure date
                                        </Label>
                                    </>
                                )}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col sm="6" className='pr-0'>
                        <Controller
                            control={control}
                            defaultValue={""}
                            name="onsiteTextAreaValue"
                            {...register('onsiteTextAreaValue', {
                                required: 'Onsite diagnostic is required.'
                            })}
                            render={({ field, fieldState, formState }) => (
                                <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                    <NotesField
                                        autoHeight={true}
                                        label="Onsite Diagnostic"
                                        required={true}
                                        fullWidth={true}
                                        formItemName="onsiteTextAreaValue"
                                        placeholder={"Enter Description"}
                                        value={onsiteTextAreaValue}
                                        onChange={(e) => {
                                            setOnsiteTextAreaValue(e)
                                            setValue('onsiteTextAreaValue', e)
                                            trigger('onsiteTextAreaValue');
                                        }
                                        }
                                        infoMessage="Provide a detailed description of the product failure."
                                    />
                                    <p className={classNames(styles.errorMargin, "errorMsg")}>{fieldState.error?.message}</p>
                                </FormGroup>
                            )}
                        />
                    </Col>
                    <Col sm="6">
                        <Controller
                            control={control}
                            defaultValue={""}
                            name="resolutionTextAreaValue"
                            {...register('resolutionTextAreaValue', {
                                required: 'Resolution is required.'
                            })}
                            render={({ field, fieldState, formState }) => (
                                <FormGroup className={`${fieldState?.error && 'form-error'}`}>
                                    <NotesField
                                        autoHeight={true}
                                        label="Resolution"
                                        formItemName="resolutionTextAreaValue"
                                        fullWidth={true}
                                        required={true}
                                        maxLength={250}
                                        placeholder={"Enter Description"}
                                        value={resolutionTextAreaValue}
                                        onChange={(e) => {
                                            setResolutionTextAreaValue(e);
                                            setValue('resolutionTextAreaValue', e)
                                            trigger('resolutionTextAreaValue');
                                        }
                                        }
                                    />
                                    <p className={classNames(styles.errorMargin, "errorMsg")}>{fieldState.error?.message}</p>
                                </FormGroup>
                            )}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ClaimInvoiceForm;