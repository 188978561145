import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import styles from "./RFSForm.module.css";

const nonCoveredRepairInfo = {
    "Dishwasher": [
        "Baskets",
        "Rollers",
        "Racks",
        "Cosmetic issues (e.g., scratches, dents and chipping)",
    ],
    "Range/Oven/Stove": [
        "Clocks",
        "Meat probe assemblies",
        "Rotisseries",
        "Racks",
        "Handles",
        "Knobs",
        "Cosmetic issues (e.g., scratches, dents, chipping, breakage to oven door, or breakage to glass/ceramic cooktop)",
    ],
    "Dryer": [
        "Venting",
        "Knobs",
        "Dials",
        "Damage to clothing",
        "Lint screens",
        "Dryer cabinet fragrance or humidity center",
        "Cosmetic issues (e.g., scratches, dents and chipping)",
    ],

    "Garbage Disposal": [
        "Problems and/or jams caused by bones or foreign objects other than food",
    ],
    "Electrical Systems": [
        "Fixtures",
        "Electrical panel",
        "Attic or whole house exhaust fans",
        "Doorbells",
        "Intercom systems",
        "Alarm systems",
        "Central vacuum systems",
        "Audio/video/computer wiring or cable",
        "Direct current wiring and systems",
        "Exterior wiring and components",
        "Telephone wiring",
        "Inadequate wiring capacity",
        "Power failure, shortage or surge",
        "Low voltage systems",
        "Load control devices",
        "Electrical generation systems",
        "Solar electrical systems",
        "Timers",
        "Touch pad assemblies",
        "Remote controls",
        "Failure caused by circuit overload",
    ],
    "Kitchen Exhaust Fan": [
        "Rooftop exhaust units",
        "Filters",
        "Cosmetic issues (e.g., scratches, dents, and chipping)",
    ],
    "Microwave": [
        "Countertop units",
        "Door glass",
        "Clocks",
        "Rotisseries",
        "Interior linings",
        "Cosmetic issues (e.g., scratches, dents, and chipping)",
    ],
    "Refrigerator": [
        "Ice makers and controls",
        "Food spoilage",
        "Media centers",
        "Cosmetic issues (e.g., scratches, dents, and chipping)",
    ],
    "Washer": [
        "Removable mini-tubs/buckets",
        "Soap dispensers",
        "Filter screens",
        "Knobs",
        "Dials",
        "Damage to clothing",
        "Water flow restrictions due to mineral deposits",
        "Drawers",
        "Cosmetic issues (e.g., scratches, dents, and chipping)",
    ],
    "Freezer": [
        "Ice makers and controls",
        "Food spoilage",
        "Media centers",
        "Cosmetic issues (e.g., scratches, dents and chipping)",
    ],
    "A/C System": [
        'Coolant replenishment in excess of 2 pounds if unrelated to a repair',
        'Commercial systems',
        'Gas-powered systems',
        'Geothermal/indirect coil systems',
        'Water or electric lines to/from central cooling system, including condensate pumps and related components',
        'Distribution systems such as air ducts, filters, air handlers or diverters, registers, pipes or radiators',
        'Portable units, swamp coolers, dehumidifiers or humidifiers',
        'Individual window, wall or rooftop units',
        'Chimneys, flues or vents',
        'Cosmetic coverings including doors, panels, trim, flushing, insulation or baseboard casings',
        `Electronics, internet-enabled or 'smart' components or functions (i.e. zone controllers)`,
        'Cooling units configured not to code or in line with manufacturer instructions',
        'Incidental or consequential damages',
        'Bringing up to code, upgrading or problems that existed at the time the member first enrolled',
    ],
    "ET Electrical Systems": [
        'Electric supplies over 400 amps',
        'Commercial, mixed use or any electric lines whose ownership is in doubt',
        'External fixtures and appliances',
        'Non-Co-Op/home-generation or home-storage connections',
        'Non-copper wiring',
        'Security systems',
        'Moving furniture or other objects to get to the repair site',
        'Any wiring or other electrical items located 12 feet above ground level outside the perimeter of the principal dwelling or garage',
        'Low-voltage or solar systems',
        'Data or audio cables',
        `Internet enabled or 'smart' functions`,
        'Incidental or consequential damages',
        'Bringing up to code, upgrading or problems that existed at the time the member first enrolled',
        'The meter is presumed Co-Op property and the member will be directed to call the Co-Op if it fails'
    ],
    "Electric Vehicle Charger": [
        'Electric wiring other than the point of connection to the charger, fuse boxes or other items for which the Electric Line Protection coverage is available',
        'Items not owned by the homeowner',
        'Items other than Level 2 electric vehicle chargers',
        'Items damaged outside the home',
        'Items lost or stolen',
        'Electric vehicles or components thereof',
        'Chargers used to charge items other than electric vehicles, or not in accordance with the vehicle manual',
        'Incidental or consequential damages beyond the benefits specified',
        'Bringing up to code, upgrading or problems that existed at the time the member first enrolled'
    ],
    "Surge Protection": [
        'Electric wiring, fuse boxes or other items for which the Electric Line Protection coverage is available',
        'Items not properly certified that loss was due to a surge event',
        'Items damaged outside the home such as AC systems, pools, dog fences, surveillance equipment, etc.',
        'Restoration of data, software or programming',
        'Electric vehicles and their connections',
        'Items of less than $100 value',
        'Incidental or consequential damages beyond the benefits specified',
        'Bringing up to code, upgrading or problems that existed at the time the member first enrolled',
    ],
    "Interior Electric Line Constellation": [
        'Electric services that are rated more than 400 amps',
        'Losses or costs that are covered under any other warranty, guarantee, service contract or insurance policy',
        'Losses that are subject to a manufacturer’s recall',
        'Losses caused by Your failure to follow or adhere to proper operation, care, or maintenance instructions',
        'Any pre-existing conditions or damages that occurred prior to the coverage start date',
        'Unauthorized modifications, alterations, or enhancements',
        'Non-copper wiring',
        'Any low-voltage system or systems dedicated to the transmission of data or signal through wiring, including wiring for speakers, garage doors, low-voltage lighting or fans, whole-home vacuums, cable television, phones, computers, and security systems',
        'Fixtures or appliances (with or without a plug) that are external to the Interior Electric, including external lighting not affixed to the side of the residence, animal fencing systems, ice melting systems, external security systems, and any external connections from your service address to a pool, HVAC unit, or other external appliance of fixture',
        'Software, remote, wireless, or internet enabled functions',
        'Extension cords, plugs, and fuses within plugs',
        'Utility meters and surge protectors',
        'Damages to nonfunctional or aesthetic parts and cosmetic damage that does not impact the proper operation or functionality, including scratches, peeling, discoloration, dents, and chips',
        'Cleaning, periodic checkups, and preventive maintenance',
        'Periphery or incidental costs arising from or relating to any needed installation, set-up, removal, or disposal services, including the costs of opening, removing, or restoring walls or floors, countertops, cabinets, or similar fixtures in or around Your Covered Product and the disposal of hazardous or toxic material',
        'Loss or damage caused by any type of abnormal or improper use, abuse, misuse, neglect, willful, or reckless misconduct',
        'Incidental, consequential, or secondary damages, including loss of use, lost profits, and any damages arising from delays in requesting or rendering service, repairs, or reimbursement',
        'Damage caused by packing, unpacking, assembly, installation, or removal',
        'Damage caused by external factors  such as rust, corrosion, warping, bending, animals, animal inhabitation or insect infestation',
        'Damage caused by improper removal or installation of replaceable components, modules, parts or peripheral devices or installation of incorrect parts',
        'Any cost associated with making space suitable for installation including modifications to flooring, walls, or cabinetry',
        'Loss or damage caused by external causes of any kind, including war, invasion, rebellion, riot, strike, labor disturbance, lockout, civil commotion, fire, theft, vandalism, animals, exposure to weather, windstorm, sand, dirt, hail, earthquake, flood water, or acts of God',
        'Costs arising from or relating to the upgrade or modification of parts, components, or equipment due to incompatibility with existing systems, service, or utility lines at the service address or to meet changes in federal, state, or local codes or regulations',
        'Loss of use of, damage to, corruption of, inability to access, or inability to manipulate any computer system, electronic hardware, or software, or components thereof, that are used to store, process, access, transmit, or receive information within the product as result of any cause or loss other than covered losses that are expressly stated in this Plan, including any unauthorized access or unauthorized use of such system, a denial of service attack, or receipt or transmission of malicious code',
        'Loss of use of, damage to, corruption of, inability to access, or inability to manipulate any electronic data, including any such loss caused by unauthorized access or unauthorized use of such data, a denial of service attack, or receipt or transmission of malicious code'
        ],
    "Electric Vehicle Charger Constellation": [
        'Losses or costs that are covered under any other warranty, guarantee, service contract or insurance policy',
        'Losses that are subject to a manufacturer’s recall',
        'Losses caused by Your failure to follow or adhere to proper operation, care, or maintenance instructions',
        'Any pre-existing conditions or damages that occurred prior to the coverage start date',
        'Unauthorized modifications, alterations, or enhancements',
        'Any low-voltage system or systems dedicated to the transmission of data or signal through wiring, including wiring for speakers, garage doors, low-voltage lighting or fans, whole-home vacuums, cable television, phones, computers, and security systems',
        'Software, remote, wireless, or internet enabled functions',
        'Extension cords, plugs, and fuses within plugs',
        'Utility meters and surge protectors',
        'Damages to nonfunctional or aesthetic parts and cosmetic damage that does not impact the proper operation or functionality, including scratches, peeling, discoloration, dents, and chips',
        'Cleaning, periodic checkups, and preventive maintenance',
        'Periphery or incidental costs arising from or relating to any needed installation, set-up, removal, or disposal services, including the costs of opening, removing, or restoring walls or floors, countertops, cabinets, or similar fixtures in or around Your Covered Product and the disposal of hazardous or toxic material',
        'Loss or damage caused by any type of abnormal or improper use, abuse, misuse, neglect, willful, or reckless misconduct',
        'Incidental, consequential, or secondary damages, including loss of use, lost profits, and any damages arising from delays in requesting or rendering service, repairs, or reimbursement',
        'Damage caused by packing, unpacking, assembly, installation or removal',
        'Damage caused by external factors other than covered ADH for electric vehicle chargers, such as rust, corrosion, warping, bending, animals, animal inhabitation or insect infestation',
        'Damage caused by improper removal or installation of replaceable components, modules, parts or peripheral devices or installation of incorrect parts',
        'Any cost associated with making space suitable for installation including modifications to flooring, walls, or cabinetry',
        'Loss or damage caused by external causes of any kind, other than covered ADH for electric vehicle chargers, including war, invasion, rebellion, riot, strike, labor disturbance, lockout, civil commotion, fire, theft, vandalism, animals, exposure to weather, windstorm, sand, dirt, hail, earthquake, flood water, or acts of God',
        'Costs arising from or relating to the upgrade or modification of parts, components, or equipment due to incompatibility with existing systems, service, or utility lines at the service address or to meet changes in federal, state, or local codes or regulations',
        'Loss of use of, damage to, corruption of, inability to access, or inability to manipulate any computer system, electronic hardware, or software, or components thereof, that are used to store, process, access, transmit, or receive information within the product as result of any cause or loss other than covered losses that are expressly stated in this Plan, including any unauthorized access or unauthorized use of such system, a denial of service attack, or receipt or transmission of malicious code',
        'Loss of use of, damage to, corruption of, inability to access, or inability to manipulate any electronic data, including any such loss caused by unauthorized access or unauthorized use of such data, a denial of service attack, or receipt or transmission of malicious code',
    ]
};

export const RepairLimitInfo = ({ isOpen, toggle, centered }) => {
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className="modal-md"
            centered={centered}>
            <ModalHeader>
                <h4 className="modal-title">Total Repair Limit</h4>
            </ModalHeader>
            <ModalBody>
                <p>
                    Authorization is required if the cost will exceed the Total
                    Repair Limit.
                </p>
                <p>
                    Repairs exceeding the limit where no authorization is
                    obtained, will result in non-payment of repair. To obtain
                    repair authorization, please call
                    <a href="tel:866-523-8649" className="callLink ml-1 mr-1">
                        (866) 523-8649
                    </a>
                    during business hours 8:00 AM to 6:00 PM EST Monday -
                    Friday.
                </p>
            </ModalBody>
            <ModalFooter>
                <div className="btnWrapper">
                    <button className="btnLink" onClick={toggle}>
                        Close
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export const NonCoveredRepairsInfo = ({
    isOpen,
    toggle,
    centered,
    incidentData,
    clientId
}) => {

    const nonCoveredProductName = (data, clientId) => {
        let nonCoveredProductName;

         switch(data?.productName) {
            case 'Electrical Systems': 
                switch(clientId?.toLowerCase()) {
                    case 'et':
                        nonCoveredProductName = "ET Electrical Systems";
                        break;
                }
                break;
            case 'Interior Electric Line':
            case 'Electric Vehicle Charger':
                switch(clientId?.toLowerCase()) { 
                    case 'constellation':
                        nonCoveredProductName = `${data?.productName} Constellation`;
                        break;
                    default:
                        nonCoveredProductName =   data?.productName;
                }
                break;
            default:
                nonCoveredProductName =   data?.productName;
        }

        return nonCoveredProductName;
    } 

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
            className="modal-md"
            centered={centered}>
            <ModalHeader>
                <h4 className="modal-title">
                    {incidentData.productName} Non-Covered Repairs
                </h4>
            </ModalHeader>
            <ModalBody>
                <ul className={`${styles.nonCoveredRepairTopics} mb-1`}>
                    {nonCoveredRepairInfo[nonCoveredProductName(incidentData,clientId)]?.map(
                        (item, index) => {
                            return (
                                <li key={index}>
                                    <span>{item} </span>
                                </li>
                            );
                        },
                    )}
                </ul>
            </ModalBody>
            <ModalFooter>
                <div className="btnWrapper">
                    <button className="btnLink" onClick={toggle}>
                        Close
                    </button>
                </div>
            </ModalFooter>
        </Modal>
    );
};
