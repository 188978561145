import React, {useState, useEffect, useContext, useReducer} from 'react';
import { Container, Card, CardBody, Row, Col, FormGroup, Label, Button, Input } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from "react-bootstrap-table2-paginator";
import classNames from "classnames";
import styles from "./PriceTool.module.css";
import { SessionContext } from "../../context/SessionContext";
import { DropDownAutoComplete } from "../../components/inputs/DropDownAutoComplete";
import MultiDropDown from '../shared/MultiDropDown/MultiDropDown'
import PageLoading from "../shared/PageLoading/PageLoading";
import AlertBanner from '../shared/AlertBanner/AlertBanner';
import { ReactComponent as CopyIcon } from "../../assets/images/Vendor/Staples/copy.svg";
import { ReactComponent as ExportIcon } from "../../assets/images/export.svg";
import SpinnerButton from "../shared/SpinnerButton/SpinnerButton";
import InfoTooltip from "../shared/InfoTooltip/InfoTooltip";

const PriceTool = () => {    
    const context = useContext(SessionContext);
    const { accountName } = context;
    const [programs, setPrograms] = useState([]);
    const [programCategories, setProgramCategories] = useState([]);
    const [retailPrices, setRetailPrices] = useState([]);
    const [retailPricesData, setRetailPricesData] = useState([]);
    const [allDistinctRetailFilters, setAllDistinctRetailFilters] = useState([]);
    const [terms, setTerms] = useState([]);
    const [termsData, setTermsData] = useState([]);
    const [repairTypes, setRepairTypes] = useState([]);
    const [repairTypesData, setRepairTypesData] = useState([]);    
    const [coverages, setCoverages] = useState([]);
    const [program, setProgram] = useState();
    const [programCategory, setProgramCategory] = useState();
    const [retailPrice, setRetailPrice] = useState([]);
    const [term, setTerm] = useState([]);
    const [repairType, setRepairType] = useState([]);
    const [coverage, setCoverage] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [keyField, setKeyField] = useState("skuNumber");
    const [defaultSortedBy, setDefaultSortedBy] = useState(
        [
            {
                dataField: "skuNumber",
                order: "asc",
            },
        ]
    );
    const [selectedMulipleRow, setSelectedMultipleRow] = useState([]);
    const [selectedMultipleRowData, setSelectedMultipleRowData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [copiedValue, setCopiedValue] = useState("");
    const [validationCheck, setValidationCheck] = useState(false);
    const [paging, setPaging] = useState(15);
    const [filterOptions, setFilterOptions] = useState([]);
    const [clearStatus, setClearStatus] = useState(false);
    const [sortData, setSortData] = useState({ sortField: 'retailRange', sortOrder: 'asc' });
    const [showLoader, setShowLoader] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [categoryDisabled, setCategoryDisabled] = useState(true);
    const [listDisabled, setListDisabled] = useState(true);
    const [showSpinner, setShowSpinner] = useState(false);
    const [noResultsFound, setNoResultsFound] = useState(false);

    const options = {
        custom: true,
        activePageNumber: 1,
        sizePerPage: 15,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        pageStartIndex: 1,
        totalSize: searchResults.length,
        sizePerPageList: [
            { text: "15 Rows", value: 15 },
            { text: "25 Rows", value: 25 },
            { text: "50 Rows", value: 50 },
            { text: "100 Rows", value: 100 }
        ]
    };

    const searchColumns = [
        {
            dataField: "retailRange",
            text: "Product Retail Range",
            sort: true,
            headerFormatter: headerFormatter,
            headerStyle: widthFormatter('rangeCol'),
            style: widthFormatter('rangeCol')
        },
        {
            dataField: "term",
            text: "Term",
            sort: true,
            headerFormatter: headerFormatter,
            headerStyle: widthFormatter('termCol'),
            style: widthFormatter('termCol')
        },
        {
            dataField: "repairType",
            text: "Repair Type",
            sort: true,
            headerFormatter: headerFormatter,
            headerStyle: widthFormatter('repairCol'),
            style: widthFormatter('repairCol')
        },
        {
            dataField: "coverage",
            text: "ADH Coverage",
            sort: true,
            headerFormatter: headerFormatter,
            headerStyle: widthFormatter('coverageCol'),
            style: widthFormatter('coverageCol')
        },
        {
            dataField: "description",
            text: "Description",
            sort: true,
            headerFormatter: headerFormatter,
            headerStyle: widthFormatter('descriptionCol'),
            style: widthFormatter('descriptionCol')
        },
        {
            dataField: "retail",
            text: "Plan Retail",
            sort: true,
            headerFormatter: headerFormatter,
            headerStyle: widthFormatter('retailCol'),
	        formatter: (col, row) => {
                return (
                    <span>${parseFloat(col).toFixed(2)}</span>
                )
            },
            style: (cell, row, rowIndex, colIndex) => {
                return ({ width: widthFormatter('retailCol'), textAlign: 'right', marginRight: '10px' })
            },
        },
        {
            dataField: "skuNumber",
            text: "SKU Number",
            sort: true,
            headerFormatter: headerFormatter,
            headerStyle: widthFormatter('skuCol'),
            style: widthFormatter('skuCol')
        },
        {
            dataField: "skuNumber",
            text: "",
            sort: false,
            formatter: (col, row) => {
                return (
                    <span className={styles.copyIcon}>
                        <CopyIcon className={styles.copy} onClick={() => { handleSKUCopy(row) }}/>
                    </span>
                );
            },
            headerStyle: widthFormatter('copyCol'),
            style: widthFormatter('copyCol'),
        }
    ];

    const selectRow = {
        mode: "checkbox",
        clickToSelect: true,
        classes: "",
        onSelect: handleSelect,
        onSelectAll: handleOnSelectAll,
        selected: selectedMulipleRow,
        style: { backgroundColor: "#efefef" },
        selectionRenderer: ({ mode, checked, disabled }) => (
            <div className={classNames("checkbox", "mb-0")}>
                <Input className="mr-2" type="checkbox" checked={checked} />
                <Label className={classNames("checkboxLabel", accountName)}></Label>
            </div>
        ),
        selectionHeaderRenderer: ({ mode, checked, disabled }) => (
            <div className={classNames("checkbox", "mb-0")}>
                <Input className="mr-2" type="checkbox" checked={checked} />
                <Label className={classNames("checkboxLabel", accountName)}></Label>
            </div>
        ),
    };

    function widthFormatter(width) {
        const widths = {
            rangeCol: '20%',
            termCol: '6%',
            repairCol: '14%',
            coverageCol: '14%',
            descriptionCol: '17%',
            retailCol: '12%',
            skuCol: '15%',
            copyCol: '2%'
        }
    
        return {
            width: widths[width],
        }
    };

    function headerFormatter(column, index, { sortElement, filterElement }) {
        let { order } = sortElement.props;
        const textWithHtml = `<b>Depot:</b> Product shipped in for repair service<br />
                                <b>Onsite:</b> Servicer will repair product on location in \"less than 10 days\"<br />
                                <b>Next Day Onsite:</b> Servicer will repair or diagnose product on location in the next business day<br />
                                <b>Credit:</b> Customer will be reimbursed or product cost via account credit`;
        const msg = <span dangerouslySetInnerHTML={{ __html: textWithHtml }} />;
        return (            
            <span className={`${order === "asc" ? styles.ascending : ""} ${order === "desc" ? styles.descending : ""}`}>
                {column.text}
                {column.dataField === "repairType" && 
                    <>
                        <span className='ml-1'>
                            <InfoTooltip 
                                iconType="info" 
                                message={msg}
                                cssClass={styles.retailType}
                                cssTextClass={styles.retailTypeInfoText}
                            />
                        </span>
                    </>
                }
                {column.dataField === "retail" && 
                    <>
                        <span className='ml-1'>
                            <InfoTooltip 
                                iconType="info" 
                                message="Product Retail Price cannot be lower than cost."
                                cssClass={styles.retailType}
                            />
                        </span>
                    </>
                }
            </span>
        );
    }

    const handleSKUCopy = (row) => {
        setCopiedValue(row.skuNumber);
        navigator.clipboard.writeText(row.skuNumber)
            .then(() => {
                setShowAlert(true);
            })
            .catch(err => {
                console.error('Failed to copy ===>>> ', err);                
                setShowAlert(false);
            });
        setShowAlert(true);
    }

    function handleSelect(row, isSelect, rowIndex, e) {
        if (isSelect) {
            setSelectedMultipleRow([...selectedMulipleRow, row.skuNumber]);
            setSelectedMultipleRowData([...selectedMultipleRowData, row]);
            setCsvData([...selectedMultipleRowData, row]);
        } else {
            setSelectedMultipleRow([...selectedMulipleRow].filter(x => x !== row.skuNumber));
            setSelectedMultipleRowData([...selectedMultipleRowData].filter(x => x.skuNumber !== row.skuNumber));
            setCsvData([...selectedMultipleRowData].filter(x => x.skuNumber !== row.skuNumber));
        }
    }

    const handleOnSelectAll = (isSelect, rows) => {
        console.log("handleOnSelectAll -> isSelect, rows ===>>> ", isSelect, rows);
    }

    const seupFilterOptions = async () => {
        try {
            setShowLoader(true);
            await fetch('account/GetAssetsByAccountId/45', {
                method: 'get',
                headers: {
                    "Content-Type": "application/json"
                },
            })
            .then(res => {
                if(res.status === 200) {
                    res.json().then(data => {
                        setFilterOptions(data);
                        const results = data.map((rec, index) => ({
                            data: rec.programId,
                            display: rec.programName,
                            id: index
                        }));
                        setPrograms(results);
                        setShowLoader(false);
                    });
                }
            })
        } catch (error) {
            console.log("Error ===>>> ", error);
        }
    }

    const handleProgramNameChange = (val) => {
        setProgram(val);
        setClearStatus(false);
        setCategoryDisabled(false);

        filterOptions.map((rec, index) => {
            if(rec.programId === val) {
                const categories = rec.assets.map((item, i) => ({
                    data: item.assetName,
                    display: item.assetName,
                    id: i
                }));
                const categoriesSorted = categories.sort((a,b) => {
                    if(a.display < b.display) return -1;
                    if(a.display > b.display) return 1;
                    return 0;
                })
                setProgramCategories(categoriesSorted);
            }
        });
    }

    const handleProgramCategoryChange = (val) => {
        setProgramCategory(val);
        setValidationCheck(true);
        setListDisabled(false);
        const defaultSelection = [];
        filterOptions.filter(fo => fo.programId === program).map((rec, index) => {
            const assets = rec.assets.find(a => a.assetName.toLowerCase() === val.toLowerCase());
            
            if (assets != null && typeof assets != "undefined") {
                let retails = assets?.retailRanges.map((item, i) => ({
                    display: item,
                    value: item
                }));
                retails.unshift({display: "Select All", value: "all"});
                let retailsData = assets?.retailRanges.map((item, i) => (item));
                retailsData.unshift("all");

                let repairTypes = assets?.repairTypes.map((item, i) => ({
                    display: item.repairTypeName,
                    value: item.repairTypeName
                }));
                repairTypes.unshift({display: "Select All", value: "all"});
                let repairTypesData = assets?.repairTypes.map((item, i) => (item.repairTypeName));
                repairTypesData.unshift("all");

                const coverages = assets?.coverages.map((item, i) => ({
                    data: item.coverageName,
                    display: item.coverageName,
                    id: i
                }));

                let terms = assets?.terms.map((item, i) => ({
                    data: item.termName,
                    display: item.termName,
                    value: item.termName,
                    id: i
                }));
                terms.unshift({display: "Select All", value: "all"});
                let termsData = assets?.terms.map((item, i) => (item.termName));
                termsData.unshift("all");

                setRetailPrices(retails);
                setRetailPricesData(retailsData);
                setAllDistinctRetailFilters(retailsData);
                setTerms(terms);
                setTermsData(termsData);
                setRepairTypes(repairTypes);
                setRepairTypesData(repairTypesData);
                setCoverages(coverages);

                setRetailPrice(defaultSelection);
                setTerm(defaultSelection);
                setRepairType(defaultSelection);
                setCoverage("");
            }
        });        
    }

    const handleRetailPrice = (val) => {
        let tempArray = [...retailPrice];
        const index = tempArray.indexOf(val);
        if (index > -1) {
            if (val === 'all') {
                tempArray = [];
            } else {
                tempArray.splice(index, 1);
                if (tempArray.indexOf('all') > -1) {
                    tempArray.splice(tempArray.indexOf('all'), 1);
                }
            }
        } else {
            if (val === 'all') {
                tempArray = [...retailPricesData];
            } else {
                tempArray.push(val);
                if (tempArray.length === retailPrices.length - 1) {
                    tempArray.push('all');
                }
            }
        }        
        setRetailPrice(tempArray);
    }

    const handleTerm = (val) => {
        let termArray = [...term];
        const index = termArray.indexOf(val);
        if (index > -1) {
            if (val === 'all') {
                termArray = [];
            } else {
                termArray.splice(index, 1);
                if (termArray.indexOf('all') > -1) {
                    termArray.splice(termArray.indexOf('all'), 1);
                }
            }
        } else {
            if (val === 'all') {
                termArray = [...termsData];
            } else {
                termArray.push(val);
                if (termArray.length === terms.length) {
                    termArray.push('all');
                }
            }
        }
        setTerm(termArray);
    }

    const handleRepairType = (val) => {
        let repairArray = [...repairType];
        const index = repairArray.indexOf(val);
        if (index > -1) {
            if (val === 'all') {
                repairArray = [];
            } else {
                repairArray.splice(index, 1);
                if (repairArray.indexOf('all') > -1) {
                    repairArray.splice(repairArray.indexOf('all'), 1);
                }
            }
        } else {
            if (val === 'all') {
                repairArray = [...repairTypesData];
            } else {
                repairArray.push(val);
                if (repairArray.length === repairTypes.length - 1) {
                    repairArray.push('all');
                }
            }
        }
        setRepairType(repairArray);
    }

    const handleCoverage = (val) => {
        setCoverage(val);
    }

    const handleClear = () => {
        setProgram("");
        setProgramCategory("");
        setRetailPrice([]);
        setTerm([]);
        setRepairType([]);
        setCoverage("");
        setValidationCheck(false);
        setSearchResults([]);
        setCategoryDisabled(true);
        setListDisabled(true);
    }

    const handleSearch = (e) => {
        const tempRetailPrice = retailPrice.filter(item => item !== "all");
        const tempTerm = term.filter(item => item !== "all");
        const tempRepairType = repairType.filter(item => item !== "all");
        try {
            setShowLoader(true);
            setShowSpinner(true);
            fetch('pricing/SalesLookUp', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    programId: program,
                    filterProductCategory: programCategory,
                    FilterRetailRange: tempRetailPrice.toString(),
                    FilterTermName: tempTerm.toString(),
                    FilterRepairType: tempRepairType.toString(),
                    FilterCoverage: coverage
                })
            }).then(res => {
                if (res.status === 204) {                    
                    setShowLoader(false);
                    setShowSpinner(false);
                    return false;
                } else if(res.status === 200) {
                    res.json().then(data => {
                        setSearchResults(data);
                        setShowLoader(false);
                        setShowSpinner(false);
                        if(data.length === 0) {
                            setNoResultsFound(true);
                        } else {
                            setNoResultsFound(false);
                        }
                    });                    
                }
            });
        } catch(error) {
            console.log("error ===>>> ", error);
        }

    }

    const handleExport = async () => {
        var route = "";
        const fileDownload = "export-priceList";
        var param = "";
        let exportAll = false

        if (csvData.length != 0) {
            param = csvData.map(d => d.skuNumber);
            exportAll = false;
        }
        else {
            param = searchResults.map(d => d.skuNumber);
            exportAll = true;
        }
        route = `pricing/ExportSelectedSalesLookupSkus/${program}/${programCategory}/${exportAll}`;

        var res = await fetch(route, {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(param),
        });

        var data = await res.blob();
        if (data != undefined) {
            const file = new Blob([data], { type: 'text/csv' });
            const fileURL = window.URL.createObjectURL(file);

            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.download = fileDownload;
            fileLink.click();

        } else {
            console.log("Error fetching file.");
            return undefined;
        }
    }

    useEffect(() => {
        seupFilterOptions();
    }, []);

    return (
        <>
            {showAlert && <AlertBanner
                key={copiedValue}
                content="SKU Number copied to clipboard"
                hasClose={true}
                onClose={() => setShowAlert(false)}
                bannerType="info"
                hasLink={false}
                show={showAlert}
            />}
            <Container className="contentWrapper">
                <h1 className="h3">Plan Pricing</h1>
                <Card>
                    <CardBody>
                        <Row className="multiColWrapper">
                            <Col md="3" className="col-5ths">
                                <FormGroup>
                                    <Label className="form-label" aria-label="Program Name" for="programName">
                                        Program Name
                                    </Label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <DropDownAutoComplete
                                        name="programName"
                                        id="programName"
                                        options={programs}
                                        onChangedValue={handleProgramNameChange}
                                        value={program}
                                        defaultValue="Please select..."
                                        clientId="staples"
                                        isTransform={true}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3" className="col-5ths">
                                <FormGroup>
                                    <Label className="form-label" aria-label="Program Category" for="programCategory">
                                        Program Category
                                    </Label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <DropDownAutoComplete
                                        name="programCategory"
                                        id="programCategory"
                                        options={programCategories}
                                        onChangedValue={handleProgramCategoryChange}
                                        value={programCategory}
                                        defaultValue="Please select..."
                                        disabled={categoryDisabled}
                                        clientId="staples"
                                        isTransform={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="multiColWrapper">
                            <Col md="3" className={classNames("col-5ths", styles.multiSelect)}>
                                <FormGroup>
                                    <Label className="form-label" aria-label="Retail Range" for="retailRange">
                                        Retail Range
                                    </Label>
                                    <MultiDropDown
                                        options={retailPrices}
                                        onOptionClick={handleRetailPrice}
                                        selected={retailPrice}
                                        borderless={false}
                                        allSelectedOption="All Selected ..."
                                        hasSearch={true}
                                        disabled={listDisabled}
                                        clientId="staples"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3" className="col-5ths">
                                <FormGroup>
                                    <Label className="form-label" aria-label="Term" for="term">
                                        Term
                                    </Label>
                                    <MultiDropDown
                                        options={terms}
                                        onOptionClick={handleTerm}
                                        selected={term}
                                        borderless={false}
                                        allSelectedOption="All Selected ..."
                                        hasSearch={false}
                                        disabled={listDisabled}
                                        clientId="staples"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3" className="col-5ths">
                                <FormGroup>
                                    <Label className="form-label" aria-label="Repair Type" for="repairType">
                                        Repair Type
                                    </Label>
                                    <MultiDropDown
                                        options={repairTypes}
                                        onOptionClick={handleRepairType}
                                        selected={repairType}
                                        borderless={false}
                                        allSelectedOption="All Selected ..."
                                        hasSearch={false}
                                        disabled={listDisabled}
                                        clientId="staples"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3" className="col-5ths">
                                <FormGroup>
                                    <Label className="form-label" aria-label="Coverage" for="coverage">
                                        ADH Coverage
                                    </Label>
                                    <DropDownAutoComplete
                                        name="coverage"
                                        id="coverage"
                                        options={coverages}
                                        onChangedValue={handleCoverage}
                                        value={coverage}
                                        defaultValue="Please select..."
                                        disabled={listDisabled}
                                        isTransform={true}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row className="multiColWrapper">
                            <Col className="d-flex justify-content-end">
                                <Button 
                                    className="btn-secondary mr-2" 
                                    onClick={handleClear}                                
                                >
                                    Clear
                                </Button>
                                <SpinnerButton
                                    onClick={handleSearch}
                                    disabled={!validationCheck}
                                    spinning={showSpinner}
                                    styleType="primary"
                                    text="Search"
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                
                <Card className="mt-2">
                    <ToolkitProvider
                        keyField={keyField}
                        columns={searchColumns || []}
                        bootstrap4
                        id="recTable"
                        defaultSorted={defaultSortedBy}
                        data={searchResults || []}
                        ref={(n) => (this.toolkit = n)}
                    >
                        {(props) => (
                            <PaginationProvider
                                pagination={ paginationFactory(options) }
                            >
                                {
                                    ({
                                        paginationProps,
                                        paginationTableProps
                                    }) => (
                                    <CardBody>
                                        <Row className={classNames("pb-2", styles.rowDrdopdown)}>
                                            <Col sm="8"></Col>
                                            <Col sm="4" className={classNames(styles.priceTableTopContainer)}>
                                                <>
                                                    <div>Rows per page: </div>
                                                    <div className={styles.customDropDown}>
                                                        <SizePerPageDropdownStandalone 
                                                            {...paginationProps}
                                                        />
                                                    </div>
                                                    <div onClick={handleExport} className={styles.export}>
                                                        <ExportIcon /><span className='ml-2'>Export</span>
                                                    </div>
                                                </>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <>                                
                                                    <BootstrapTable
                                                        remote={false}
                                                        id="priceListTable"
                                                        classes={styles.pricingTable}
                                                        defaultSorted={defaultSortedBy}
                                                        keyField={keyField}
                                                        data={searchResults || []}
                                                        columns={searchColumns || []}
                                                        bordered={false}
                                                        selectRow={selectRow}
                                                        {...paginationTableProps}
                                                    />
                                                    <div>
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                    </div>
                                                </>
                                            </Col>
                                        </Row>
                                        {(searchResults.length === 0 && !noResultsFound) && <Row>
                                            <Col className={styles.searchText}>
                                                <label >Select a category to view results.</label>
                                            </Col>
                                        </Row>}
                                        {noResultsFound && <Row>
                                            <Col className={styles.searchText}>
                                                <label>No plans are available with the current search criteria.</label>
                                            </Col>
                                        </Row>}
                                    </CardBody>)
                                }
                            </PaginationProvider>
                        )}                      
                    </ToolkitProvider>
                </Card>            
            </Container>        
        </>
    );
}

export default PriceTool;