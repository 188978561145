import React, { useState, useEffect }  from "react";
import { ReactComponent as CoverageIcon } from '../../../../assets/images/coverage-period.svg';
import { ReactComponent as TenYearCoverageIcon } from '../../../../assets/images/Vendor/Rheem/10yrs-coverage-pp.svg';
import styles from './PlanOptions.module.css';
import { useContext } from "react";
import { SessionContext } from "../../../../context/SessionContext";
import { getBrandContent } from '../../../BrandingWhiteLabel/BrandContent';

const SelectATerm = () => {  

  const context = useContext(SessionContext);
  const { accountName } = context;
  const currentBrand = getBrandContent(accountName);
  const profile = context.getProfile();
  const [laborOptions, setLaborOptions] = useState({digit: 10, text: "Ten"});
  const [partsOptions, setPartsOptions] = useState({digit: 10, text: "Ten"});
  const [warrantyOptions, setWarrantyOptions] = useState(null);

  const loadWarrantyOptions = () => {
    const accountId = profile?.accountId;

    fetch("Account/getWarrantyOptions/" + accountId).then((res) => {
      if (res.ok) {
        res.json().then((warrantyOptions) => {
          setWarrantyOptions(warrantyOptions);
          const sortedMfg = warrantyOptions?.partsOptions?.sort(
            (a, b) => parseFloat(b) - parseFloat(a)
          );

          const sortedMfgLabor = warrantyOptions?.laborOptions?.sort(
            (a, b) => parseFloat(b) - parseFloat(a)
          );

          const parts = sortedMfg?.[0] ?? 10;
          const labour = sortedMfgLabor?.[0] ?? 10;
          
          setPartsOptions({
            digit: parts,
            text: digitToString(parts)
          });
          setLaborOptions({
            digit: labour,
            text: digitToString(labour)
          });
          
        });
      }
    });
  };

  const digitToString = (n) => {
    if(n < 0) return false;

    const singleDigit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const doubleDigit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen'];
    let word = "";

    if(n === 0) return 'Zero';

    if(n < 10) {
      word = singleDigit[n]; 
    } else if(n < 16) {
      word = doubleDigit[n - 10]; 
    }

    return word;
  }

  useEffect(() => {
    loadWarrantyOptions();
  },[]);

  return (
    <>
      {accountName.toLowerCase() === "lennox" || accountName.toLowerCase() === "homedepot" ? (
        <>
          <p>
            Terms range from three to {laborOptions.text.toLowerCase()} years from the installation date*.
            Term options will vary depending on the product, {currentBrand.pricingLevelName.toLowerCase()} and plan
            selected.
          </p>
          <CoverageIcon className={styles.coverageIcon} />
          <p className="mb-0">
            <span className={styles.note}>*</span> {laborOptions.text} year terms are only
            available for DLSC equipment.
          </p>
        </>
      ) : (
        <>        
          <p>
            Terms range from three to {laborOptions.text.toLowerCase()} years from the installation date. 
            Term options will vary depending on the product, {currentBrand.pricingLevelName.toLowerCase()} and plan type.
          </p>
          {laborOptions.digit === 10 && <TenYearCoverageIcon className={styles.coverageIcon} />}
          {laborOptions.digit === 12 && <CoverageIcon className={styles.coverageIcon} />}
        </>
      )}
  </>
  );
}

export default SelectATerm