import React, { Component, Fragment } from "react";
import { Row, Col, FormGroup, Card, CardBody } from "reactstrap";
import { DropDownAutoComplete } from '../inputs/DropDownAutoComplete';
import styles from './AdvantageCampaigns.module.css';
import { PlanOptions } from '../Sales/PlanDetails/PlanOptions';
import { ReactComponent as CalendarIcon } from '../../assets/images/calendar.svg';
import { ReactComponent as ConfirmCheck } from '../../assets/images/circlecheck-ADV.svg';
import { CardDetails } from '../Sales/PaymentInformation/CardDetails';
import Calendar from 'react-calendar';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import classNames from "classnames";
import SimpleReactValidator from 'simple-react-validator';
import { PaymentOptionType, PaymentEntityType } from '../Global/PaymentConstants';
import { SessionContext } from "../../context/SessionContext";
import { getBrandContent } from "../BrandingWhiteLabel/BrandContent";
import BrandedIcon from '../../components/BrandingWhiteLabel/BrandedIcon'
import CheckboxInput from "../shared/CheckboxInput/CheckboxInput";
import SpinnerButton from "../shared/SpinnerButton/SpinnerButton";
import indefinite from 'indefinite';

const advSessionStorage = "advSessionStorage";

let dateInput = "dateInput";
let formControl = "form-control";
let daysBetweenStartAndEndDates = 31;

export class AdvantageCampaigns extends Component {

  constructor(props) {
    super(props);
    let sessionValue = sessionStorage.getItem(advSessionStorage);

    if (sessionValue != null) {
      sessionValue = JSON.parse(sessionValue);
      this.state = sessionValue.stateValue;
    }
    else {
      this.state = {
        createCampaign: false,
        startDate: "",
        endDate: "",
        modalOpen: false,
        tempDate: '',
        showCalendar: null,
        campaignEndDate: undefined,
        campaignEndDateTemp: '',
        showEndDate: false,
        showContactInput: false,
        showConfirmMessage: false,
        programid: '',
        dealerId: props.dealerId,
        excludeRncflag: '',
        createdBy: '',
        accountId: props.accountId,
        country: props.country,
        advantageName: props.advantageName,
        dealerProgramId: props.dealerProgramId,
        contactMeChecked: false,
        isTermShown: false,
        phone: props.phone,
        email: props.email,
        pristineState: false,
        phoneError: false,
        repeatPhoneError: false,
        emailError: false,
        fieldBlankError: false,
        termsAndConditionChecked: false,
        validationFired: false,
        planState: {
          selectedLabor: undefined,
          selectedCoverage: undefined,
          selectedYears: undefined
        },
        dealerName: props.dealerName,
        preferencePhone: true,
        termsAndConditionsPath: props.termsAndConditionsPath ? this.createFileUrl(props.termsAndConditionsPath) : '',
        earliestStartDate: moment().add(1, 'days'),
        dateFormatString: this.isCanada() ? "DD/MM/YYYY" : "MM/DD/YYYY",
        dateRegex: this.isCanada() ? /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/ : /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/,
        isAutoPay: false,
        saveCampaign: false,
        savedCard: props.savedCard || {},
        triggerLaborReimbursementEvent: false,
        showTerms: false
      }
    }

    this.validator = new SimpleReactValidator({
      element: message => <div className={classNames(styles.contactTypes, "errorMsg pl-0")}>
        {message.charAt(0).toUpperCase() + message.slice(1)}
      </div>,
      messages: {
        required: ':attribute is required.',
        min: ':attribute is required.',

      },
      validators: {
        isTrue: {
          message: "You must agree to the Terms and Conditions to continue.",
          rule: (val) => {
            return val === true;
          }
        },
        isOptionCheck: {
          message: ':attribute.',
          rule: (val) => {
            return typeof (val) == "boolean";
          },
          required: true
        }
      }
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (JSON.stringify(this.state) !== JSON.stringify(prevState)) {
      this.updateAdvSessionStorage();
    }
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps.phone != this.state.phone || prevProps.email != this.state.email)
      this.setState({
        phone: prevProps.phone,
        email: prevProps.email,
      })
  }

  onExcludeRNCChange = (flag) => {
    this.setState({ excludeRncflag: flag.target.value == 'true' });
  }

  onTypeRadioChange = (newData) => {
    this.setState({ ...newData });
  }

  onTermRadioChange = (newData) => {
    this.setState({ ...newData });
  }

  hidePaymentSection = () => {
    let res = this.props.profile.isLennoxDealer ? true : false;
    return res;
  }

  validationCheck = () => {
    if (!this.validator.allValid() |
      (this.refs.planOptions !== undefined && !this.refs.planOptions.formIsValid())) {
      this.setState({
        validationFired: true
      }, () => {
        this.validator.showMessages();

        this.forceUpdate();
      });

      return false;
    }
    return true
  }

  onCreateCampaign = async () => {
    const { accountName } = this.context
    const savedPayment = Object.keys(this.state.savedCard).length !== 0

    if (!this.validator.allValid() ||
      (this.state.isAutoPay && !savedPayment &&
        this.refs.creditCard != undefined && this.refs.creditCard.checkForValidationErrors()) ||
        (this.refs.planOptions != undefined && !this.refs.planOptions.formIsValid())) {

        this.setState({
          validationFired: true
        }, () => {
            if (this.refs.creditCard != undefined)
              this.refs.creditCard.checkForValidationErrors();
            if (this.refs.planOptions != undefined)
              this.refs.planOptions.formIsValid();
          this.validator.showMessages();
          this.forceUpdate();
        });

        return;
      }

    this.setState({ saveCampaign: true });
    var canSaveCampaign = true;

    if (this.state.isAutoPay && !this.hidePaymentSection() && !savedPayment) {
        let cc = await this.refs.creditCard.pay();
        if (!cc.isSuccessful) {
          canSaveCampaign = false;
          this.setState({ saveCampaign: false });
          return;
      }
    }

    if (canSaveCampaign) {
      var model = {
        "campaigns": [
          {
            "campaignId": 0,
            "dealerProgramId": this.state.dealerProgramId,
            "dealerId": this.state.dealerId,
            "tierId": this.state.planState.selectedLabor,
            "coverageTypeId": this.state.planState.selectedCoverage,
            "termId": this.state.planState.selectedYears,
            "excludeRncflag": this.state.excludeRncflag,
            "startDate": new Date(new moment(this.state.startDate, this.isCanada() ? "DD/MM/YYYY" : "MM/DD/YYYY").format("MM/DD/YYYY")),
            "endDate": new Date(new moment(this.state.endDate, this.isCanada() ? "DD/MM/YYYY" : "MM/DD/YYYY").format("MM/DD/YYYY")),
            "createdBy": "VendorPortal",
            "createdDate": new Date()
          }],
        "accountId": this.state.accountId,
        "dealerId": this.state.dealerId,
        "ProgramPhoneNumber": getBrandContent(accountName).programPhone,
        "isAutoPay": this.state.isAutoPay
      }

      var response = await fetch("dealer/EnrollAdvantageDealer/", {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(model)
      });

      if (response.ok) {
        this.setState({
          createCampaign: true,
          startDate: "",
          endDate: "",
          modalOpen: false,
          tempDate: '',
          showCalendar: null,
          campaignEndDate: undefined,
          campaignEndDateTemp: '',
          showEndDate: false,
          programid: '',
          dealerId: this.props.dealerId,
          excludeRncflag: '',
          isTermShown: false,
          saveCampaign: false
        }, () => {
          //This triggers the refresh of the page after a campaign has been created.
          this.props.getDealerCampaigns();
        });
      }
      else {
        this.setState({ saveCampaign: false })
      }
    }
  }

  isCanada = () => {
    return this.props.country === "Canada";
  }

  handleStartCalendarDate = (e) => {
    const { showCalendar } = this.state;
    var resetEndDate = false;

    var newStartDate = moment(e);
    if (!newStartDate.isValid())
      newStartDate = this.state.earliestStartDate.clone();

    var currentEndDate = moment(this.state.endDate, this.state.dateFormatString);
    var defaultEndDate = moment(newStartDate.format(this.state.dateFormatString), this.state.dateFormatString).add(daysBetweenStartAndEndDates, 'days');

    if (currentEndDate.isValid()) {
      var maxDate = moment(defaultEndDate);
      if (currentEndDate.isBefore(maxDate))
        resetEndDate = true;
    }

    this.setState({
      startDate: newStartDate.format(this.state.dateFormatString),
      showCalendar: null,
      campaignEndDateTemp: defaultEndDate.toDate(),
      endDate: resetEndDate ? defaultEndDate.format(this.state.dateFormatString) : this.state.endDate
    });
  }

  handleEndCalendarDate = (e) => {
    var newEndDate = moment(e);

    if (!newEndDate.isValid())
      newEndDate = moment(this.getEarliestEndDate(), this.state.dateFormatString);

    this.setState({
      endDate: newEndDate.format(this.state.dateFormatString),
      showCalendar: null
    });
  }

  openCalendar = (type) => {
    this.setState({
      showCalendar: type
    });
  }

  handleCleaveDateStart = e => {
    let currentStartDate = e.target.value;

    if (this.validator.helpers.testRegex(currentStartDate, this.state.dateRegex)) {
      var newStartDate = moment(currentStartDate, this.state.dateFormatString);

      if (!newStartDate.isValid() || (newStartDate.isValid() && newStartDate.isSameOrBefore(this.state.earliestStartDate, 'day'))) {
        this.resetDate('startDate');
      } else {
        this.handleStartCalendarDate(newStartDate.toDate());
      }
    } else {
      if (currentStartDate.length != null && currentStartDate.length <= 10) {
        this.resetDate('startDate');
      }

    }
  }

  resetDate = (date) => {
    this.setState({
      [date]: null
    }, () => {
      if (date === 'startDate') {
        this.handleStartCalendarDate(this.getEarliestStartDate().toDate());
      }
      else if (date === 'endDate') {
        this.handleEndCalendarDate(moment(this.getEarliestEndDate(), this.state.dateFormatString));
      }
    });
  }

  handleCleaveDateEnd = e => {
    let currentEndDate = e.target.value;

    if (this.validator.helpers.testRegex(currentEndDate, this.state.dateRegex)) {
      var newEndDate = moment(currentEndDate, this.state.dateFormatString);
      var startDate = moment(this.state.startDate, this.state.dateFormatString);

      if (startDate.isValid()) {
        var earliestEndDate = moment(this.state.startDate, this.state.dateFormatString).add(daysBetweenStartAndEndDates, 'days');
        if (newEndDate.isBefore(earliestEndDate)) {
          this.resetDate('endDate');
          return;
        }
      }
      this.handleEndCalendarDate(newEndDate.toDate());
    } else {
      if (currentEndDate.length != null && currentEndDate.length <= 10) {
        this.resetDate('endDate');
      }
    }
  }

  getEarliestEndDate = () => {
    var currentStart = moment(this.state.startDate, this.state.dateFormatString);

    if (currentStart.isValid()) {
      return currentStart.add(daysBetweenStartAndEndDates, 'days').format(this.state.dateFormatString);
    }
    else {
      var earliestStartDateString = this.getEarliestStartDate().format(this.state.dateFormatString);
      var earliestEndDate = moment(earliestStartDateString, this.state.dateFormatString);
      return earliestEndDate.add(daysBetweenStartAndEndDates, 'days').format(this.state.dateFormatString);
    }
  }

  handleEndDateDropDown = (value, name) => {
    if (name) {
      var showEndDate = value === 'second';
      this.setState({
        [name]: value,
        showEndDate: showEndDate,
        endDate: ""
      });
    }
  }

  onClose = () => {
    this.setState({ modalOpen: false, endDate: "" });
  }

  handleAdvantagePlanOptionSelection = (e) => {
    this.setState({
      planState: {
        selectedLabor: e.tierId,
        selectedCoverage: e.coverageTypeId,
        selectedYears: e.termId
      }
    });
  }

  onPlanTypeSelected = () => {
    this.setState({ isTermShown: true });
  }

  handleContactMe = async (flag) => {
    this.validator.showMessageFor('email');
    this.validator.showMessageFor('phone number');
    let phoneError = false;
    let emailError = false;
    if (!this.state.phone) {
      phoneError = true;
      this.setState({ phoneError: true });
    }

    if (this.state.phone && this.state.phone.length < 10 || this.validator.message("phone number", this.state.phone, "required|phone")) {
      phoneError = true;
    }
    if(this.state.repeatPhoneError) {
      phoneError = true;
    }
    if (!this.state.email) {
      emailError = true;
      this.setState({ emailError: true });
    }
    if (this.state.email && this.validator.message("email", this.state.email, "required|email")) {
      emailError = true;
    }
    if ((this.state.phone && phoneError === false && this.state.preferencePhone
      || this.state.email && emailError === false && !this.state.preferencePhone)
      && this.state.contactMeChecked === true) {

      var model = {
        "AgentQueueId": 33,
        "EnqueueReasonId": 25,
        "Phone": this.state.phone,
        "Email": this.state.email,
        "ItemValue": this.props.partyId,
        "ContractPreference": this.state.preferencePhone == true ? 1 : 2
      }

      var response = await fetch("queue/savequeueitem/", {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(model)
      });

      if (response.ok) {
        this.setState({ showConfirmMessage: flag });
      }
    }
    this.setState({ pristineState: true, fieldBlankError: true });
  }

  handleCheckChange = () => {
    this.setState({ contactMeChecked: !this.state.contactMeChecked, contactMeError: this.state.contactMeChecked });
  }

  handleTemrsAndConditionCheckChange = () => {
    this.setState({ termsAndConditionChecked: !this.state.termsAndConditionChecked, termsAndConditionError: this.state.termsAndConditionChecked });
  }

  handlePhoneChange = (evt) => {
    const { value } = evt.target;
    this.setState({ phone: value, pristineState: false });

    let checkRepeatingPhoneFlag = false;
    if(value.length === 14) {
      const firstChar = value[1];      
      for(let index = 2; index < value.length; index++) {
          if(value[index] !== "(" && value[index] !== "-" && value[index] !== " " && value[index] !== ")") {
              if(firstChar === value[index]) {
                checkRepeatingPhoneFlag = true;
              } else {
                checkRepeatingPhoneFlag = false;
                break;
              }
          }                        
      }
    }
    
    if (value.length === 14 && checkRepeatingPhoneFlag) {
      this.setState({ repeatPhoneError: true });
    } else {
      this.setState({ repeatPhoneError: false });
    }
  }

  handleEmailChange = (evt) => {
    this.setState({ email: evt.target.value, pristineState: false });
    this.validator.showMessageFor('email');
    if (evt.target.value.trim() == "" || this.validator.message("email", evt.target.value, "required|email")) {
      this.setState({ emailError: true });
    } else {
      this.setState({ emailError: false })
    }
  }

  displayTermsAndConditions = () => {
    return this.state.termsAndConditionsPath != null && this.state.termsAndConditionsPath !== '';
  }

  createFileUrl = (documentLink) => {

    fetch(documentLink)
      .then(res => {
        if (res.status === 200) {
          return res.arrayBuffer()
        }
        else {
          return undefined;
        }
      })
      .then(data => {
        if (data !== undefined) {
          const file = new Blob([data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          this.setState({ termsAndConditionsPath: fileURL });
          return fileURL;
        }
        else {
          console.log("Error fetching file.");
          return undefined;
        }
      });
  }

  handleRadioClick = e => {
    const { id } = e.target;
    if (id == 'phoneNumber') {
      this.setState({ preferencePhone: true });
    }
    if (id == "emailContact") {
      this.setState({ preferencePhone: false });
    }
    if (this.state.email) {
      this.setState({ emailError: false });
    } else {
      this.setState({ emailError: true });
    }
    if (this.state.email && this.validator.message("email", this.state.email, "required|email")) {
      this.setState({ emailError: true });
    }
    if (this.state.phone) {
      this.setState({ phoneError: false });
    } else {
      this.setState({ phoneError: true });
    }
    if (this.state.phone && this.validator.message("phone number", this.state.phone, "required|phone")) {
      this.setState({ phoneError: true });
    }
  }

  handleAutoPayChange(e) {
    this.setState({ isAutoPay: e.target.checked });
  }

  updateAdvSessionStorage = () => {
    this.setState({ triggerLaborReimbursementEvent: true });
    const stateValue = this.state;

    if (this.props?.updateAdvSessionStorage != undefined) {
      this.props.updateAdvSessionStorage(stateValue);
    }
  }

  handleCreateCampaignClick = () => {
    this.setState({ createCampaign: true })

    if (this.props?.toggleContinue != undefined) {
      this.props.toggleContinue(false, 'AdCampaign');
    }
  }

  getEarliestStartDate = () => {
    const earliestDate = moment().add(1, 'days');

    if (!moment.isMoment(this.state.earliestStartDate)) {
      this.setState({ earliestStartDate: earliestDate });
    }

    return earliestDate;
  }

  render() {
    const {
      createCampaign, startDate, endDate, modalOpen, showCalendar,
      campaignEndDate, showEndDate, campaignEndDateTemp, showContactInput, showConfirmMessage,
      excludeRncflag, campaignTierId, termsAndConditionsPath, dealerName, phone,
      email, fieldBlankError, preferencePhone, saveCampaign, savedCard, isAutoPay
    } = this.state;
    const enableSaveCard = (savedCard != undefined) ? (Object.keys(savedCard).length !== 0) : false;
    this.validator.purgeFields();
    var earliestEndDate = moment(this.getEarliestEndDate(), this.state.dateFormatString).toDate();
    var endDateValue = moment(endDate, this.state.dateFormatString).isValid() ? moment(endDate, this.state.dateFormatString).toDate() : earliestEndDate;
    var startDateValue = moment(startDate, this.state.dateFormatString).isValid() ? moment(startDate, this.state.dateFormatString).toDate() : null;
    const { accountName } = this.context
    const benefits = getBrandContent(accountName)?.advantageBenefits?.preferencesScreen
    const errorOnPhoneField = ((
      this.state.pristineState &&
      fieldBlankError &&
      !this.state.phone &&
      preferencePhone &&
      this.state.phoneError
    ) || (
        this.state.phoneError &&
        preferencePhone &&
        this.validator.message("phone number", this.state.phone, "required|phone")) || (this.state.pristineState && this.state.repeatPhoneError)
    );
    const errorOnPhoneMessage = (((
      this.state.pristineState &&
      preferencePhone &&
      this.state.phoneError &&
      fieldBlankError &&
      !this.state.phone
    ) || (
        !this.state.phone &&
        preferencePhone &&
        this.state.phoneError)) &&
      this.validator.message("phone number", this.state.phone, "required|phone") && (
        <div className={classNames(styles.contactTypes, "errorMsg d-flex pl-0")}>
          Please enter a valid phone number.
        </div>
      )
    );
    const errorOnEmailField = ((
      this.state.emailError &&
      this.state.pristineState &&
      fieldBlankError &&
      !preferencePhone
    ) || (
        this.state.emailError &&
        !preferencePhone &&
        this.validator.message("email", this.state.email, "required|email"))
    );
    const errorOnEmailMessage = (((
      this.state.pristineState &&
      !preferencePhone &&
      !email && this.state.emailError === true &&
      fieldBlankError
    ) || (
        !email && !preferencePhone &&
        this.state.emailError &&
        this.validator.message("email", this.state.email, "required|email"))) && (
        <div className={classNames(styles.contactTypes, "errorMsg d-flex pl-0")}>
          Email is required.
        </div>
      )
    );
    
    return (
      <CardBody>
        <Row className="m-0">
          <Col xs="12" lg="8" className={styles.stepsCol}>
            {!createCampaign ? (
              <div className={styles.campaignStepsCard}>
                <Row className="m-0">
                  <Col md="3" lg="3" className={styles.advCampIcon}>
                    <BrandedIcon accountName={accountName} iconType={'enroll'} />
                  </Col>
                  <Col className={styles.advantageCampaignsSteps}>
                    <p className={styles.listHeader}> {this.state.advantageName} is a sales option designed to make your life easier!<br />
                      Enjoy discounted pricing and automated processing.
                    </p>
                    <ul className={styles.stepsAdvCampaigns}>
                      <li><b>Step 1</b><p>Create {indefinite(this.state.advantageName)} Campaign and select your sales preferences.</p></li>
                      <li><b>Step 2</b><p>After you register your customer’s product with the manufacturer, a plan will be auto generated.</p></li>
                      <li><b>Step 3</b><p>Login to manage payments or take advantage of AutoPay.</p></li>
                    </ul>
                    <b>It's easy - include labor coverage on every installation with no processing!</b>
                    <div className={styles.crtCampaignBtn}>
                      <button className="btn-primary mt-4" onClick={() => { this.handleCreateCampaignClick() }}>Create Campaign</button>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <div>
                <h2 className="h5 subHead">Create New Campaign</h2>
                <p>Plans will be auto generated with these selections. Select labor reimbursement to view plan options.</p>
                <Row className="multiColWrapper">
                  <Col md="3">
                    <FormGroup className={!this.validator.check(startDate, "required") && this.state.validationFired ? "form-error" : ""}>
                      <label for="campaignStartDt" className="form-label">Campaign Start Date</label>
                      <span aria-hidden="true" className="required-indicator">*</span>
                      <div className={classNames("calendar-input", styles.calendarIcon)} id="campaignStartDt" aria-required="true">
                        <Cleave
                          onFocus={() => this.openCalendar('startDate')}
                          name="startDate"
                          className={classNames(formControl, dateInput)}
                          value={startDate || ""}
                          onChange={this.handleCleaveDateStart} options={{
                            date: true,
                            delimiter: '/',
                            datePattern: this.isCanada() ? ['d', 'm', 'Y'] : ['m', 'd', 'Y']
                          }}
                          placeholder={this.isCanada() ? "DD/MM/YYYY" : "MM/DD/YYYY"}
                        />
                        <CalendarIcon className={`datePicker ${styles.dateIcon}`} name="calendarIcon" onClick={() => this.openCalendar('startDate')} />
                      </div>
                      {showCalendar === 'startDate' &&
                        <Calendar name="startDate" minDate={this.getEarliestStartDate().toDate()} value={startDateValue} onChange={this.handleStartCalendarDate} />
                      }
                      {this.validator.message("campaignStartDate", startDate, "required")}

                      </FormGroup>
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <label for="campaignEndDtOption" className="form-label">Campaign End Date</label>
                        <DropDownAutoComplete
                          name="campaignEndDate"
                          options={[{ data: 'first', display: 'Until Further Notice' }, { data: 'second', display: 'Select a Date' }]}
                          className="form-control"
                          onChangedValue={this.handleEndDateDropDown}
                          onBlur={this.handleEndDateDropDown}
                          placeholder="Please select..."
                          Value={campaignEndDate}
                          id="campaignEndDtOption"
                          defaultValue={'first'}
                        />
                      </FormGroup>
                    </Col>
                    {showEndDate && (<Col md="3">
                      <FormGroup className={!this.validator.check(endDate, "required") && this.state.validationFired ? "form-error" : ""}>
                        <label for="campaignEndDt" className="form-label">Campaign End Date</label>
                        <span aria-hidden="true" className="required-indicator">*</span>
                        <div className={classNames("calendar-input", styles.calendarIcon)} id="campaignEndDt" aria-required="true">
                          <Cleave onFocus={() => this.openCalendar('endDate')} name="endDate" className={classNames(formControl, dateInput)} value={endDate || ""} onChange={this.handleCleaveDateEnd} options={{
                            date: true,
                            delimiter: '/',
                            datePattern: this.isCanada() ? ['d', 'm', 'Y'] : ['m', 'd', 'Y']
                          }}
                            placeholder={this.isCanada() ? "DD/MM/YYYY" : "MM/DD/YYYY"}
                          />
                          <CalendarIcon className="datePicker" name="calendarIcon" onClick={() => this.openCalendar('endDate')} />
                        </div>
                        {showCalendar === "endDate" &&
                          <Calendar name="endDate"
                            minDate={earliestEndDate}
                            defaultValue={earliestEndDate}
                            value={endDateValue}
                            onChange={this.handleEndCalendarDate}
                          />
                        }
                        {showEndDate && this.validator.message("campaignEndDate", endDate, "required")}

                      </FormGroup>
                    </Col>)}
                  </Row>
                  <FormGroup className={!this.validator.check(excludeRncflag, "required") && this.state.validationFired ? "form-error" : ""}>
                    <label for="typeOfInst" className="form-label d-inline">Do you want to exclude residential new construction?</label>
                    <span aria-hidden="true" className="required-indicator">*</span>
                    <div className="d-flex" id="typeOfInst" onChange={this.onExcludeRNCChange}>
                      <div className={'squareRadioBtn'} role="radio" tabIndex="0">
                        <input id="yesExclude" type="radio" aria-required="true" value={true} aria-labelledby="Exclude Residential new construction" name="servicerOptions" checked={excludeRncflag === true} />
                        <label className="radio-controls-label m-0 w-100" for="yesExclude">Yes</label>
                      </div>
                      <div className={'squareRadioBtn'} role="radio" tabIndex="0">
                        <input id="dontExclude" type="radio" aria-required="true" value={false} aria-labelledby="Don't exclude Residential new construction" name="servicerOptions" checked={excludeRncflag === false} />
                        <label className="radio-controls-label m-0 w-100" for="dontExclude">No</label>
                      </div>
                    </div>
                    {this.validator.message("selectAnOptionToContinue", excludeRncflag, "isOptionCheck")}
                  </FormGroup>
                  <FormGroup>
                    <PlanOptions
                      ref="planOptions"
                      isAdvantage={true}
                      accountId={this.state.accountId}
                      onTypeChange={this.onTypeRadioChange}
                      onTermChange={this.onTermRadioChange}
                      onAdvantagePlanOptionChange={this.handleAdvantagePlanOptionSelection}
                      country={this.state.country}
                      onPlanTypeSelected={this.onPlanTypeSelected}
                      language={this.isCanada() ? "en-CA" : "en-US"}
                      assetPackage={this.state.planState}
                      dealerProfile={this.props.profile}
                      triggerLaborReimbursementEvent={this.state.triggerLaborReimbursementEvent}
                      isTermShown={this.state.isTermShown}
                    />
                  </FormGroup>
                  {this.state.isTermShown &&
                    <div className={styles.threeYrPlan}>
                      3 Year Plans Save Up to 25%
                                    </div>
                  }
                  {!this.props.isVendorCreation && <hr className='cardSectionHR' />}
                  {!this.hidePaymentSection() &&
                    <CardDetails
                      ref="creditCard"
                      showPaymentDetails={false}
                      headLabel="Payment Details"
                      partyId={this.props.partyId}
                      saveOnly={true}
                      mailingAddress={this.props.mailingAddress != null ? this.props.mailingAddress : {}}
                      billingAddress={this.props.billingAddress != null ? this.props.billingAddress : {}}
                      isCanadaState={this.isCanada()}
                      amount={0}
                      hideCard={enableSaveCard || (!enableSaveCard && !isAutoPay)}
                      profile={this.props.profile}
                      chargedEntityType={PaymentEntityType.NA}
                      chargedEntityIds={[]}
                      hideSaveCard={true}
                      savedCardType={PaymentOptionType.preferred}
                      accountId={this.props.profile.accountId}
                      atAdvantageCampaign={true}
                      countryId={this.props.profile.countryId}>

                  <div className={classNames("scriptBox", "align-items-start", "flex-column", "mb-4", "mt-1")}>
                    <CheckboxInput
                      id="autoPayCheck"
                      clientId={accountName}
                      handleCheck={e => this.handleAutoPayChange(e)}
                      customClass="mb-0"
                      textCustomClass="font-weight-bold"
                    >
                      Enroll in Weekly Autopay
                    </CheckboxInput>
                    <p className={styles.customParagraph}>
                      {!enableSaveCard ?
                        "By checking this box you authorize us to automatically bill your preferred payment method at the first of each week."
                        : 
                        `By checking this box you authorize us to automatically bill your preferred payment method (${savedCard[0].vendor.toUpperCase()} #${savedCard[0].lastFour}) at the first of each week.`
                      }
                    </p>
                  </div>
                  
                  {isAutoPay && 
                    <p>
                      Please provide your preferred payment method. This payment method will be used for your ({this.state.advantageName}) sales and pre-selected during manual sales entry.
                    </p>
                  }
                  
                  <p>Note: Your preferred payment method can be updated on the Billing Preferences tab.</p>
                </CardDetails>
              }

                  {this.displayTermsAndConditions() &&
                    <>
                      <div className="checkbox mt-4 mb-0">
                        <div>
                          <input className="mr-2" type="checkbox" id="termsCheck" checked={this.state.termsAndConditionChecked} onChange={this.handleTemrsAndConditionCheckChange.bind(this)} />
                          <label for="termsCheck" className={`checkboxLabel ${accountName}`}>Agree to <a className={styles.linkStyle} href={termsAndConditionsPath} rel="noopener noreferrer" target="_blank"> Terms and Conditions</a>.</label>
                        </div>
                      </div>

                      {this.validator.message(`${dealerName}`, this.state.termsAndConditionChecked, "isTrue")}
                    </>
                  }

                </div>
              )}
          </Col>
          <Col lg="4" className={styles.advBenCol}>
            <div className={styles.benefitsList}>
              <div className={styles.secondCardHeader}>
                <h3 className={styles.secondCardHeaderh5}>{this.state.advantageName} Program Benefits</h3>
              </div>
              <div className={styles.listOfBenefits}>
                <p>Automate labor warranty processing for all future installs, with significant discounts and automatic billing.</p>
                <hr className="small" />
                <p>Why Sign Up for {this.state.advantageName}?</p>
                <ul className={`row mx-0 ${styles.advlist}`}>
                  {benefits && benefits.map((b, i) => (
                    <li key={i}>{b}</li>
                  ))}
                </ul>
                {!showConfirmMessage ? (<Fragment><div className="checkbox mb-4">
                  <input
                    className="mr-2"
                    type="checkbox"
                    id="contactMeCheck"
                    checked={this.state.contactMeChecked}
                    onChange={this.handleCheckChange.bind(this)}
                  />
                  <label
                    for="contactMeCheck"
                    className={`checkboxLabel ${styles.radioLabel} ${accountName}`}
                    onClick={() =>
                      this.setState({ showContactInput: !showContactInput })
                    }
                  >
                    I would like someone to contact me about the{" "}
                    {this.state.advantageName} Program.
                  </label>
                </div>
                  {!this.state.contactMeChecked && this.state.pristineState && (
                    <div className={classNames("errorMsg mb-2")}>
                      Please select this checkbox to continue.
                                        </div>
                  )}
                  {showContactInput == true && (
                    <div className="col-lg-12 col-md-6 p-0">
                      <FormGroup className={classNames(styles.contactTypes, "mb-2", errorOnPhoneField && "form-error")}>
                        <div className={`radio ${styles.radioWrapper}`}>
                          <input type="radio"
                            name="contactType"
                            id="phoneNumber"
                            value="contactNumber"
                            defaultChecked
                            onClick={this.handleRadioClick} />
                          <label className={classNames("controls-label")} for="phoneNumber">
                            <span className={classNames(errorOnPhoneField && "errorMsg")}>
                              Call
                            </span>
                          </label>
                        </div>
                        <div className={styles.validationWrapper}>
                          <Cleave 
                            placeholder=""
                            name="contactNumber"
                            onChange={this.handlePhoneChange}
                            value={this.state.phone}
                            className="form-control"
                            id="phoneNumber"
                            options={{ 
                              blocks: [0, 3, 3, 4], 
                              delimiters: ['(', ') ', '-'], 
                              numericOnly: true 
                            }}
                          />
                          <span>{errorOnPhoneMessage}</span>
                          {this.state.pristineState &&
                            preferencePhone &&
                            phone &&
                            (phone.length !== 10 && phone.length < 14) && (
                              <span className={classNames(styles.contactTypes, "errorMsg")}>
                                Please enter a valid phone number.
                              </span>
                            )
                          }
                          {this.state.pristineState && this.state.repeatPhoneError && 
                            <span className={classNames(styles.contactTypes, "errorMsg")}>
                              Please enter a valid phone number.
                            </span>
                          }
                        </div>
                      </FormGroup>
                      <FormGroup className={classNames(styles.contactTypes, "mb-2", errorOnEmailField && "form-error")}>
                        <div className={`radio ${styles.radioWrapper}`}>
                        <input type="radio" name="contactType" id="emailContact" value="contactMail"
                          onClick={this.handleRadioClick} />
                        <label className="controls-label mb-0" for="emailContact">
                          <span className={errorOnEmailField && "errorMsg"}>
                            Email
                                                    </span>
                        </label>
                      </div>
                        <div className={styles.validationWrapper}>
                          <span>
                            <input
                              type="email"
                              name="contactMail"
                              id="emailContact"
                              className="form-control"
                              value={this.state.email}
                              defaultValue={email}
                              onChange={this.handleEmailChange}
                            />
                          </span>
                          <span >{errorOnEmailMessage}</span>
                          {this.state.pristineState &&
                            !preferencePhone &&
                            email &&
                            this.validator.message("email", email, "required|email") && (
                              <span className={classNames(styles.contactTypes, "errorMsg")}>
                                Please enter a valid email address.
                              </span>
                            )
                          }
                        </div>
                      </FormGroup>
                    </div>
                  )} 
                  <div className="d-flex justify-content-center">
                    <button className="btn-secondary" onClick={() => this.handleContactMe(!showConfirmMessage)}>Contact Me</button>
                  </div></Fragment>) : (
                    <div className={styles.contactConfirmation}>
                      <ConfirmCheck />
                      <div>Thank you, we will contact you within 1 to 2 business days.</div>
                    </div>
                  )}
              </div>
            </div>
          </Col>
        </Row>
        {createCampaign && !this.props.isVendorCreation && (
          <div className={styles.createCampnBtn}>
            <SpinnerButton
              onClick={this.onCreateCampaign}
              disabled={saveCampaign}
              spinning={saveCampaign}
              styleType="primary"
              text={"Create Campaign"}
            />
          </div>
        )}
        <div>
        </div>
      </CardBody>
    );
  }
}

AdvantageCampaigns.contextType = SessionContext