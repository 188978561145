import React, { useContext } from 'react'
import { useClaimInvoiceStore } from "../lib/state";
import { Label } from "reactstrap";
import { SessionContext } from '../../../context/SessionContext';
import { calc } from '../Calculators';
import classNames from 'classnames';
import styles from './Formatters.module.css';

export const OemCheckbox = ({ col, row, rowIndex, tableType }) => {
    const { getCurrentTabAndSubtab } = useContext(SessionContext);
    const { invoiceId } = getCurrentTabAndSubtab().subtab;
    const [data, updatePartsRow, updatePartsMarkupRow] = useClaimInvoiceStore((state) => [
        state?.[invoiceId]?.[tableType]?.data[rowIndex]?.oem,
        state?.updatePartsRow,
        state?.updatePartsMarkupRow
    ]);
    const context = useContext(SessionContext);
    const { accountName } = context;

    const handleOemChange = (e) => {
        const { checked } = e.target;
        // Need calculations when checkbox checked
        const total = calc[tableType]({ ...row, oem: checked });
        if (tableType === 'parts') {
            updatePartsRow(invoiceId, tableType, rowIndex, { oem: checked, total });
            return;
        }
        updatePartsMarkupRow(invoiceId, tableType, rowIndex, { oem: checked, total });
    };

    return (
        <div className={classNames(styles.alignment, "checkbox mb-0")}>
            <input
                checked={data ?? row.oem}
                id={`oem_${rowIndex}`}
                className="mr-0"
                type="checkbox"
                name={`oem_${rowIndex}`}
                onChange={handleOemChange}
                disabled={row.wFlag}
            />
            <Label for={`oem_${rowIndex}`} className={classNames("checkboxLabel", accountName)} />
        </div>
    )
};
