import React from "react";
import { Row, Col } from "reactstrap";
import classnames from 'classnames';
import { getBrandContent } from '../../../BrandingWhiteLabel/BrandContent';
import BrandedIcon from '../../../BrandingWhiteLabel/BrandedIcon';


import styles from './WhySell.module.css';
import { useContext } from "react";
import { SessionContext } from "../../../../context/SessionContext";



const WhySell = () => {
  const context = useContext(SessionContext)
  const { accountName } = context;
  const accountNameToLower  = accountName.toLowerCase();
  let screenRecords = getBrandContent("whysell");

  return (
    <>
      <h1 className="h5">Why Sell</h1>
      <Row className="m-0">
        <Col lg="6" className={styles.whySellPoints}>
          <span>
            {accountNameToLower !== 'ge' 
              ? <BrandedIcon accountName={accountName} iconType={'graphs'} />
              : <BrandedIcon accountName={accountName} iconType={'people'} />
            }
          </span>
          <div>
            <h2 className="h6 mb-0">Differentiate Yourself</h2>
            <p>
              Boost revenue and close more sales by offering comprehensive
              coverage
            </p>
          </div>
        </Col>
        <Col lg="6" className={styles.whySellPoints}>
          <span className={styles.ratingsIcon}>
            <BrandedIcon accountName={accountName} iconType={'ratings'} />
          </span>
          <div>
            <h2 className="h6 mb-0">Build Customer Loyalty</h2>
            <p>
              Gain a customer for life by building loyalty and trust through
              quality service
            </p>
          </div>
        </Col>
      </Row>
      <Row className="m-0">
        <Col lg="6" className={styles.whySellPoints}>
          <span>
            {accountNameToLower !== 'ge' 
              ? <BrandedIcon accountName={accountName} iconType={'lock'} />
              : <BrandedIcon accountName={accountName} iconType={'revenuehand'} />
            }
          </span>
          <div>
            <h2 className="h6 mb-0">Lock in Service Revenue</h2>
            <p>
              Establish guaranteed service revenue with our competitive
              reimbursement rate options
            </p>
          </div>
        </Col>
        <Col lg="6" className={styles.whySellPoints}>
          <span className={classnames(styles.checkboxesIcon, accountNameToLower === "lennox" && styles.checkboxesIconLennox)}>
            {accountNameToLower === "ge" ? 
              <BrandedIcon accountName={accountName} iconType={'advantagecheck'} /> : 
              <BrandedIcon accountName={accountName} iconType={'checkboxes'} />
            }            
          </span>
          <div>
            <h2 className="h6 mb-0">Coverage Flexibility</h2>
            <p className="mb-4">
              Select from multiple coverage, reimbursement and sales options for
              you and your customer
            </p>
          </div>
        </Col>
      </Row>
     
      <>
        <h1 className="h5 mb-2">{screenRecords[accountNameToLower]?.heading}</h1>
        {screenRecords[accountNameToLower]?.para1 && <p className={styles. mb-2}>
          {screenRecords[accountNameToLower]?.para1}
        </p>}
        {screenRecords[accountNameToLower]?.para2 && <p className={styles.purchasePath}>
          {screenRecords[accountNameToLower]?.para2}
        </p>}
        {screenRecords[accountNameToLower]?.para3 && <p className={styles.purchasePath}>
          {screenRecords[accountNameToLower]?.para3}
        </p>}
      </>
    </>
  );
};

export default WhySell