import React from "react";
import styles from './WhatsCovered.module.css';
import BootstrapTable from "react-bootstrap-table-next";
import { Row, Col, FormGroup, Card, CardBody, Progress } from "reactstrap";
import { ReactComponent as YesIcon } from '../../../../assets/images/checkmark.svg';
import { ReactComponent as NoIcon } from '../../../../assets/images/close.svg';
import { ReactComponent as StandardIcon } from '../../../../assets/images/ribbon.svg';
import { ReactComponent as HomeWarrIcon } from '../../../../assets/images/home-warranty.svg';
import { ReactComponent as ShieldIcon } from '../../../../assets/images/Vendor/Lennox/ComfortShield.svg';
import ProtectionPlusIcon from '../../../../assets/images/Vendor/Rheem/Protection_Plus_Icon.svg';
import { ReactComponent as ClimateShieldIcon } from '../../../../assets/images/Vendor/Carrier/climate-shield.svg';
import { ReactComponent as GEShieldIcon } from '../../../../assets/images/Vendor/GE/comfort-protect-shield.svg';
import { ReactComponent as HDShieldIcon } from '../../../../assets/images/Vendor/HomeDepot/HD_logo_Icon.svg';
import { ReactComponent as ICPShieldIcon } from '../../../../assets/images/Vendor/ICP/ComfortPromise_Icon.svg';
import classnames from 'classnames';
import { getBrandContent } from '../../../BrandingWhiteLabel/BrandContent'
import { useContext } from "react";
import { SessionContext } from "../../../../context/SessionContext";
import InfoTooltip from "../../../shared/InfoTooltip/InfoTooltip";

const coverageTable = styles.coverageTable;

const YesIconComp = () => {
	return (
		<div className={styles.yesIcon}><YesIcon /></div>
	)
};

const NoIconComp = () => {
	return (
		<div className={styles.noIcon}><NoIcon /></div>
	)
};


const WhatsCovered = ({ props, onClick }) => {

	const context = useContext(SessionContext);
  const { accountName } = context;
  const accountNameToLower  = accountName.toLowerCase();
	const isLennox = accountNameToLower === 'lennox';
	const isCarrier = accountNameToLower === 'carrier';
	const isRheem = accountNameToLower === 'rheem';
	const isGE = accountNameToLower === 'ge';
    const isICP = accountNameToLower === 'icp';
    const isHD = accountName.toLowerCase() === 'homedepot';
    const isMcc = accountNameToLower === 'mcc';
  
  let screenRecords = getBrandContent("whatscovered");
	
  const coverageColumnsAll = [
    {
      dataField: "typeOfCoverage",
      text: "",
    },
    {
      dataField: "typeofShield",
      text: (
        <div className="align-items-center d-flex">
          {isLennox && (
            <>
              <span>
                <ShieldIcon className={styles.shieldIcon} />
              </span>
              <span>Comfort Shield Lennox</span>{" "}
            </>
          )}
          {isRheem && (
            <>
              <span>
                <img
                    className={styles.shieldIcon}
                    src={ProtectionPlusIcon}
                    alt="comfortshield"
                />
              </span>
              <span>{getBrandContent(accountName).programName}</span>
            </>
          )}
          {isCarrier && (
            <>
              <span>
                <ClimateShieldIcon className={styles.shieldIcon} />
              </span>
              <span>{getBrandContent(accountName).programName}</span>
            </>
          )}
          {isGE && (
            <>
              <span>
                <GEShieldIcon className={styles.shieldIcon} />
              </span>
              <span className={styles.noWrapIcon}>{getBrandContent(accountName).programName}</span>
            </>
          )}
          {isICP && (
            <>
              <span>
                <ICPShieldIcon className={styles.shieldIcon} />
              </span>
              <span>{getBrandContent(accountName).programName}</span>
            </>
          )}
          {isHD && (
            <>
              <span>
                <HDShieldIcon className={styles.shieldIcon} />
              </span>
              <span>{getBrandContent(accountName).programName}</span>
            </>
          )}
          {isMcc && (
            <>
              <span>
                <ICPShieldIcon className={styles.shieldIcon} />
              </span>
              <span>{getBrandContent(accountName).programName}</span>
            </>
          )}
        </div>
      )
    },
    {
      dataField: "stdPartsWarranty",
      text: (
        <div className="align-items-center d-flex">
          <span>
            <StandardIcon className={styles.headerIcon} />
          </span>
          <span>Manufacturer's Std Parts Warranty</span>
        </div>
      ),
    },
    {
      dataField: "typicalHomeWarranty",
      text: (
        <div className="align-items-center d-flex">
          <span>
            <HomeWarrIcon className={styles.headerIcon} />
          </span>
          <span>Typical Home Warranty</span>
        </div>
      ),
    },

  ];

  let coverageDataAll = [
		{
			typeOfCoverage: (<div>Cost of Labor{accountNameToLower === "lennox" ? (<sup>2</sup>) : ""}</div>),
			typeofShield: (<YesIconComp />),
			stdPartsWarranty: (<NoIconComp />),
			typicalHomeWarranty: (<YesIconComp />)
		},
		{
			typeOfCoverage: "Diagnostic Costs",
			typeofShield: (<YesIconComp />),
			stdPartsWarranty: (<NoIconComp />),
			typicalHomeWarranty: (<NoIconComp />)
		},
		{
			typeOfCoverage: "Service Provided by You",
			typeofShield: (<YesIconComp />),
			stdPartsWarranty: (<YesIconComp />),
			typicalHomeWarranty: (<NoIconComp />)
		},
		{
			typeOfCoverage: "Fully Transferable",
			typeofShield: (<YesIconComp />),
			stdPartsWarranty: (<NoIconComp />),
			typicalHomeWarranty: (<YesIconComp />)
		},
		{
			typeOfCoverage: "No Deductible or Service Fees",
			typeofShield: (<YesIconComp />),
			stdPartsWarranty: (<YesIconComp />),
			typicalHomeWarranty: (<NoIconComp />)
		},
	];
    
  if(screenRecords[accountNameToLower] !== null && screenRecords[accountNameToLower]?.layout === "table") {
    const refrigerantIndex = screenRecords[accountNameToLower].partFlag === "yes" ? 3 : 2;
   
    (screenRecords[accountNameToLower].partFlag !== "no") && coverageDataAll.splice(0, 0, {
      typeOfCoverage: (<div>Parts Coverage</div>),
      typeofShield: (<YesIconComp />),
      stdPartsWarranty: (<YesIconComp />),
      typicalHomeWarranty: (<YesIconComp />)
    });
    (screenRecords[accountNameToLower].refrigerantFlag !== "no") && coverageDataAll.splice(refrigerantIndex, 0, {
      typeOfCoverage: "Refrigerant Coverage for Covered Repairs",
      typeofShield: (<YesIconComp />),
      stdPartsWarranty: (<NoIconComp />),
      typicalHomeWarranty: (<YesIconComp />)
    });
  }

	return (
		<>
			<h1 className="h5">What's Covered</h1>
      {(screenRecords[accountNameToLower]?.layout === "slider")  && 
        <>
          <p className={styles.desc}>
            {getBrandContent(accountName).programName} Extended Service Agreements (ESAs) are designed to wrap around 
            the {getBrandContent(accountName).clientName} manufacturer's warranty to provide your customers total 
            combined coverage for both parts and labor throughout the duration of their manufacturer’s warranty.
          </p>
          <Row>
            <Col className="mb-2">
              <div className={styles.measureheader}>
                <div className={styles.circleLabel}>
                    <span className={classnames((isLennox) ? styles.OEMCovLennox : styles.OEMCov, styles.circle)}></span>
                    <p className="mb-0">OEM Coverage</p>
                </div>
                <div className={styles.circleLabel}>
                    <span className={classnames((isLennox) ? styles.planCovLennox : styles.planCov, styles.circle)}></span>
                    <p className="mb-0">{getBrandContent(accountName).programName} Coverage</p>
                </div>
              </div>            
            </Col>
          </Row>
          <Row className="twoColWrapper">
            <Col xs="12" sm="6" className={styles.twoColWrapperXS}>
              <Card className="px-3 pt-3">
                <h1 className="h6">
                  OEM: {screenRecords[accountNameToLower]?.OEM_PL_PartsTerm}-year Parts &amp; {screenRecords[accountNameToLower]?.OEM_PL_LaborTerm}-year Labor
                </h1>
                <p className={styles.desc}>
                  Depending on the equipment series, the manufacturer's warranty varies. Customers can extend their 
                  labor coverage for an additional {screenRecords[accountNameToLower]?.ESA_PL_LaborTermWritten} years to match the length of their OEM parts warranty.
                </p>
                <p className={classnames(styles.desc, "mb-0")}>Product Coverage</p>
                <div>
                  <div className={styles.progressBars}>
                    <div className={styles.progressBarsTop}>
                      <div className={styles.oemSubHeadLeft}>OEM - <strong>{screenRecords[accountNameToLower]?.OEM_PL_LaborTerm} Yrs</strong></div>
                      <div className={styles.esaSubHeadRight}>ESA - <strong>{screenRecords[accountNameToLower]?.ESA_PL_LaborTerm} Yrs</strong></div>
                    </div>                  
                    <Progress multi className={styles.progress}>                  
                      <Progress bar className={classnames(styles.progress, styles.oem)} value={3/10*100}></Progress>                  
                      <Progress bar className={classnames(styles.progress, (isLennox) ? styles.esaLennox : styles.esa)} value={7/10*100}></Progress>
                    </Progress>
                    <div className={styles.progressTimeline}>
                      <p className={classnames('h5', styles.carrierProgressLabel)}>{props?.isCanadaState ? "Labour" : "Labor"}</p>
                      <p className={'h5'}>{screenRecords[accountNameToLower]?.OEM_PL_PartsTerm} Yrs</p>
                    </div>
                  </div>
                </div>
                <div className="mt-1 mb-1">
                  <div className={styles.progressBars}>
                    <div className={styles.oemSubHead}>OEM - <strong>{screenRecords[accountNameToLower]?.OEM_PL_PartsTerm} Yrs</strong></div>
                    <Progress bar className={classnames(styles.progress, styles.oem)} value={100}></Progress>
                    <div className={styles.progressTimeline}>
                      <div className={classnames('h5', styles.partsSubhead, styles.carrierProgressLabel)}>
                        Parts
                        <div className={styles.infoIcon}>
                          <InfoTooltip
                            iconType="Info" 
                            message="Factory parts warranty assumes equipment is eligible for parts extension with the equipment OEM and that registration is done within the OEM guidelines." 
                           />
                        </div>
                      </div>
                      <p className={'h5'}>{screenRecords[accountNameToLower]?.OEM_PL_PartsTerm} Yrs</p>
                    </div>
                  </div>
                </div>              
              </Card>
            </Col>
            <Col Col xs="12" sm="6">
              <Card className="px-3 pt-3">
                <h1 className="h6">OEM: {screenRecords[accountNameToLower]?.ESA_P_LaborTerm}-year Parts</h1>
                <p className={styles.desc}>
                  Customers with equipment that comes with a parts only OEM warranty can purchase an 
                  Extended Service Agreement to add labor coverage for up to {screenRecords[accountNameToLower]?.ESA_P_LaborTermWritten} years.
                </p>
                <p className={classnames(styles.desc, "mb-0", "mt-4")}>Product Coverage</p>
                <div>
                  <div className={styles.progressBars}>
                    <div className={styles.oemSubHead}>ESA - <strong>Up to {screenRecords[accountNameToLower]?.ESA_P_LaborTerm} Yrs</strong></div>
                    <Progress bar className={classnames(styles.progress, (isLennox) ? styles.esaLennox : styles.esa)} value={100}></Progress>
                    <div className={styles.progressTimeline}>
                      <p className={classnames('h5', styles.carrierProgressLabel)}>{props?.isCanadaState ? "Labour" : "Labor"}</p>
                      <p className={'h5'}>{screenRecords[accountNameToLower]?.ESA_P_LaborTerm} Yrs</p>
                    </div>
                  </div>
                </div>  
                <div className="mt-1 mb-1">
                  <div className={styles.progressBars}>
                    <div className={styles.oemSubHead}>OEM - <strong>Up to {screenRecords[accountNameToLower]?.OEM_P_PartsTerm} Yrs</strong></div>
                    <Progress bar className={classnames(styles.progress, styles.oem)} value={100}></Progress>
                    <div className={styles.progressTimeline}>
                      <div className={classnames('h5', styles.partsSubhead, styles.carrierProgressLabel)}>
                        Parts
                        <div className={styles.infoIcon}>
                          <InfoTooltip 
                            iconType="Info" 
                            message="Factory parts warranty assumes equipment is eligible for parts extension with the equipment OEM and that registration is done within the OEM guidelines." 
                          />
                        </div>
                      </div>
                      <p className={'h5'}>{screenRecords[accountNameToLower]?.OEM_P_PartsTerm} Yrs</p>
                    </div>
                  </div>
                </div>  
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="mt-2">
              <h1 className="h5">
                Extended Service Agreement Benefits
                <span className="required-indicator">*</span>
              </h1>
              <Row className="ml-0">
                <Col sm="3" className="ml-0 pl-0">
                  <ul className={classnames(styles.agreementLennox, "pl-3")}>
                    <li className="mb-2">Covers the Cost of Labor</li>
                    <li>Covers Diagnostics</li>
                  </ul>
                </Col>
                <Col sm="4" className="ml-0 pl-0">
                  <ul className={styles.agreementLennox}>
                    <li className="mb-2">Fully Transferrable</li>
                    <li>No Deductible or Service Fees</li>
                  </ul>
                </Col>
                <Col sm="7"></Col>
              </Row>            
            </Col>
          </Row>
          <Row>
            <Col className="mt-2">
              <h2 className="h5 mb-2">Available On</h2>
              <p className="mb-0">
                HVAC/Residential Equipment. See{" "}
                <span className={styles.link} onClick={onClick}>
                  plan pricing
                </span>{" "}
                for details.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="mt-3">
                *Depending on the plan purchased, benefits will vary. Limitations and exclusions apply. 
                Please see the full terms and conditions for further details.
              </p>
            </Col>
          </Row>
        </>
      }
      {(screenRecords[accountNameToLower]?.layout === "table") && 
        <>
          <p className={styles.desc}>
						{getBrandContent(accountName).programName} is designed to extend and
            enhance the Standard Manufacturer’s Limited Warranty for up to ten 
            years. Customers receive additional benefits not available
            with the manufacturer’s warranty or other home warranty plans.
          </p>
          <div className={classnames(coverageTable, "m-lg-0")}>
            <BootstrapTable
              keyField="planBenefits"
              data={coverageDataAll}
              columns={coverageColumnsAll}
              bordered={true}
              bootstrap4
              wrapperClasses="table-responsive-lg"
            />
          </div>          
          <div className="mt-3 mb-2">
            *Depending on the plan purchased, benefits will vary. Limitations and exclusions apply. 
            Please see the full terms and conditions for further details.
          </div>
          <Row>
            <Col className="mt-2">
              <h2 className="h5 mb-2">Available On</h2>
              <p className="mb-0">
                HVAC/Residential Equipment. See{" "}
                <span className={styles.link} onClick={onClick}>
                  plan pricing
                </span>{" "}
                for details.
              </p>
            </Col>
          </Row>
        </>
      }
    </>
  );
};

export default WhatsCovered