import React, { Component } from "react";
import { Col, FormGroup, Row } from "reactstrap";
import styles from "./DealerProfile.module.css";
import classNames from "classnames";
import { DropDownInputWithCheckbox } from '../inputs/DropDownInputWithCheckbox';
import SimpleReactValidator from 'simple-react-validator';
import Cleave from 'cleave.js/react';
import DeleteButton from "../shared/DeleteButton/DeleteButton";

let slider = styles.slider;
let round = styles.round;
let commSwitch = styles.commSwitch;
let smsSwitch = styles.smsSwitch;
let emailSwitch = styles.emailSwitch;

export class DealerContactInformation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactTypeOptions: props.contactTypeOptions,
      selectedTypes: this.getSelectedTypeText(props.selectedTypes),
      contactTypes: props.selectedTypes,
      index: props.id,
      validationCheck: false
    };

    this.validator = new SimpleReactValidator({
      element: message => <div className="errorMsg">{message.trim().charAt(0).toUpperCase() + message.trim().slice(1)}</div>,
      messages: {
        phone: 'Please enter a valid phone number.',
        required: ':attribute is required.',
        min: ':attribute is required.',
        email: ':attribute is invalid.'
      }
    });

    this.handleContactType = this.handleContactType.bind(this);
  }

  handleContactType = (values) => {
    console.log("onChange", values);
    if (values && Array.isArray(values)) {

      if (this.state == undefined) {
        const updatedSelection = this.options.filter(value => {
          const { value: findId } = value;
          const newValue = values.find(({ value: id }) => findId === id);
          return newValue != null;
        })

        let contactTypes = updatedSelection.map(p => p.value);

        this.setState({ selectedTypes: this.getSelectedTypeText(values), contactTypes },
          () => this.props.onContactUpdated('contactTypes', contactTypes, this.state.index));
      }
      else {
        if (this.state.contactTypeOptions.length == 0) {
          const updatedSelection = this.props.contactTypeOptions.filter(value => {
            const { value: findId } = value;
            const newValue = values.find(({ value: id }) => findId === id);
            return newValue != null;
          })

          let contactTypes = updatedSelection.map(p => p.value);

          this.setState({ selectedTypes: this.getSelectedTypeText(values), contactTypes },
            () => this.props.onContactUpdated('contactTypes', contactTypes, this.state.index));
        }
        else {
          const updatedSelection = this.state.contactTypeOptions.filter(value => {
            const { value: findId } = value;
            const newValue = values.find(({ value: id }) => findId === id);
            return newValue != null;
          })

          let contactTypes = updatedSelection.map(p => p.value);

          this.setState({ selectedTypes: this.getSelectedTypeText(values), contactTypes },
            () => this.props.onContactUpdated('contactTypes', contactTypes, this.state.index));
        }
      }
    }
    this.validator.showMessages();
    this.forceUpdate();
  }

  getSelectedTypeText = (types) => {
    var selectedText = "";
    if (types != null) {
      selectedText = `${types.length} Selected`;
    }
    return selectedText;
  }

  handleInputChange = e => {
    let { name, value, rawValue } = e.target;
    if (name === 'phoneNumber')
      value = rawValue;
    this.props.onContactUpdated(name, value, this.state.index);
  }



  checkValidation = () => {
    this.validator.showMessages();
    this.forceUpdate();
    return this.validator.allValid()
  }

  componentDidUpdate(prevProps) {
    if(this.props?.contactModel?.validationFired  && !this.state.validationCheck ) {
      this.setState({ validationCheck: true })
    }
  }

  componentDidMount() {
    this.props.onContactUpdated("validated", this.validator.allValid(), this.state.index);
  }

  render() {
    const { onDeleteContact, id, contactRadio, onSelect, contactTypeOptions, contactModel, duplicatePhoneId, duplicatePhoneError } = this.props;
    const { selectedTypes, contactTypes } = this.state;
    const { contactPreferences, email, contactId, isPrimary, name, phoneNumber } = contactModel;

    const contactTypesDisplay = contactTypes.map(p => {
      return { "value": p }
    });

    const smsPrefEnabled = contactPreferences == null ? false : contactPreferences.findIndex(cp => cp != null && cp == 1) > -1;
    const emailPrefEnabled = contactPreferences == null ? false : contactPreferences.findIndex(cp => cp != null && cp == 2) > -1;

    return (
      <div
        className={`${contactRadio
            ? classNames(styles.bgSelected, 'mt-2')
            : classNames(
              "bg-transparent m-0",
              styles.unselectedContact,
              `${this.props.key == 0 ? "" : "mt-2"}`
            )
          }`}>
        <div className="d-flex justify-content-between mb-2">
          <div className="d-flex">
            <span className={styles.contactSelection}>
              <input type="radio" id={`selectContact_${id}`} name="contactSelection" onChange={() => onSelect(id)} checked={contactRadio} />
              <label for={`selectContact_${id}`} className={styles.contactSelLabel}>Primary Contact</label>
            </span>
            {/* <span className={styles.primeCont}>Primary Contact</span> */}
          </div>
          <DeleteButton onClick={() => onDeleteContact(id)} />
        </div>
        <Row className={classNames("multiColWrapper m-0", styles.contactInfoInput)}>
          <Col sm="12" md="4" lg="3" className="col-5ths">
            <FormGroup className={(parseInt(selectedTypes.substr(0, 1)) === 0 && this.state.validationCheck) ? "form-error" : ""}>
              <label className="form-label" for={`contactType_${id}`}>Contact type</label>
              <span aria-hidden="true" className="required-indicator">*</span>
              <div id={`contactType_${id}`}><DropDownInputWithCheckbox id={id} onChangedValue={this.handleContactType} options={contactTypeOptions} selectedValues={contactTypesDisplay} selectedText={selectedTypes} /></div>
              {this.validator.message("Contact Type", parseInt(selectedTypes.substr(0, 1)), "required|min:1,num")}
            </FormGroup>
          </Col>
          <Col sm="12" md="4" lg="3" className="col-5ths">
            <FormGroup className={!this.validator.check(name, "required") && this.state.validationCheck ? "form-error" : ""}>
              <label className="form-label" for={`contactName_${id}`}>Name</label>
              <span aria-hidden="true" className="required-indicator">*</span>
              <input type="text" aria-required="true" className="form-control" name="name" id={`contactName_${id}`} value={name} onChange={this.handleInputChange}></input>
              {this.state.validationCheck && this.validator.message("Name", name, "required")}

            </FormGroup>
          </Col>
          <Col sm="12" md="4" lg="3" className="col-5ths">
            <FormGroup className={((!this.validator.check(phoneNumber, "required|phone") || (duplicatePhoneId === id && duplicatePhoneError)) && this.props.showValidationErrors) ? "form-error" : ""}>
              <label className="form-label" for={`contactPhone_${id}`}>Phone</label>
              <span aria-hidden="true" className="required-indicator">*</span>
              <Cleave 
                options={{
                  blocks: [0, 3, 3, 4],
                  delimiters: ['(', ') ', '-'], 
                  numericOnly: true
                }} 
                className="form-control"
                name="phoneNumber" 
                id={`contactPhone_${id}`} 
                value={phoneNumber} 
                onChange={this.handleInputChange} 
                aria-required="true" 
              />
              {/* checking for phone validator or duplicate if any one is true error message is shown*/}
              {((this.props.showValidationErrors &&
                !this.validator.check(phoneNumber, "required|phone")) ||
                (this.props.duplicatePhoneId === id &&
                  this.props.duplicatePhoneError &&
                  this.props.showValidationErrors)) && (
                <div className="errorMsg">
                  Please enter a valid phone number.
                </div>
              )}
             
            </FormGroup>
          </Col>
          <Col sm="12" md="4" lg="3" className="col-5ths">
            <FormGroup className={!this.validator.check(email, "required|email") && this.state.validationCheck ? "form-error" : ""}>
              <label className="form-label" for={`contactEmail_${id}`}>Email</label>
              <span aria-hidden="true" className="required-indicator">*</span>
              <input type="email" aria-required="true" name="email" className="form-control" id={`contactEmail_${id}`} value={email} onChange={this.handleInputChange}></input>
              {this.state.validationCheck && this.validator.message("Email", email, "required|email")}
            </FormGroup>
          </Col>
          <Col sm="12" md="7" lg="3" className="col-5ths">
            <FormGroup>
              <label className="form-label" for="commPreference">Comm Preference</label>
              <div id="commPreference" className={styles.switchWrapper}>
                <label className={classNames(commSwitch, smsSwitch)} for={`smsPref_${id}`}>
                  <input type="checkbox" id={`smsPref_${id}`} name="smsCommEnabled" checked={smsPrefEnabled} onChange={this.handleInputChange} />
                  <span className={classNames(slider, round)}></span>
                </label>
                <label className={classNames(commSwitch, emailSwitch)} for={`emailPref_${id}`}>
                  <input type="checkbox" id={`emailPref_${id}`} name="emailCommEnabled" checked={emailPrefEnabled} onChange={this.handleInputChange} />
                  <span className={classNames(slider, round)}></span>
                </label>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}