const StaplesProfile = {
    companyPhone: "",
    companyFax: "",
    dealerName: "",
    dba: "",
    dealerStatus: 1,
    dealerId: "",
    clientDealerId: "",
    physicalAddress: {
        stateModel: {            
            countryModel: {
                }
            }
        },
    mailingAddress: {
        stateModel: {
            countryModel: {
                }
            }
        },
    country: "United States",
    countryId: 1,
    taxId: null,
    taxIdTypeId: null,
    licenseNumber: "",
    isFloridaDealer: false,
    languageId: 1,
    contacts: [
        ],
    distributorNames: [],
    isLennoxDealer: false,
    advantageProgramStatusId: "",
    advantageProgramName: "",
    isAdvantageEligible: false,
    accountId: 45,
    dealerProgramId: "",
    emailAddress: "",
    partyId: "",
    addressesAreTheSame: false,
    servicer: {
        servicerId: null,
        insuranceAmount: null
        },
    isInvoiceDealer: false,
    dealerDocumentLinks: {},
    dealerTerms: {
        dealerAgreementAcceptanceID: "",
        dealerId: "",
        needsToReacceptFlag: false,
        modifiedBy: "",
        modifiedDate: "",
        createdBy: "",
        createdDate: "",
        dealerModel: null,
        acceptedBy: null
        },
    masterProgramId: "",
    dealerStateId: "",
    isCommercialInstallUnSupported: false,
    legacyDealerId: "",
    dealerFirstName: "",
    dealerLastName: "",
    dealerConfigurations: [],
    emailPreferences: [],
    isInstallTypeVisible: false,
    brandList: {
        accountIds: [
                45
            ],
        brandList: [
            "Staples"
            ]
        }
}

export default StaplesProfile;