import React, { useContext } from 'react';
import {
    Container
} from 'reactstrap';
import NeedHelp from './Modals/NeedHelp/NeedHelp';
import { SessionContext } from '../context/SessionContext';
import styles from '../components/Nav/NavHeader.module.css';
import { isStaplesClient } from '../lib/util';

function FooterContainer() { 
    const session = useContext(SessionContext);
    const { languageCode, translationsFooter, accountName } = session;
    const isStaples = isStaplesClient(accountName);

    const parseHtmlEntities = (str) => {
        let temp = document.createElement('div');
        temp.innerHTML = str;
        return temp.textContent;
    }

    const copyrightText = `Copyright &copy; ${(new Date().getFullYear())}, American International Group, Inc. All rights reserved.`

    const copyrightTextEnglish = parseHtmlEntities(copyrightText);    

    return (
        <>
            {isStaples && 
                <Container className="py-3">
                    <p>
                        Warranty Service Plans are sold by Staples. 
                        Plans are issued and administered by AIG WarrantyGuard, Inc., part of AIG, or one or more of its affiliates. 
                        Plans are subject to detailed terms, conditions, and limitations. For further information, please review the full plan terms and conditions.
                        Staples in not affiliated with AIG or any of its affiliates. Trademarks used with permission.
                    </p>
                </Container>
            }
            <footer role="contentinfo" aria-label="footer">            
                <Container>
                    <ul className="footerNav">
                        <li>
                            <a 
                                href={languageCode === "fr-ca" ? "https://www.aig.ca/fr/renseignements-personnels" : "https://www.aig.com/privacy-policy"}
                                target="_blank" 
                                aria-label="Privacy Policy opens a new details window"
                            >
                                {translationsFooter?.privacyPolicy || "Privacy Policy"}
                            </a>
                        </li>
                        <li>
                            <a 
                                href={languageCode === "fr-ca" ? "https://www.aig.ca/fr/conditions-dutilisation" : "https://www.aig.com/terms-of-use"}
                                target="_blank" 
                                aria-label="Site Terms of Use opens a new details window"
                            >
                                {translationsFooter?.termsOfUse || "Terms Of Use"}
                            </a>
                        </li>
                    </ul>
                    <p className={styles.copyRight}>
                        {translationsFooter?.copyright || copyrightTextEnglish}
                    </p>
                </Container>
                <NeedHelp />
            </footer>
        </>
        
    );  
}

export default FooterContainer;