import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router';
import { Home } from './components/Home';
import '../src/css/global.css';
import '../src/css/dropdown.css';
import '../src/css/datepicker.css';
import '../src/css/autodropdown.css';
import '../src/css/Paginator.css';
import '../src/css/tooltip.css';
import '../src/css/svgStyles.css';

import { AddProducts } from './components/Sales/OrderInformation/AddProducts';
import { AdvantageCampaigns } from './components/AdvantageCampaigns/AdvantageCampaigns';
import { CardDetails } from './components/Sales/PaymentInformation/CardDetails';
import Contract from '../src/components/Contract/Contract';
import { ContractSearch } from './Screens/ContractSearch';
import { CustomerInformation } from './components/Sales/CustomerInformation/CustomerInformation';
import { DealerHome } from './Screens/DealerHome/DealerHome';
import { DealerProfile } from './components/DealerProfile/DealerProfile';
import FooterContainer from '../../ClientApp/src/components/Footer'
import { Header } from './components/Header';
import { InformationReview } from './components/Sales/InformationReview';
import { OrderInfoContainer } from './Screens/OrderInfoContainer';
import { PaymentContainer } from './Screens/PaymentContainer';
import { PaymentMethod } from './components/Sales/PaymentInformation/PaymentMethod';
import { PlanDetails } from '../src/components/Sales/PlanDetails/PlanDetails';
import { SessionContext, SessionProvider } from '../src/context/SessionContext';
import { ViewCampaign } from '../src/components/ViewCampaign/ViewCampaign';
import { WhiteLabelProvider } from './components/BrandingWhiteLabel/WhiteLabelProvider';
import { PaymentGatewayContainer } from './Screens/PaymentGatewayContainer';
import ErrorConfirmation from './components/ErrorConfirmation/ErrorConfirmation';
import DealerTerms from './components/DealerTerms/DealerTerms';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import { ContactUs } from './Screens/ContactUs/ContactUs';
import CertificateOfCoverage from './components/Fulfillment/CertificateOfCoverage';
import { AuthProvider } from '../src/context/AuthProvider';
import { EnrollmentForm } from './components/EnrollmentForm/EnrollmentForm';
import { RFSForm } from './components/RFS/RFSForm';
import { Login } from './Screens/Login';
import Logout from './Screens/Logout';
import { ForgotPassword } from './Screens/ForgotPassword';
import { ConfirmPassword } from './Screens/ConfirmPassword';
import { SubmitInvoice } from '../src/components/SubmitInvoice/SubmitInvoice';
import { InvoiceConfirmation } from '../src/components/Confirmation/InvoiceConfirmation';
import { Authorizations } from '../src/components/Authorizations/Authorizations';
import { AuthorizationConfirmation } from './components/AuthorizationConfirmation/AuthorizationConfirmation';
import ReportsContainer  from './components/Report/ReportsContainer';
import verifycode from './Screens/VerifyCode';
import { DealerLogin } from './Screens/DealerLogin';
import { Transactions } from '../src/components/Transactions/Transactions';
import VendorCreation from './components/VendorCreation/VendorCreation';
import PriceTool from './components/PriceTool/PriceTool';
import DevelopmentBrandToggler from './components/BrandingWhiteLabel/DevelopmentBrandToggler';
import { getEnvironment } from './components/Global/helpers'

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.state = {
            env: ''
        }
    }
    async componentDidMount () {
        const data = await getEnvironment();
        console.log(data, "API DATA")
        this.setState({
            env: data.environment.toLowerCase()
        })
    }

    render() {
        var directRoutes = ["certificate", "pricetool"];

        const isLocal = this.state.env === 'local'
        const isDevelopment = this.state.env === 'development'
        const isStaging = this.state.env === 'staging'
        const isProduction = this.state.env === 'production'
        const isAnyNonProduction = this.state.env !== 'production'
        const isStagingOrLower = isLocal || isDevelopment || isStaging
        const isDevelopmentOrLower = isLocal || isDevelopment

        return (
            <React.Fragment>
            <AuthProvider directRoutes={directRoutes}>
                <SessionProvider>
                        <WhiteLabelProvider>
                            <Header />
                            <main role="main">
                                <Switch>
                                    <Route path='/login' component={Login} />
                                    <Route path='/logout' component={Logout} />
                                    <Route path="/dealerlogin" component={DealerLogin} />
                                    <Route path='/forgotpassword' component={ForgotPassword} />
                                    <Route path='/confirmpassword' component={ConfirmPassword} />
                                    <Route path='/enroll' component={VendorCreation} />
                                    <ProtectedRoute exact path='/' component={Home} />
                                    <Route exact path='/verifycode' component={verifycode} />
                                    <ProtectedRoute exact path='/orderinfo' component={OrderInfoContainer} />
                                    <ProtectedRoute exact path='/addprod' component={AddProducts} />
                                    <ProtectedRoute exact path='/inforeview' component={InformationReview} />                                
                                    <ProtectedRoute exact path="/customerinfo" component={OrderInfoContainer} />
                                    <ProtectedRoute exact path="/plandetails" component={OrderInfoContainer} />
                                    <ProtectedRoute exact path="/carddetails" component={CardDetails} />
                                    <ProtectedRoute exact path="/paymentmethod" component={OrderInfoContainer} />
                                    <ProtectedRoute exact path="/payment" component={OrderInfoContainer} />
                                    <ProtectedRoute exact path="/profile" component={DealerProfile} /> 
                                    <ProtectedRoute exact path='/contractsearch' component={ContractSearch} />
                                    <ProtectedRoute exact path='/viewcampaign' component={ViewCampaign} />
                                    <ProtectedRoute exact path="/advantage" component={AdvantageCampaigns} />
                                    <ProtectedRoute exact path='/contract' component={Contract} />
                                    <ProtectedRoute exact path='/dealerhome' component={DealerHome} />
                                    <ProtectedRoute exact path='/error' component={ErrorConfirmation} />
                                    <ProtectedRoute exact path='/termsandconditions' component={DealerTerms} /> 
                                    <Route exact path='/vendor/paymentgateway' component={PaymentGatewayContainer} /> 
                                    <Route exact path='/certificate' component={CertificateOfCoverage} />
                                    <Route exact path='/requestforservice/:clientId' component={RFSForm} />
                                    <Route exact path='/requestforservice/:clientId/:channelId' component={SubmitInvoice} />
                                    <ProtectedRoute exact path='/vendor/contactus' component={ContactUs} />
                                    <ProtectedRoute exact path='/enrollmentform' component={ErrorConfirmation} />
                                    <Route exact path='/submitinvoice' component={SubmitInvoice} />
                                    <Route exact path='/authorization' component={Authorizations} />
                                    <Route exact path='/authconfirmation' component={AuthorizationConfirmation} />
                                    <Route exact path='/invoiceConfirmation' component={InvoiceConfirmation} />
                                    <Route exact path='/pricetool' component={PriceTool} />
                                    <Route component={ErrorConfirmation} />
                                    <Route exact path='/transactions' component={Transactions} />
                                </Switch>
                            </main>
                            <FooterContainer />
                            {isDevelopmentOrLower && <DevelopmentBrandToggler />}
                        </WhiteLabelProvider>
                </SessionProvider>
            </AuthProvider>       
            </React.Fragment>
        );
    }
}
