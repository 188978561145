import React, { Component } from 'react';
import { getFeatureFlags } from '../Global/helpers';
import qs from 'qs';
import { pdfjs } from "react-pdf";
import styles from './CertificateOfCoverage.module.css';
import { Card, CardBody, Container } from "reactstrap";
import PageLoading from '../shared/PageLoading/PageLoading';
import { SessionContext } from "../../context/SessionContext";
import CertificateOfCoverageError from './CertificateOfCoverageError';
import CertificateCoverage from './CertificateCoverage';

export default class CertificateOfCoverage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contractNumber: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).contractNumber,
            token: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token,
            languageCode: qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).languageCode ?? 'en',
            expiredToken: false,
            contractNotFound: false,
            loadingPdf: true,
            showControlledError: false,
            assetDetails: {},
            showLogo: (qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).languageCode !== undefined || qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).languageCode !== "") ? true : false
        }
    }

    componentDidMount = async () => {
        var flags = await getFeatureFlags();
        let translationFlag = flags?.find(value => value.featureFlagName === "Lennox Translations")?.active ?? false;
        if (translationFlag && (this.state.languageCode !== "en" || this.state.languageCode !== "en-us" || this.state.languageCode !== "en-ca")) {
            this.context.useTranslations("ErrorMessages", this.state.languageCode);
            this.context.useTranslationsHeader("Header", this.state.languageCode);
            this.context.useTranslationsFooter("Footer", this.state.languageCode);
        }
        this.context.setPaymentGatewayStatus(true);   
        this.handleLoadCertificate();
    }

    handleLoadCertificate = async () => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        const validateTokenResponse = await fetch(`token/ValidateToken/${this.state.token}`);
        
        if (validateTokenResponse.ok) {
            // the token is valid
            const languageCodes = ['fr-ca', 'fr-us', 'en-ca', 'en-us'];
            if (languageCodes.includes(this.state.languageCode)) {
                const certificateDetails = await fetch(`contract/GetCertificateOfCoverageDetails/${this.state.contractNumber}/hvac`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/pdf',
                    }
                });
                if (certificateDetails?.status === 200) {
                    const details = await certificateDetails?.json();
                    console.log(details);
                    this.setState({ assetDetails: details, loadingPdf: false })
                    // Load static page using details
                }
                else {
                    this.setState({ showControlledError: true, loadingPdf: false });
                    return;
                }

            } else {
                const res = await fetch(`document/GetCertificateBlob/${this.state.contractNumber}`, {
                    method: 'get',
                    headers: {
                        'Content-Type': 'application/pdf',
                    }
                });

                if (res.status !== 200) {
                    this.setState({ showControlledError: true, loadingPdf: false });
                    return;
                }

                const resData = await res.blob();
                this.setState({ loadingPdf: false });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(resData);
                } else {
                    const url = window.URL.createObjectURL(resData);
                    window.location.assign(url);
                }
            }
        }
        else {
            this.setState({ expiredToken: true, loadingPdf: false });
        }
    }

    handleTryAgain(statePropsToUpdate) {
        this.setState(statePropsToUpdate)
    }

    render() {
        const { accountName, isTranslationsResolved, translations } = this.context;
        
        return (
            <div>
                {(!this.state.expiredToken || !!this.state.assetDetails?.assetDetails?.length) && this.state.loadingPdf && (
                    <Container className="contentWrapper">
                        <Card>
                            <CardBody className={styles.spinnerCard}>
                                <PageLoading text="Certificate is Loading" />
                            </CardBody>
                        </Card>
                    </Container>
                )}
                {(this.state.languageCode !== "en" && Object.keys(translations)?.length === 0) && !this.state.loadingPdf && (
                    <Container className="contentWrapper">
                        <Card>
                            <CardBody className={styles.spinnerCard}>
                                <PageLoading text="Certificate is Loading" />
                            </CardBody>
                        </Card>
                    </Container>
                )}
                {this.state.expiredToken &&
                    <CertificateOfCoverageError
                        accountName={accountName}
                        styles={styles}
                        handleTryAgain={() => this.handleTryAgain({ loadingPdf: true, showControlledError: false })}
                        handleLoadCertificate={this.handleLoadCertificate}
                        expiredToken={true}
                        languageCode={this.state.languageCode}
                    />}

                {this.state.assetDetails?.assetDetails?.length &&
                    <CertificateCoverage
                        assetDetails={this.state.assetDetails}
                        styles={styles}
                        languageCode={this.state.languageCode}
                        showLogo={this.state.showLogo}
                    />
                }

                {this.state.contractNotFound && <p>Unable to locate contract</p>}

                {!this.state.loadingPdf
                    && !this.state.contractNotFound
                    && !this.state.expiredToken
                    && !this.state.showControlledError
                    && !this.state.assetDetails?.assetDetails?.length
                    && <p>Certifcate Processed</p>}

                {this.state.showControlledError && !this.state.expiredTokens &&
                    <CertificateOfCoverageError
                        accountName={accountName}
                        styles={styles}
                        handleTryAgain={() => this.handleTryAgain({ loadingPdf: true, showControlledError: false })}
                        handleLoadCertificate={this.handleLoadCertificate}
                        expiredToken={false}
                        languageCode={this.state.languageCode}
                    />
                }
            </div>
        )
    }
}

CertificateOfCoverage.contextType = SessionContext