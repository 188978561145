import React, { Fragment, useState, useContext, useCallback, useEffect } from "react";
import { Container, Card, Col, Row, CardHeader, Nav, NavLink, NavItem } from "reactstrap";
import MainTabContent from './MainTabContent';
import classnames from 'classnames';
import styles from "./Resources.module.css";
import WhatsCovered from './SalesAids/WhatsCovered/WhatsCovered';
import PlanOptions from './SalesAids/PlanOptions/PlanOptions';
import WhySell from './SalesAids/WhySell/WhySell';
import KeySellingPoints from './SalesAids/KeySellingPoints/KeySellingPoints';
import ComfortShieldprogramFaqs from './Faqs/ComfortShieldprogramFaqs';
import AdvantageProgramFaqs from './Faqs/AdvantageProgramFaqs';
import TraneClaims from './Claims/TraneClaims';
import { getBrandContent } from '../BrandingWhiteLabel/BrandContent';
import { SessionContext } from "../../context/SessionContext";
import { ReactComponent as ArrowIcon } from '../../assets/images/arrow-cheveron.svg';
import { dataLayerPush } from '../../components/Global/helpers';
import { accountDocumentTypes } from '../../components/Global/DocumentHelper';
import DealerDocumentContainer from './DealerDocumentContainer';

const ResourceContainer = () => {
    const [activeTab, setActiveTab] = useState(null);
    const [currentTab, setCurrentTab] = useState(null);
    const [navigationCheck, setNavigationCheck] = useState(false);
    const [hideContent, setHideContent] = useState(true);
    const context = useContext(SessionContext);
    const { accountName } = context;
    const currentBrand = getBrandContent(accountName);
    const accountNameLower = accountName.toLowerCase();
    const profile = context.getProfile();
    const brochureSubheading = 'Download a PDF of the brochure and customize it with your business contact information and logo.';
    const { getCurrentTabAndSubtab, updateTab } = context;

    const { tab } = getCurrentTabAndSubtab();

    const onChangeTab = useCallback((leftTab, topTab) => {
        setCurrentTab(leftTab);
        setActiveTab(topTab);
    }, [activeTab, setActiveTab, currentTab, setCurrentTab]);

    useEffect(() => {
        dataLayerPush('Pageview', {
            pagePath: '/resources',
            pageTitle: 'Resources'
        })
    })

    useEffect(() => {
        if (tab.state !== undefined) {
            setActiveTab(tab.state.activeResourceTab);
            setCurrentTab(tab.state.currentResourceTab);
            setNavigationCheck(tab.state.resourceNavigationCheck);
        } else {
            setActiveTab({ data: 'salesAids', display: "Sales Aids" });
            setCurrentTab(0);
            setNavigationCheck(false);
        }

        if (tab.activeResourceTab) {
            setActiveTab(tab.activeResourceTab);
            setCurrentTab(1);
        }
    }, []);


    useEffect(() => {
        updateTab({
            tabId: tab.tabId,
            tabType: "resources",
            state: {
                activeResourceTab: activeTab,
                currentResourceTab: currentTab,
                resourceNavigationCheck: navigationCheck
            }
        });
    }, [activeTab, currentTab, navigationCheck]);

    const navTabs = {
        lennox: {
            main: [
                { data: 'salesAids', display: "Sales Aids" },
                { data: 'contractManagement', display: "Contract Management" },
                { data: 'marketingMaterials', display: "Marketing Materials" },
                { data: 'claims', display: "Claims" },
                { data: 'faqs', display: "FAQs" }
            ],
            salesAids: [
                { title: "What's Covered", component: <WhatsCovered onClick={() => setCurrentTab(4)} /> }, // each of these should also have the corresponding content component/jsx file. 
                { title: "Plan Options", component: <PlanOptions /> },
                { title: "Why Sell", component: <WhySell /> },
                { title: "Key Selling Points", component: <KeySellingPoints onClick={() => setCurrentTab(4)} /> },
                {
                    title: "Plan Pricing",
                    component: <DealerDocumentContainer
                        key={'Plan Pricing'}
                        accountDocumentType={accountDocumentTypes.PlanPricing}
                        profile={profile}
                        title={'Plan Pricing'}
                        hasScroll={true}
                    />
                },
            ],
            contractManagement: [
                {
                    title: "Contract Transfer",
                    component: <DealerDocumentContainer
                        key={'Contract Transfer'}
                        accountDocumentType={accountDocumentTypes.ContractTransfer}
                        profile={profile}
                        title={'Contract Transfer'}
                    />
                },
                {
                    title: "Customer Cancellation",
                    component: <DealerDocumentContainer
                        key={'Customer Cancellation'}
                        accountDocumentType={accountDocumentTypes.CustomerCancellation}
                        profile={profile}
                        title={'Customer Cancellation'}
                    />
                },
                {
                    title: "Cancellation And Rewritten",
                    component: <DealerDocumentContainer
                        key={'Cancellation And Rewritten'}
                        accountDocumentType={accountDocumentTypes.CancellationAndRewritten}
                        profile={profile}
                        title={'Cancellation And Rewritten'}
                    />
                },
            ],
            marketingMaterials: [
                {
                    title: "Consumer Brochure",
                    component: <DealerDocumentContainer
                        key={'Consumer Brochure'}
                        accountDocumentType={accountDocumentTypes.ConsumerBrochure}
                        profile={profile}
                        title={`Consumer Brochure ${currentBrand.programName}`}
                        subHeading={brochureSubheading}
                    />
                },
                {
                    title: "Dealer Brochure",
                    component: <DealerDocumentContainer
                        key={'Dealer Brochure'}
                        accountDocumentType={accountDocumentTypes.DealerBrochure}
                        profile={profile}
                        title={`Dealer Brochure ${currentBrand.programName}`}
                        hasScroll={true}
                    />
                },
                {
                    title: "Why Sell Comfort Shield",
                    component: <DealerDocumentContainer
                        key={'Why Sell'}
                        accountDocumentType={accountDocumentTypes.WhySell}
                        profile={profile}
                        title={`Why Sell ${currentBrand.programName}`}
                    />
                },
                {
                    title: "Program Updates",
                    component: <DealerDocumentContainer
                        key={'Program Updates'}
                        accountDocumentType={accountDocumentTypes.ProgramUpdates}
                        profile={profile}
                        title={`Program Updates`}
                    />
                },
            ],
            claims: [
                {
                    title: "Manual Claim Form",
                    component: <DealerDocumentContainer
                        key={`Manual Claim Form`}
                        accountDocumentType={accountDocumentTypes.ManualClaimForm}
                        profile={profile}
                        title={`Manual Claim Form`}
                    />
                },
                {
                    title: "Repair Times Matrix",
                    component: <DealerDocumentContainer
                        key={`Repair Times Matrix`}
                        accountDocumentType={accountDocumentTypes.RepairTimesMatrix}
                        profile={profile}
                        title={`Repair Times Matrix`}
                        hasScroll={true}
                    />
                },
                {
                    title: "Combined Repair Times Matrix",
                    component: <DealerDocumentContainer
                        key={`Combined Repair Times Matrix`}
                        accountDocumentType={accountDocumentTypes.RepairTimesMatrix}
                        profile={profile}
                        identifier={'Combined'}
                        title={`Combined Repair Times Matrix`}
                        hasScroll={true}
                    />
                },
            ],
            faqs: [
                { title: `${currentBrand.programName} Program FAQS`, component: <ComfortShieldprogramFaqs /> },
                { title: `${currentBrand.advantageProgramName} FAQS`, component: <AdvantageProgramFaqs changeTab={onChangeTab} /> },
            ],
        },
        rheem: {
            main: [
                { data: 'salesAids', display: "Sales Aids" },
                { data: 'contractManagement', display: "Contract Management" },
                { data: 'marketingMaterials', display: "Marketing Materials" },
                { data: 'claims', display: "Claims" },
                { data: 'faqs', display: "FAQs" }
            ],
            salesAids: [
                { title: "What's Covered", component: <WhatsCovered onClick={() => setCurrentTab(4)} /> }, // each of these should also have the corresponding content component/jsx file. 
                { title: "Plan Options", component: <PlanOptions /> },
                { title: "Why Sell", component: <WhySell /> },
                { title: "Key Selling Points", component: <KeySellingPoints onClick={() => setCurrentTab(4)} /> },
                {
                    title: "Plan Pricing",
                    component: <DealerDocumentContainer
                        key={'Plan Pricing'}
                        accountDocumentType={accountDocumentTypes.PlanPricing}
                        profile={profile}
                        title={'Plan Pricing'}
                        hasScroll={true}
                    />
                },
            ],
            contractManagement: [
                {
                    title: "Contract Transfer",
                    component: <DealerDocumentContainer
                        key={'Contract Transfer'}
                        accountDocumentType={accountDocumentTypes.ContractTransfer}
                        profile={profile}
                        title={'Contract Transfer'}
                    />
                },
                {
                    title: "Customer Cancellation",
                    component: <DealerDocumentContainer
                        key={'Customer Cancellation'}
                        accountDocumentType={accountDocumentTypes.CustomerCancellation}
                        profile={profile}
                        title={'Customer Cancellation'}
                    />
                },
            ],
            marketingMaterials: [
                {
                    title: "Dealer Booklet",
                    component: <DealerDocumentContainer
                        key={'Dealer Booklet'}
                        accountDocumentType={accountDocumentTypes.DealerBrochure}
                        profile={profile}
                        identifier={'Booklet'}
                        title={`Dealer Booklet ${currentBrand.programName}`}
                        hasScroll={true}
                    />
                },
                {
                    title: "Why Sell",
                    component: <DealerDocumentContainer
                        key={'Why Sell'}
                        accountDocumentType={accountDocumentTypes.WhySell}
                        profile={profile}
                        title={`Why Sell ${currentBrand.programName}`}
                    />
                },
            ],
            claims: [
                {
                    title: "Manual Claim Form",
                    component: <DealerDocumentContainer
                        key={`Manual Claim Form`}
                        accountDocumentType={accountDocumentTypes.ManualClaimForm}
                        profile={profile}
                        title={`Manual Claim Form`}
                    />
                },
                {
                    title: "Reimbursement Schedule",
                    component: <DealerDocumentContainer
                        key={`Reimbursement Schedule`}
                        accountDocumentType={accountDocumentTypes.ReimbursementSchedules}
                        profile={profile}
                        title={`Reimbursement Schedule`}
                    />
                },
                {
                    title: "Core Plan Repair Hours",
                    component: <DealerDocumentContainer
                        key={`Core Plan Repair Hours`}
                        accountDocumentType={accountDocumentTypes.ClaimRepairHours}
                        profile={profile}
                        identifier={'Core'}
                        title={`Core Plan Repair Hours`}
                    />
                },
                {
                    title: "Premium Plan Repair Codes",
                    component: <DealerDocumentContainer
                        key={`Premium Plan Repair Codes`}
                        accountDocumentType={accountDocumentTypes.ClaimRepairCodes}
                        profile={profile}
                        identifier={'Premium'}
                        title={`Premium Plan Repair Codes`}
                    />
                },
            ],
            faqs: [
                { title: `${currentBrand.programName} Program FAQS`, component: <ComfortShieldprogramFaqs /> },
                { title: `${currentBrand.advantageProgramName} FAQS`, component: <AdvantageProgramFaqs changeTab={onChangeTab} /> },
            ],
        },
        carrier: {
            main: [
                { data: 'salesAids', display: "Sales Aids" },
                { data: 'contractManagement', display: "Contract Management" },
                { data: 'marketingMaterials', display: "Marketing Materials" },
                { data: 'claims', display: "Claims" },
                { data: 'faqs', display: "FAQs" }
            ],
            salesAids: [
                { title: "What's Covered", component: <WhatsCovered onClick={() => setCurrentTab(4)} /> }, // each of these should also have the corresponding content component/jsx file. 
                { title: "Plan Options", component: <PlanOptions /> },
                { title: "Why Sell", component: <WhySell /> },
                { title: "Key Selling Points", component: <KeySellingPoints onClick={() => setCurrentTab(4)} /> },
                {
                    title: "Plan Pricing",
                    component: <DealerDocumentContainer
                        key={'Plan Pricing'}
                        accountDocumentType={accountDocumentTypes.PlanPricing}
                        profile={profile}
                        title={'Plan Pricing'}
                        hasScroll={true}
                    />
                },
            ],
            contractManagement: [
                {
                    title: "Contract Transfer",
                    component: <DealerDocumentContainer
                        key={'Contract Transfer'}
                        accountDocumentType={accountDocumentTypes.ContractTransfer}
                        profile={profile}
                        title={'Contract Transfer'}
                    />
                },
                {
                    title: "Customer Cancellation",
                    component: <DealerDocumentContainer
                        key={'Customer Cancellation'}
                        accountDocumentType={accountDocumentTypes.CustomerCancellation}
                        profile={profile}
                        title={'Customer Cancellation'}
                    />
                },
            ],
            marketingMaterials: [
                {
                    title: "Consumer Brochure",
                    component: <DealerDocumentContainer
                        key={'Consumer Brochure'}
                        accountDocumentType={accountDocumentTypes.ConsumerBrochure}
                        profile={profile}
                        title={`Consumer Brochure ${currentBrand.programName}`}
                        subHeading={brochureSubheading}
                    />
                },
                {
                    title: "Dealer Booklet",
                    component: <DealerDocumentContainer
                        key={'Dealer Booklet'}
                        accountDocumentType={accountDocumentTypes.DealerBrochure}
                        profile={profile}
                        identifier={'Booklet'}
                        title={`Dealer Booklet ${currentBrand.programName}`}
                        hasScroll={true}
                    />
                }
            ],
            claims: [
                {
                    title: "Manual Claim Form",
                    component: <DealerDocumentContainer
                        key={`Manual Claim Form`}
                        accountDocumentType={accountDocumentTypes.ManualClaimForm}
                        profile={profile}
                        title={`Manual Claim Form`}
                        hasScroll={true}
                    />
                },
                {
                    title: "Reimbursement Schedule",
                    component: <DealerDocumentContainer
                        key={`Reimbursement Schedule`}
                        accountDocumentType={accountDocumentTypes.ReimbursementSchedules}
                        profile={profile}
                        title={`Reimbursement Schedule`}
                        hasScroll={true}
                    />
                },
                {
                    title: "Prime Plan Repair Hours",
                    component: <DealerDocumentContainer
                        key={`Prime Plan Repair Hours`}
                        accountDocumentType={accountDocumentTypes.ClaimRepairHours}
                        profile={profile}
                        identifier={'Prime'}
                        title={`Prime Plan Repair Hours`}
                    />
                },

            ],
            faqs: [
                { title: `${currentBrand.programName} Program FAQS`, component: <ComfortShieldprogramFaqs /> },
                { title: `${currentBrand.advantageProgramName} FAQS`, component: <AdvantageProgramFaqs changeTab={onChangeTab} /> },
            ],
        },
        trane: {
            main: [
                { data: 'salesAids', display: "Sales Aids" },
                { data: 'contractManagement', display: "Contract Management" },
                { data: 'marketingMaterials', display: "Marketing Materials" },
                { data: 'claims', display: "Claims" },
                { data: 'faqs', display: "FAQs" }
            ],
            salesAids: [
                { title: "What's Covered", component: <WhatsCovered onClick={() => setCurrentTab(4)} /> }, // each of these should also have the corresponding content component/jsx file. 
                { title: "Plan Options", component: <PlanOptions /> },
                { title: "Why Sell", component: <WhySell /> },
                { title: "Key Selling Points", component: <KeySellingPoints onClick={() => setCurrentTab(4)} /> },
                {
                    title: "Plan Pricing",
                    component: <DealerDocumentContainer
                        key={'Plan Pricing'}
                        accountDocumentType={accountDocumentTypes.PlanPricing}
                        profile={profile}
                        title={'Plan Pricing'}
                        hasScroll={true}
                    />
                },
            ],
            contractManagement: [
                {
                    title: "Contract Transfer",
                    component: <DealerDocumentContainer
                        key={'Contract Transfer'}
                        accountDocumentType={accountDocumentTypes.ContractTransfer}
                        profile={profile}
                        title={'Contract Transfer'}
                    />
                },
                {
                    title: "Customer Cancellation",
                    component: <DealerDocumentContainer
                        key={'Customer Cancellation'}
                        accountDocumentType={accountDocumentTypes.CustomerCancellation}
                        profile={profile}
                        title={'Customer Cancellation'}
                    />
                },
            ],
            marketingMaterials: [
                {
                    title: "Consumer Brochure",
                    component: <DealerDocumentContainer
                        key={'Consumer Brochure'}
                        accountDocumentType={accountDocumentTypes.ConsumerBrochure}
                        profile={profile}
                        title={`Consumer Brochure ${currentBrand.programName}`}
                        subHeading={brochureSubheading}
                    />
                },
                {
                    title: "Dealer Brochure",
                    component: <DealerDocumentContainer
                        key={'Dealer Brochure'}
                        accountDocumentType={accountDocumentTypes.DealerBrochure}
                        profile={profile}
                        title={`Dealer Brochure ${currentBrand.programName}`}
                    />
                },
                {
                    title: `Why Sell ${currentBrand.programName}`,
                    component: <DealerDocumentContainer
                        key={'Why Sell'}
                        accountDocumentType={accountDocumentTypes.WhySell}
                        profile={profile}
                        title={`Why Sell ${currentBrand.programName}`}
                        hasScroll={true}
                    />
                },
                {
                    title: "Getting Started",
                    component: <DealerDocumentContainer
                        key={'Getting Started'}
                        accountDocumentType={accountDocumentTypes.GettingStarted}
                        profile={profile}
                        title={`Getting Started`}
                    />
                },
            ],
            claims: [
                {
                    title: 'Manual Claim Form',
                    component: <DealerDocumentContainer
                        key={`Manual Claim Form`}
                        accountDocumentType={accountDocumentTypes.ManualClaimForm}
                        profile={profile}
                        title={`Manual Claim Form`}
                    />
                },
                {
                    title: "Reimbursement Schedule",
                    component: <DealerDocumentContainer
                        key={`Reimbursement Schedule`}
                        accountDocumentType={accountDocumentTypes.ReimbursementSchedules}
                        profile={profile}
                        title={`Reimbursement Schedule`}
                    />
                },
            ],
            faqs: [
                { title: `${currentBrand.programName} Program FAQS`, component: <ComfortShieldprogramFaqs /> },
            ],
        },
        ge: {
            main: [
                { data: 'salesAids', display: "Sales Aids" },
                { data: 'contractManagement', display: "Contract Management" },
                { data: 'marketingMaterials', display: "Marketing Materials" },
                { data: 'claims', display: "Claims" },
                { data: 'faqs', display: "FAQS" }
            ],
            salesAids: [
                { title: "What's Covered", component: <WhatsCovered onClick={() => setCurrentTab(4)} /> }, // each of these should also have the corresponding content component/jsx file. 
                { title: "Plan Options", component: <PlanOptions /> },
                { title: "Why Sell", component: <WhySell /> },
                { title: "Key Selling Points", component: <KeySellingPoints onClick={() => setCurrentTab(4)} /> },
                {
                    title: "Plan Pricing",
                    component: <DealerDocumentContainer
                        key={'Plan Pricing'}
                        accountDocumentType={accountDocumentTypes.PlanPricing}
                        profile={profile}
                        title={'Plan Pricing'}
                        hasScroll={true}
                    />
                },
            ],
            contractManagement: [
                {
                    title: "Contract Transfer",
                    component: <DealerDocumentContainer
                        key={'Contract Transfer'}
                        accountDocumentType={accountDocumentTypes.ContractTransfer}
                        profile={profile}
                        title={'Contract Transfer'}
                    />
                },
                {
                    title: "Customer Cancellation",
                    component: <DealerDocumentContainer
                        key={'Customer Cancellation'}
                        accountDocumentType={accountDocumentTypes.CustomerCancellation}
                        profile={profile}
                        title={'Customer Cancellation'}
                    />
                },
            ],
            marketingMaterials: [
                {
                    title: "Consumer Brochure",
                    component: <DealerDocumentContainer
                        key={'Consumer Brochure'}
                        accountDocumentType={accountDocumentTypes.ConsumerBrochure}
                        profile={profile}
                        title={`Consumer Brochure ${currentBrand.programName}`}
                        subHeading={brochureSubheading}
                    />
                },
                {
                    title: "Dealer Brochure",
                    component: <DealerDocumentContainer
                        key={'Dealer Brochure'}
                        accountDocumentType={accountDocumentTypes.DealerBrochure}
                        profile={profile}
                        title={`Dealer Brochure ${currentBrand.programName}`}
                    />
                },
                {
                    title: `Why Sell ${currentBrand.programName}`,
                    component: <DealerDocumentContainer
                        key={'Why Sell'}
                        accountDocumentType={accountDocumentTypes.WhySell}
                        profile={profile}
                        title={`Why Sell ${currentBrand.programName}`}
                        hasScroll={true}
                    />
                },
                {
                    title: "Getting Started",
                    component: <DealerDocumentContainer
                        key={'Getting Started'}
                        accountDocumentType={accountDocumentTypes.GettingStarted}
                        profile={profile}
                        title={`Getting Started`}
                    />
                },
            ],
            claims: [
                {
                    title: 'Manual Claim Form',
                    component: <DealerDocumentContainer
                        key={`Manual Claim Form`}
                        accountDocumentType={accountDocumentTypes.ManualClaimForm}
                        profile={profile}
                        title={`Manual Claim Form`}
                    />
                },
                {
                    title: 'Repair Times Matrix',
                    component: <DealerDocumentContainer
                        key={`Repair Times Matrix`}
                        accountDocumentType={accountDocumentTypes.RepairTimesMatrix}
                        profile={profile}
                        title={`Repair Times Matrix`}
                        hasScroll={true}
                    />
                },
            ],
            faqs: [
                { title: `${currentBrand.programName} Program FAQS`, component: <ComfortShieldprogramFaqs /> },
            ],
      },
      icp: {
        main: [
          { data: 'salesAids', display: "Sales Aids" },
          { data: 'contractManagement', display: "Contract Management" },
          { data: 'marketingMaterials', display: "Marketing Materials" },
          { data: 'claims', display: "Claims" },
          { data: 'faqs', display: "FAQS" }
        ],
        salesAids: [
          { title: "What's Covered", component: <WhatsCovered onClick={() => setCurrentTab(4)} /> }, // each of these should also have the corresponding content component/jsx file. 
          { title: "Plan Options", component: <PlanOptions /> },
          { title: "Why Sell", component: <WhySell /> },
          { title: "Key Selling Points", component: <KeySellingPoints onClick={() => setCurrentTab(4)} /> },
          {
            title: "Plan Pricing",
            component: <DealerDocumentContainer
              key={'Plan Pricing'}
              accountDocumentType={accountDocumentTypes.PlanPricing}
              profile={profile}
              title={'Plan Pricing'}
              hasScroll={true}
            />
          },
        ],
        contractManagement: [
          {
            title: "Contract Transfer",
            component: <DealerDocumentContainer
              key={'Contract Transfer'}
              accountDocumentType={accountDocumentTypes.ContractTransfer}
              profile={profile}
              title={'Contract Transfer'}
            />
          },
          {
            title: "Customer Cancellation",
            component: <DealerDocumentContainer
              key={'Customer Cancellation'}
              accountDocumentType={accountDocumentTypes.CustomerCancellation}
              profile={profile}
              title={'Customer Cancellation'}
            />
          },
        ],
        marketingMaterials: [
          {
            title: "Consumer Brochure",
            component: <DealerDocumentContainer
              key={'Consumer Brochure'}
              accountDocumentType={accountDocumentTypes.ConsumerBrochure}
              profile={profile}
              title={`Consumer Brochure ${currentBrand.programName}`}
              subHeading={brochureSubheading}
            />
          },
          {
            title: "Dealer Booklet",
            component: <DealerDocumentContainer
              key={'Dealer Booklet'}
              accountDocumentType={accountDocumentTypes.DealerBrochure}
              profile={profile}
              identifier={'Booklet'}
              title={`Dealer Booklet ${currentBrand.programName}`}
              hasScroll={true}
            />
          },
        ],
        claims: [
          {
            title: 'Manual Claim Form',
            component: <DealerDocumentContainer
              key={`Manual Claim Form`}
              accountDocumentType={accountDocumentTypes.ManualClaimForm}
              profile={profile}
              title={`Manual Claim Form`}
            />
          },
          {
            title: "Reimbursement Schedule",
            component: <DealerDocumentContainer
              key={`Reimbursement Schedule`}
              accountDocumentType={accountDocumentTypes.ReimbursementSchedules}
              profile={profile}
              title={`Reimbursement Schedule`}
            />
          },
        ],
        faqs: [
          { title: `${currentBrand.programName} FAQs`, component: <ComfortShieldprogramFaqs changeTab={onChangeTab}/> },
          { title: `${currentBrand.advantageProgramName} FAQs`, component: <AdvantageProgramFaqs changeTab={onChangeTab} /> },
        ]
      },
      'homedepot': {
        main: [
          { data: 'salesAids', display: "Sales Aids" },
          { data: 'contractManagement', display: "Contract Management" },
          { data: 'marketingMaterials', display: "Marketing Materials" },
          { data: 'claims', display: "Claims" },
          { data: 'faqs', display: "FAQS" }
        ],
        salesAids: [
          { title: "What's Covered", component: <WhatsCovered onClick={() => setCurrentTab(4)} /> }, // each of these should also have the corresponding content component/jsx file. 
          { title: "Plan Options", component: <PlanOptions /> }, // Commenting out until branding is implemented
          { title: "Why Sell", component: <WhySell /> },
          { title: "Key Selling Points", component: <KeySellingPoints onClick={() => setCurrentTab(4)} /> },
          {
            title: "Plan Pricing",
            component: <DealerDocumentContainer
              key={'Plan Pricing'}
              accountDocumentType={accountDocumentTypes.PlanPricing}
              profile={profile}
              title={'Plan Pricing'}
              hasScroll={true}
            />
          },
        ],
        contractManagement: [
          {
            title: "Contract Transfer",
            component: <DealerDocumentContainer
              key={'Contract Transfer'}
              accountDocumentType={accountDocumentTypes.ContractTransfer}
              profile={profile}
              title={'Contract Transfer'}
            />
          },
          {
            title: "Customer Cancellation",
            component: <DealerDocumentContainer
              key={'Customer Cancellation'}
              accountDocumentType={accountDocumentTypes.CustomerCancellation}
              profile={profile}
              title={'Customer Cancellation'}
            />
          },
        ],
        marketingMaterials: [
          {
            title: "Consumer Brochure",
            component: <DealerDocumentContainer
              key={'Consumer Brochure'}
              accountDocumentType={accountDocumentTypes.ConsumerBrochure}
              profile={profile}
              title={`Consumer Brochure ${currentBrand.programName}`}
              subHeading={brochureSubheading}
            />
          },
          {
            title: "Dealer Booklet",
            component: <DealerDocumentContainer
              key={'Dealer Booklet'}
              accountDocumentType={accountDocumentTypes.DealerBrochure}
              profile={profile}
              identifier={'Booklet'}
              title={`Dealer Booklet ${currentBrand.programName}`}
              hasScroll={true}
            />
          },
          {
            title: `Why Sell ${currentBrand.programName} `,
            component: <DealerDocumentContainer
              key={'Why Sell'}
              accountDocumentType={accountDocumentTypes.WhySell}
              profile={profile}
              title={`Why Sell ${currentBrand.programName}`}
            />
          },
          {
            title: "Program Updates",
            component: <DealerDocumentContainer
              key={'Program Updates'}
              accountDocumentType={accountDocumentTypes.ProgramUpdates}
              profile={profile}
              title={`Program Updates`}
            />
          },
        ],
        claims: [
          {
            title: 'Manual Claim Form',
            component: <DealerDocumentContainer
              key={`Manual Claim Form`}
              accountDocumentType={accountDocumentTypes.ManualClaimForm}
              profile={profile}
              title={`Manual Claim Form`}
            />
          },
          {
            title: "Reimbursement Schedule",
            component: <DealerDocumentContainer
              key={`Reimbursement Schedule`}
              accountDocumentType={accountDocumentTypes.ReimbursementSchedules}
              profile={profile}
              title={`Reimbursement Schedule`}
            />
          },
        ],
        faqs: [
          { title: `${currentBrand.programName} FAQs`, component: <ComfortShieldprogramFaqs changeTab={onChangeTab}/> },
          { title: `${currentBrand.advantageProgramName} FAQs`, component: <AdvantageProgramFaqs changeTab={onChangeTab} /> },
        ]
      },
      'mcc': {
        main: [
          { data: 'salesAids', display: "Sales Aids" },
          { data: 'contractManagement', display: "Contract Management" },
          { data: 'marketingMaterials', display: "Marketing Materials" },
          { data: 'claims', display: "Claims" },
          { data: 'faqs', display: "FAQS" }
        ],
        salesAids: [
          { title: "What's Covered", component: <WhatsCovered onClick={() => setCurrentTab(4)} /> }, // each of these should also have the corresponding content component/jsx file. 
          { title: "Plan Options", component: <PlanOptions /> }, // Commenting out until branding is implemented
          { title: "Why Sell", component: <WhySell /> },
          { title: "Key Selling Points", component: <KeySellingPoints onClick={() => setCurrentTab(4)} /> },
          {
            title: "Plan Pricing",
            component: <DealerDocumentContainer
              key={'Plan Pricing'}
              accountDocumentType={accountDocumentTypes.PlanPricing}
              profile={profile}
              title={'Plan Pricing'}
              hasScroll={true}
            />
          },
        ],
        contractManagement: [
          {
            title: "Contract Transfer",
            component: <DealerDocumentContainer
              key={'Contract Transfer'}
              accountDocumentType={accountDocumentTypes.ContractTransfer}
              profile={profile}
              title={'Contract Transfer'}
            />
          },
          {
            title: "Customer Cancellation",
            component: <DealerDocumentContainer
              key={'Customer Cancellation'}
              accountDocumentType={accountDocumentTypes.CustomerCancellation}
              profile={profile}
              title={'Customer Cancellation'}
            />
          },
        ],
        marketingMaterials: [
          {
            title: "Dealer Booklet",
            component: <DealerDocumentContainer
              key={'Dealer Booklet'}
              accountDocumentType={accountDocumentTypes.DealerBrochure}
              profile={profile}
              identifier={'Booklet'}
              title={`Dealer Booklet ${currentBrand.programName}`}
              hasScroll={true}
            />
          },
          {
            title: `Why Sell ${currentBrand.programName} `,
            component: <DealerDocumentContainer
              key={'Why Sell'}
              accountDocumentType={accountDocumentTypes.WhySell}
              profile={profile}
              title={`Why Sell ${currentBrand.programName}`}
            />
          }
        ],
        claims: [
          {
            title: 'Manual Claim Form',
            component: <DealerDocumentContainer
              key={`Manual Claim Form`}
              accountDocumentType={accountDocumentTypes.ManualClaimForm}
              profile={profile}
              title={`Manual Claim Form`}
            />
          },
          {
            title: "Reimbursement Schedule",
            component: <DealerDocumentContainer
              key={`Reimbursement Schedule`}
              accountDocumentType={accountDocumentTypes.ReimbursementSchedules}
              profile={profile}
              title={`Reimbursement Schedule`}
            />
          },
        ],
        faqs: [
          { title: `${currentBrand.programName} FAQs`, component: <ComfortShieldprogramFaqs /> },
          { title: `${currentBrand.advantageProgramName} FAQs`, component: <AdvantageProgramFaqs changeTab={onChangeTab} /> }
        ]
      },
      'nordyne': {
        main: [
          { data: 'salesAids', display: "Sales Aids" },
          { data: 'contractManagement', display: "Contract Management" },
          { data: 'marketingMaterials', display: "Marketing Materials" },
          { data: 'claims', display: "Claims" },
          { data: 'faqs', display: "FAQS" }
        ],
        salesAids: [
          { title: "What's Covered", component: <WhatsCovered onClick={() => setCurrentTab(4)} /> }, // each of these should also have the corresponding content component/jsx file. 
          { title: "Plan Options", component: <PlanOptions /> }, // Commenting out until branding is implemented
          { title: "Why Sell", component: <WhySell /> },
          { title: "Key Selling Points", component: <KeySellingPoints onClick={() => setCurrentTab(4)} /> },
          {
            title: "Plan Pricing",
            component: <DealerDocumentContainer
              key={'Plan Pricing'}
              accountDocumentType={accountDocumentTypes.PlanPricing}
              profile={profile}
              title={'Plan Pricing'}
              hasScroll={true}
            />
          },
        ],
        contractManagement: [
          {
            title: "Contract Transfer",
            component: <DealerDocumentContainer
              key={'Contract Transfer'}
              accountDocumentType={accountDocumentTypes.ContractTransfer}
              profile={profile}
              title={'Contract Transfer'}
            />
          },
          {
            title: "Customer Cancellation",
            component: <DealerDocumentContainer
              key={'Customer Cancellation'}
              accountDocumentType={accountDocumentTypes.CustomerCancellation}
              profile={profile}
              title={'Customer Cancellation'}
            />
          },
        ],
        marketingMaterials: [
          {
            title: "Dealer Booklet",
            component: <DealerDocumentContainer
              key={'Dealer Booklet'}
              accountDocumentType={accountDocumentTypes.DealerBrochure}
              profile={profile}
              identifier={'Booklet'}
              title={`Dealer Booklet ${currentBrand.programName}`}
              hasScroll={true}
            />
          },
          {
            title: `Why Sell ${currentBrand.programName} `,
            component: <DealerDocumentContainer
              key={'Why Sell'}
              accountDocumentType={accountDocumentTypes.WhySell}
              profile={profile}
              title={`Why Sell ${currentBrand.programName}`}
            />
          },
        ],
        claims: [
          {
            title: 'Manual Claim Form',
            component: <DealerDocumentContainer
              key={`Manual Claim Form`}
              accountDocumentType={accountDocumentTypes.ManualClaimForm}
              profile={profile}
              title={`Manual Claim Form`}
            />
          },
          {
            title: "Reimbursement Schedule",
            component: <DealerDocumentContainer
              key={`Reimbursement Schedule`}
              accountDocumentType={accountDocumentTypes.ReimbursementSchedules}
              profile={profile}
              title={`Reimbursement Schedule`}
            />
          },
        ],
        faqs: [
          { title: `${currentBrand.programName} FAQs`, component: <ComfortShieldprogramFaqs changeTab={onChangeTab} /> },
          { title: `${currentBrand.advantageProgramName} FAQs`, component: <AdvantageProgramFaqs changeTab={onChangeTab} /> },
        ]
      }
    };

    const setMobileNavigation = (val) => {
        setNavigationCheck(val);
    }

    const handleMobileContent = () => {
        setHideContent(false);
    }
    
    return (
        <div>
            {activeTab !== null && <main role="main">
                <Container className="contentWrapper">
                    <Card className="d-none d-sm-block">
                        <div className="mt-2" id={styles.topNav}>
                            <Nav tabs role="tablist"
                                className={classnames(styles.nav)} >
                                {navTabs[accountNameLower].main.map((navTab, index) => (
                                    <ul
                                        className={classnames("mt-3", { "ul": true }, navTab.data === activeTab.data ? styles.ActiveTopNav : "")}
                                        key={index}>
                                        <li
                                            onClick={() => {
                                                setCurrentTab(0);
                                                setActiveTab(navTab);
                                            }}>
                                            {navTab.display}
                                        </li>
                                    </ul>
                                ))}
                            </Nav>
                        </div>
                        <MainTabContent sideNavTabs={navTabs[accountNameLower]} mainTab={activeTab} activeTab={currentTab} onTabChange={setCurrentTab} />
                    </Card>
                    <Card className="d-block d-sm-none">
                        {navigationCheck && <Row>
                            <Col onClick={() => { setMobileNavigation(false); setHideContent(true); }} className="pt-3 pb-1 px-4 ml-2 ml-md-0 media">
                                <span className={classnames(styles.iconContainer, "mr-1")}><ArrowIcon /></span>
                                <h1 className="h6 mt-1">Resources</h1>
                            </Col>
                        </Row>}
                        {!navigationCheck && <div className={classnames(styles.sideNav)}>
                            <ul >
                                {navTabs[accountNameLower].main.map((navTab, index) => (
                                    <li
                                        className={classnames(index === activeTab ? styles.activeSideTab : "", "m")}
                                        key={index}
                                        onClick={() => {
                                            setCurrentTab(0);
                                            setActiveTab(navTab);
                                            setMobileNavigation(true);
                                        }}>{navTab.display}
                                    </li>
                                ))}
                            </ul>
                        </div>}
                        <MainTabContent
                            sideNavTabs={navTabs[accountNameLower]}
                            mainTab={activeTab}
                            activeTab={currentTab}
                            onTabChange={setCurrentTab}
                            navigationCheck={navigationCheck}
                            hideContent={hideContent}
                            handleMobileContent={handleMobileContent}
                        />
                    </Card>
                </Container>
            </main>}
        </div>
    )
}
export default ResourceContainer;