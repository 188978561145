import React, { Component, Fragment } from "react";
import { Container, Row, Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import styles from "./OrderInfoContainer.module.css";
import classNames from "classnames";
import { PlanDetails } from "../components/Sales/PlanDetails/PlanDetails";
import { InformationReview } from "../components/Sales/InformationReview";
import { CustomerInformation } from "../components/Sales/CustomerInformation/CustomerInformation";
import { OrderInformation } from "../components/Sales/OrderInformation/OrderInformation"
import 'react-circular-progressbar/dist/styles.css';
import { ProgressBar } from '../components/Sales/ProgressBar';
import { AddProducts } from "../components/Sales/OrderInformation/AddProducts";
import { AddProductInputs } from "../components/Sales/OrderInformation/AddProductInputs";
import { OrderInfoProvider } from '../context/OrderInfoProvider';
import OrderInfoContext from "../context/OrderInfoContext";
import { SessionProvider, SessionContext } from "../context/SessionContext";
import { PaymentMethod } from "../components/Sales/PaymentInformation/PaymentMethod"
import { PaymentContainer } from './PaymentContainer';
import { Confirmation } from "../components/Sales/Confirmation/Confirmation";
import { AdvPlanSummary } from "../components/Modals/AdvPlanSummary";
import { SaveSaleModal } from "../components/Modals/SaveSaleModal/SaveSaleModal";
import BottomButtons from "../components/shared/BottomButtonWrapper/BottomButtons";
import NumberFormat from 'react-number-format';
import moment from 'moment';
import PageLoading from '../components/shared/PageLoading/PageLoading';
import { Spinner } from 'react-bootstrap';
import { DealerProfile } from "../components/DealerProfile/DealerProfile";
import { NotificationIds } from '../components/Global/NotificationConstants';
import { getBrandContent } from '../components/BrandingWhiteLabel/BrandContent';
import { dataLayerPush } from '../components/Global/helpers';
import SpinnerButton from '../components/shared/SpinnerButton/SpinnerButton';
import { ProductTable } from "../components/Sales/OrderInformation/ProductTable";
import { SaveSaleConfirmation } from '../components/Sales/SaveSaleConfirmation';
import { getCurrencySymbol } from '../lib/util';

let customPayBtn = styles.customPayBtn;

export class OrderInfoContainer extends Component {
    constructor(props) {
        super(props);

    if (props.state != undefined)
      this.state = props.state;
    else {
      this.state = {
        allowEdit: null,
        activeTab: 1,
        canContinue: false, //continue button
        productTableData: [],
        assetDeleted: false,
        prodDataArray: [],
        assetPackagesData: [],
        orderParty: true,
        installationAddress: "",
        installationAddress2: "",
        installationCity: "",
        installationState: "",
        installationPostal: "",
        installtionStateAbb: "",
        installationDate: "",
        orderInfoStates: [],
        installationStateId: "",
        isCanadaState: false,
        customerInfo: [],
        mailInfo: "",
        optA: false,
        optB: false,
        optC: true,
        optD: true,
        optE: false,
        stateInfo: "",
        mobileInfo: "",
        altMobileInfo: "",
        emailDealer: "",
        emailCustomer: "",
        chkInstallationAddress: "",
        orderInfoHasError: false,
        productTableHasError: false,
        productTableHasChanged: false,
        showAdvantageModal: false,
        profileModel: {},
        saveSale: false,
        paymentAmount: 0,
        taxAmount: 0.00,
        dealersActiveCampaigns: [],
        dealerId: -1,
        dealerProfile: [],
        installType: "Residential",
        pageReady: false,
        continueActionActive: false,
        createContractResults: null,
        serialNum: "",
        modelNum: "",
        brandNameDropDown: "",
        prodDescDropDown: "",
        mfgPartsDropDown: "",
        serialNumPlanDetails: "",
        modelNumPlanDetails: "",
        brandNameDropDown: "",
        prodDescDropDownPlanDetails: "",
        mfgPartsDropDownPlanDetails: "",
        tabId: props.tabId,

                resetDetails: {
                    retainInfo: false,
                    updateTriggered: null
                },
                offerDetails: null,
                offerLookupRequest: null,
                contractCreationError: null,
                IsCommercialInstallUnSupported: false,
                showProgressBar: true,
                confirmationEmailSent: false,
                pendingSuccess: false,

                canadaInstallLocation: false,
                registrationNumber: "",
                duplicatePackages: [],
                duplicateSerialNumbers: [],
                hasDuplicate: false,
                hasDuplicateAdv: false,
                oemDataLoaded: false,
                paymentSuccessful: false,
                saveSaleSuccess: false,
                savedSaleId: null,
                planPriceTotal: 0,
                planPriceTotalWithTax: 0,
                savedSaleTab: 0,
                savedSaleLoaded: false,
                continueAddProductClicked: false,
                gstTotal: 0,
                pstTotal: 0,
                savedSaleId: null,
                saveSaleLastName: "",
                dealerPo: "",
            };
        }

        this.handleContinueClick = this.handleContinueClick.bind(this);
        this.handleInformationReviewClick = this.handleInformationReviewClick.bind(this);
        this.toggleSafeSale = this.toggleSafeSale.bind(this);
        this.paymentRef = React.createRef();
        this.invokeIfInstallTypeChanged = this.invokeIfInstallTypeChanged.bind(this);
        this.setOffers = this.setOffers.bind(this);
    }

    resetCreatedContracts = () => {
        this.setState({ createContractResults: null });
    }

    checkIfCanContinue = (blnValue, deleteAsset) => {
        this.setState({ canContinue: blnValue });
        if (deleteAsset && this.state.activeTab === 2) {
            this.setState({ activeTab: 1, assetDeleted: true })
        }
    }

    invokeIfInstallTypeChanged() {
        return this.refs.addProducts.clearControlsOnInstallTypeChange();
    }

    updateProductTable = (data) => {

        this.setState({
            productTableData: data,
            prodDataArray: data,
            productTableUpdated: true
        });
        this.resetCreatedContracts();
    }

    updateAssetPackagesData = (data) => {
        this.setState({ assetPackagesData: data });
        this.resetCreatedContracts();
    }

    updateOrderParty = (id, value, stateList, abb, stateId, screen) => {
        if (id === 'installationState') {
            const countryCd = stateList.find(f => f.data === stateId).country;
            this.setState({
                installationState: value,
                orderInfoStates: stateList,
                installtionStateAbb: abb,
                installationStateId: stateId,
                canadaInstallLocation: countryCd == "CA" ? true : false
            });
        }
        else {            
            if(screen !== undefined && screen === "planDetails") {
                this.setState({ [id+"PlanDetails"]: value });
            } else {
                this.setState({ [id]: value });
            }
        }

        this.resetCreatedContracts();
    }

    updateCustomerInfo = (model, value, opt1, opt2, opt3) => {

        if (model === "customer") {
            this.setState({ customerInfo: value, saveSaleLastName: value[0].customerLastName })
        } else if (model === "mail") {
            this.setState({ mailInfo: value })
        } else if (model === "options_a") {
            this.setState({ optA: opt1, optB: opt2, optC: opt3 })
        } else if (model === "options_b") {
            this.setState({ optD: opt1, optE: opt2 })
        } else if (model === "state") {
            this.setState({ stateInfo: value })
        } else if (model === "mobile") {
            this.setState({ mobileInfo: value })
        } else if (model === "alt_mobile") {
            this.setState({ altMobileInfo: value })
        } else if (model === "email_dealer") {
            this.setState({ emailDealer: value })
        } else if (model === "email_customer") {
            this.setState({ emailCustomer: value })
        } else if (model === "check") {
            this.setState({ chkInstallationAddress: value })
        }
        this.resetCreatedContracts();
    }

    handleContinueClick = async e => {
        const ret = this.refs.customerInformation.validateCustomerInformation();
        if (ret === false) {
            this.refs.customerInformation.verifyAddress();
            this.checkDuplicateContractMatches();
        }

        this.setState({ contractCreationError: null, continueActionActive: false, canContinue: true });
    }

    handleContinueAddProductClick = async (e) => {
        const offerLookupSuccessful = await this.refs.addProducts.checkAvailablePlans();
        const orderInformationHasError = this.refs.orderInformation.validateOrderInformation();
        const productTableHasError = this.refs.addProducts.validateProductTable();

        if (!orderInformationHasError && !productTableHasError && offerLookupSuccessful) {
            this.refs.orderInformation.checkAddress();
        }

        this.setState({
            orderInfoHasError: orderInformationHasError,
            productTableHasError: productTableHasError || !offerLookupSuccessful,
            continueActionActive: false,
            canContinue: true
        });
    }

    handleContinueValidationClick = (addressHasError) => {
        const orderInfoHasError = this.state.orderInfoHasError;
        const productTableHasError = this.state.productTableHasError;

        if (orderInfoHasError === false && productTableHasError === false && addressHasError === false) {
            this.setState({ activeTab: this.state.activeTab + 1 });
        } else {
            return;
        }
    }

    handleModalReturnHome = () => {
        const { tabDispatch, getCurrentTabAndSubtab } = this.context
        const { tab } = getCurrentTabAndSubtab()
        const { tabId } = tab

        tabDispatch("CLOSE_TAB", { tabId })
    }

    handleSpinnerClick = (funcName) => {
        this.setState({
            continueActionActive: true,
            canContinue: false
        }, () => {
            this[`${funcName}`]();
        });
    }

    handleBackToOrderInfo = (retainValue) => {
        this.handleResetInfo(retainValue)
        this.setState({ activeTab: 1, saveSaleSuccess: false, canContinue: true });
    }

    handleContinuePlanDetailsClick = async e => {
        const validationStatus = this.refs.planDetails.validatePackageData(this.state.assetPackagesData);
        const validatePrice = this.refs.planDetails.validatePackagePriceDetailsData();
        if (validationStatus) {
            if (validatePrice) {
                this.setState({
                    continueActionActive: false,
                    canContinue: true
                })
                return
            }
            const assetPackagesForGtm = [];
            (this.state.assetPackagesData || []).map((value, i) => assetPackagesForGtm.push({
                item_name: value?.name,
                quantity: 1,
                price: value?.planPrice,
                reimbursement: '',
                type: '',
                term: value?.selectedYears,
                retail: value?.equipmentRetailPrice,
                product_qty: value?.assets?.length,
                products: value.assets?.map(e => ({
                    brand: e?.brand,
                    prod_description: this.state.productTableData.find(
                        x => x.assetId == e.assetId
                    ).prodDescription
                }))
            }))
            //console.log({ x: this.state.assetPackagesData, assetPackagesForGtm })
            dataLayerPush(null, { ecommerce: null }) // clear last ecommerce data
            dataLayerPush('add_to_cart', {
                ecommerce: {
                    items: [
                        ...assetPackagesForGtm
                    ]
                }
            })
            await this.handleContinuePlanDetails();
        }
        else {
            this.setState({
                continueActionActive: false,
                canContinue: true
            });
            return;
        }
    }

    handleContinuePlanDetails = async assetPackages => {
        const validationStatus = this.refs.planDetails.validatePackageData();
        if (validationStatus) {

            var dealerProfile = this.context.getProfile();
            var packages = assetPackages ? assetPackages : this.state.assetPackagesData;
            var offerIds = packages.map(p => p.offerId);
            var request = {
                masterProgramId: dealerProfile.masterProgramId,
                offers: offerIds,
                state: this.state.installationState,
                purchaser: "DEALER",
                zip: this.state.installationPostal,
                address1: this.state.installationAddress,
                address2: this.state.installationAddress2,
                city: this.state.installationCity
            }

            var result = await fetch('tax/CalculateTaxes', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });

            if (result.status === 200) {
                var taxData = await result.json();
                let pricingSummary = taxData.offerTaxDetails;
                let planPriceTotal = 0;
                let taxTotal = 0;
                let gstTotal = 0;
                let pstTotal = 0;

                for (var index = 0; index < pricingSummary.length; index++) {
                    var offerSummary = pricingSummary[index];
                    if (typeof offerSummary.retailAmount != 'undefined') {
                        planPriceTotal = planPriceTotal + offerSummary.retailAmount;
                        if (offerSummary.taxModel != null) {
                            taxTotal = taxTotal + (offerSummary.taxModel.calculatedTaxTotalAmount != null ? offerSummary.taxModel.calculatedTaxTotalAmount : 0);
                            gstTotal = gstTotal + (offerSummary.taxModel.calculatedTaxNationalAmount != null ? offerSummary.taxModel.calculatedTaxNationalAmount : 0);
                            pstTotal = pstTotal + (offerSummary.taxModel.calculatedTaxStateAmount != null ? offerSummary.taxModel.calculatedTaxStateAmount : 0);
                        }
                    }
                }

                this.setState({
                    activeTab: this.state.activeTab + 1,
                    planPriceTotal: planPriceTotal,
                    planPriceTotalWithTax: (planPriceTotal + taxTotal),
                    taxAmount: taxTotal,
                    gstTotal: gstTotal,
                    pstTotal: pstTotal,
                    showAdvantageModal: false,
                    continueActionActive: false,
                    canContinue: true
                });
                if (assetPackages) {
                    this.updateAssetPackagesData(packages);
                }
            } else {
                this.setState({
                    activeTab: this.state.activeTab + 1,
                    continueActionActive: false,
                    canContinue: true
                });
            }
        }
        else {
            this.setState({
                continueActionActive: false
            });
            return;
        }
    }

    handleContinuePaymentInfo = async e => {

        if (this.paymentRef != null) {
            var paymentComponent = this.paymentRef.current;
            if (!paymentComponent.checkForValidationErrors()) {
                paymentComponent.pay();
            } else {
                this.onFailedPayment();
            }
        }
    }

    onSuccessfulPayment = async () => {
        var contractIds = this.state.createContractResults.map(contract => contract.contractId);
        this.setState({ paymentSuccessful: true });

        fetch('contract/activatecontracts', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ contractIds: contractIds })
        }).then(res => {
            if (res.ok) {
                this.setState({
                    activeTab: this.state.activeTab + 1,
                    continueActionActive: false,
                    canContinue: true
                });
            } else {
            }
        });

        const { fromPendingPaymentScreen } = this.context

        if (fromPendingPaymentScreen == true) {
            fetch('contract/updatecontractenforcementdate', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ contractIds: contractIds })
            }).then(res => {
                if (res.ok) {
                }
            });
        }

        if (this.state.pendingSuccess) {
            this.context.pendingReportTrack.selecteContractId = [];
            this.context.pendingReportTrack.selecteContractRows = [];
            const response = await fetch('contract/ContractsByStatus/' + this.state.dealerId + '/8');
            const contracts = await response.json();
            var pendingContracts = contracts.filter(c => !contractIds.includes(c.contractId));
            if (pendingContracts.length <= 0) {
                this.context.removeNotificationAndAlert(NotificationIds.PaymentPastDue);
                this.context.removeNotificationAndAlert(NotificationIds.AutoPayError);
                this.context.removeNotificationAndAlert(NotificationIds.PendingContracts);
            } else {
                await this.context.updateNotificationAndAlert(NotificationIds.AutoPayError, pendingContracts.length);
                await this.context.updateNotificationAndAlert(NotificationIds.PendingContracts, pendingContracts.length);

                const currentDate = moment();
                var pastDueContracts = pendingContracts.filter(c =>
                    currentDate.diff(moment(c.contractConfigurationModel?.find(cc =>
                        cc.contractAttributeId == 18)?.value, 'MM-DD-YYYY HH:mm:ss.SSS'), "days") > 14);

                if (pastDueContracts.length <= 0) {
                    this.context.removeNotificationAndAlert(NotificationIds.PaymentPastDue);
                }
            }
        }

        if (this.state.savedSaleId) {
            fetch(`contract/SetSavedSaleStatus/${this.state.savedSaleId}/5`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                if (res.ok) {
                }
            });
        }
    }

    onFailedPayment = () => {
        this.setState({
            continueActionActive: false,
            canContinue: true
        });
    }

    handleInformationReviewClick(tabValue) {
        this.setState({ activeTab: tabValue });
    }

    componentDidMount() {

        let context = this.context;
        let profileModel = context.getProfile();
        let pageInfo = context.getSourcePage();
        let activeTab = this.state.activeTab;
        let loadPaymentDetails = false;
        let sourcePage = [];
        var { pendingSuccess } = this.state;
        let targetPageState = {};

        const { getCurrentTabAndSubtab, tabDispatch } = this.context;
        const { tab, subtab } = getCurrentTabAndSubtab();

        if (pageInfo !== undefined && pageInfo !== null) {
            sourcePage = pageInfo.sourcePage;
            pendingSuccess = this.state.paymentSuccessful ? pendingSuccess : false;
            let sourcePageId = sourcePage.pageId.toLowerCase();
            targetPageState = sourcePage.targetPageState;

            if (sourcePageId === "reports" && !pendingSuccess) {
                loadPaymentDetails = true;
                activeTab = sourcePage.targetTab;

                this.setState({
                    activeTab: 0,
                    showProgressBar: false,
                    pendingSuccess: true
                });
            }
            else if (sourcePageId === "reports_incompletesales") {
                if (targetPageState != null) {
                    this.mapSavedSale(targetPageState.savedSaleId);
                }
            }
        }


        this.setState({
            profileModel: profileModel,
            dealerPartyId: profileModel.partyId, //Needed for Payment Container
            dealerId: profileModel.dealerId,
            dealerProfile: profileModel,
            isCanadaState: profileModel.countryId === 2,
        }, () => {
            if (this.state.profileModel.contacts.length > 0 && this.state.activeTab !== 6) {
                this.updateCustomerInfo("email_dealer", this.state.profileModel.contacts.find(c => c.isPrimary)?.email);
            }

            this.getDealersActiveCampaign(this.state.dealerId);

            if (loadPaymentDetails) {
                this.loadPaymentTabDetails(sourcePage.contracts, activeTab);
            }
        });

        if (!this.state.oemDataLoaded) {
            fetch('OEMRegistration/getOEMRegistration')
                .then(res => res.json())
                .then(data => {
                    var specialCharsCheck = /[!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]+/;
                    var poCheck = /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|n|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|n|num|number)*\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|n|num|number)? *\d+|(#|n|num|number) *\d+)/i;

                    let tempArr = [...this.state.productTableData];
                    var maxRowId = 0;

                    let productInstallDate = new Date();
                    productInstallDate.setDate(productInstallDate.getDate() + 1);
                    let nextDayDate = new Date();
                    nextDayDate.setDate(nextDayDate.getDate() + 1);
                    let prevOEMDate = "";
                    let isValidProductInstallDate = true;
                    for (var product of data.products) {                        
                        let oemDate = new Date(product.installDate.replace(/-/g, '\/'));
                        if (!specialCharsCheck.test(product.installDate) && product.installDate !== "" && oemDate !== "Invalid date" && nextDayDate > oemDate && isValidProductInstallDate) {
                            productInstallDate = oemDate;
                            if (prevOEMDate == "") {
                                prevOEMDate = productInstallDate;
                            }
                            else if (productInstallDate.getDate() != prevOEMDate.getDate()) {
                                isValidProductInstallDate = false;
                            }
                        }
                        else {
                            isValidProductInstallDate = false;
                        }

                        if (product.categoryID == "") {
                            product.categoryID = null;
                        }

                        let newRow = {};
                        let oemBrandCheck = (profileModel?.brandList?.brandList !== null) && profileModel?.brandList?.brandList.findIndex(item => item === product?.brand);
                        
                        newRow.masterProgramId = profileModel.masterProgramId;
                        newRow.rowId = maxRowId;
                        newRow.serial = product.serialNumber;
                        newRow.serialNumBack = product.serialNumber;
                        newRow.brandName = (oemBrandCheck >= 0) ? product.brand : "";
                        newRow.brandNameBack = (oemBrandCheck >= 0) ? product.brand : "";
                        newRow.brandNameId = (oemBrandCheck >= 0) ? product.brand : "";
                        newRow.prodDescription = product.categoryName;
                        newRow.prodDescription = "";
                        newRow.prodDescriptionBack = "";
                        newRow.categoryId = product.categoryID;
                        newRow.oemWarrantyParts = product.oemWarrantyParts;
                        newRow.modelNum = product.itemName;
                        newRow.MFGWarranty = "";
                        newRow.MFGWarrantyBack = "";
                        newRow.MFGWarrantyId = ""
                        newRow.editOrDeleteTag = 0;
                        newRow.editOrDelete = "";
                        newRow.tErrorSerial = false;
                        newRow.tErrorModel = false;
                        newRow.tErrorDesc = false;
                        newRow.tErrorMfg = false;
                        newRow.tErrorBrand = false;
                        newRow.isChecked = true;
                        newRow.isVerified = false;
                        newRow.dummy = false;
                        newRow.planName = "";
                        newRow.isFromOEM = true;
                        newRow.isOEMAddProdUpd = false;
                        tempArr.push(newRow);
                        maxRowId = maxRowId + 1;
                    }

                    if (productInstallDate != nextDayDate && productInstallDate <= new Date() && isValidProductInstallDate) {
                        productInstallDate = this.state.isCanadaState ? moment(productInstallDate).format("DD/MM/YYYY") :
                            moment(productInstallDate).format("MM/DD/YYYY");
                        this.setState({
                            installationDate: productInstallDate
                        });
                    }

                    let tempCustArr = [...this.state.customerInfo];
                    let custInfoRow = {};
                    custInfoRow.customerFirstName = data.homeOwnerFirstName;
                    custInfoRow.customerLastName = data.homeOwnerLastName;
                    custInfoRow.customerEmail = data.homeOwnerEmailAddress;
                    custInfoRow.customerAlternatePhone = (data.homeOwnerPhoneNumber > 10 || data.homeOwnerPhoneNumber <10) ? "" : data.homeOwnerPhoneNumber;

                    tempCustArr.push(custInfoRow);

                    let zipVal = "";
                    if (!(specialCharsCheck.test(data.homeOwnerZipCode))) {
                        zipVal = data.homeOwnerZipCode.trim();
                    }
                    else {
                        zipVal = data.homeOwnerZipCode.replace(/[^a-zA-Z0-9-]/g, "");
                    }

                    let address1 = "";
                    if (!(poCheck.test(data.homeOwnerAddressLine1))) {
                        address1 = data.homeOwnerAddressLine1;
                    }

                    let address2 = "";
                    if (!(poCheck.test(data.homeOwnerAddressLine2))) {
                        address2 = data.homeOwnerAddressLine2;
                    }

                    this.setState({
                        installationAddress: address1,
                        installationAddress2: address2,
                        installtionStateAbb: data.homeOwnerState,
                        installationCity: data.homeOwnerCity,
                        installationPostal: zipVal,
                        productTableData: tempArr,
                        canContinue: true,
                        registrationNumber: data.registrationNumber,
                        customerInfo: tempCustArr,
                        altMobileInfo: data.homeOwnerPhoneNumber,
                        oemDataLoaded: true
                    });
                })
                .catch(e => { });
        }
    }

    componentDidUpdate() {
        const { getCurrentTabAndSubtab, tabDispatch } = this.context;
        const { tab, subtab } = getCurrentTabAndSubtab();
        let { updateTab } = this.context;
        updateTab({
            tabId: tab.tabId,
            tabType: "orderinfo",
            state: this.state
        });
    }

    toggleSafeSale = () => {
        this.setState({
            saveSale: !this.state.saveSale,
        });
    }

    getDealersActiveCampaign = async (dealerId) => {
        let date = new Date();
        date = moment(date).format("YYYY-MM-DD");

        let res = await fetch(`dealer/GetActiveCampaignsByDealerId/${dealerId}/${date}`);

        if (res.status === 200) {
            let campaigns = await res.json();

            if (campaigns !== null) {
                this.setState({ dealersActiveCampaigns: campaigns });
            }
        }
    }

    getFulfillmentDetails = () => {
        var fulfillmentDetails = [];
        var dealerType = 2;
        var customerType = 3;
        var emailId = 1;
        var mailId = 2;
        var dealerEmail = this.state.emailDealer;
        var customerEmail = this.state.emailCustomer;

        if (this.state.optB === true) {
            fulfillmentDetails.push({
                partyType: dealerType,
                fulfillmentPreferenceType: mailId
            });
        }

        if (this.state.optC === true) {
            fulfillmentDetails.push({
                partyType: dealerType,
                fulfillmentPreferenceType: emailId,
                email: dealerEmail
            });
        }

        if (this.state.optD === true || this.state.optE === true) {
            fulfillmentDetails.push({
                partyType: customerType,
                fulfillmentPreferenceType: mailId
            });
        }
        if (this.state.optE === true) {
            fulfillmentDetails.push({
                partyType: customerType,
                fulfillmentPreferenceType: emailId,
                email: customerEmail
            });
        }

        return fulfillmentDetails;
    }

    onInfoReviewContinue = async () => {
        var contractOffers = [];
        let profile = this.context.getProfile();
        var packages = this.state.assetPackagesData;
        var customerDetails = this.state.customerInfo[0];
        var customerMailing = {};
        var installDate = this.state.installationDate;
        var isCADealer = this.state.isCanadaState;
        let usFormattedDate = moment(isCADealer ? moment(installDate, "DD/MM/YYYY").format("MM/DD/YYYY") :
            this.state.installationDate).format();

        if (!this.state.chkInstallationAddress && this.state.mailInfo[0] != null) {
            var mailingDetails = this.state.mailInfo[0];
            customerMailing = {
                address1: mailingDetails.customerMailingAddress,
                address2: mailingDetails.customerMailingAddress2,
                city: mailingDetails.customerMailingCity,
                zip: mailingDetails.customerMailingPostal,
                state: mailingDetails.customerMailingState,
                stateId: mailingDetails.customerMailingStateId,
                addressTypeId: 3
            }
        } else {
            customerMailing = {
                address1: this.state.installationAddress,
                address2: this.state.installationAddress2,
                city: this.state.installationCity,
                zip: this.state.installationPostal,
                state: this.state.installationState,
                stateId: this.state.installationStateId,
                addressTypeId: 3
            }
        }

        for (var i = 0; i < packages.length; i++) {
            var assets = [];

            var currentPackage = packages[i];
            var packageAssets = packages[i].assets;

            for (var p = 0; p < packageAssets.length; p++) {
                var currentAsset = packageAssets[p];

                assets.push({
                    assetId: currentAsset.assetId,
                    serialNumber: currentAsset.serialNumber,
                    modelNumber: currentAsset.modelNumber,
                    installDate: currentAsset.installDate,
                    brandId: currentAsset.brandId,
                    coverage: {
                        parts: currentPackage.coverage.parts,
                        labor: currentPackage.coverage.labor
                    },
                    assetPurchasePrice: parseFloat(currentPackage.equipmentRetailPrice),
                    seriesId: currentAsset.seriesId,
                    sizeId: currentAsset.sizeId
                });
            }

            contractOffers.push({
                clientContractNumber: currentPackage.dealerPo,
                equipmentInstallationDate: usFormattedDate,
                offerId: currentPackage.offerId,
                assets: assets
            });
        }

        var request = {
            accountId: profile.accountId,
            dealerId: profile.dealerId,
            isCodTransaction: !profile.isInvoiceDealer,
            fulfillmentPreference: 1,
            InstallationAddress: {
                address1: this.state.installationAddress,
                address2: this.state.installationAddress2,
                city: this.state.installationCity,
                state: this.state.installationState,
                stateId: this.state.installationStateId,
                zip: this.state.installationPostal,
                addressTypeId: 2
            },
            contractOffers: contractOffers,
            partyInfo: {
                firstName: customerDetails.customerFirstName,
                lastName: customerDetails.customerLastName,
                emailAddresses: [
                    {
                        email: customerDetails.customerEmail,
                        isPrimary: true
                    }],
                phoneNumbers: [
                    {
                        number: this.state.mobileInfo,
                        phoneTypeId: 2,
                        isPrimary: true
                    },
                    {
                        number: this.state.altMobileInfo,
                        phoneTypeId: 1
                    }],
                mailingAddress: customerMailing
            },
            fulfillmentPreferences: this.getFulfillmentDetails()
        };
        if (profile.isInvoiceDealer) {
            this.createContracts(request, this.state.activeTab + 2);
        } else {
            if (Array.isArray(this.state.createContractResults) && this.state.createContractResults.length > 0) {
                this.setState({
                    activeTab: this.state.activeTab + 1,
                    continueActionActive: false,
                    canContinue: true
                });
            } else {
                this.createContracts(request, this.state.activeTab + 1);
            }
        }
    }

    createContracts = (contractRequest, successTabId) => {
        fetch('contract/create', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(contractRequest)
        }).then(res => {
            if (res.ok) {
                res.json().then(results => {
                    if (results.contracts == undefined) {
                        this.setState({
                            contractCreationError: results.message,
                            continueActionActive: false,
                            canContinue: true
                        });
                    } else {
                        this.setState({
                            activeTab: successTabId,
                            createContractResults: results.contracts,
                            contractCreationError: null,
                            continueActionActive: false,
                            canContinue: true
                        });
                    }
                });
            } else {
            }
        });
    }

    loadTab = (tabInfo) => this.context.tabDispatch('ADD_TAB', tabInfo);

    handleResetInfo = (retainInformation) => {
        if (retainInformation === true) {
            this.setState({
                activeTab: 1,
                canContinue: false, //continue button

                productTableData: [],
                prodDataArray: [],
                orderParty: true,
                installationAddress: this.state.installationAddress,
                installationAddress2: this.state.installationAddress2,
                installationCity: this.state.installationCity,
                installationState: this.state.installationState,
                installationPostal: this.state.installationPostal,
                installtionStateAbb: this.state.installtionStateAbb,
                installationDate: this.state.installationDate,
                installationStateId: this.state.installationStateId,
                installationType: this.state.installationType,
                orderInfoStates: [],
                assetPackagesData: [],

                customerInfo: this.state.customerInfo,
                mailInfo: this.state.mailInfo,
                optA: this.state.optA,
                optB: this.state.optB,
                optC: this.state.optC,
                optD: this.state.optD,
                optE: this.state.optE,
                stateInfo: this.state.stateInfo,
                mobileInfo: this.state.mobileInfo,
                altMobileInfo: this.state.altMobileInfo,
                emailDealer: this.state.emailDealer,
                emailCustomer: this.state.emailCustomer,
                chkInstallationAddress: this.state.chkInstallationAddress,
            });
        }
        else if (retainInformation === false) {
            this.setState({
                activeTab: 1,
                canContinue: false, //continue button

                productTableData: [],
                prodDataArray: [],
                orderParty: true,
                installationAddress: "",
                installationAddress2: "",
                installationCity: "",
                installationState: "",
                installationPostal: "",
                installtionStateAbb: "",
                installationStateId: "",
                installationType: "Residential",
                installationDate: "",
                orderInfoStates: [],
                assetPackagesData: [],

                customerInfo: "",
                mailInfo: "",
                optA: false,
                optB: false,
                optC: true,
                optD: true,
                optE: false,
                stateInfo: "",
                mobileInfo: "",
                altMobileInfo: "",
                emailDealer: "",
                emailCustomer: "",
                chkInstallationAddress: "",
                confirmationEmailSent: false,
                savedSaleId: null
            });
        }

        let resetInfo = this.state.resetDetails;
        resetInfo.retainInfo = retainInformation;
        resetInfo.updateTriggered = false;

        this.setState({
            resetDetails: resetInfo,
            planDetailsData: []
        });
    }

    handleNewSaleClick = (retainInfo) => {
        this.handleResetInfo(retainInfo);
        this.handleInformationReviewClick(1);
    }

    componentWillUnmount() {
        this.updateContext();
    }

    updateContext() {
        let { tabDispatch, getCurrentTabAndSubtab } = this.context;
        const { tab } = getCurrentTabAndSubtab()
        const { tabId } = tab || {}

        tabDispatch({
            title: this.state.showProgressBar !== false ? "Sale Entry" : "Make Sale",
            tabType: "orderinfo",
            state: this.state
        });
    }

    setOffers = (offerDetails, offerLookupRequest) => {
        if (this.state.registrationNumber != "") {
            if (offerDetails != null && offerDetails.packages != null) {
                for (var packageItem of offerDetails.packages) {
                    if (packageItem != null) {
                        packageItem.dealerPo = this.state.registrationNumber;
                    }
                }
            }
        }

        this.setState({
            offerDetails,
            offerLookupRequest: offerLookupRequest != null ?
                offerLookupRequest :
                this.state.offerLookupRequest
        });
    }

    getOffers = () => {
        return {
            offers: this.state.offerDetails,
            request: this.state.offerLookupRequest
        };
    }

    loadPaymentTabDetails = async (contracts, targetTab) => {
        if (contracts !== undefined) {
            let contractIds = contracts.map(x => { return x.contractId });
            let { dealerProfile } = this.state;
            let accountId = this.state.dealerProfile.accountId.toString();

            let cppdRequest = {
                ContractId: contractIds,
                AccountId: accountId,
                FinancialTypeId: 3
            }

            await fetch('contract/ContractPlanPriceDetails', {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(cppdRequest)
            })
                .then(res => res.json())
                .then(async (data) => {
                    let totalPrice = 0;
                    let totalTax = 0;
                    let mailingAddress = dealerProfile.mailingAddress;

                    let contracts = data.map(x => {
                        totalPrice = totalPrice + x.amount;
                        totalTax = totalTax + x.tax;
                        let contractModel = x.contractModel;
                        let installDate = contractModel.contractAssetModel[0]?.assetPurchaseDate;

                        return {
                            contractId: contractModel.contractId,
                            contractNumber: contractModel.contractNumber,
                            installDate: installDate,
                            planName: contractModel.planDescription,
                            planPrice: x.amount + x.tax,
                            lastName: contractModel.party.lastName
                        }
                    });

                    let customerInfo = data.map(x => {
                        let contractModel = x.contractModel;

                        return {
                            customerFirstName: contractModel.party.firstName,
                            customerLastName: contractModel.party.lastName
                        }
                    });


                    const context = this.context;
                    const pageInfo = context.getSourcePage();
                    var retVal = false;
                    if (pageInfo.sourcePage.pageId.toLowerCase() === "reports") {
                        retVal = await this.checkContractStatusAndRedirect(pageInfo.sourcePage.contracts);
                        if (retVal) {
                            this.setState({
                                paymentSuccessful: true,
                                showProgressBar: true
                            });
                        }
                    }

                    this.setState({
                        customerInfo: customerInfo,
                        planPriceTotal: totalPrice,
                        taxAmount: totalTax,
                        planPriceTotalWithTax: totalPrice + totalTax,
                        createContractResults: contracts,
                        installationAddress: mailingAddress.address1,
                        installationAddress2: mailingAddress.address2,
                        installationCity: mailingAddress.city,
                        installationState: mailingAddress.state,
                        installtionStateAbb: mailingAddress.state,
                        installationPostal: mailingAddress.zip,
                        activeTab: retVal == false ? targetTab : 6,
                        canContinue: true
                    });
                });
        }
    }

    checkContractStatusAndRedirect = async (contracts) => {
        const contractIds = contracts.map(x => { return x.contractId });
        const response = await fetch('contract/' + contractIds[0] + '/hvac');
        const contract = await response.json();

        if (typeof contracts != undefined) {
            if (typeof contract != 'undefined') {
                const status = contract.statusId;
                if (status == 1) {
                    return true;
                }
            }
            return false;
        }

    }

    checkDuplicateContractMatches = () => {

        let duplicateRequest = {
            accountId: this.state.dealerProfile.accountId,
            dealerId: this.state.dealerId,
            lastName: this.state.customerInfo[0].customerLastName,
            zip: this.state.installationPostal,

            assetPackageData: this.state.assetPackagesData.map(p => {
                return {
                    assetPackageId: p.packageId,
                    offerId: p.offerId,
                    assets: p.assets.map(a => {
                        return {
                            assetId: a.assetId,
                            serialNumber: a.serialNumber,
                            modelNumber: a.modelNumber,
                            installDate: a.installDate,
                            brandId: a.brandId
                        }
                    })
                }
            }),
            planDetailsData: this.state.assetPackagesData.map(r => {
                return {
                    laborReimbursement: r.selectedLabor,
                    coverageType: r.selectedCoverage,
                    termId: r.selectedTermId
                }
            })
        };

        fetch('contract/GetDuplicateContractMatches', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(duplicateRequest)
        })
            .then(res => res.json())
            .then(data => {
                //console.log(data);
                this.setState({
                    duplicatePackages: data.assetPackageIds,
                    duplicateSerialNumbers: data.serialNumbers,
                    hasDuplicate: data.assetPackageIds.length > 0 ? true : false,
                    hasDuplicateAdv: data.duplicatePlan
                });
            });

    }

    handleSaveSale = async (lastName, dealerPo) => {
        let offerDetails = this.state.offerDetails ?? {};

        let saleInfo = {
            installDetails: {
                installationType: this.state.installType ?? '',
                installationDate: this.state.installationDate,
                installationAddress: this.state.installationAddress,
                installationAddress2: this.state.installationAddress2,
                installationCity: this.state.installationCity,
                installationPostal: this.state.installationPostal,
                installationStateId: this.state.installationStateId === '' ? null : this.state.installationStateId,
                installationState: this.state.installationState,
                installtionStateAbb: this.state.installtionStateAbb,
            },
            productDetails: this.state.productTableData.map(p => {
                return {
                    ...p,
                    brandId: p.brandNameId,
                    serialNumber: p.serialNumber,
                    serialNumberBack: p.serialNumberBack,
                    modelNumber: p.modelNum,
                    mfgParts: p.MFGWarrantyId,
                    mfgLabor: p.MFGLabor,
                    clientAssetMappingId: p.prodDescriptionId,
                };
            }),
            packageDetails: this.state.assetPackagesData.map(p => {
                return {
                    ...p,
                    assetPackageId: p.packageId,
                    equipmentRetailPrice: p.equipmentRetailPrice ?? '',
                    planName: p.name,
                    dealerPo: p.dealerPo ? dealerPo : p.dealerPo,
                    planRetailPrice: p.planRetailPrice ?? '',
                    planRetailPriceQST: p.planRetailPriceGST ?? '',
                    planRetailPriceGST: p.planRetailPriceGST ?? '',
                };
            }),
            customerDetails: {
                dealerPo: dealerPo,
                firstName: this.state.customerInfo[0]?.customerFirstName ?? '',
                lastName: lastName ?? this.state.customerInfo[0]?.customerLastName ?? '',
                company: this.state.customerInfo[0]?.customerCompany ?? '',
                email: this.state.customerInfo[0]?.customerEmail ?? '',
                mobilePhone: this.state.mobileInfo,
                alternatePhone: this.state.altMobileInfo,
                mailingAddress: this.state.mailInfo?.[0]?.customerMailingAddress ?? '',
                mailingAddress2: this.state.mailInfo?.[0]?.customerMailingAddress2 ?? '',
                mailingCity: this.state.mailInfo?.[0]?.customerMailingCity ?? '',
                mailingState: this.state.mailInfo?.[0]?.customerMailingState ?? '',
                mailingStateId: this.state.mailInfo?.[0]?.customerMailingStateId ?? null,
                mailingPostal: this.state.mailInfo?.[0]?.customerMailingPostal ?? '',
                dealerReceiptOption: this.state.optA ? 0 : this.state.optB ? 1 : 2,
                dealerReceiptEmail: this.state.emailCustomer,
                customerReceiptOption: this.state.optD ? 0 : 1,
                customerReceiptEmail: this.state.emailDealer,
                chkInstallationAddress: this.state.chkInstallationAddress ?? true
            },
            offerDetails: offerDetails,
            planSummary: {
                planPriceTotal: this.state.planPriceTotal,
                planPriceTotalWithTax: this.state.planPriceTotalWithTax,
                taxAmount: this.state.taxAmount,
                gstTotal: this.state.gstTotal,
                pstTotal: this.state.pstTotal,
            }
        }

        let savedSaleRequest = {
            savedSaleId: this.state.savedSaleId ?? 0,
            dealerId: this.state.dealerId,
            statusId: 9,
            stepNumber: this.state.activeTab,
            saleInfo: JSON.stringify(saleInfo)
        };

        console.log(JSON.stringify(savedSaleRequest));
        await fetch('contract/SaveSale', {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(savedSaleRequest)
        }).then(res => {
            if (res.ok) {
                res.json().then(result => {
                    if (result != null) {
                        this.setState({
                            saveSaleSuccess: true,
                            saveSale: false,
                            savedSaleId: result.savedSaleId,
                            savedSaleTab: 0,
                            savedSaleLoaded: false,
                            dealerPo: dealerPo
                        });
                    }
                });
            }
        });
    }

    mapSavedSale = (savedSaleId) => {
        fetch(`contract/GetSavedSaleById/${savedSaleId}`)
            .then(res => res.json())
            .then(data => {
                const savedSale = data;
                const saleInfo = savedSale.saleInfoData;

                let productTableData = {};
                let assetPackagesData = {};
                let customerDetails = {};
                let customerInfo = {};
                let mailInfo = {};
                let installDetails = saleInfo.installDetails ?? {};
                let offerDetails = saleInfo.offerDetails ?? {};
                let planSummary = saleInfo.planSummary ?? {};
                let installDate = '';
                let saveSaleTab = savedSale.stepNumber === 5 ? 4 : savedSale.stepNumber;

                if (saleInfo.customerDetails != null) {
                    let custInfo = saleInfo.customerDetails;

                    customerDetails = {
                        mobileInfo: custInfo.mobilePhone,
                        altMobileInfo: custInfo.alternatePhone,
                        stateInfo: custInfo.mailingStateId,
                        optA: custInfo.dealerReceiptOption == 0,
                        optB: custInfo.dealerReceiptOption == 1,
                        optC: custInfo.dealerReceiptOption == 2,
                        emailCustomer: custInfo.dealerReceiptEmail,
                        optD: custInfo.customerReceiptOption == 0,
                        optE: custInfo.customerReceiptOption == 1,
                        emailDealer: custInfo.customerReceiptEmail,
                        chkInstallationAddress: custInfo.chkInstallationAddress ?? true
                    };

                    customerInfo = [{
                        customerFirstName: custInfo.firstName,
                        customerLastName: custInfo.lastName,
                        customerCompany: custInfo.company,
                        customerEmail: custInfo.email
                    }];

                    mailInfo = [{
                        customerMailingAddress: custInfo.mailingAddress,
                        customerMailingAddress2: custInfo.mailingAddress2,
                        customerMailingCity: custInfo.mailingCity,
                        customerMailingPostal: custInfo.mailingPostal,
                        customerMailingState: custInfo.mailingState,
                        customerMailingStateId: custInfo.mailingStateId
                    }];
                }

                if (saleInfo.productDetails != null) {
                    productTableData = saleInfo.productDetails.map(x => {
                        return {
                            ...x,
                            brandNameId: x.brandId,
                            serial: x.serialNumber,
                            modelNum: x.modelNumber,
                            MFGWarrantyId: x.mfgParts,
                            MFGLabor: x.mfgLabor,
                            MFGWarrantyWarranty: x.mfgWarranty,
                            MFGWarrantyBack: x.mfgWarrantyBack,
                            prodDescriptionId: x.clientAssetMappingId,
                        }
                    });
                }

                installDate = installDetails.installationDate;

                if (saleInfo.packageDetails != null) {
                    assetPackagesData = saleInfo.packageDetails.map(x => {
                        return {
                            ...x,
                            packageId: x.assetPackageId,
                            name: x.planName
                        }
                    });

                    if (installDate === '') { 
                        let assetDate = assetPackagesData[0]?.assets[0]?.installDate ?? '';

                        if (assetDate !== '') {
                            let dateFormat = (this.state.isCanadaState ?? false) ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
                            installDate = moment(moment(assetDate, 'YYYY-MM-DD')).format(dateFormat);
                        }

                        saveSaleTab = installDate === '' ? 1 : saveSaleTab;
                    }
                }

                this.setState({
                    installationType: installDetails.installationType,
                    installationDate: installDate,
                    installationAddress: installDetails.installationAddress,
                    installationAddress2: installDetails.installationAddress2,
                    installationCity: installDetails.installationCity,
                    installationPostal: installDetails.installationPostal,
                    installationStateId: installDetails.installationStateId,
                    installationState: installDetails.installationState,
                    installtionStateAbb: installDetails.installtionStateAbb,

                    mobileInfo: customerDetails.mobileInfo,
                    altMobileInfo: customerDetails.altMobileInfo,
                    stateInfo: customerDetails.stateInfo,
                    optA: customerDetails.optA,
                    optB: customerDetails.optB,
                    optC: customerDetails.optC,
                    optD: customerDetails.optD,
                    optE: customerDetails.optE,
                    emailCustomer: customerDetails.emailCustomer,
                    emailDealer: customerDetails.emailDealer,
                    chkInstallationAddress: customerDetails.chkInstallationAddress,

                    customerInfo: customerInfo,
                    mailInfo: mailInfo,
                    productTableData: productTableData,
                    assetPackagesData: assetPackagesData,
                    offerDetails: offerDetails,

                    planPriceTotal: planSummary.planPriceTotal,
                    planPriceTotalWithTax: planSummary.planPriceTotalWithTax,
                    taxAmount: planSummary.taxAmount,
                    gstTotal: planSummary.gstTotal,
                    pstTotal: planSummary.pstTotal,

                    savedSaleId: savedSale.savedSaleId,
                    savedSaleTab: saveSaleTab,
                    activeTab: 1,
                    dealerPo: saleInfo.customerDetails.dealerPo,

                }, () => {

                    if (!this.state.savedSaleLoaded) {
                        this.refs.addProducts.loadDescriptionsFromSavedSale(productTableData);
                    }
                });
            });
    }

    updateSavedSaleTab = () => {
        this.setState({ activeTab: this.state.savedSaleTab, savedSaleLoaded: true, canContinue: true });
    }

    setSaveSaleLastName = (value) => {
        var customerInfoUpdate = [...this.state.customerInfo]

        if (customerInfoUpdate.length != 0) {
            customerInfoUpdate[0].customerLastName = value;
            this.setState({ saveSaleLastName: value, customerInfo: customerInfoUpdate })
            return
        }
        this.setState({ saveSaleLastName: value })
    }
    setSaveSaleDealerPo = (value) => {

        var customerInfoUpdate = [...this.state.customerInfo]
        var assetPackagesDataUpdate = [...this.state.assetPackagesData]

        if (customerInfoUpdate.length != 0) {
            customerInfoUpdate[0].dealerPo = value;
            assetPackagesDataUpdate[0].dealerPo = value;
            this.setState({ dealerPo: value, customerInfo: customerInfoUpdate, assetPackagesData: assetPackagesDataUpdate })
            return
        }
        this.setState({ dealerPo: value })
    }

    goToTab = (tab) => {
        this.setState({ activeTab: tab, saveSaleSuccess: false, canContinue: true });
    }

    setSavedSaleId = (value) => {
        this.setState({ savedSaleId: value });
    }

    render() {
        let installAddyObject = {
            address1: this.state.installationAddress,
            address2: this.state.installationAddress2,
            city: this.state.installationCity,
            state: this.state.installationState,
            stateId: this.state.installationStateId,
            stateAbbrev: this.state.installtionStateAbb,
            zip: this.state.installationPostal
        };
        //console.log(installAddyObject);
        const { activeTab, saveSaleSuccess } = this.state;

        let paymentAmount = this.state.planPriceTotal;
        let taxAmount = this.state.taxAmount;
        let orderTotal = (paymentAmount + taxAmount);
        let profile = this.context.getProfile();
        const currency = getCurrencySymbol(this.state.profileModel?.countryId);
        if (this.state.IsCommercialInstallUnSupported != profile.isCommercialInstallUnSupported) {
            this.setState({
                IsCommercialInstallUnSupported: profile.isCommercialInstallUnSupported
            })
        }

        return (
            <OrderInfoProvider
                dealerProfile={this.context.getProfile()}
                accountName={this.context.accountName}>
                <div>
                    {this.state.showProgressBar && (<ProgressBar activeTab={activeTab} hasDuplicate={this.state.hasDuplicate} hasDuplicateAdv={this.state.hasDuplicateAdv} advantageProgramName={getBrandContent(this.context.accountName).advantageProgramName} tabCount={!profile.isLennoxDealer && profile.isInvoiceDealer ? 5 : 6} />)}
                    <main role="main">
                        {activeTab === 1 && !saveSaleSuccess && (
                            <Fragment>
                                <Container className="contentWrapper">
                                    <h1 className={`h3 ${styles.pageHeader}`}>Order Information</h1>
                                    <OrderInformation
                                        updateOrderParty={this.updateOrderParty}
                                        installationAddress={this.state.installationAddress}
                                        installationAddress2={this.state.installationAddress2}
                                        installationCity={this.state.installationCity}
                                        installationState={this.state.installationState}
                                        installationStateId={this.state.installationStateId}
                                        installtionStateAbb={this.state.installtionStateAbb}
                                        installationPostal={this.state.installationPostal}
                                        orderInfoStates={this.state.orderInfoStates}
                                        updateProductTable={this.updateProductTable}
                                        stateInfo={this.state.stateInfo}
                                        installationDate={this.state.installationDate}
                                        handleContinueValidationClick={this.handleContinueValidationClick}
                                        ref="orderInformation"
                                        invokeIfInstallTypeChanged={this.invokeIfInstallTypeChanged}
                                        hideCommercialInstallType={this.state.IsCommercialInstallUnSupported}
                                        isLennoxDealer={profile.isLennoxDealer}
                                        checkIfCanContinue={this.checkIfCanContinue}
                                        oemDataLoaded={this.state.oemDataLoaded}
                                        profile={profile}
                                    />
                                    <AddProducts
                                        checkIfCanContinue={this.checkIfCanContinue}
                                        ref="addProducts"
                                        allowEdit={this.state.allowEdit}
                                        updateProductTable={this.updateProductTable}
                                        productTableData={this.state.productTableData}
                                        setOffers={this.setOffers}
                                        getOffers={this.getOffers}
                                        updateSavedSaleTab={this.updateSavedSaleTab}
                                        assetUpdated={this.state.assetDeleted}
                                        updateOrderParty={this.updateOrderParty}
                                        serialNum={this.state.serialNum}
                                        modelNum={this.state.modelNum}
                                        prodDescDropDown={this.state.prodDescDropDown}
                                    />
                                    {/*<button className="btn-primary float-right mb-3" disabled={!this.state.canContinue} onClick={() => this.setState({ activeTab: this.state.activeTab + 1 })}>Continue</button>*/}
                                    <Container>
                                        <BottomButtons
                                            rightButtons={[
                                                (
                                                    <button className="btnLink" onClick={this.toggleSafeSale}>Save Sale</button>
                                                ),
                                                <SpinnerButton
                                                    styleType='primary'
                                                    spinning={this.state.continueActionActive}
                                                    disabled={!this.state.canContinue}
                                                    onClick={() => this.handleSpinnerClick("handleContinueAddProductClick")}
                                                    text={'Continue'}
                                                />
                                            ]}
                                        />
                                    </Container>
                                </Container>
                            </Fragment>
                        )}

                    {activeTab === 2 && !saveSaleSuccess && (
                        <Fragment>
                            <PlanDetails ref="planDetails"
                                checkIfCanContinue={this.checkIfCanContinue}
                                isCanadaState={this.state.canadaInstallLocation}
                                installationStateId={this.state.installationStateId}
                                updateAssetPackagesData={this.updateAssetPackagesData}
                                updateProductTable={this.updateProductTable}
                                assetPackagesData={this.state.assetPackagesData}
                                productTableData={this.state.productTableData}
                                productList={this.state.productList}
                                productTableUpdated={this.state.productTableUpdated}
                                profileModel={this.state.profileModel}
                                installationDate={this.state.installationDate}
                                dealersActiveCampaigns={this.state.dealersActiveCampaigns}
                                dealerProfile={this.state.dealerProfile}
                                installType={this.state.installType}
                                offerDetails={this.state.offerDetails}
                                offerLookupRequest={this.state.offerLookupRequest}
                                setOffers={this.setOffers}
                                getOffers={this.getOffers}
                                savedSale={this.state.savedSaleTab == 2 && !this.state.continueAddProductClicked}
                                dealerPo={this.state.dealerPo}
                                updateOrderParty={this.updateOrderParty}
                                serialNum={this.state.serialNumPlanDetails}
                                modelNum={this.state.modelNumPlanDetails}
                                brandNameDropDown={this.state.brandNameDropDownPlanDetails}
                                prodDescDropDown={this.state.prodDescDropDownPlanDetails}
                                mfgPartsDropDown={this.state.mfgPartsDropDownPlanDetails}
                            />
                            <Container className={styles.btnContainer}>
                                <BottomButtons
                                    leftButtons={[
                                        <button className="btn-secondary" onClick={() => this.setState({ activeTab: this.state.activeTab - 1, canContinue: true, productTableUpdated: false })}>Back</button>
                                    ]}
                                    rightButtons={[
                                        <button className="btnLink" onClick={this.toggleSafeSale}>Save Sale</button>,
                                        <SpinnerButton
                                            styleType='primary'
                                            spinning={this.state.continueActionActive}
                                            disabled={!this.state.canContinue}
                                            onClick={() => this.handleSpinnerClick("handleContinuePlanDetailsClick")}
                                            text={'Continue'}
                                        />,
                                    ]}
                                />
                            </Container>
                        </Fragment>
                    )}
                    {activeTab === 3 && !saveSaleSuccess && (
                        <Fragment>
                            <CustomerInformation
                                updateOrderParty={this.updateOrderParty}
                                installationAddress={this.state.installationAddress}
                                installationAddress2={this.state.installationAddress2}
                                installationCity={this.state.installationCity}
                                installationState={this.state.installationState}
                                installtionStateAbb={this.state.installtionStateAbb}
                                installationStateId={this.state.installationStateId}
                                installationPostal={this.state.installationPostal}
                                orderInfoStates={this.state.orderInfoStates}
                                customerInfo={this.state.customerInfo}
                                mailInfo={this.state.mailInfo}
                                updateCustomerInfo={this.updateCustomerInfo}
                                optA={this.state.optA}
                                optB={this.state.optB}
                                optC={this.state.optC}
                                optD={this.state.optD}
                                chkInstallationAddress={this.state.chkInstallationAddress}
                                optE={this.state.optE}
                                emailDealer={this.state.emailDealer}
                                emailCustomer={this.state.emailCustomer}
                                stateInfo={this.state.stateInfo}
                                mobileInfo={this.state.mobileInfo}
                                altMobileInfo={this.state.altMobileInfo}
                                ref="customerInformation"
                                handleContinueValidationClick={this.handleContinueValidationClick}
                                assetPackagesData={this.state.assetPackagesData}
                                productTableData={this.state.productTableData}
                            />
                            <Container className={styles.btnContainer}>
                                <BottomButtons
                                    leftButtons={[
                                        <button className="btn-secondary" onClick={() => this.setState({ activeTab: this.state.activeTab - 1 })}>Back</button>
                                    ]}
                                    rightButtons={[
                                        <button className="btnLink" onClick={this.toggleSafeSale}>Save Sale</button>,
                                        <SpinnerButton
                                            styleType='primary'
                                            spinning={this.state.continueActionActive}
                                            disabled={!this.state.canContinue}
                                            onClick={() => this.handleSpinnerClick("handleContinueClick")}
                                            text={'Continue'}
                                        />,
                                    ]}
                                />
                            </Container>
                        </Fragment>
                    )}
                    {activeTab === 4 && !saveSaleSuccess && (
                        <Fragment>
                            <InformationReview
                                handleInformationReviewClick={this.handleInformationReviewClick}
                                productTableData={this.state.productTableData}
                                installationAddress={this.state.installationAddress}
                                installationAddress2={this.state.installationAddress2}
                                installationCity={this.state.installationCity}
                                installationState={this.state.installationState}
                                installtionStateAbb={this.state.installtionStateAbb}
                                installationStateId={this.state.installationStateId}
                                installationPostal={this.state.installationPostal}
                                installationDate={this.state.installationDate}
                                chkInstallationAddress={this.state.chkInstallationAddress}
                                emailCustomer={this.state.emailCustomer}
                                mobileInfo={this.state.mobileInfo}
                                altMobileInfo={this.state.altMobileInfo}
                                customerInfo={this.state.customerInfo}
                                stateInfo={this.state.stateInfo}
                                mailInfo={this.state.mailInfo}
                                orderInfoStates={this.state.orderInfoStates}
                                assetPackagesData={this.state.assetPackagesData}
                                isDealerInvoice={profile.isInvoiceDealer}
                                isLennox={profile.isLennoxDealer}
                                isCanadaState={this.state.canadaInstallLocation}
                                planPriceTotal={this.state.planPriceTotal}
                                taxAmount={this.state.taxAmount}
                                gstTotal={this.state.gstTotal}
                                pstTotal={this.state.pstTotal}
                                planPriceTotalWithTax={this.state.planPriceTotalWithTax}
                                duplicatePackages={this.state.duplicatePackages}
                                duplicateSerialNumbers={this.state.duplicateSerialNumbers}
                                hasDuplicate={this.state.hasDuplicate}
                                hasDuplicateAdv={this.state.hasDuplicateAdv}
                                countryId={this.state.profileModel.countryId}
                            />
                            <Container className={styles.btnContainer}>
                                {this.state.contractCreationError}
                                <BottomButtons
                                    leftButtons={[
                                        <button className="btn-secondary" onClick={() => this.setState({ activeTab: this.state.activeTab - 1 })}>Back</button>
                                    ]}
                                    rightButtons={[
                                        <button className="btnLink" onClick={this.toggleSafeSale}>Save Sale</button>,
                                        <SpinnerButton
                                            styleType='primary'
                                            spinning={this.state.continueActionActive}
                                            disabled={this.state.hasDuplicate || this.state.hasDuplicateAdv || !this.state.canContinue}
                                            onClick={() => this.handleSpinnerClick("onInfoReviewContinue")}
                                            text={profile.isInvoiceDealer ? "Submit to Invoicing" : "Continue"}
                                        />
                                    ]}
                                />
                            </Container>
                        </Fragment>)
                    }
                    {activeTab === 5 && !saveSaleSuccess && (
                        <Fragment>
                            <PaymentContainer
                                ref={this.paymentRef} dealerPartyId={this.state.dealerPartyId}
                                amount={this.state.planPriceTotal}
                                taxAmount={this.state.taxAmount}
                                gstTotal={this.state.gstTotal}
                                pstTotal={this.state.pstTotal}
                                customerInfo={this.state.customerInfo}
                                installationAddress={this.state.dealerProfile.mailingAddress}
                                products={this.state.productList}
                                packageData={this.state.assetPackagesData}
                                onSuccessfulPayment={this.onSuccessfulPayment}
                                onFailedPayment={this.onFailedPayment}
                                contracts={this.state.createContractResults}
                                accountId={profile.accountId}
                                profileModel={this.state.profileModel}
                                emailDealer={this.state.emailDealer}
                                isCanadaState={this.state.canadaInstallLocation}
                                assetPackagesData={this.state.assetPackagesData}
                                productTableData={this.state.productTableData}
                            />
                            <Container className={styles.btnContainer}>
                                {
                                    this.state.showProgressBar &&
                                    <BottomButtons
                                        leftButtons={[
                                            <button className="btn-secondary" onClick={() => this.setState({ activeTab: this.state.activeTab - 1 })}>Back</button>
                                        ]}

                                        rightButtons={[
                                            <button className="btnLink" onClick={this.toggleSafeSale}>Save Sale</button>,
                                            <SpinnerButton
                                                styleType="primary"
                                                spinning={this.state.continueActionActive}
                                                disabled={!this.state.canContinue}
                                                onClick={() => this.handleSpinnerClick("handleContinuePaymentInfo")}
                                                text={
                                                    <NumberFormat
                                                    value={orderTotal}
                                                    displayType={"text"}
                                                    thousandSeperator={true}
                                                    prefix={`Pay ${currency}`}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="ml-1"
                                                    />
                                                }
                                            />
                                        ]}
                                    />
                                }
                                {
                                    !this.state.showProgressBar &&
                                    <BottomButtons
                                        leftButtons={[
                                            <button
                                                className="btn-secondary"
                                                onClick={() => {
                                                    this.context.tabDispatch('CLOSE_TAB', { tabId: this.context.activeTab });
                                                    this.context.tabDispatch('ADD_TAB', {
                                                        title: "Reports",
                                                        tabType: "reports"
                                                    })
                                                }}
                                            >
                                                Back
                                            </button>
                                        ]}
                                        rightButtons={[
                                            //<button className="btnLink" onClick={this.toggleSafeSale}>Save Sale</button>,
                                            <SpinnerButton
                                                type="primary"
                                                spinning={this.state.continueActionActive}
                                                disabled={!this.state.canContinue}
                                                onClick={() => this.handleSpinnerClick("handleContinuePaymentInfo")}
                                                text={
                                                    <NumberFormat
                                                    value={orderTotal}
                                                    displayType={"text"}
                                                    thousandSeperator={true}
                                                    prefix={`Pay ${currency}`}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    className="ml-1"
                                                    />
                                                }
                                            />
                                        ]}
                                    />
                                }
                            </Container>                            
                        </Fragment>
                    )}
                    {activeTab === 6 && !saveSaleSuccess && (
                        <Fragment>
                            <Confirmation
                                ref="confirmation"
                                handleNewSaleClick={this.handleNewSaleClick}
                                contracts={this.state.createContractResults}
                                loadTab={this.loadTab}
                                dealerProfile={this.state.dealerProfile}
                                planPriceTotalWithTax={this.state.planPriceTotalWithTax}
                                customerInfo={this.state.customerInfo}
                                showNewSaleButton={this.state.showProgressBar}
                                updateOrderParty={this.updateOrderParty}
                                confirmationEmailSent={this.state.confirmationEmailSent}
                                updateConfirmationEmail={() => this.setState({ confirmationEmailSent: true })}
                                pendingSuccess={this.state.pendingSuccess}
                                assetPackagesData={this.state.assetPackagesData}
                                taxAmount={this.state.taxAmount}
                                productTableData={this.state.productTableData}
                                isCanadaState={this.state.canadaInstallLocation}
                            />
                        </Fragment>)
                    }

                    {saveSaleSuccess && (
                        <SaveSaleConfirmation
                            assetPackagesData={this.state.assetPackagesData}
                            handleModalReturnHome={this.handleModalReturnHome}
                            handleBackToOrderInfo={this.handleBackToOrderInfo}
                            isCanadaState={this.state.isCanadaState}
                            planPrice={this.state.assetPackagesData.length > 0 ? this.state.assetPackagesData[0].planPrice : ""}
                            customerLastName={this.state.saveSaleLastName}
                            activeTab={activeTab}
                            goToTab={this.goToTab}
                            installationDate={this.state.installationDate}
                            setSavedSaleId={this.setSavedSaleId}
                            customerInfo={this.state.customerInfo}
                            accountName={this.context.accountName}
                        />)
                    }

                    {<AdvPlanSummary
                        isOpen={this.state.showAdvantageModal}
                        toggle={() => this.setState({ showAdvantageModal: !this.state.showAdvantageModal })}
                        centered={true}
                        onModalOk={this.handleContinuePlanDetails}
                        onModalReturn={this.handleModalReturnHome}
                        assetPackagesData={this.state.assetPackagesData}
                        advantageProgramName={getBrandContent(this.context.accountName).advantageProgramName}
                    />}

                    {this.state.saveSale &&
                        //!!this.state.selectedProduct &&
                        <SaveSaleModal
                            customerLastName={this.state.saveSaleLastName}
                            dealerPo={Array.isArray(this.state.assetPackagesData) && this.state.assetPackagesData.length > 0 ? this.state.assetPackagesData[0].dealerPo === undefined ? this.state.dealerPo : this.state.assetPackagesData[0].dealerPo : ""}
                            isOpen={this.state.saveSale}
                            toggle={() => this.setState({ saveSale: !this.state.saveSale, canContinue: true })}
                            centered={true}
                            onSaveSale={this.handleSaveSale}
                            setSaveSaleLastName={this.setSaveSaleLastName}
                            setSaveSaleDealerPo={this.setSaveSaleDealerPo}
                        />
                    }
                </main>
            </div >
        </OrderInfoProvider>

        );
    }
}

OrderInfoContainer.contextType = SessionContext;