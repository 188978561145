import React, { useContext } from 'react';
import styles from './WhiteLabeling.module.css';

import { SessionContext } from '../../context/SessionContext';

const DevelopmentBrandToggler = () => {

    const { updateClientId, accountName } = useContext(SessionContext);
    console.log(accountName, "account name")

    const toggleBrand = (brand) => {
        if (brand === 'staples') {
            updateClientId('staples')
        } else {            
            updateClientId(brand);
        }
    } 

    return (
        <div className={styles.footerAlign}>
            <div className={styles.footerAlign}>
                <p>Select Brand to Test:</p>
                <button className="btn-secondary  ml-4" onClick={() => toggleBrand('staples')}>Staples</button>
            </div>
        </div>
    )
}

export default DevelopmentBrandToggler;