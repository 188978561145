import React, { createContext, Component, useEffect } from 'react';
import { AuthContext } from './AuthProvider';
import { countryConstants } from './LanguageTranslation/CountryConstants';
import PageLoading from '../components/shared/PageLoading/PageLoading';
import { getUploadedDocs } from '../components/Global/DocumentUploadHelper';
import { getSubtabProps, getReportSubtabType } from '../lib/util';
import { getConfiguration } from '../components/Global/ConfigurationHelper';
import moment from 'moment';
import { getFeatureFlags, getTranslation } from '../components/Global/helpers';
import StaplesProfile from './StaplesProfile';

export const SessionContext = createContext();

export class SessionProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            accountName: "",
            loginError: null,
            tabs: JSON.parse(sessionStorage.getItem("tabs")) || [],
            activeTab: parseInt(sessionStorage.getItem("activeTab")),
            uploadedDocs: {},
            languageCode: "en",
            translations: {},
            translationsHeader: {},
            translationsFooter: {},
            isTranslationsResolved: false,
            forgotPassClick: false,
            verifyPassClick: false,
            confirmPassClick: false,
            paymentGatewayStatus: false,
            getProfile: () => {
                var profile = JSON.parse(sessionStorage.getItem("profile"));
                return profile;
            },
            setProfile: (profileModel) => {
                sessionStorage.setItem("profile", JSON.stringify(profileModel));
            },
            getTerms: () => {
                var terms = JSON.parse(sessionStorage.getItem("terms"));
                return terms;
            },
            setTerms: (terms) => {
                sessionStorage.setItem("terms", JSON.stringify(terms));
                sessionStorage.setItem("needToAcceptTermsPages", true);
            },
            needsToReacceptFlag: () => {
                var profile = this.state.getProfile();
                var response = false;
                if (profile?.dealerStatus === 2 || profile?.dealerTerms?.needsToReacceptFlag) {
                    response = true;
                } else if (profile === null || profile?.dealerTerms === null || profile?.dealerTerms?.needsToReacceptFlag === null) {
                    response = false;
                }
                return response;
            },
            getNeedToAcceptTermsPages: () => {
                var res = JSON.parse(sessionStorage.getItem("needToAcceptTermsPages"));
                return res;
            },
            setNeedToAcceptTermsPages: (param) => {
                sessionStorage.setItem("needToAcceptTermsPages", param);
            },
            getDealerNotifications: async (profile) => {
                await this.getDealerNotifications(profile);
            },
            getNewTabId: () => {
                var ids = this.state.tabs.map(a => a.tabId);
                if (ids.length === 0)
                    return 1;
                return Math.max(...ids) + 1;
            },
            getNewSubtabId: () => {
                const currentTab = this.state.tabs.find(tab => tab.tabId === this.state.activeTab)
                const ids = currentTab.subtabs.map(st => st.subtabId)
                return Math.max(...ids) + 1;
            },
            tabLookup: (tabId) => {
                const tabsCopy = [...this.state.tabs]
                const selectedTabIndex = tabsCopy.findIndex(t => t.tabId === tabId)
                return [tabsCopy, selectedTabIndex]
            },
            
            tabDispatch: (action, payload) => {
                switch (action) {
                    case "ADD_TAB": {
                        const tab = payload;
                        const { tabType, contractNumber, editpayment } = tab;
                        const tabId = this.state.getNewTabId();
                        const initialSubtabs = [];
                        let activeSubtab = 1;
                        
                        const tabTypeExistsAtIndex = this.state.tabs.findIndex(t => t.tabType && t.tabType === tabType);

                        if (tabTypeExistsAtIndex > -1) { 
                            const existingTab = this.state.tabs[tabTypeExistsAtIndex];
                            const existingContractNumber = existingTab.contractNumber;
                            if(existingContractNumber === contractNumber) {
                                if((tabType === 'claimOverview') && 
                                    (this.state.tabs[tabTypeExistsAtIndex]?.claimOverviewModel?.claimNumber === payload?.claimOverviewModel?.claimNumber || 
                                        this.state.tabs[tabTypeExistsAtIndex]?.claimOverviewModel?.claimNumber === payload?.claimOverviewModel?.eventModel?.featureClaimModel?.claimModel?.claimNumber)) {
                                        
                                    this.setState({ tabs: this.state.tabs, activeTab: existingTab.tabId }, () => {
                                        sessionStorage.setItem("tabs", JSON.stringify(this.state.tabs));
                                        sessionStorage.setItem("activeTab", existingTab.tabId);
                                    });
                                    return this.state
                                }
                                if (
                                    tabType === "claimOverview" &&
                                    (this.state.tabs[tabTypeExistsAtIndex]?.claimOverviewModel?.claimNumber !==
                                      payload?.claimOverviewModel?.claimNumber ||
                                      this.state.tabs[tabTypeExistsAtIndex]?.claimOverviewModel?.claimNumber !==
                                        payload?.claimOverviewModel?.eventModel?.featureClaimModel?.claimModel
                                          ?.claimNumber)
                                  ) {
                                    const reportFlag = payload?.claimReportStatus;
                                    const claimNumber =
                                      reportFlag === true
                                        ? payload?.claimOverviewModel?.claimNumber
                                        : payload?.claimOverviewModel?.eventModel?.featureClaimModel?.claimModel
                                            ?.claimNumber;
                                    const assetId =
                                      reportFlag === true
                                        ? undefined
                                        : payload?.claimOverviewModel?.eventModel?.featureClaimModel?.claimModel
                                            ?.contractAssetId;
                                    const filteredSubtabTypeAtIndex = this.state.tabs[
                                      tabTypeExistsAtIndex
                                    ]?.subtabs?.filter(
                                      (t) => t.claimNumber === payload?.claimOverviewModel?.claimNumber
                                    );
                                    const lengthSubtabs = this.state.tabs[tabTypeExistsAtIndex]?.subtabs.length;
                                    if (!filteredSubtabTypeAtIndex.length) {
                                      const updatedtabs = this.state.tabs.filter(
                                        (i) => i.activeSubtab !== lengthSubtabs
                                      );
                                      activeSubtab = lengthSubtabs + 1;
                                      const claimSubtab = {
                                        subtabType: "staticclaiminvoice",
                                        invoiceId: payload?.claimOverviewModel?.invoiceId,
                                        claimNumber: claimNumber,
                                        contractNumber: payload.contractNumber,
                                        assetId: assetId,
                                        clientId: payload?.client,
                                        subtabId: activeSubtab,
                                      };
                                      const claimSubtabExtended = {
                                        ...claimSubtab,
                                        ...getSubtabProps(claimSubtab),
                                      };
                                  
                                      let newClaimSubtabs = this.state.tabs[tabTypeExistsAtIndex].subtabs;
                                      newClaimSubtabs.push(claimSubtabExtended);
                                      var newTab = {
                                        ...tab, // tabType, title
                                        tabId: this.state.tabs[tabTypeExistsAtIndex].tabId,
                                        subtabs: newClaimSubtabs,
                                        activeSubtab,
                                      };
                                  
                                      var tabs = [...updatedtabs, newTab];
                                  
                                      this.setState({ tabs: tabs, activeTab: existingTab.tabId }, () => {
                                        sessionStorage.setItem("tabs", JSON.stringify(this.state.tabs));
                                        sessionStorage.setItem("activeTab", tabId);
                                      });
                                      return this.state;
                                    } else {
                                      this.state.tabs[tabTypeExistsAtIndex].activeSubtab =
                                        filteredSubtabTypeAtIndex[0]?.subtabId;
                                      this.state.tabs[tabTypeExistsAtIndex].claimOverviewModel =
                                        payload.claimOverviewModel;
                                      this.setState(
                                        { tabs: this.state.tabs, activeTab: existingTab.tabId },
                                        () => {
                                          sessionStorage.setItem("tabs", JSON.stringify(this.state.tabs));
                                          sessionStorage.setItem("activeTab", existingTab.tabId);
                                        }
                                      );
                                      return this.state;
                                    }
                                  }

                                if(tabType !== 'claimOverview') { 
                                    this.setState({ tabs: this.state.tabs, activeTab: existingTab.tabId }, () => {
                                        sessionStorage.setItem("tabs", JSON.stringify(this.state.tabs));
                                        sessionStorage.setItem("activeTab", existingTab.tabId);
                                    });
                                    return this.state
                                }                                
                            }
                        }
                        const tabNeedsBaselineSubtabs = ['contract', 'serviceRequest', 'reports', 'claimOverview'].includes(tabType)
						if (tabNeedsBaselineSubtabs) { // add an initial "contract" subtab
                            if (tabType === 'contract') {
                                const contractSubtab = {
                                    subtabType: 'contract',
                                    contractNumber: payload.contractNumber,
                                    subtabId: 1,
                                    hideContractSubtab: false
                                }
    
                                const contractSubtabExtended = {
                                    ...contractSubtab,
                                    ...getSubtabProps(contractSubtab)
                                }
                                initialSubtabs.push(contractSubtabExtended)
                            }							

                            if (tabType === 'serviceRequest') {
                                let contractSubtabExtended = {}
                                if(!payload.hideContractSubtab) {
                                    const contractSubtab = {
                                        subtabType: 'contract',
                                        contractNumber: payload.contractNumber,
                                        subtabId: 1
                                    }    
                                    contractSubtabExtended = {
                                        ...contractSubtab,
                                        ...getSubtabProps(contractSubtab)
                                    }
                                }

                                const rfsSubtab = {
                                    subtabType: 'serviceRequest',
                                    eventData: payload.eventData,
                                    serviceNumber: payload.serviceNumber,
                                    hideContractSubtab: payload?.hideContractSubtab,
                                    subtabId: (payload?.hideContractSubtab) ? 1 : 2
                                }
                                const rfsSubtabExtended = {
                                    ...rfsSubtab,
                                    ...getSubtabProps(rfsSubtab)
                                }

                                if(payload.hideContractSubtab) {
                                    initialSubtabs.push(rfsSubtabExtended);
                                    activeSubtab = 1
                                } else {
                                    initialSubtabs.push(contractSubtabExtended, rfsSubtabExtended);
                                    activeSubtab = 2
                                }                                
                            }

                            if (tabType === 'claimOverview') {
                                let contractSubtabExtended = {}
                                const reportFlag = payload?.claimReportStatus;
                                const claimNumber = (reportFlag === true) ? 
                                    payload?.claimOverviewModel?.claimNumber : 
                                    payload?.claimOverviewModel?.eventModel?.featureClaimModel?.claimModel?.claimNumber;
                                const assetId = (reportFlag === true) ? 
                                    undefined : 
                                    payload?.claimOverviewModel?.eventModel?.featureClaimModel?.claimModel?.contractAssetId;
                                
                                if (!payload.hideContractSubtab) {
                                    const contractSubtab = {
                                        subtabType: 'contract',
                                        contractNumber: payload.contractNumber,
                                        subtabId: 1,
                                        hideContractSubtab: false
                                    }    
                                    contractSubtabExtended = {
                                        ...contractSubtab,
                                        ...getSubtabProps(contractSubtab)
                                    }
                                }
                                const claimSubtab = {
                                    subtabType: 'staticclaiminvoice',
                                    invoiceId: payload?.claimOverviewModel?.invoiceId,
                                    claimNumber: claimNumber,
                                    contractNumber: payload.contractNumber,
                                    assetId: assetId,
                                    clientId: payload?.client,
                                    subtabId: 2
                                }
                                const claimSubtabExtended = {
                                    ...claimSubtab,
                                    ...getSubtabProps(claimSubtab)
                                }

                                if(!payload.hideContractSubtab) {
                                    initialSubtabs.push(contractSubtabExtended, claimSubtabExtended);
                                } else {
                                    initialSubtabs.push(claimSubtabExtended);
                                }
                                
                                activeSubtab = 2
                            }

                            if (tabType === 'reports') {
                                const reportSubtab = {
                                    subtabType: getReportSubtabType(tab.reportType),
                                    reportType: tab.reportType,
                                    subtabId: 1,
                                    closeStatus: false
                                }
    
                                const reportSubtabExtended = {
                                    ...reportSubtab,
                                    ...getSubtabProps(reportSubtab)
                                }
                                initialSubtabs.push(reportSubtabExtended)
                            }
                        }


                        // focus profile tab if already open, instead of opening a new one.
                        if (tabType === "profile") {
                            const tabTypeExistsAtIndex = this.state.tabs.findIndex(t => t.tabType === tabType)
                            if (tabTypeExistsAtIndex > -1) {
                                const existingTab = this.state.tabs[tabTypeExistsAtIndex]
                                const { tabId: existingTabId, activeProfileTab: newProfileTab } = existingTab
                                existingTab.activeProfileTab = newProfileTab
                                const newTabs = [...this.state.tabs]
                                newTabs[tabTypeExistsAtIndex].activeProfileTab = tab.activeProfileTab
                                this.setState({
                                    activeTab: existingTabId,
                                    tabs: newTabs
                                }, () => {
                                    sessionStorage.setItem("tabs", JSON.stringify(this.state.tabs));
                                    sessionStorage.setItem("activeTab", existingTabId);
                                })
                                return
                            }
                        }

                        if (tabType === "contactus") {
                            const contactUsAtIndex = this.state.tabs.findIndex(t => t.tabType === tabType)
                            if (contactUsAtIndex > -1) {
                                const existingContactTab = this.state.tabs[contactUsAtIndex]
                                const { tabId: existingContactTabId, activeContactTab: newContactTab } = existingContactTab
                                existingContactTab.activeContactTab = newContactTab
                                const newContactTabs = [...this.state.tabs]
                                newContactTabs[contactUsAtIndex].activeContactTab = tab.activeContactTab
                                this.setState({
                                    activeTab: existingContactTabId,
                                    tabs: newContactTabs
                                }, () => {
                                    sessionStorage.setItem("tabs", JSON.stringify(this.state.tabs));
                                    sessionStorage.setItem("activeTab", existingContactTabId);
                                })
                                return
                            }
                        }

                        var newTab = {
                            ...tab, // tabType, title
                            tabId,
                            subtabs: initialSubtabs,
                            activeSubtab,
                        }
                        var tabs = [...this.state.tabs, newTab];

                        this.setState({ tabs: tabs, activeTab: tabId }, () => {
                            sessionStorage.setItem("tabs", JSON.stringify(this.state.tabs));
                            sessionStorage.setItem("activeTab", tabId);
                        });
                        return this.state
                    }
                    case "UPDATE_TAB": {
                        const [tabs, i] = this.state.tabLookup(this.state.activeTab)
                        const currentTab = tabs[i]
                        
                        const t = tabs.map(t => {
                            if (t.tabId === currentTab.tabId) {
                                return {
                                    ...t,
                                    ...payload
                                };
                            }
                            return t;
                        });
                        this.setState({
                            tabs: t
                        }, () => sessionStorage.setItem("tabs", JSON.stringify(t)));;
                        return
                    }

                    case "ADD_SUBTAB": {                      
                        const { subtabType } = payload
                        const [tabs, i] = this.state.tabLookup(this.state.activeTab)
                        const tabToUpdate = tabs[i]
                      
                        const newSubtab = {
                            ...payload,
                            ...getSubtabProps(payload),
                            subtabId: this.state.getNewSubtabId()
                        }

                        const dontRepeatTabs = [
                            "claim_overview", 
                            "claim",
                            "contractReport", 
                            "claimReport", 
                            "incompleteSalesReport",
                            "paymentHistoryReport", 
                            "pendingContractReport", 
                            "serviceRequestReport"
                        ];

                        if (dontRepeatTabs.includes(subtabType)) {
                            if(tabToUpdate.tabType === "reports") {
                                const alreadyExists = tabToUpdate.subtabs.find(st => (st.subtabType === subtabType));
                                if (!alreadyExists) {
                                    tabToUpdate.subtabs.push(newSubtab);                                    
                                    tabToUpdate.activeSubtab = newSubtab.subtabId
                                } 
                            } else {
                                const alreadyExists = tabToUpdate.subtabs.find(st => (dontRepeatTabs.includes(st.subtabType) && payload.claimNumber === st.claimNumber))
                                if (!alreadyExists) {
                                    tabToUpdate.subtabs.push(newSubtab)
                                }
                                tabToUpdate.activeSubtab = newSubtab.subtabId
                            }                            

                            this.setState({
                                tabs
                            }, () => sessionStorage.setItem("tabs", JSON.stringify(tabs)));;
                            return
                        }

                        tabToUpdate.subtabs = ([...tabToUpdate.subtabs, newSubtab])
                        tabToUpdate.activeSubtab = newSubtab.subtabId
                        this.setState({
                            tabs
                        }, () => sessionStorage.setItem("tabs", JSON.stringify(tabs)));;
                        return
                    }

                    case "UPDATE_SUBTAB": {
                        const [tabs, i] = this.state.tabLookup(this.state.activeTab)
                        const currentTab = tabs[i]
                        const { subtabId } = payload
                        
                        const updatedSubtabs = currentTab.subtabs.map(subtab => {
                            if (subtab.subtabId === subtabId) {
                                return {
                                    ...subtab,
                                    ...payload
                                };
                            }
                            return subtab;
                        });

                        currentTab.subtabs = updatedSubtabs
                        
                        this.setState({
                            tabs
                        }, () => sessionStorage.setItem("tabs", JSON.stringify(tabs)));;
                        return
                    }

                    case "CLOSE_TAB": {
                        const { tabId } = payload
                        var tabs = [...this.state.tabs];
                        const closeIndex = tabs.findIndex(t => tabId === t.tabId)
                        const isClosedTabLastInList = closeIndex === tabs.length - 1
                        const isClosedTabCurrentlyActive = tabId === this.state.activeTab
                        tabs.splice(closeIndex, 1)
                        sessionStorage.removeItem('eventDetails');
                        if (!isClosedTabCurrentlyActive) {
                            // tabs.splice(closeIndex, 1)
                            sessionStorage.setItem("tabs", JSON.stringify(tabs));
                            this.setState({
                                tabs
                            }, () => {
                                sessionStorage.setItem("tabs", JSON.stringify(tabs))
                            })
                            return
                        }

                        const newActiveTabId = tabs[closeIndex - 1]?.tabId || 0
                        // if (isClosedTabLastInList) {
                        // tabs.splice(closeIndex, 1)
                        if (tabs.length === 0) {
                            this.setState({
                                activeTab: 0,
                                tabs
                            }, () => {
                                sessionStorage.setItem("activeTab", 0);
                                sessionStorage.setItem("tabs", JSON.stringify(tabs));
                            })
                            return
                        } else {
                            this.setState({
                                activeTab: newActiveTabId,
                                tabs
                            }, () => {
                                sessionStorage.setItem("activeTab", newActiveTabId);
                                sessionStorage.setItem("tabs", JSON.stringify(tabs));
                            })
                            return
                        }
                        // }
                        // do your clean-ups here after removing a tab.
                        this.state.doCleanUp();
                    }
                    case "CLOSE_SUBTAB": {
                        const { subtabId, subtabType, rpId } = payload
                        const [tabs, i] = this.state.tabLookup(this.state.activeTab)
                        const tabToUpdate = tabs[i]

                        const subtabs = [...tabToUpdate.subtabs]

                        let filteredSubtabs = subtabs.filter(st => st.subtabId !== subtabId)

                        const newActiveSubtabId = () => {
                            // INTENDED TO CONTROL FOR SPECIFIC CASEES OF CLOSED SUBTABS AND WHICH SHOULD FOCUS AFTER ONE CLOSES.
                            // NOT CURRENTLY IN USE, BUT LEAVING HERE FOR FUTURE BREADCRUMBS
                            // if (subtabType === 'offer_overview') {
                            //     const parentOfferReportSubtab = tabToUpdate.subtabs.findIndex(st => st.rpId === rpId && st.subtabType === 'offer_report')
                            //     return tabToUpdate.subtabs[parentOfferReportSubtab].subtabId
                            // }
                            return 1
                        }

                        tabToUpdate.subtabs = filteredSubtabs
                        tabToUpdate.activeSubtab = newActiveSubtabId()

                        this.setState({ tabs }, () => {
                            sessionStorage.setItem("tabs", JSON.stringify(tabs));
                        })

                        return
                    }
                    case 'SET_ACTIVE_TAB': {
                        const { activeTab } = payload
                        this.setState({ activeTab }, () => {
                            sessionStorage.setItem("activeTab", activeTab);
                        })

                        return
                    }
                    case 'SET_ACTIVE_SUBTAB': {
                        const { subtabId } = payload
                        const [tabs, i] = this.state.tabLookup(this.state.activeTab)
                        const tabToUpdate = tabs[i]
                        tabToUpdate.activeSubtab = subtabId
                        this.setState({ tabs }, () => {
                            sessionStorage.setItem("tabs", JSON.stringify(tabs));
                        })

                        return
                    }
                    case "CLOSE_MULTIPLE_SUBTABS_ON_ACTION":
                    // For cases where closing one subtab closes any related subtabs as a group 
                    default:
                        return;
                }
            },
            getCurrentTabAndSubtab: () => {
                var tab = this.state.tabs.find((t) => t.tabId === this.state.activeTab);
                const subtab = tab?.subtabs?.find(st => st.subtabId === tab.activeSubtab)

                return ({ tab, subtab })
            },

            updateTab: (tab) => {
                const t = this.state.tabs.map((item, id) => {
                    if (item.tabType == tab.tabType) {
                        item.state = tab.state;
                        return item;
                    }
                    return item;
                });
            },

            updateReportTab: (tab) => {
                const tempTab = this.state.tabs.map((item, id) => {
                    if (item.tabType == tab.tabType) {
                        item.reportType = tab.reportType;
                        return item;
                    }
                    return item;
                });
            },

            removeTab: (tabNum) => {
                var tabs = this.state.tabs;
                tabs.splice(tabNum - 1, 1);
                var newActiveTab = (tabNum === this.state.activeTab) ? tabs.length : this.state.activeTab;

                this.setState({ tabs: tabs, activeTab: newActiveTab }, () => {
                    sessionStorage.setItem("tabs", JSON.stringify(this.state.tabs));
                    sessionStorage.setItem("activeTab", newActiveTab);
                });
                // do your clean-ups here after removing a tab.
                this.state.doCleanUp();
            },
            getNewTabId: () => {
                var ids = this.state.tabs.map(a => a.tabId);
                if (ids.length === 0)
                    return 1;
                return Math.max(...ids) + 1;
            },
            setUser: (user, callback) => {
                sessionStorage.setItem("user", JSON.stringify(user));
                if (callback)
                    callback();
            },
            isAuthenticated: () => {
                return this.state.getProfile() != null;
            },
            hasPermission: (permission) => {
                var user = this.state.getUser();
                if (user && user.permissionModels) {
                    var result = (user.permissionModels.find(p => p.permissionId.toUpperCase() === permission.toUpperCase()) != undefined)
                    return result;
                }
                return false;
            },

            canAccessTab: (tabType) => true,

            setLennox: (isLennox) => {
                this.setState({ isLennox })
            },
            setRheem: (isRheem) => {
                this.setState({ isRheem })
            },
            setCarrier: (isCarrier) => {
                this.setState({ isCarrier })
            },
            setTrane: (isTrane) => {
                this.setState({ isTrane })
            },
            logout: () => {
                sessionStorage.removeItem("user");
                sessionStorage.removeItem("profile");

                this.setState({ tabs: [] }, () => {
                    sessionStorage.removeItem("tabs");
                    sessionStorage.removeItem("activeTab");
                });
            },
            showHelp: false,
            setShowHelp: (val) => {
                this.setState({ showHelp: val });
            },
            notifications: [],
            alerts: [],
            handleCloseAlert: (id) => {
                this.setState({
                    alerts: this.state.alerts.filter((a) => {
                        return id != a.id;
                    })
                });
            },
            handleDismissNotification: (id) => {
                this.setState({
                    notifications: this.state.notifications.filter((notif) => {
                        return id != notif.id;
                    })
                });
                this.state.handleCloseAlert(id);
            },
            handleLinkClick: async (title, destination, linkText, claimNumber, profile) => {
                switch (destination) {
                    case 'profile':
                        this.state.tabDispatch('ADD_TAB', {
                            tabId: this.state.tabs.length + 1,
                            title: title,
                            tabType: destination,
                            state: { activeTab: 1 },
                            activeProfileTab: 1
                        })
                        break;
                    case 'campaign':
                        this.state.tabDispatch('ADD_TAB', {
                            tabId: this.state.tabs.length + 1,
                            title: title,
                            tabType: 'profile',
                            state: { activeTab: 2 },
                            activeProfileTab: 2
                        })
                        break;
                    case 'payment':
                        this.state.tabDispatch('ADD_TAB', {
                            tabId: this.state.tabs.length + 1,
                            title: title,
                            tabType: 'profile',
                            state: { activeTab: 4 },
                            activeProfileTab: 4
                        })
                        break;
                    case 'reports':
                        switch (linkText) {
                            case 'View Service Request':
                                let hideContractSubtab = true;
                                var fetchdata = await fetch(`contract/GetContractServiceRequestByDealerId/${profile.dealerId}/${profile.clientId}`);
                                var responsejson = await fetchdata.json();
                                var serviceRecords = responsejson.filter(x => x.eventNumber.includes(claimNumber));
                                
                                const response = await fetch(`contract/GetContractByContractNumber/${serviceRecords[0].contractNumber}/${profile.clientId}`);
                                const jsonResponse = await response.json();

                                let data = jsonResponse;

                                if (profile.dealerId && data[0].dealerId && (profile.dealerId === data[0].dealerId)) {
                                    hideContractSubtab = false;
                                } else {
                                    hideContractSubtab = true;
                                }

                                this.state.tabDispatch('ADD_TAB', {
                                    tabId: this.state.tabs.length + 1,
                                    title:
                                        (serviceRecords[0].firstName != null ? serviceRecords[0].firstName : "") +
                                        " " +
                                        (serviceRecords[0].lastName != null ? serviceRecords[0].lastName : ""),
                                    tabType: "serviceRequest",
                                    contractNumber: serviceRecords[0].contractNumber,
                                    serviceNumber: serviceRecords[0].eventNumber,
                                    eventData: serviceRecords[0],
                                    hideContractSubtab: hideContractSubtab
                                })
                                break;
                            default:
                                this.state.tabDispatch('ADD_TAB', {
                                    tabId: this.state.tabs.length + 1,
                                    title: title,
                                    tabType: destination
                                })
                                break;
                        }
                        break;
                    case 'pending':
                        this.state.tabDispatch('ADD_TAB', {
                            tabId: this.state.tabs.length + 1,
                            title: title,
                            tabType: 'reports',
                            reportType: 'pendingcontracts'
                        })
                        break;
                    default:
                        this.setState({ errorMessage: 'Invalid tab type.' })
                        break;
                }
            },

            removeNotificationAndAlert: (id) => {
                this.setState({
                    notifications: this.state.notifications.filter((notif) => {
                        return notif.id !== id;
                    }).sort((a, b) => {
                        if (a.created > b.created) { return -1; }
                        if (a.created < b.created) { return 1; }
                        return 0;
                    }),
                    alerts: this.state.notifications.filter((alert) => {
                        return alert.id !== id;
                    }).sort((a, b) => {
                        if (a.priority < b.priority) { return -1; }
                        if (a.priority > b.priority) { return 1; }
                        return 0;
                    })
                });
            },

            buildDealerNotification: async (noteReason, dealerId, accountId, contracts, claimNumber) => {
                let notifications = this.state.notifications;
                let alerts = this.state.alerts;

                const res = await fetch(`note/BuildDealerNotification/${noteReason}/${dealerId}/${accountId}/${contracts}/${claimNumber}`);
                const n = await res.json();

                notifications.push({
                    title: n.title,
                    message: n.message,
                    type: n.type,
                    badgeStatusId: n.badgeStatusId,
                    hasClose: n.hasClose,
                    hasLink: n.hasLink,
                    linkText: n.linkText,
                    linkDestination: n.linkDestination,
                    linkDestinationTitle: n.linkDestinationTitle,
                    id: n.id,
                    created: n.created,
                    priority: n.priority
                });
                notifications.sort((a, b) => {
                    if (a.created > b.created) { return -1; }
                    if (a.created < b.created) { return 1; }
                    return 0;
                });

                alerts.push({
                    title: n.title,
                    message: n.message,
                    type: n.type,
                    badgeStatusId: n.badgeStatusId,
                    hasClose: n.hasClose,
                    hasLink: n.hasLink,
                    linkText: n.linkText,
                    linkDestination: n.linkDestination,
                    linkDestinationTitle: n.linkDestinationTitle,
                    id: n.id,
                    claimNumber: n.claimNumber,
                    created: n.created,
                    priority: n.priority,
                    showAlert: true
                });
                alerts.sort((a, b) => {
                    if (a.priority < b.priority) { return -1; }
                    if (a.priority > b.priority) { return 1; }
                    return 0;
                });
                this.setState({
                    notifications: notifications,
                    alerts: alerts
                });
            },


            updateNotificationAndAlert: async (id, value) => {
                if (value <= 0) {
                    this.state.removeNotificationAndAlert(id);
                } else if (this.state.notifications.filter(n => n.id === id).length > 0) {
                    this.state.removeNotificationAndAlert(id);
                    await this.state.buildDealerNotification(id, this.state.getProfile().dealerId, this.state.getProfile().accountId, value, 0);
                }
            },

            loginExceeded: false,
            setLoginExceeded: (val) => {
                this.setState({ loginExceeded: val });
            },

            setIsResetPasswordSuccess: (val) => {
                sessionStorage.setItem("isResetPasswordSuccess", val);
            },

            getIsResetPasswordSuccess: () => {
                return sessionStorage.getItem("isResetPasswordSuccess");
            },

            setSourcePage: (sourcePageModel) => {
                sessionStorage.setItem("sourcePage", JSON.stringify(sourcePageModel));
            },
            getSourcePage: () => {
                var srcPage = JSON.parse(sessionStorage.getItem("sourcePage"));
                return srcPage;
            },

            getCardSettings: () => {
                return JSON.parse(sessionStorage.getItem("AccountCardSettings"));
            },

            getPageDetails: async () => {
                this.getHomePageDetails(this.state.getProfile());
            },

            resetHomePageFlagStats: () => {
                this.resetHomePageFlagStats();
            },

            allHomePageFlagStatsUpdated: () => {
                return this.allHomePageFlagStatsUpdated();
            },

            doCleanUp: () => {
                this.state.setSourcePage({
                    sourcePage: {
                        pageId: "",
                        targetTab: 0,
                        contracts: [],
                        targetPageState: {}
                    }
                })
            },
            fromPendingPaymentScreen: false,
            setFromPendingPaymentScreen: (val) => {
                this.setState({ fromPendingPaymentScreen: val });
            },

            setUploadedDocs: (docs, type) => {
                let { uploadedDocs } = this.state;

                uploadedDocs[type] = docs;
                this.setState({ uploadedDocs });

                sessionStorage.setItem(type, JSON.stringify(true));
            },
            getUploadedDocs: () => {
                return this.state.uploadedDocs;
            },

            isExternalLogin: false,
            targetTab: "",
            token: "",
            pendingReportTrack: {
                selecteContractId: [],
                selecteContractRows: [],
                contractType: "",
                pendingBackFlag: false
            },
            targetTabFilter: "",
            dangerouslyUpdateAuthState: (newVals) => {
                this.context.updateAuthState(newVals)
            },
            selectedCsv: [],
            setSelectedCsv: (val) => {
                this.setState({ selectedCsv: val });
            },
            getSelectedCsv: () => {
                return this.state.selectedCsv;
            },
            homePageDetails: {
                dealerProfile: {},
                dealerProfileLoaded: false,
                eligibleForCampaign: null,
                enrolledToCampaign: 'UNENROLLED',
                advantageProgramName: '',
                activeContractCount: 0,
                incompleteSaleCount: 0,
                pendingPaymentCount: 0,
                incompleteDeletedSaleCount: 0,
                serviceRequestCount: 0,
                flags: {
                    activeContractStatsUpdated: false,
                    incompleteSaleStatsUpdated: false,
                    pendingPaymentStatsUpdated: false,
                    serviceRequestStatsUpdated: false,
                    incompleteDeletedStatsUpdated: true
                }
            },
            featureFlags: [],
            getFeatureFlags: () => {
                return this.state.featureFlags;
            },
            getAccountFeatureFlag: (flagName) => {
                const profile = this.state.getProfile();
                const cardSettings = this.state.getCardSettings();

                let accountFlag = cardSettings?.vendorPortalFeatureFlags?.some(x => x.featureFlagName.trim().toLowerCase() === flagName.trim().toLowerCase()
                    && ( (x.active === true && x.accountSettings === null) ||
                        (x.accountSettings?.some(xx => xx.accountIds.some(id => id === profile?.accountId) && xx.active === true))
                    )) ?? false;

                return accountFlag;
            },
            useTranslations: (componentPathFromSrcFolder, language) => {
                let lang = "EN";
                switch(language.toLowerCase()) {
                    case "fr-us":
                        lang = "FR/US";
                        break;
                    case "fr-ca":
                        lang = "FR";
                        break;
                    case "en":
                        lang = "EN";
                        break;
                    default: 
                        console.error("Invalid language code.");
                }
                
                const fullPath = `Vendor/${lang}/components/${componentPathFromSrcFolder || ''}.json`;

                const fetchMyAPI = async () => {
                    await getTranslation(fullPath, lang)
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                languageCode: language.toLowerCase(),
                                translations: data,
                                isTranslationsResolved: true
                            })
                        }).catch(err => {
                            console.log("API error... ===>>> ", err);
                            if (componentPathFromSrcFolder === "PaymentGateway") {
                                this.setState({
                                    translations: countryConstants?.FR?.translations?.paymentGatewayText
                                })
                            }
                            if (componentPathFromSrcFolder === "ErrorMessages") {
                                this.setState({
                                    translations: countryConstants?.FR?.translations?.paymentGatewayErrorText
                                })
                            }
                        })
                }
                fetchMyAPI();
            },
            useTranslationsHeader: (componentPathFromSrcFolder, language) => {
                let lang = "EN";
                switch(language.toLowerCase()) {
                    case "fr-us":
                        lang = "FR/US";
                        break;
                    case "fr-ca":
                        lang = "FR";
                        break;
                    case "en":
                        lang = "EN";
                        break;
                    default: 
                        console.error("Invalid language code.");
                }
                const fullPath = `Vendor/${lang}/components/${componentPathFromSrcFolder || ''}.json`;

                const fetchMyAPI = async () => {
                    await getTranslation(fullPath, lang)
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                languageCode: language.toLowerCase(),
                                translationsHeader: data,
                                isTranslationsResolved: true
                            })
                        }).catch(err => {
                            console.log("API error... ===>>> ", err);
                        })
                }
                fetchMyAPI();
            },
            useTranslationsFooter: (componentPathFromSrcFolder, language) => {
                let lang = "EN";
                switch(language.toLowerCase()) {
                    case "fr-us":
                        lang = "FR/US";
                        break;
                    case "fr-ca":
                        lang = "FR";
                        break;
                    case "en":
                        lang = "EN";
                        break;
                    default: 
                        console.error("Invalid language code.");
                }
                const fullPath = `Vendor/${lang}/components/${componentPathFromSrcFolder || ''}.json`;

                const fetchMyAPI = async () => {
                    await getTranslation(fullPath, lang)
                        .then(response => response.json())
                        .then(data => {
                            this.setState({
                                languageCode: language.toLowerCase(),
                                translationsFooter: data,
                                isTranslationsResolved: true
                            })
                        }).catch(err => {
                            console.log("API error... ===>>> ", err);
                        })
                }
                fetchMyAPI();
            },
            setPaymentGatewayStatus: (status) => {
                this.setState({ paymentGatewayStatus: status });
            },
            getConfigValue: async (accountId, configName) => {
                let sessionVar = JSON.parse(sessionStorage.getItem(configName)) ?? "" ;

                if (sessionVar === "") {
                    return await this.getConfigValue(accountId, configName)
                }
                else {
                    return sessionVar;
                }
            },
            updateForgotPassLink: (value) => {
                this.setState({ forgotPassClick: value });
            },
            updateVerifyPassLink: (value) => {
                this.setState({ verifyPassClick: value });
            },
            updateConfirmPassLink: (value) => {
                this.setState({ confirmPassClick: value });
            },
            updateClientId: (value) => {
                this.setState({ accountName: value });
            }
        }

    }

    async componentDidMount() {
        const bypassLoginClients = ["staples"];
        var { context } = this;
        var flags = await getFeatureFlags();
        const staplesProfile = StaplesProfile;
        this.setState({
            featureFlags: flags
        });

        if (bypassLoginClients.includes(context.accountName.toLowerCase())) {
            await this.getHomePageCardSettings("45"); //account ID for staples
            sessionStorage.setItem("profile", JSON.stringify(staplesProfile));
            this.setState({
                isLoading: false,
                accountName: context.accountName.replace(/ /g,''),
            })            
        }
        else if (context.isSessionValid()) {
            fetch(`/dealer/getdealerprofile/${context.dealerId}`).then(res => {
                if (res.ok) {
                    res.json().then(profileModel => {
                        this.setState({
                            authenticated: true,
                            isLoading: false,
                            accountName: context.accountName.replace(/ /g,''),
                            isExternalLogin: context.isExternalLogin,
                            targetTabFilter: context.targetTabFilter,
                            targetTab: context.targetTab,
                            token: context.token
                        }, () => {
                            context.history.push({
                                pathname: "/"
                            });
                            sessionStorage.setItem("profile", JSON.stringify(profileModel));
                            this.getDealerNotifications(profileModel);
                            this.getHomePageDetails(profileModel);

                            if (context.isFromOEMPP) {
                                this.state.tabDispatch('ADD_TAB', {
                                    tabId: 1,
                                    title: "Enter Sale",
                                    tabType: "orderinfo"
                                });
                            }
                        });
                    });
                } else {
                    this.handleErrorOnLoad();
                }
            });
        } else {
            this.handleErrorOnLoad();
        }
    }

    handleErrorOnLoad() {
        var { context } = this;
        this.setState({
            isLoading: false,
            accountName: context.accountName,
            errorCode: context.errorCode
        });
    }

    async getDealerNotifications(profile) {
        try {
            const response = await fetch('dealer/getdealernotifications/'.concat(profile.dealerId));
            const notifications = await response.json();

            this.setState({
                notifications: notifications.map(n => {
                    return {
                        title: n.title,
                        message: n.message,
                        type: n.type,
                        badgeStatusId: n.badgeStatusId,
                        hasClose: n.hasClose,
                        hasLink: n.hasLink,
                        linkText: n.linkText,
                        linkDestination: n.linkDestination,
                        linkDestinationTitle: n.linkDestinationTitle,
                        id: n.id,
                        created: n.created,
                        priority: n.priority,
                        claimNumber: n.claimNumber
                    }
                })
                    .sort((a, b) => {
                        if (a.created > b.created) { return -1; }
                        if (a.created < b.created) { return 1; }
                        return 0;
                    }),
                alerts: notifications.map(n => {
                    return {
                        title: n.title,
                        message: n.message,
                        type: n.type,
                        badgeStatusId: n.badgeStatusId,
                        hasClose: n.hasClose,
                        hasLink: n.hasLink,
                        linkText: n.linkText,
                        linkDestination: n.linkDestination,
                        linkDestinationTitle: n.linkDestinationTitle,
                        id: n.id,
                        created: n.created,
                        priority: n.priority,
                        claimNumber: n.claimNumber,
                        showAlert: true
                    }
                })
                    .sort((a, b) => {
                        if (a.priority < b.priority) { return -1; }
                        if (a.priority > b.priority) { return 1; }
                        return 0;
                    })
            })
        }
        catch (error) {
            this.setState({ errorMessage: 'Error fetching notifications. Try again.' })
        }
    }

    resetHomePageFlagStats = () => {
        let { homePageDetails, homePageDetails: { flags } } = this.state;

        for (const f in flags) {
            if (f !== 'incompleteDeletedStatsUpdated')
                flags[f] = false;
        }

        homePageDetails.flags = flags;
        this.setState({ homePageDetails });
    }

    allHomePageFlagStatsUpdated = () => {
        let allFlagsUpdated = true;
        let { homePageDetails: { flags } } = this.state;
        if (flags) {
            for (const [key, value] of Object.entries(flags)) {
                if (value === false) {
                    allFlagsUpdated = false;
                    break;
                }
            }
        }

        return allFlagsUpdated;
    }

    getHomePageDetails = async (profileModel) => {
        const activeContracts = 1;    // Active
        const incompleteSale = 9;     // Incomplete
        const pendingContracts = 8;     // Pending
        let { homePageDetails, homePageDetails: { dealerProfileLoaded, enrolledToCampaign, eligibleForCampaign } } = this.state;

        let advantageProgramName = '';
        let status = enrolledToCampaign ?? 'UNENROLLED';
        let date = new Date();
        var dealerId = profileModel?.dealerId;

        eligibleForCampaign = eligibleForCampaign ?? 'false';
        date = moment(date).format("YYYY-MM-DD");
        if (profileModel && profileModel?.status !== null) {
            console.log(profileModel, "profilemodel")
            await this.getHomePageCardSettings(profileModel.accountId);
            await this.getContractsByStatus(dealerId, activeContracts, 'activeContractCount', 'activeContractStatsUpdated');
            await this.getIncompleteSalesByStatus(dealerId, incompleteSale, 'incompleteSaleCount', 'incompleteSaleStatsUpdated');
            // var c = await this.getPartyTransactions(dealerProfile.partyId);
            await this.getContractsByStatus(dealerId, pendingContracts, 'pendingPaymentCount', 'pendingPaymentStatsUpdated');
            await this.getServiceRequestCount(dealerId, 'serviceRequestCount', 'serviceRequestStatsUpdated', 'hvac');
            
            if (!dealerProfileLoaded) {
                eligibleForCampaign = profileModel.isAdvantageEligible;

                if (profileModel.advantageProgramStatusId === 1) /// Enrolled
                {
                    advantageProgramName = profileModel.advantageProgramName;
                    status = "ENROLLED_NO_CAMPAIGN";
                    // Check for active campaigns
                    var resCampaigns = await fetch(`dealer/GetActiveCampaignsByDealerId/${dealerId}/${date}`);

                    if (resCampaigns !== null) {
                        var activeCampaigns = await resCampaigns.json();

                        if (activeCampaigns !== null) {
                            status = "ENROLLED_ACTIVE";
                        }
                    }
                }
                else {
                    advantageProgramName = 'Advantage';
                }

                homePageDetails.eligibleForCampaign = eligibleForCampaign;
                homePageDetails.advantageProgramName = advantageProgramName;
                homePageDetails.dealerProfile = profileModel;
                homePageDetails.dealerProfileLoaded = true;
                homePageDetails.enrolledToCampaign = status;

                this.setState({ homePageDetails })
            }
        }
    }

    getHomePageCardSettings = async (accountId) => {
        await getConfiguration(accountId, 'accountcardsettings')
            .then(data => {
                if (data) {
                    sessionStorage.setItem("AccountCardSettings", JSON.stringify(data))
                }
            })
    }

    updateHomePageStats = (statsId, statsValue, flagId, flagValue) => {
        let { homePageDetails, homePageDetails: { flags } } = this.state;

        homePageDetails[statsId] = statsValue;
        flags[flagId] = flagValue;

        homePageDetails.flags = flags;

        this.setState({ homePageDetails })
    }

    getPartyTransactions = async (partyId, statsId, flagId) => {
        try {
            /// Get the transaction id's.
            var res = await fetch(`party/PaymentOption/GetAll/${partyId}`);
            const transactionStatusId = 1; // Pending

            var transId = [];
            var transInstanceRequestModel = {
                statusId: transactionStatusId,
                transactionId: []
            }

            if (res !== null) {
                var data = await res.json();

                if (data !== null) {
                    for (var i = 0; i < data.length; i++) {
                        transId.push(data[i].transactionId);
                    }

                    if (transId.length > 0) {
                        transInstanceRequestModel.partyId = partyId;
                        transInstanceRequestModel.transactionId = transId;

                        // Get the pending transactions.
                        this.getPendingPayments(transInstanceRequestModel, statsId, flagId);
                    }
                }
            }
        } catch (e) {
            console.log("Internal server error");
        }
    }

    getPendingPayments = async (request, statsId, flagId) => {
        try {
            const path = "Payment/GetTransactionInstanceByTransStatusId";
            const content = JSON.stringify(request);

            const requestOps = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: content
            };

            fetch(path, requestOps)
                .then(res => res.json())
                .then(pendingPayments => {
                    const statsValue = pendingPayments.length ?? 0;

                    this.updateHomePageStats(statsId, statsValue, flagId, true);
                })
                .catch(() => {
                    // no need to keep on updating if something went wrong.
                    this.updateHomePageStatsFlag(statsId, 0, flagId, true);
                })
        }
        catch (e) {
            console.log("Internal server error");
            this.updateHomePageStatsFlag(statsId, 0, flagId, true);
        }
    }

    getContractsByStatus = async (dealerId, statusId, statsId, flagId) => {
        try {
            const path = `contract/ContractsByStatus/${dealerId}/${statusId}`;

            fetch(path)
                .then(res => res.json())
                .then(contractResults => {
                    const statsValue = contractResults.length ?? 0;

                    this.updateHomePageStats(statsId, statsValue, flagId, true);
                })
                .catch(() => {
                    // no need to keep on updating if something went wrong.
                    this.updateHomePageStats(statsId, 0, flagId, true);
                })
        }
        catch (e) {
            console.log("Internal server error");
            this.updateHomePageStatsFlag(statsId, 0, flagId, true);
        }
    }

    getIncompleteSalesByStatus = async (dealerId, statusId, statsId, flagId) => {
        try {
            const path = `contract/GetSavedSaleByDealerIdAndStatusId/${dealerId}/${statusId}`;
            fetch(path)
                .then(res => res.json())
                .then(contractResults => {
                    const statsValue = contractResults.length ?? 0;

                    this.updateHomePageStats(statsId, statsValue, flagId, true);
                })
                .catch(() => {
                    this.updateHomePageStats(statsId, 0, flagId, true);
                })
        }
        catch (e) {
            console.log("Internal server error");
            this.updateHomePageStatsFlag(statsId, 0, flagId, true);
        }
    }

    getServiceRequestCount = async (dealerId, statsId, flagId, clientId) => {
        try {
            const path = `contract/GetContractServiceRequestByDealerId/${dealerId}/${clientId}`;
            fetch(path)
                .then(res => res.json())
                .then(contractResults => {
                    const statsValue = contractResults.length ?? 0;

                    this.updateHomePageStats(statsId, statsValue, flagId, true);
                })
                .catch(() => {
                    this.updateHomePageStats(statsId, 0, flagId, true);
                })
        }
        catch (e) {
            console.log("Internal server error");
            this.updateHomePageStatsFlag(statsId, 0, flagId, true);
        }
    }

    getConfigValue = async (accountId, configName) => { 
        getConfiguration(accountId, configName)
            .then(data => {
                if (data) {
                    const config = JSON.stringify(data)
                    sessionStorage.setItem(configName, config)

                    return config;
                }})
            .catch(() => {
                return null;
                })
    }

    render() {
        if (this.state.isLoading)
            return <PageLoading />
        return (
            <SessionContext.Provider value={{
                ...this.state,
                targetTab: this.context.targetTab,
                targetTabFilter: this.context.targetTabFilter
            }}>
                {this.props.children}
            </SessionContext.Provider>
        );
    }
}

SessionProvider.contextType = AuthContext;