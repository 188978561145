import React, { Component } from 'react';
import {
    Row, Col,
    Card, CardHeader, CardBody,
    UncontrolledTooltip,
} from 'reactstrap';
import styles from './ContractInfo.module.css';
import moment from 'moment';
import { ReactComponent as InfoIcon } from '../../assets/images/info-icon.svg';
import { ContractStatus } from './ContractStatus';
import DetailsTable from './DetailsTable/DetailsTable';
import InfoToolTip from "../shared/InfoTooltip/InfoTooltip";
import { getCurrencySymbol } from '../../lib/util';
const nullDefaultDate = "01/01/0000";

export class ContractInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientId: this.props.clientId,
            contract: {},
            claimsLimit: null,
        }
    }
    isCancelled = (statusId) => {
        return statusId === 4;
    }
    componentWillMount() {
        this.setState({
            contract: this.props.contract
        });
    }

    componentDidMount() {
        //this.fetchClaims()
    }

    fetchClaims = async () => {
        try {
            const response = await fetch(`/claim/GetClaimsLimit/${this.props.contract.contractId}/${this.props.clientId}`);
            const claimsLimit = await response.json();

            this.setState({
                claimsLimit: claimsLimit
            });
        }
        catch (error) {
            this.setState({ errorMessage: 'Error fetching claim overview. Try again.' })
        }
    }

    getProfilePricing = (pricing, type) => {
        if (pricing == null)
            return '$0.00';
        var amount = pricing.filter(x => x.pricingAttributeName === type);
        if (amount == null || amount.length == 0)
            return '$0.00';

        return this.formatToMoney(amount[0].value);
    }

    getDeductibleAmount = (contract) => {
        var deductible = '$0.00';
        //contract.ContractAssetModel.FirstOrDefault(x => x.OfferId == 1).Offer.OfferConfigurationModel.FirstOrDefault().Value
        if (contract.contractAssetModel != null && contract.contractAssetModel.length > 0
            && contract.contractAssetModel[0].offer != null) {
            var riskDeductible = contract.riskProfile.riskProfileConfigurationModel.filter(y => y.pricingAttributeId == 19);

            if (riskDeductible == null || riskDeductible == 0) {
                var offerDeductible = contract.contractAssetModel[0].offer.offerConfigurationModel.filter(x => x.pricingAttributeId == 19);
                if (offerDeductible == null || offerDeductible.length == 0)
                    deductible = this.formatToMoney(0);
                else
                    deductible = this.formatToMoney(offerDeductible[0].value);
            }
            else {

                deductible = this.formatToMoney(riskDeductible[0].value);
            }

        }
        else {
            deductible = '$0.00';
        }

        return deductible;
    }

    formatToMoney = (val) => {
        const currency = this.props.dealerProfile?.countryId === 1? 'USD': 'CAD';
        let formatter =  new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
            minimumFractionDigits: 2
        });

        return formatter.format(val).replace('CA$', 'CAD $');
    }

    render() {
        let contract = this.state.contract;
        let coverageTypeDescList = ["Labor Plus", "Labour Plus", "Parts and Labor Plus", "Parts and Labour Plus"];
        let installDate = (contract.contractAssetModel == null || contract.contractAssetModel.length === 0 || contract.contractAssetModel[0].assetPurchaseDate == null) ? nullDefaultDate : moment(contract.contractAssetModel[0].assetPurchaseDate).local().format(contract.country == "US" ? "L" : "DD/MM/YYYY")
        const currency = getCurrencySymbol(this.props.dealerProfile?.countryId);
        return (
            <>
                {/* <Card> */}
                {/* <CardHeader>CN: {contract.contractNumber}
                        <ContractStatus statusId={contract.statusId} />
                    </CardHeader> */}
                    {/* <CardBody> */}
                        <Row className="multiColWrapper">
                            <Col>
                                <DetailsTable
                                    tableRows={[
                                    ['Dealer PO#/Registration #', contract.clientContractNumber],
                                    ['Plan Type', (contract.contractType === null ? "" : contract?.contractType?.name)],
                                    ['Total Equipment Retail', contract.contractAssetModel == null || contract.contractAssetModel.length === 0 ? `${currency}0.00`: this.formatToMoney(contract.contractAssetModel[0].assetPurchasePrice)],
                                    ['Dealer Plan Price', contract.financialTransaction == null ? `${currency}0.00` : this.formatToMoney(contract.financialTransaction.amount)],
                                    ['Sales Tax', contract.financialTransaction == null ? `${currency}0.00` : this.formatToMoney(contract.financialTransaction.tax)],
                                    ]}
                                />
                                <DetailsTable
                                tableRows={[
                                    ['Installation Date', installDate === nullDefaultDate ? "Null" : installDate],
                                    ['Plan Purchase Date', ((contract.purchaseDate == null || contract.purchaseDate === nullDefaultDate) ? 'Null' : moment(contract.purchaseDate).isBefore(moment(installDate, contract.country == "US" ? "L" : "DD/MM/YYYY")) ?
                                        "Invalid Date" : moment(contract.purchaseDate).local().format(contract.country == "US" ? "L" : "DD/MM/YYYY"))],
                                    [(<div className={styles.tableCellWithTooltip}>
                                        Plan Begin Date
                                        <span className={styles.iconInfo}>
                                            <InfoToolTip
                                                iconType="question"
                                                message="The date that coverage goes into effect after the expiration of any wait periods."
                                            />
                                        </span>
                                    </div>
                                    ), contract.planStartDate === nullDefaultDate ? 'Null' : moment(contract.planStartDate).local().format(contract.country == "US" ? "L" : "DD/MM/YYYY")],
                                    [this.isCancelled(contract.statusId) ? 'Cancellation Date' : 'Plan Expiration Date', contract.planExpirationDate === nullDefaultDate ? 'Null' : moment(contract.planExpirationDate).local().format(contract.country == "US" ? "L" : "DD/MM/YYYY")],
                                ]}
                                />
                            </Col>
                            <Col>
                                <DetailsTable
                                    tableRows={[
                                        // wire up data in the second item of each tuple.
                                    ['Plan Description', contract.planDescription],
                                    ['Plan Details', contract.planDetails],
                                    ['Labor Reimbursement', (contract.laborRate > 0 ? this.formatToMoney(contract.laborRate) : this.formatToMoney(0))],
                                    ['Trip Rate', (contract.tripRate > 0 ? this.formatToMoney(contract.tripRate) : this.formatToMoney(0))],
                                    ['Deductible', this.getDeductibleAmount(contract)],
                                    ['Parts Allowance/Markup', coverageTypeDescList.indexOf(contract.coverageTypeDescription) != -1 ? 'Yes' : 'No'],
                                    ['Refrigerant Coverage', contract.refrigerantCoverage ? 'Yes' : 'No'],
                                        // INTERNAL ? Submitted By = "AIG USER" : DealerName
                                        ['Submitted By', contract.createdBy],
                                    ]}
                                />
                            </Col>
                        </Row>
                {/* </CardBody> */}
                {/* </Card> */}
            </>

        );
    }
}