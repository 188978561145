import React, { useState, useContext } from 'react';
import { Card, CardHeader, CardBody, Row, Col, FormGroup, UncontrolledTooltip } from "reactstrap"
import { SessionContext } from '../../context/SessionContext';
import { useClaimInvoiceStore } from "../../components/InvoiceTables/lib/state/ClaimInvoiceStore";
import styles from './Claim.module.css'
import moment from 'moment';
import ClaimInvoiceForm from './ClaimInvoiceForm.jsx';
import { useForm } from 'react-hook-form';
import { InvoiceController } from '../InvoiceTables/Table/InvoiceController';
import classNames from 'classnames';
import { Controller } from "react-hook-form";
import { useClaimStoreForm } from './useClaimStoreForm';
import NotesField from '../shared/NotesField/NotesField';
import { UploadServiceOrderModal } from '../../components/Modals/UploadServiceOrderModal/UploadServiceOrderModal.jsx';
import BootstrapTable from "react-bootstrap-table-next";
import { ReactComponent as DeleteIcon } from "../../assets/images/trash.svg"
import { ReactComponent as DownloadIcon } from "../../assets/images/download-arrow.svg"
import { DeleteDocument } from '../Modals/DeleteDocument';
import { useEffect } from 'react';
import SpinnerButton from "../../components/shared/SpinnerButton/SpinnerButton";
import { useHistory } from 'react-router-dom';
import { ClaimConfirmation } from '../Confirmation/ClaimConfirmation';
import { Modal, ModalBody, ModalFooter } from "reactstrap";

export const Claim = (contract) => {
    const [inValidationMode, setInValidationMode] = useState(false);
    const [uploadDocument, setUploadDocument] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(false);    
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showClaimConfirmation, setShowClaimConfirmation] = useState(false);
    const [isViewOnly, setIsViewOnly] = useState(false);
    const [serviceWorkOrderError, setServiceWorkOrderError] = useState(false);
    const [currentClickIndex, setCurrentClickIndex] = useState(null);
    const [selectedDocRow, setSelectedDocRow] = useState(null);
    const { tabDispatch, getCurrentTabAndSubtab } = useContext(SessionContext)
    const { tab, subtab } = getCurrentTabAndSubtab()
    const { activeSubtab } = tab
    const { getProfile } = useContext(SessionContext)
    const { invoiceId } = getCurrentTabAndSubtab().subtab;
    const { contractNumber } = getCurrentTabAndSubtab().tab;
    const [allFiles, setAllFiles] = useState(subtab?.uploadDocsArray || []);
    const accountMasterProgramId = getProfile().masterProgramId;
    const currentInvoice = useClaimInvoiceStore((state) => state[invoiceId]);
    const [invoiceData, setInvoiceData] = useState({
        invoiceNumber: '',
        invoiceDate: moment(new Date()).format("MM/DD/YYYY"),
        serviceCompletionDate: moment(new Date()).format("MM/DD/YYYY"),
        failureDate: moment(new Date()).format("MM/DD/YYYY"),
        onSiteDiagnostic: '',
        resolution: '',
    });
    const [claimResponse, setClaimResponse] = useState({})
    const invoiceStoreState = useClaimInvoiceStore((state) => {
        return state;
    });
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [formData, setFormData] = useState({})
    const isNonUs = contract?.contract?.country !== 'US';
    const dateFormatToUse = isNonUs ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

    const {
        setAdditionalRequestedRates,
        onSiteDiagnostic,
        invoiceNumber,
        invoiceDate,
        failureDate,
        failureDateCheck,
        serviceCompletionDate,
        onsiteTextAreaValue,
        resolutionTextAreaValue,
    } = useClaimStoreForm();

    const history = useHistory();

    const formProps = useForm({
        mode: 'onChange',
        shouldUnregister: true,
        defaultValues: {
            invoiceDate: moment(new Date()).format(dateFormatToUse),
            failureDate: failureDate,
            serviceCompletionDate: failureDateCheck ? failureDate : serviceCompletionDate,
            onSiteDiagnostic: onSiteDiagnostic,
            invoiceNumber: invoiceNumber,
            resolutionTextAreaValue: resolutionTextAreaValue,
            onsiteTextAreaValue: onsiteTextAreaValue,
            additionalRequestedRates: additionalRequestedRates
        }
    });

    const {
        register,
        control,
        setValue,
        trigger,
        watch,
        formState: { errors },
        handleSubmit
    } = formProps;

    const additionalRequestedRates = watch('additionalRequestedRates');

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (!!subtab?.uploadDocsArray) {
            setAllFiles(subtab?.uploadDocsArray)
        }
    }, [tab?.tabId])

    //Combing through the current Invoice and calculating the totals 
    const getTotal = (currentInvoice) => {
        const allDataCombined = Object.entries(currentInvoice || [])?.reduce((acc, e) => {
            acc = [...acc, ...e?.[1]?.data]
            return acc;
        }, []);

        const allTotal = (allDataCombined || []).reduce((acc, lineItem) => acc + parseInt(lineItem?.total || 0), 0);
        return allTotal;
    }

    const getAuthorizationDetails = (currentInvoice) => {
        const allReplacementDetails = invoiceStoreState.getReplacementDetails();
        const allData = [];
        Object.entries(currentInvoice ? currentInvoice : {}).forEach(([key, value]) => {
            if (key === 'labor') {
                value.data.forEach((e) => {
                    const replacementDetails = allReplacementDetails?.filter(rd => rd?.asset?.selected?.[0]?.assetId === e.asset.selected?.[0]?.assetId && rd?.component?.selected?.[0]?.value === e.component.selected?.[0]?.value)?.[0];
                    const laborData = {
                        Qty: e.hours,
                        AuthorizationType: e?.repair?.selected?.[0]?.display?.includes('Unit Replaced') ? "replacement" : "labor",
                        AssetId: e.asset.selected?.[0]?.assetId ? e.asset.selected?.[0]?.assetId : e.asset.selected?.[0]?.value,
                        RepairActionId: e.repair.selected[0]?.value,
                        AttributeId: e.component.selected[0]?.value,
                        UnitPrice: e.rate,
                        ContractAssetId: contract.contract.contractAssetModel?.filter(model => model.assetId === (e.asset.selected?.[0]?.assetId || e.asset.selected?.[0]?.value))?.[0]?.contractAssetId,
                        RequestedAmount: e.total,
                        ReplacementDetails: {
                            AssetId: replacementDetails?.asset?.selected?.[0]?.assetId ?? replacementDetails?.asset?.selected?.[0]?.value,
                            BrandId: replacementDetails?.brandName,
                            ModelNumber: replacementDetails?.modelNumber,
                            SerialNumber: replacementDetails?.serialNumber,
                            MFGPartsWarranty: replacementDetails?.mfgParty,
                            MFGLaborWarranty: replacementDetails?.mfgLabor,
                            SeriesId: accountMasterProgramId === 6 ? replacementDetails?.series : null,
                            Documents: replacementDetails?.file?.map(file => {
                                return {
                                    selectedDoc: file.selectedDoc,
                                    documentData: file.selectedDoc?.split("base64,")?.[1],
                                    documentType: file?.[0]?.type,
                                    documentName: file?.[0]?.name
                                }
                            })
                        },
                    }
                    allData.push(laborData);
                });
            }
            if (key === 'laborSelectRate') {
                value.data.forEach((e) => {
                    const replacementDetails = allReplacementDetails?.filter(rd => rd?.asset?.selected?.[0]?.assetId === e.asset.selected?.[0]?.assetId && rd?.component?.selected?.[0]?.value === e.component.selected?.[0]?.value)?.[0];
                    const laborSelectRateData = {
                        Qty: e.repair.options[0]?.repairHours,
                        AuthorizationType: e?.repair?.selected?.[0]?.display?.includes('Unit Replaced') ? "replacement" : "labor",
                        Description: "laborSelectRate",
                        AssetId: e.asset.selected?.[0]?.assetId ? e.asset.selected?.[0]?.assetId : e.asset.selected?.[0]?.value,
                        ContractAssetId: contract.contract.contractAssetModel?.filter(model => model.assetId === (e.asset.selected?.[0]?.assetId || e.asset.selected?.[0]?.value))?.[0]?.contractAssetId,
                        RepairActionId: e.repair.selected[0]?.value,
                        AttributeId: e.component.selected[0]?.value,
                        UnitPrice: e.rate,
                        RequestedAmount: e.total,
                        RepairCode: e.repairCode,
                        Percentage: parseFloat(e.perc),
                        ReplacementDetails: {
                            AssetId: replacementDetails?.asset?.selected?.[0]?.assetId,
                            BrandId: replacementDetails?.brandName,
                            ModelNumber: replacementDetails?.modelNumber,
                            SerialNumber: replacementDetails?.serialNumber,
                            MFGPartsWarranty: replacementDetails?.mfgParty,
                            MFGLaborWarranty: replacementDetails?.mfgLabor,
                            SeriesId: accountMasterProgramId === 6 ? replacementDetails?.series : null,
                            Documents: replacementDetails?.file?.map(file => {
                                return {
                                    selectedDoc: file.selectedDoc,
                                    documentData: file.selectedDoc?.split("base64,")?.[1],
                                    documentType: file?.[0]?.type,
                                    documentName: file?.[0]?.name
                                }
                            })
                        },
                    }
                    allData.push(laborSelectRateData);
                });
            }
            if (key === 'partsMarkup') {
                value.data.forEach((e) => {
                    const partsMarkupData = {
                        Qty: e.qty,
                        AssetId: e.asset.selected?.[0]?.assetId ? e.asset.selected?.[0]?.assetId : e.asset.selected?.[0]?.value,
                        ContractAssetId: contract.contract.contractAssetModel?.filter(model => model.assetId === e.asset.selected?.[0]?.assetId)?.[0]?.contractAssetId,
                        AuthorizationType: "partsMarkup",
                        OemWarranty: e.oem,
                        MarkupAmount: e.markup,
                        UnitPrice: e.price ?? e.price[0],
                        RequestedAmount: e.total,
                        PartNumber: e.partNumber,
                        Description: e.description.selected[0]?.display
                    }
                    allData.push(partsMarkupData);
                });
            }

            if (key === 'parts') {
                value.data.forEach((e) => {
                    const partsData = {
                        Qty: e.qty,
                        RequestedAmount: e.total,
                        AssetId: e.asset.selected?.[0]?.assetId ? e.asset.selected?.[0]?.assetId : e.asset.selected?.[0]?.value,
                        ContractAssetId: contract.contract.contractAssetModel?.filter(model => model.assetId === e.asset.selected?.[0]?.assetId)?.[0]?.contractAssetId,
                        AuthorizationType: "parts",
                        OemWarranty: e.oem,
                        MarkupAmount: e.markup,
                        UnitPrice: e.price ?? e.price[0],
                        PartNumber: e.partNumber,
                        Description: e.description.selected[0]?.display
                    }
                    allData.push(partsData);
                });
            }
            if (key === 'partsAllowance') {
                value.data.forEach((e) => {
                    const partsAllowanceData = {
                        RequestedAmount: e.total,
                        AuthorizationType: "parts",
                        Description: "partsAllowance"
                    }
                    allData.push(partsAllowanceData);
                });
            }

            if (key === 'refrigerant') {
                value.data.forEach((e) => {
                    const refrigerantData = {
                        RequestedAmount: e.total,
                        AuthorizationType: "refrigerant",
                        Description: e.refrigerantType === 'Reclaim' ? 'Reclaim' : e.refrigerantType.selected?.[0]?.display,
                        Qty: e.refrigerantType === 'Reclaim' ? 0 : e.qty,
                        UnitPrice: e.price
                    }
                    allData.push(refrigerantData);
                });
            }

            if (key === 'trip') {
                value.data.forEach((e) => {
                    const tripData = {
                        Qty: 1,
                        RequestedAmount: e.total,
                        AuthorizationType: "trip",
                        Reason: e.reason.selected?.[0]?.display ? e.reason.selected?.[0]?.display : "Initial Trip",
                        TripType: "Round Trip",
                        UnitPrice: e.tripRate
                    }
                    allData.push(tripData);
                });
            }

            if (key === 'tax') {
                value.data.forEach((e) => {
                    const taxTypeKeys = Object.keys(e);
                    const taxTypes = taxTypeKeys?.filter(taxTypeKey => taxTypeKey?.length == 3 && taxTypeKey?.endsWith('st'));
                    const getTaxConcat = () => {
                        const nameToUse = ['partsAllowance', 'partsMarkup'].includes(e.name) ? 'parts' : e.name;
                        return taxTypes.map(taxKey => `${nameToUse.concat(key).concat(contract?.contract?.country?.toLowerCase() === 'ca' ? taxKey : "")}`)
                    }
                    const taxTypeConcat = taxTypes?.length > 0 ?
                        getTaxConcat()
                        : [e.name.concat(key).concat(contract?.contract?.country?.toLowerCase() === 'ca' ? taxTypes : "")];
                    const caValues = taxTypes?.map(tax => {
                        const authType = 
                            (tax?.toLowerCase()?.includes('parts') || tax?.toLowerCase()?.includes('partsmarkup') || tax?.toLowerCase()?.includes('partsAllowance')) 
                                ? 'partstax' 
                                : e.name.includes('labor') ? 'labortax'
                                : taxTypeConcat?.filter(taxTypeConc => taxTypeConc.includes(tax))?.[0];
                        return {
                            RequestedAmount: e?.[tax],
                            AuthorizationType: authType
                        }
                    });
                    const usValues = taxTypeConcat?.map(taxConcat => {
                        const authType = 
                            (taxConcat?.toLowerCase()?.includes('parts') || taxConcat?.toLowerCase()?.includes('partsmarkup') || taxConcat?.toLowerCase()?.includes('partsAllowance'))
                                ? 'partstax'
                                : e.name.includes('labor') ? 'labortax'
                                : taxConcat;
                        return {
                            RequestedAmount: e?.total,
                            AuthorizationType: authType
                        }
                    })

                    const values = contract?.contract?.country?.toLowerCase() === 'ca' ? caValues : usValues;
                    allData.push(...values);
                });
            }
        });
        return allData;
    };

    //wire up needed once we have eligibilty rules api from Backend dev team
    const handleContinue = async (e) => {
        setInValidationMode(!inValidationMode);
        e.preventDefault();
        return;
    }

    const handleUploadDocument = (e) => {
        e.preventDefault();
        setUploadDocument(true);
        setIsOpen(true);
    }

    const handleDownloadDocument = (colIndex, e) => {
        e.preventDefault();
        const blob = allFiles?.[colIndex]?.file;
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.href = url
        link.download = blob.name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    }


    const handleDeleteDocument = (colIndex, e, row) => {
        e.preventDefault();
        setCurrentClickIndex(colIndex);
        setSelectedDocRow(row);
        setShowDeleteModal(true);
    }

    const defaultSortedBy = [{
        dataField: "dateEntered",
        order: "desc"  // or asc
    }];


    const headerFormatter = (column, index, { sortElement, filterElement }) => {
        const { order } = sortElement.props;
        return (
            <span className={`${order === "asc" ? "ascending" : ''} ${order === "desc" ? "descending" : ''}`}>
                {column.text}
            </span>
        );
    }

    const onSubmit = (data) => {

        setFormData(data);
        setInValidationMode(true);
        setIsLoading(false);
        const stateTaxError = invoiceStoreState.getTaxError();
        const isDataDuplicateExists = invoiceStoreState.getIsDataDuplicateExists();
        const isMarkupErrorExists = invoiceStoreState.getIsMarkupErrorExists();
        const isMaxPriceErrorExists = invoiceStoreState.getIsMaxPriceErrorExists();
        const isRepairErrorExists = invoiceStoreState.getIsRepairErrorExists();
        const isNCRepairErrorExists = invoiceStoreState.getIsNCRepairErrorExists();
        const count = document.querySelectorAll('[title="error-col"]');
        const isTaxErrorExists = stateTaxError?.some(e => e.error);
        if (count.length > 0 || isTaxErrorExists || isDataDuplicateExists || isMarkupErrorExists || isMaxPriceErrorExists || isRepairErrorExists || isNCRepairErrorExists) return;
        const isServiceWorkOrderExist = allFiles?.some(e => e.typeOfDocument === "Service Work Order Invoice");
        if (!isServiceWorkOrderExist) {
            setServiceWorkOrderError(true);
            return;
        }
        setShowConfirmModal(true);
        setUploadDocument(false);
    }

    const submitClaim = async (data) => {
        setIsLoading(true);
        const response = await fetch(`claim/submitclaim/hvac`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    ContractId: contract.contract.contractId,
                    ContractAssetId: invoiceStoreState.getInitialSelectedAsset(),
                    InvoiceNumber: formData.invoiceNumber,
                    InvoiceDate: new Date(moment(formData.invoiceDate, dateFormatToUse).toDate()),
                    FailureDate: new Date(moment(formData.failureDate, dateFormatToUse).toDate()),
                    ServiceCompletionDate: new Date(moment(formData.serviceCompletionDate, dateFormatToUse).toDate()),
                    OnsiteDiagnostic: formData.onsiteTextAreaValue,
                    Resolution: formData.resolutionTextAreaValue,
                    InvoiceTotal: getTotal(currentInvoice),
                    AdditionalDetails: formData.additionalRequestedRates,
                    AuthorizationDetails: getAuthorizationDetails(currentInvoice, contract),
                    Documents: allFiles?.map(file => {
                        return {
                            selectedDoc: file.selectedDoc,
                            documentData: file.selectedDoc?.split("base64,")?.[1],
                            documentTypeId: file.documentType?.data,
                            notes: file.notes,
                            documentName: file.documentName
                        }
                    })
                }
            )
        }).then(res => {
            setIsLoading(false)
            if (res.status === 200) {
                return res.json();
            }
            else {
                return null; //This will trigger the internal error
            }
        });
        setInValidationMode(true);
        setClaimResponse({
            claimNumber: response?.claimId,
            invoiceNumber: formData.invoiceNumber,
            responseStatus: response?.resultStatus,
            ruleMessage: response?.ruleResultModel?.resultMessage,
            invoiceDate: formData.invoiceDate,
            failureDate: formData.failureDate,
            serviceCompletionDate: formData.serviceCompletionDate,
            onsiteDiagnostic: formData.onSiteTextAreaValue,
            resolution: formData.resolutionTextAreaValue,
            invoiceTotal: getTotal(currentInvoice),
            additionalDetails: formData.additionalRequestedRates,
            authorizationDetails: getAuthorizationDetails(currentInvoice, contract),
            allFiles: allFiles
        })
        setShowClaimConfirmation(true);
        setShowConfirmModal(false);
    }

    const toggleConfirmationModal = () => {
        setShowConfirmModal(false);
    }
    const onError = (data) => {
        const isServiceWorkOrderExist = allFiles?.some(e => e.typeOfDocument === "Service Work Order Invoice");
        if (!isServiceWorkOrderExist) {
            setServiceWorkOrderError(true);
        }
        setInValidationMode(true);
    }
    const closeModal = () => {
        setIsOpen(false);
    }
    const handleFileUpload = (file) => {
        const allFilesArray = Array.isArray(allFiles) ? allFiles : [allFiles];
        const currentFileArray = Array.isArray(file) ? file : [file];
        setAllFiles([...allFilesArray, ...currentFileArray]);

        tabDispatch('UPDATE_SUBTAB', {
            subtabId: activeSubtab,
            uploadDocsArray: [...allFilesArray, ...currentFileArray]
        })
        setServiceWorkOrderError(false);
        closeModal();
    }

    const ActionFormatter = (column, row, colIndex) => {
        return <div className="d-flex">
            <button className={styles.button} >
                <DownloadIcon
                    alt='Download'
                    className={styles.icon}
                    onClick={(e) => handleDownloadDocument(colIndex, e)}
                />
            </button>
            <button className={classNames(styles.button, styles.buttonWidth)}>
                <DeleteIcon
                    alt='Delete'
                    className={styles.icon}
                    onClick={(e) => handleDeleteDocument(colIndex, e, row)}
                />
            </button>
        </div>
    }

    const handleDelete = () => {
        const filtered = allFiles?.filter((e, i) => i !== currentClickIndex);
        setAllFiles(filtered);
        toggleDeleteModal();
    }

    const toggleDeleteModal = () => {
        setCurrentClickIndex(null);
        setShowDeleteModal(false);
    }


    const columnsVendor = [
        {
            dataField: "dateEntered",
            text: "Date",
            classes: styles.columnDate,
            sort: true,
            headerFormatter: headerFormatter,
            formatter: (col, row) => {
                return moment(col).format('MM/DD/YYYY hh:mm A');
            },
            sortFunc: (a, b, order) => {
                if (order === "desc") {
                    return moment(b).valueOf() - moment(a).valueOf();
                }
                return moment(a).valueOf() - moment(b).valueOf();
            }
        },
        {
            dataField: "documentName",
            text: "Document Name",
            classes: styles.columnDate,
            sort: true,
        },
        {
            dataField: "typeOfDocument",
            text: "Type of Document",
            classes: styles.columnType,
            sort: true,
        },
        {
            dataField: "size",
            text: "Size",
            sort: true,
        },
        {
            dataField: "notes",
            text: "Additional Notes",
            sort: true,
            formatter: (col, row) => {
                if (col != null && col.length > 40) {

                    return <>
                        <p className="btnLink hastooltip" key={col} id={`btntool-${row.documentId}`}>
                            {`${col.slice(0, 40)}...`}
                        </p>
                        <UncontrolledTooltip
                            target={`btntool-${row.documentId}`}
                            placement="bottom"
                            autohide={false}
                            popperclassname={styles.popper}
                            innerClassName={styles.tooltipInner}
                            arrowClassName={styles.tooltipArrow}
                            key={`${row.documentId}_tooltip`}
                        >
                            <p>{col}</p>
                        </UncontrolledTooltip>
                    </>
                }
                else
                    return col;
            }
        },
        {
            dataField: "actions",
            dummyField: true,
            text: "",
            formatter: ActionFormatter,
        }
    ]

    const allFilesArray = Array.isArray(allFiles) ? allFiles : [allFiles];

    return (
        <>
            {!showClaimConfirmation &&
                <form key="claim-form" onSubmit={handleSubmit(onSubmit, onError)}>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col sm="4">Submit Claim</Col>
                            </Row>
                        </CardHeader>
                        <CardBody className={styles.Invoice}>
                            <ClaimInvoiceForm formProps={formProps} contract={contract} />
                            <InvoiceController isViewOnly={isViewOnly} inValidationMode={inValidationMode} />
                        </CardBody>
                        <Col sm="6" className="ml-2">
                            <Controller
                                control={control}
                                defaultValue={""}
                                name="additionalRequestedRates"
                                render={({ field, fieldState, formState }) => (
                                    <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                        <NotesField
                                            autoHeight={true}
                                            label="Additional Details About Requested Rates"
                                            fullWidth={true}
                                            maxChars={250}
                                            formItemName="additionalRequestedRates"
                                            placeholder={"Enter Description"}
                                            value={additionalRequestedRates}
                                            onChange={(e) => {
                                                setAdditionalRequestedRates(e)
                                                setValue('additionalRequestedRates', e)
                                                trigger('additionalRequestedRates');
                                            }
                                            }
                                        />
                                        <p className={classNames(styles.errorMargin, "errorMsg")}>{fieldState.error?.message}</p>
                                    </FormGroup>
                                )}
                            />
                        </Col>

                        <Col className="ml-2" md="12">
                            <hr></hr>
                            <h4 mt="3">Upload Document</h4>
                            <p>Please upload a copy of the Service Work Order Invoice and any additional documents that would be beneficial during the review of this claim.</p>
                            <button className="btn-secondary mb-3" onClick={handleUploadDocument}>Upload Document</button>
                            {serviceWorkOrderError &&
                                <p className={classNames(styles.errorMargin, 'mt-2', "errorMsg")}>Uploading a Service Work Order Invoice is required to submit a claim.</p>
                            }
                            <hr></hr>
                            <h4 mt="3">Uploaded Documents</h4>
                            <div className={styles.uploadedFilesTable}>
                                <BootstrapTable
                                    keyField='dateEntered'
                                    data={allFilesArray}
                                    defaultSortedBy={defaultSortedBy}
                                    columns={columnsVendor}
                                    bordered={false}
                                    bootstrap4
                                    noDataIndication={!allFilesArray.length > 0 && 'No Documents Uploaded'}
                                    wrapperClasses={"table-responsive-lg"}
                                />
                            </div>
                        </Col>
                    </Card>

                    <div className="btnWrapper">
                        <button className="btnLink">Save Claim</button>
                        <SpinnerButton
                            disabled={false}
                            spinning={false}
                            styleType="primary"
                            text={"Continue"}
                            htmlType="submit"
                        />
                    </div>
                </form>}
            {showClaimConfirmation && <ClaimConfirmation claimResponse={claimResponse} />}
            {showDeleteModal && <DeleteDocument isOpen={true} toggle={toggleDeleteModal} handleDelete={handleDelete} />}
            {uploadDocument && <UploadServiceOrderModal contract={contract} isOpen={isOpen} onEdit={closeModal} handleFileUpload={handleFileUpload} />}
            {showConfirmModal && <Modal
                isOpen={true}
                toggle={toggleConfirmationModal}
                className="confirmCard"
                centered={true}
            >
                <div className={styles.confirmCardHeader}>
                    <h4>Submit Claim</h4>
                </div>

                <ModalBody>
                    <p>Are you sure you want to submit this claim?</p>
                </ModalBody>

                <ModalFooter>
                    <button className={classNames("btnLink", styles.customBtnLink)} onClick={toggleConfirmationModal}>
                        Close
                    </button>

                    <SpinnerButton
                        onClick={submitClaim}
                        disabled={isLoading}
                        spinning={isLoading}
                        className="btn-primary"
                        text={"Submit Claim"}
                        htmlType="submit"
                    />
                </ModalFooter>
            </Modal>
            }

        </>
    )
}