import React, { Children, Component, Fragment } from "react";
import styles from "./PaymentMethod.module.css";
import { Card, CardBody, Col, Row, Container, Input, Label, FormGroup } from "reactstrap";
import classNames from 'classnames';
import { DeleteCard } from "../../Modals/DeleteCard";
import SimpleReactValidator from 'simple-react-validator';
import { PaymentContainer } from '../../../Screens/PaymentContainer';
import { CardDetails } from "./CardDetails";
import { ReactComponent as AddIcon } from '../../../assets/images/plus.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/trash.svg';
import NumberFormat from 'react-number-format';
import { CreditCardImage } from './CreditCardImage';
import { PaymentOptionType } from '../../Global/PaymentConstants';
import NeutralInfoBadge from '../../shared/NeutralInfoBadge/NeutralInfoBadge'
import { PaymentEntityType } from '../../Global/PaymentConstants';
import { NotificationIds } from '../../Global/NotificationConstants';
import { SessionContext } from '../../../context/SessionContext';
import SpinnerButton from "../../shared/SpinnerButton/SpinnerButton";
import { getCurrencySymbol } from '../../../lib/util';

let addNewCard = styles.addNewCard;
let amount = styles.amount;

export class PaymentMethod extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeCard: null,
      deleteCard: false,
      partyId: props.partyId,
      amount: this.props.paymentAmount,
      paymentOptions: "",
      ccNumber: '',
      ccType: 'unknown',
      ccTypeId: '',
      cardExpires: '',
      securityCode: '',

      errors: {
        ccInvalid: false,
        ccExpired: false,
        ccNumber: false,
        cardExpires: false,
        securityCode: false
      },

      paid: false,
      addNewCard: false,
      showCardDetails: false,
      preferredType: this.props.preferredType ?? PaymentOptionType.saved,
      isCardSelected: true,
      chargedEntityType: this.props.chargedEntityType ?? PaymentEntityType.NA,
      chargedEntityIds: this.props.chargedEntityIds ?? [],
      optionTypeFilter: this.props.optionTypeFilter != undefined ? this.props.optionTypeFilter : 0,
      selectedCardForDeletion: '',
      spinnerStatus: false,
      profileModel: this.props.profileModel,
      payBySaveError: false
    }

    this.maskCC = this.maskCC.bind(this);
    this.ccTypeChanged = this.ccTypeChanged.bind(this);
    this.onCardSelect = this.onCardSelect.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.isCardChecked = this.isCardChecked.bind(this);
    this.cardNotExpired = this.cardNotExpired.bind(this);

    this.validator = new SimpleReactValidator({
      validators: {
        card_expires: {
          message: 'The :attribute is invalid.',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^(0[1-9]|1[0-2])\/?([0-9]{2})$/) && params.indexOf(val) === -1
          },

          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
          required: true
        },

        cvv: {
          message: 'The :attribute is invalid.',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[0-9]{3,4}$/) && params.indexOf(val) === -1
          },

          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
          required: true
        },
      },

      element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,

      messages: {
        card_num: 'The credit card number is invalid.',
        card_exp: 'The :attribute is invalid.',
        required: 'The :attribute is required.'
      },
    });
  }

  maskCC(value) {
    let maskedValue = value;
    maskedValue = maskedValue.replace(/\d(?=\d{4})/g, "*");
    return maskedValue;
  }

  ccTypeChanged(type) {
    switch (type) {
      case "visa":
        break;

      case "mastercard":
        break;

      case "amex":
        break;

      case "discover":
        break;

      default:
        type = "unknown"
        break;
    };

    this.setState(() => ({
      ccType: type
    }));
  }

  onCardSelect = ({ target: { id } }) => {
    let options = this.state.paymentOptions;
    var option = options.find(p => p.token === id);
    if (this.cardNotExpired(option)) {
      this.setState({
        activeCard: id,
        isCardSelected: true
      });

      this.props.toggleCardDetails(false);
    }
  }

  onNewCardSelect = () => {
    this.setState({ isCardSelected: false });
    this.props.toggleCardDetails(true);
  }

  handleInputChange = e => {
    let { name, value } = e.target;
    let fieldName = name;
    let errors = this.state.errors;
    let validations = "required";

    if (name === "ccNumber") {
      value = e.target.value;
    }

    if (name === "cardExpires") {
      value = e.target.rawValue;
    }

    switch (name) {
      case "ccNumber":
        errors["ccInvalid"] = false;
        fieldName = "credit card number";
        validations = "required|card_num";
        break;

      case "cardExpires":
        errors["ccExpired"] = false;
        fieldName = "expiration date";
        validations = "required|card_expires";
        break;

      case "securityCode":
        fieldName = "security code"
        validations = "required|cvv";
        break;

      default:
        break;
    }

    errors[name] = !this.validator.check(value, validations);

    this.setState({
      [name]: value,
      errors: errors
    });

    this.validator.showMessageFor(fieldName);
  }

  getPaymentOptions = () => {
    const { optionTypeFilter } = this.state;
    const { enableSavedCard } = this.props;

    fetch('party/PaymentOptions/' + this.state.partyId)
      .then(res => {
        if (res.ok) {
          res.json()
            .then(data => {
              if (optionTypeFilter !== 0 && data.length > 0) {
                let newData = data.filter(x => {
                  return optionTypeFilter.includes(x.preferredOptionType);
                });

                data = newData;
              }

              this.setState({
                paymentOptions: data
              }, () => { this.getInitialCard(); });

              (enableSavedCard || typeof enableSavedCard === "undefined") && this.props.toggleCardDetails(data.length === 0 ? true : false);
            });
        }
        else {
          this.props.toggleCardDetails(true);
        }
      });
  }

  getInitialCard = () => {
    let options = this.state.paymentOptions;
    if (this.state.preferredType === PaymentOptionType.saved) {
      if (options != null && options.length > 0) {

        for (var card of options) {
          if (this.cardNotExpired(card)) {
            this.setState({
              activeCard: card.token
            });
            break;
          }
        }
      }
      else {
        this.props.toggleCardDetails(true);
      }
    } else if (this.state.preferredType === PaymentOptionType.advantagePreferred) {
      if (options != null && options.length > 0) {

        for (var card of options) {
          if (this.cardNotExpired(card) && card.preferredOptionType === this.state.preferredType) {
            this.setState({
              activeCard: card.token
            });
            break;
          }
        }
      }
      else {
        this.props.toggleCardDetails(true);
      }
    } else {
      var option = options.find(p => p.preferredOptionType === this.state.preferredType);
      if (option != null) {
        this.setState({
          activeCard: option.token
        });
      }
    }
  }

  payBySavedMethod = async () => {
    var token = this.state.activeCard;

    let response = await fetch(`payment/ChargeByToken/${this.state.clientId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        amount: this.state.amount,
        gatewayTransactionId: token,
        currencyCode: 'USD',
        cultureName: 'en-US',
        RelatedTo: this.state.chargedEntityType,
        RelatedToIds: this.state.chargedEntityIds,
      })
    }).then(res => {
      if (res.status === 200) {
        return res.json();
      }
      else {
        return null;
      }
    });

    if (response != null) {
      if (response.isSuccessful) {
        return { isSuccessful: true }
      }
    }
    this.setState({ payBySaveError: true });

    return { isSuccessful: false }
  }

  componentDidMount() {
    this.getPaymentOptions();
  }

  onDeleteCard = () => {
    const { activeCard, paymentOptions, selectedCardForDeletion } = this.state;
    var selectedCard = paymentOptions.find(c => c.token === activeCard || c.token === selectedCardForDeletion);

    if (selectedCard != null) {
      var paymentOptionId = selectedCard.paymentOptionId;

      fetch(`party/DeletePaymentOptions/${paymentOptionId}/${this.state.clientId}`, {
        method: 'POST'
      })
        .then(response => {
          if (response.ok) {
            this.setState({
              deleteCard: false,
              selectedCardForDeletion: ''
            }, () => {
              this.getPaymentOptions();
            });
          }
        });
    }
  }

  isCardChecked = (val) => {
    if (this.cardNotExpired(val)) {
      if (this.state.activeCard === val.token &&
        (this.state.isCardSelected || !this.props.cardSelected)) {
        return true;
      }
    }
    return false;
  }

  cardNotExpired = (val) => {
    if (val === undefined)
      return false;

    let currentMonth = new Date().getMonth() + 1;
    let currentYear = new Date().getFullYear();
    if (val.expirationYear > currentYear) {
      return true;
    }
    else if (val.expirationYear == currentYear) {
      if (val.expirationMonth >= currentMonth) {
        return true
      }
    }
    return false;
  }

  onUpdatePaymentMethod = () => {
    const { activeCard, paymentOptions } = this.state;
    this.context.removeNotificationAndAlert(NotificationIds.PreferredPaymentExpired);
    var selectedCard = paymentOptions.find(c => c.token === activeCard);
    var previousCards = paymentOptions.filter(c => c.preferredOptionType === this.state.preferredType && c.token !== activeCard);

    if (selectedCard != null) {
      const allCalls = [];
      this.setState({ spinnerStatus: true })
      previousCards.forEach((card) => {
        const requestOptions = {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            paymentOptionId: card.paymentOptionId,
            paymentOptionTypeId: PaymentOptionType.saved,
            transactionId: card.transactionId,
            partyId: this.props.partyId
          })
        };
        allCalls.push(fetch(`party/UpdatePaymentOption`, requestOptions));
      });

      fetch(`party/UpdatePaymentOption`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          paymentOptionId: selectedCard.paymentOptionId,
          paymentOptionTypeId: this.state.preferredType,
          transactionId: selectedCard.transactionId,
          partyId: this.props.partyId
        })
      })
        .then(response => {
          if (response.ok) {
            this.setState({ spinnerStatus: false });
            this.props.toggleEdit();
            if (this.props.updatePaymentDetails != undefined) {
              this.props.updatePaymentDetails();
            }
          }
        });
    }
    else {
      return;
    }
  }

  render() {
    const { activeCard, preferredType, deleteCard, addNewCard, isCardSelected } = this.state;
    const { showPaymentMethod = true, showPaymentDetails = true, children, showCardDetails = true, atAdvantageCampaign = false, hideSavedCard = false, accountName, billingAddress } = this.props;
    const currency = getCurrencySymbol(this.state.profileModel?.countryId);
    let paymentOptions = this.state.paymentOptions;
    let paymentAmount = this.state.amount;

    const showBillingAddress = false;

    let billingAddressText = 'N/A';

    if (billingAddress !== "") {
      billingAddressText = (billingAddress?.address1 !== "" && (billingAddress?.address1 + ", ")) +
        (billingAddress?.address2 !== "" && (billingAddress?.address2 + " ")) +
        (billingAddress?.city !== "" && (billingAddress?.city + ", ")) +
        (billingAddress?.state !== "" && (billingAddress?.state + " ")) +
        (billingAddress?.zip !== "" && billingAddress?.zip);
    }

    return (
      <>
        {paymentOptions.length > 0 && (
          <Card className={(atAdvantageCampaign || accountName === 'ge') && (styles.advantageContainer)}>
            <CardBody className={(atAdvantageCampaign || accountName === 'ge') && ("p-0")}>
              <Container className="pl-0 pr-0">
                {showPaymentDetails && (
                  !atAdvantageCampaign && (
                    accountName !== 'ge' && (
                      <div className="d-md-flex justify-content-start">
                        <h2 className="h5 subHead mr-3">Choose a Payment Method</h2>
                        <NeutralInfoBadge text="Payment Amount" amount={paymentAmount} currency={currency}/>
                      </div>
                    )
                  )
                )}
                {children}
                {paymentOptions != null && !hideSavedCard &&
                  <div className={`${!showPaymentDetails === true ? "row m-0 col-xl-9 p-0" : "row m-0"}`}>
                    {paymentOptions.map((po, key) => (
                      <div className={styles.radioCylinder} onClick={this.onCardSelect} id={po.token}>
                        <Input type="radio" checked={this.isCardChecked(po)} name="termOfCoverage" />

                        <div className={styles.controlsLabel} htmlFor={po.token} onClick={this.onCardSelect} id={po.token}>
                          <CreditCardImage vendor={po.vendor} />
                          <p className={styles.accountType}> Credit</p>
                          {/* <input type="button" className={styles.deleteCardIcon} onClick={() => this.setState({ deleteCard: true })} id={po.token}></input> */}
                          <DeleteIcon className={styles.deleteCardIcon} onClick={() => this.setState({ deleteCard: true, selectedCardForDeletion: po.token })} id={po.token} />
                          <div className={styles.cardExpiry}>
                            <p>ending in <b>{po.lastFour}</b></p>
                            {this.cardNotExpired(po) === true ? < p className={styles.expDate}>{po.expirationMonth}/{po.expirationYear}</p> : <p className={styles.expiredWarning}>Expired</p>}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                }

                {(!atAdvantageCampaign && showBillingAddress) &&
                  <div className="col-lg-3 pl-0">
                    <FormGroup>
                      <label className="form-label">Billing Address</label>
                      <p className="mb-0">
                        {billingAddressText}
                      </p>
                    </FormGroup>
                  </div>
                }

                {showPaymentMethod && !showCardDetails && !hideSavedCard &&
                  <div>
                    <button className={classNames("btn-secondary", "float-right", styles.addNewCardBtn)} aria-labelledby="Add New Card" onClick={this.onNewCardSelect}>
                      <AddIcon className={styles.addIconStyling} />
                      Add New Card
                    </button>
                  </div>
                }

                {
                    this.state.payBySaveError &&
                        <div className="validationSummary mt-3">
                            There was an issue processing your payment. Please try again.
                        </div>
                }

                {(!showPaymentMethod && showCardDetails) && !hideSavedCard &&
                  <>
                    <div className="d-flex justify-content-between mt-3" >
                      <button className={classNames("btn-secondary", styles.addNewCardBtn)} aria-labelledby="Add New Card" onClick={this.onNewCardSelect}>
                        <AddIcon className={styles.addIconStyling} />
                        Add New Card
                      </button>
                    </div>
                    <div>
                      <div className="btnWrapper">
                        <button className="btnLink" onClick={this.props.toggleEdit}>Cancel</button>
                        {/* <button className="btn-primary" onClick={this.onUpdatePaymentMethod}>Save</button> */}
                        <SpinnerButton
                          onClick={this.onUpdatePaymentMethod}
                          disabled={this.state.spinnerStatus}
                          spinning={this.state.spinnerStatus}
                          type={"primary"}
                          text={"Save"}
                        />
                      </div>
                    </div>
                  </>
                }
                <DeleteCard
                  isOpen={this.state.deleteCard}
                  toggle={() => this.setState({ deleteCard: !this.state.deleteCard, selectedCardForDeletion: '' })}
                  centered={true}
                  onDelete={this.onDeleteCard}
                />
              </Container>
            </CardBody>
          </Card>
        )}
      </>
    );
  }
}
PaymentMethod.contextType = SessionContext;
