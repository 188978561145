import React, { useContext } from 'react';
import { SessionContext } from '../../context/SessionContext';
import { ContactUs } from '../../Screens/ContactUs/ContactUs';
import { DealerHome } from '../../Screens/DealerHome/DealerHome';
import { OrderInfoContainer } from '../../Screens/OrderInfoContainer';
import Contract from '../Contract/Contract';
import { Search } from '../Contract/Search';
import { DealerProfile } from '../DealerProfile/DealerProfile';
import ReportsContainer from '../Report/ReportsContainer';
import { ReportContextProvider } from '../Report/ReportsContext';
import ResourceContainer from '../Resources/ResourceContainer';
import PriceTool from '../PriceTool/PriceTool';

export default function MainTabContent () {
	const { tabs, activeTab, getCurrentTabAndSubtab } = useContext(SessionContext)
	const { tab } = getCurrentTabAndSubtab()

	const getTab = () => {
		if (!tab || activeTab === 0) return <DealerHome tabId={5} />

    switch (tab.tabType) {
      case "search":
        return <Search tabId={tab.tabId} state={tab.state} />

			case "contract":
				return <Contract 
					title={tab.title} 
					tabId={tab.tabId} 
					contractNumber={tab.contractNumber} 
					client={tab.client} 
					state={tab.state} 
					serviceNumber={tab.serviceNumber} 
					eventData={tab.eventData} 
				/>;

        case "serviceRequest":
        return <Contract
            title={tab.title}
            tabId={tab.tabId}
            contractNumber={tab.contractNumber}
            serviceNumber={tab.serviceNumber}
            client={tab.client}
            state={tab.state}
            eventData={tab.eventData}
            />;

        case "claimOverview":
            return <Contract
                title={tab.title}
                tabId={tab.tabId}
                contractNumber={tab.contractNumber}
                claimNumber={tab?.claimNumber}
                client={tab.client}
                state={tab.state}
            />;

      case "reports":
        return (
          <ReportContextProvider reportType={tab.reportType}>
            <ReportsContainer />
          </ReportContextProvider>
        );

      case "orderinfo":
        return <OrderInfoContainer title={tab.title} tabId={tab.tabId} state={tab.state} />;

      case "profile":
        return <DealerProfile title={tab.title} tabId={tab.tabId} activeProfileTab={getCurrentTabAndSubtab().tab?.activeProfileTab} />;

      case "resources":
        return <ResourceContainer title={tab.title} tabId={tab.tabId} state={tab.state} />

      case "contactus":
        return <ContactUs title={tab.title} tabId={tab.tabId} state={tab.state} />

      case "pricing":
        return <PriceTool title={tab.title} tabId={tab.tabId} state={tab.state} />

			default: return <>Developer Error. Tab Type not recognized.</>
		}
    }

	return (
		<div className="container-fluid">
			<main>
				<div className="mainContainerWrapper">
					<div className="tab-content">
						{getTab()}
					</div >
				</div>
			</main>
		</div>
	)
}