import React, { Component, createRef } from 'react';
import { Container, Card, CardBody, Row, Col, FormGroup } from "reactstrap";
import styles from './EnrollmentForm.module.css';
import SimpleReactValidator from 'simple-react-validator';
import { DropDownAutoComplete } from '../inputs/DropDownAutoComplete';
import  AddressFieldRow from '../shared/AddressFieldRow/AddressFieldRow';
import { DealerContactInformation } from '../DealerProfile/DealerContactInformation';
import { ReactComponent as AddIconSVG } from '../../assets/images/Add_Icon.svg';
import classNames from "classnames";
import { SessionContext } from '../../context/SessionContext';
import { getBrandContent } from "../BrandingWhiteLabel/BrandContent";
import NumberFormat from 'react-number-format';
import { Spinner } from 'react-bootstrap';
import { dataLayerPush } from '../../components/Global/helpers';
import Cleave from 'cleave.js/react';

const defaultContactModel = {
  contactId: -1,
  name: "",
  phoneNumber: "",
  email: "",
  contactTypes: [],
  isPrimary: false,
  contactPreferences: [],
  validated: false
};

const defaultOptions = {
  contactPreferenceTypeOptions: [],
  contactTypeOptions: [],
  languageOptions: [
    { data: '1', display: 'English' },
    { data: '2', display: 'French' }],
  stateOptions: [],
  taxIdTypes: [
    { data: 5, taxCode: 'SSN', display: 'Sole Proprietor' },
    { data: 2, taxCode: 'INC', display: 'Corporation' },
    { data: 4, taxCode: 'UNC', display: 'Other' },
  ],
}

const taxIdTypes = [
  { data: 5, taxCode: 'SSN', display: 'Sole Proprietor' },
  { data: 2, taxCode: 'INC', display: 'Corporation' },
  { data: 4, taxCode: 'UNC', display: 'Other' },
]

const preferenceTypes = {
  sms: 1,
  email: 2
}

export class EnrollmentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usePhysicalAddress: props.profileModel.physicalAddress == props.profileModel.mailingAddress ? true : false,
      dealerId: '',
      initialModel: props.profileModel,
      updatedModel: props.profileModel,
      optionsModels: defaultOptions,
      showContactValidationErrors: false,
      errorSaveUnsuccessful: false,
      primaryContactIndexCheck: null,
      companyName: '',
      notInsured: props.profileModel.servicer.insuranceAmount == null ? true : props.profileModel.servicer.insuranceAmount.length > 0 ? false : true,
      ownershipType: '',
      countryOptions: [],
      terms: props.termsModel,
      saveError: false,
      saveInProgress: false,
      duplicatePhoneField: '',
      duplicatePhoneError: false,
      duplicatePhoneId: ''
    };

        this.validator = new SimpleReactValidator({
            element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
            messages: {
                phone: 'Please enter a valid phone number.',
                required: ':attribute is required.',
                min: ':attribute is required.',
                regex: ':attribute is invalid.'
            },
        });
        this.physicalAddressRef = createRef();
        this.mailingAddressRef = createRef();
        this.contactRef = createRef([]);
        this.save = this.save.bind(this);
    }


  addContact = () => {
    let model = this.state.updatedModel;
    if (this.contactRef?.current?.length) {
      this.contactRef.current = [...this.contactRef.current, createRef(null)]
    } else {
      this.contactRef.current = [createRef(null)]
    }

    model.contacts.push(JSON.parse(JSON.stringify(defaultContactModel)));
    model.contacts = model.contacts.filter(x => x.contactTypes != 7);

    this.setState({ updatedModel: model }, () => {
      if (model.contacts.length === 1) {
        this.onSelect(0);
      }
    });
  }

  onDeleteContact = (id) => {
    let model = this.state.updatedModel;
    model.contacts.splice(id, 1);
    this.setState({ updatedModel: model }, () => {
      this.contactRef.current.splice(id, 1)
    });
  }

  onOptionChange = (value, name, rawValue = "") => {

    let updatedModel = this.state.updatedModel;
    let stateOptions = this.state.optionsModels.stateOptions;
    let addressType = name == "State" ? "physicalAddress" : "mailingAddress";

    var state = stateOptions.find(({ data }) => data === value).abbreviation;

    updatedModel[addressType].stateId = value;
    updatedModel[addressType].state = state;

    if (addressType == "physicalAddress" && state == "FL")
      updatedModel.isFloridaDealer = true;
    if (addressType == "physicalAddress" && state != "FL")
      updatedModel.isFloridaDealer = false;

    this.setState({
      updatedModel: updatedModel
    });
  }

  formatTelephone = (text) => {

    const mobile_in = text.replace(/\D/g, '').substring(0, 10);

    let mobile_out = mobile_in;

    const zip = mobile_in.substring(0, 3);
    const middle = mobile_in.substring(3, 6);
    const last = mobile_in.substring(6, 10);

    if (mobile_in.length > 6) { mobile_out = `(${zip}) ${middle}-${last}`; }
    else if (mobile_in.length > 3) { mobile_out = `(${zip}) ${middle}`; }
    else if (mobile_in.length > 0) { mobile_out = `(${zip}`; }

    return mobile_out;

  };

  checkDuplicatePhone = (input_field, field_1, field_2, value, index=null) => {
    let updatedModel = this.state.updatedModel;
    this.setState({
      duplicatePhoneField: '',
      duplicatePhoneError: false,
      duplicatePhoneId: ''
    });
    if(index !== null) {
      if(value !== "" && (value === updatedModel[field_1] || value === updatedModel[field_2])) {
        this.setState({
          duplicatePhoneField: input_field,
          duplicatePhoneError: true,
          duplicatePhoneId: index,
          showContactValidationErrors: true
        });
      } else {
        updatedModel.contacts.map((contact, key) => {
          if(key !== index) {
            if(value !== "" && value === contact[input_field]) {
              this.setState({
                duplicatePhoneField: input_field + "_" + index,
                duplicatePhoneError: true,
                duplicatePhoneId: index,
                showContactValidationErrors: true
              });
            }
          }          
        })
      }
    }
    else {
      if(value !== "" && value === updatedModel[field_1]) {
        this.setState({
          duplicatePhoneField: input_field,
          duplicatePhoneError: true,
          showContactValidationErrors: true
        });
      } else {
        updatedModel.contacts.map((contact, key) => {
          if(value !== "" && value === contact[field_2]) {
            this.setState({
              duplicatePhoneField: input_field,
              duplicatePhoneError: true,
              showContactValidationErrors: true
            });
          }
        })
      }
    }    
  }

  handleInputChange = e => {
    let { name, value, rawValue } = e.target;
    var model = this.state.updatedModel;
    
    switch (name) {
      case "CompanyName":
        model.dealerName = value;
        break;
      case "DBAName":
        model.dba = value;
        break;
      case "companyPhone":
        model.companyPhone = rawValue;
        if(this.state.showContactValidationErrors) {
          this.checkDuplicatePhone("companyPhone", "companyFax", "phoneNumber", rawValue, null);
        } 
        break;
      case "companyFax":
        model.companyFax = rawValue;
        if(this.state.showContactValidationErrors) { 
          this.checkDuplicatePhone("companyFax", "companyPhone", "phoneNumber", rawValue, null);
        }
        break;
      case "InsuredAmount":
        model.servicer.insuranceAmount = value;
        break;
      case "FloridaLicenseNumber":
        model.licenseNumber = value;
        break;
      case "TaxID":
        model.taxId = value;
        break;
      case "Address":
        model.physicalAddress.address1 = value;
        break;
      case "mAddress":
        model.mailingAddress.address1 = value;
        break;
      case "aptNum":
        model.physicalAddress.address2 = value;
        break;
      case "maptNum":
        model.mailingAddress.address2 = value;
        break;
      case "city":
        model.physicalAddress.city = value;
        break;
      case "mcity":
        model.mailingAddress.city = value;
        break;
      case "zip":
        model.physicalAddress.zip = value;
        break;
      case "mzip":
        model.mailingAddress.zip = value;
        break;
    }

    this.setState({ updatedModel: model });
  }

  save = () => {
    const contactDetails = this.state.updatedModel.contacts.map((data) => {
      data.validationFired = true
      return data
    })
    this.setState({ saveInProgress: true, errorSaveUnsuccessful: false, validationFired: true, updatedModel: { ...this.state.updatedModel, contacts: contactDetails } });
    this.forceUpdate();
    var route = "dealer/DealerSaveProfile";
    const terms = JSON.parse(this.state.terms);

    var model = this.state.updatedModel;
    const addressResult = this.physicalAddressRef.current.validate();
    const mailingAddressResult = this.mailingAddressRef.current ? this.mailingAddressRef.current.validate() : true;
    this.checkDuplicatePhone("companyPhone", "companyFax", "phoneNumber", model.companyPhone, null);
    this.checkDuplicatePhone("companyFax", "companyPhone", "phoneNumber", model.companyFax, null);
    const contactResult = this.contactRef?.current?.map(contact => contact.checkValidation());
    if (!contactResult.includes(false) &&
      this.validator.allValid() &&
      !this.state.notInsured &&
      addressResult &&
      mailingAddressResult && 
      !this.state.duplicatePhoneError) {
      try {
        fetch(route, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            companyName: model.dealerName,
            companyPhone: model.companyPhone.replace(/\D/g, ''),
            companyFax: model.companyFax,
            dealerName: terms.acceptedBy,
            dba: model.dba,
            dealerStatus: model.dealerStatus,
            dealerId: model.dealerId,
            clientDealerId: model.clientDealerId,
            languageId: model.languageId,
            physicalAddress: model.physicalAddress,
            mailingAddress: model.mailingAddress,
            county: model.country,
            countryId: model.countryId,
            taxId: model.taxId,
            licenseNumber: model.licenseNumber,
            isFloridaDealer: model.isFloridaDealer,
            isLennoxDealer: model.isLennoxDealer,
            accountId: model.accountId,
            dealerProgramId: model.dealerProgramId,
            emailAddress: model.emailAddress,
            partyId: model.partyId,
            insured: true,
            insuredAmount: model.servicer.insuranceAmount,
            contacts: model.contacts,
            ownershipType: model.taxIdTypeId
          })
        }).then(response => {
          if (response.status === 200) {
            response.json().then(data => {
              this.validator.hideMessages();
              this.setState({
                saveError: false,
                saveInProgress: false,
                showContactValidationErrors: false,

              }, () => {
                window.location.assign("./");
              });
            });

          } else {
            this.setState({
              saveInProgress: false,
              errorSaveUnsuccessful: true
            });
          }
        });
      }
      catch (error) {
        console.log(error);
        this.setState({
          saveError: true,
          saveInProgress: false,
          errorSaveUnsuccessful: true
        });
      }
    }
    else {
      this.setState({
        showContactValidationErrors: true,
        saveInProgress: false
      }, () => {
        this.validator.showMessages();
        this.forceUpdate();
      });
    }
  }

  addressValidated = () => {
    let physicalAddress = this.state.updatedModel.physicalAddress;
    let mailingAddress = this.state.updatedModel.mailingAddress;

    let isPhysicalAllValid = physicalAddress.address1 != null && physicalAddress.address1 != '' &&
      physicalAddress.city != null && physicalAddress.city != '' &&
      physicalAddress.stateId != null && physicalAddress.stateId != 0 &&
      physicalAddress.zip != null && physicalAddress.zip != '' ? true : false;

    let isMailingAllValid = mailingAddress.address1 != null && mailingAddress.address1 != '' &&
      mailingAddress.city != null && mailingAddress.city != '' &&
      mailingAddress.stateId != null && mailingAddress.stateId != 0 &&
      mailingAddress.zip != null && mailingAddress.zip != '' ? true : false;

    return isPhysicalAllValid && isMailingAllValid ? true : false;
  }

  contactsValidated = () => {
    let contacts = this.state.updatedModel.contacts.filter(x => x.contactTypes != 7);
    let allContractValid = contacts.findIndex(c => c.validated == null || c.validated === false) === -1
    return allContractValid;
  }

  componentDidMount() {
    dataLayerPush('Pageview', {
      pagePath: '/enroll',
      pageTitle: 'Enrollment Details'
    })
    this.pullDealerProfileOptions();
    this.getCountryOptions();
    this.mapDealerProfile();
  }

  mapDealerProfile = () => {
    var profile = this.context.getProfile();
    const {physicalAddress, mailingAddress} = this.state.updatedModel;
    const address1 = physicalAddress.address1;
    const address2 = physicalAddress.address2;
    const city = physicalAddress.city;
    const stateId = physicalAddress.stateId;
    const zip = physicalAddress.zip;

    const mailingaddress1 = mailingAddress.address1;
    const mailingaddress2 = mailingAddress.address2;
    const mailingcity = mailingAddress.city;
    const mailingstateId = mailingAddress.stateId;
    const mailingzip = mailingAddress.zip;
    const isCheckBoxSelect = (address1 ===  mailingaddress1 && address2 === mailingaddress2 && 
      city === mailingcity && stateId === mailingstateId && zip === mailingzip)? true : false;
    
    if (profile.mailingAddress.address1 != null)
      this.setState({ usePhysicalAddress: isCheckBoxSelect});
  }

  getCountryOptions = async () => {
      try {
          fetch('party/states')
              .then(res => res.json())
              .then(data => {
                  var countries = [];
                  for (var da in data) {
                      var d = data[da].countryModel;
                      if (!countries.find(x => x.display === d.name) && (d.countryId == 1 || d.countryId == 2))
                          countries.push({ data: d.countryId, display: d.name, iso2: d.iso2, iso3: d.iso3 });
                  }
                  this.setState({ countryOptions: countries });
              });
      } catch (error) {
          console.error(error);
      }
  }

  pullDealerProfileOptions = async () => {
    try {
      var route = `dealer/GetDealerProfileOptions`;

      let response = await fetch(route).then(response => {
        if (response.status === 200) {
          return response.json();
        }
        throw new Error(`Network response was not ok ${response.status}`);
      });

      this.setState({
        optionsModels: response
      });

    } catch (error) {
      console.error(error);
    }
  }

  createCopy = (model) => {
    return JSON.parse(JSON.stringify(model))
  }

  getFormattedPhoneNumber = (phone) => {
    if (phone == null)
      return '';

    var formattedPhone = phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    return formattedPhone === '' ? phone : formattedPhone;
  }

  getFormattedFax = (fax) => {
    if (fax == null)
      return '';

    var formattedFax = fax.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    return formattedFax === '' ? fax : formattedFax;
  }

  onSelect = (primaryContactIndex) => {
    var model = this.state.updatedModel;
    var updatedContacts = model.contacts.map((c, index) => {
      c.isPrimary = primaryContactIndex == index;
      return c;
    });
    model.contacts = updatedContacts;
    this.setState({ updatedModel: model, primaryContactIndexCheck: primaryContactIndex });
  }

  onContactUpdated = (field, value, index) => {
    let model = this.state.updatedModel;
    var contact = model.contacts[index];
    if (field === 'smsCommEnabled' || field === 'emailCommEnabled') {
      var commId = field === 'smsCommEnabled' ? preferenceTypes.sms : preferenceTypes.email;
      let index = contact.contactPreferences.findIndex(r => r === commId);

      if (index === -1) {
        contact.contactPreferences.push(commId);
      } else {
        contact.contactPreferences.splice(index, 1);
      }
    }
    else {
      model.contacts[index][field] = value;
      (field === "phoneNumber") && this.checkDuplicatePhone("phoneNumber", "companyPhone", "companyFax", value, index);
    }
    this.setState({ updatedModel: model });
  }

  handleDropdownChange = (val) => {
    var model = this.state.updatedModel;
    model.languageId = val;
    this.setState({ updatedModel: model });
  }

  handleCountryChange = (val) => {
    var model = this.state.updatedModel;
    model.countryId = val;
    model.country = this.state.countryOptions.find(x => x.data === val).display;
    this.setState({ updatedModel: model });
  }

  setNotInsured = (val) => {
    this.setState({ notInsured: val });
  }

  handleOwnershipChange = (val) => {
    var model = this.state.updatedModel;
    model.taxIdTypeId = val;
    this.setState({ ownershipType: val, updatedModel: model });
  }

  setIsPhysicalAddress = e => {
    this.setState({ usePhysicalAddress: e.target.checked });
    this.validator.purgeFields();
    if (e.target.checked) {
      var model = this.state.updatedModel;
      model.mailingAddress = this.createCopy(model.physicalAddress);
      model.mailingAddress.addressTypeId = 3;
      this.setState({ updatedModel: model });
      }
      setTimeout(() => {
          var model = this.state.updatedModel;
          this.setState({ updatedModel: model });
      }, 500);

  }

  getContactRef = (el, key) => {
    if (this.contactRef.current) {
      this.contactRef.current[key] = el
    } else {
      this.contactRef.current = [el]
    }
  }

  render() {
    const { accountName } = this.context

    let maxContacts = 10;

    let { dba, dealerId, dealerName, clientDealerId, companyPhone, companyFax,
      country, countryId, taxId, licenseNumber, languageId, physicalAddress, mailingAddress,
      servicer, isFloridaDealer, taxIdTypeId
    } = this.state.updatedModel;

    let address1 = physicalAddress.address1;
    let address2 = physicalAddress.address2;
    let city = physicalAddress.city;
    let stateId = physicalAddress.stateId;
    let zip = physicalAddress.zip;

    let maddress1 = mailingAddress.address1;
    let maddress2 = mailingAddress.address2;
    let mcity = mailingAddress.city;
    let mstateId = mailingAddress.stateId;
    let mzip = mailingAddress.zip;

    let contacts = this.state.updatedModel.contacts.filter(x => x.contactTypes != 7);
    if (contacts == null || contacts.length === 0)
      this.addContact();

    const { contactPreferenceTypeOptions,
      contactTypeOptions, stateOptions, languageOptions } = this.state.optionsModels;

    let taxIdTypeOptions = taxIdTypes.filter(x => (x.taxCode == 'SSN' || x.taxCode == 'INC' || x.taxCode == 'UNC'));

        let typeOptions = contactTypeOptions.filter(o => o.value != 7 && o.display.toLowerCase() != 'service power contact');

    let primaryContactIndex = this.props.profileModel.contacts.findIndex(c => c.isPrimary != null && c.isPrimary);
    if (this.state.primaryContactIndexCheck !== null && (this.state.primaryContactIndexCheck !== primaryContactIndex)) {
      primaryContactIndex = this.state.primaryContactIndexCheck;
    }

    const duplicatePhoneErrMsg = "Please enter a valid phone number.";

        return (
            <Container className="contentWrapper">
                <h1 className="h4">{getBrandContent(accountName).programName} Enrollment Account Information </h1>
                <Card>
                    <CardBody>
                        <p>Thank you for accepting the Program Enrollment Terms and Conditions. Please verify the following to access the {getBrandContent(accountName).programName} portal.</p>
                        <h2 className="h4 subHead">Company Information</h2>
                        <Row className="multiColWrapper">
                            <Col xs="12" md="4"  className={styles.colSize}>
                                <FormGroup className={!this.validator.check(dealerName, "required") && this.state.showContactValidationErrors ? "form-error" : ""}>
                                    <label className="form-label" for="companyName">Company Name</label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <input aria-labelledby="companyName" className="form-control" type="text" placeholder="" id="companyName" aria-required="true" name="CompanyName" value={dealerName} onChange={this.handleInputChange} maxLength="50" />
                                    {this.validator.message("companyName", dealerName, "required")}
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="4" className={styles.colSize}>
                                <FormGroup>
                                    <label className="form-label" for="dbaName">DBA Name</label>
                                    <input aria-labelledby="dbaName" className="form-control" type="text" placeholder="" id="dbaName" name="DBAName" value={dba} onChange={this.handleInputChange} maxLength="50" />
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="4" className={styles.colSize}>
                                <FormGroup>
                                    <label className="form-label" for="dealerIDProgram">{getBrandContent(accountName).programName} Dealer ID</label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <input aria-labelledby="dealerIDProgram" className="form-control" type="text" placeholder="" id="dealerIDProgram" aria-required="true" value={dealerId} name="ProgramDealerID" disabled />
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="4" className={styles.colSize}>
                                <FormGroup>
                                    <label className="form-label" for="dealerIDClient">{this.state.isLennoxDealer ? 'Lennox ' : 'Client '}Dealer ID</label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <input aria-labelledby="dealerIDClient" className="form-control" type="text" placeholder="" id="dealerIDClient" aria-required="true" value={clientDealerId} name="ClientDealerID" disabled />
                                </FormGroup>
                            </Col>
                            
                        </Row>
                        {/* Second Row */}
                        <Row className="multiColWrapper">
                        <Col xs="12" md="4" className="col-5ths">
                                <FormGroup className={(!this.validator.check(companyPhone, "required|phone") && this.state.showContactValidationErrors) ? "form-error" : ""}>
                                  <label className="form-label" htmlFor="companyPhone">Company Phone</label>
                                  <span aria-hidden="true" className="required-indicator">*</span>
                                  <Cleave 
                                    options={{
                                      blocks: [0, 3, 3, 4],
                                      delimiters: ['(', ') ', '-'], 
                                      numericOnly: true
                                    }} 
                                    className="form-control" 
                                    id="companyPhone"
                                    name="companyPhone" 
                                    value={companyPhone} 
                                    onChange={this.handleInputChange} 
                                    onBlur= {this.handleInputChange}
                                    aria-required="true"
                                  />
                                  {(this.state.duplicatePhoneField === "companyPhone" && this.state.duplicatePhoneError) ? 
                                    <div className='errorMsg'> {duplicatePhoneErrMsg} </div> :
                                    this.validator.message("companyPhone", companyPhone, "required|phone")
                                  }
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="4" className="col-5ths">
                                <FormGroup className={(((!this.validator.check(companyFax, "phone")) || (this.state.duplicatePhoneField === "companyFax" && this.state.duplicatePhoneError))) && this.state.showContactValidationErrors ? "form-error" : ""}>
                                    <label className="form-label" for="companyFax">Company Fax</label>
                                     <Cleave 
                                        options={{
                                          blocks: [0, 3, 3, 4],
                                          delimiters: ['(', ') ', '-'], 
                                          numericOnly: true
                                        }} 
                                        className="form-control" 
                                        id="companyFax"
                                        name="companyFax" 
                                        value={companyFax} 
                                        onChange={this.handleInputChange} 
                                        onBlur= {this.handleInputChange}
                                  />
                                  
                                  {((this.state.duplicatePhoneField === "companyFax" && this.state.duplicatePhoneError) &&
                                    this.state.showContactValidationErrors)? 
                                      (<div className='errorMsg'>{duplicatePhoneErrMsg}</div>):
                                      this.validator.message("companyFax", companyFax, "phone")
                                  }
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="4" className="col-5ths">
                                <FormGroup className={!this.validator.check(languageId, "required") && this.state.showContactValidationErrors || languageId == 0 && this.state.showContactValidationErrors ? "form-error" : ""}>
                                    <label className="form-label" for="language">Language</label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <DropDownAutoComplete
                                        name={'Language'}
                                        id={'language'}
                                        aria-required="true"
                                        value={languageId}
                                        options={languageOptions}
                                        onChangedValue={this.handleDropdownChange}
                                    />
                                    {this.validator.message("language", languageId === 0 ? '' : languageId, "required")}
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="4" className="col-5ths">
                                <FormGroup className={!this.validator.check(countryId, "required") && this.state.showContactValidationErrors || countryId == 0 && this.state.showContactValidationErrors ? "form-error" : ""}>
                                    <label className="form-label" for="country">Country</label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <DropDownAutoComplete
                                        name={'Country'}
                                        id={'country'}
                                        aria-required="true"
                                        options={this.state.countryOptions}
                                        value={countryId}
                                        onChangedValue={this.handleCountryChange}
                                    />
                                    {this.validator.message("Country", countryId, "required")}
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="4" className="col-5ths">
                                <FormGroup>
                                    <label className="form-label" for="currentlyInsured">Currently Insured</label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <div className="d-flex" id="currentlyInsured">
                                        <div className="radio mr-3">
                                            <input type="radio" id="insuredYes" name="InsuredCheck" aria-required="true" checked={!this.state.notInsured} onClick={() => { this.setNotInsured(false) }} />
                                            <label className="controls-label" for="insuredYes">Yes</label>
                                        </div>
                                        <div className="radio">
                                            <input type="radio" id="insuredNo" name="InsuredCheck" aria-required="true" checked={this.state.notInsured} onClick={() => { this.setNotInsured(true) }} />
                                            <label className="controls-label" for="insuredNo">No</label>
                                        </div>
                                    </div>
                                    {this.state.notInsured ? <div className="errorMsg">You must be insured to enroll.</div> : ""}
                                </FormGroup>
                            </Col>
                            
                        </Row>
                        {/* Third Row */}
                        <Row className="multiColWrapper">
                            <Col xs="12" md="4" className="col-5ths">
                                <FormGroup className={!this.validator.check(servicer.insuranceAmount, "required") && this.state.showContactValidationErrors ? "form-error" : ""}>
                                    <label className="form-label" for="insuredAmount">Insured Amount</label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <NumberFormat aria-labelledby="insuredAmount" className="form-control" displayType={'input'} placeholder="" id="insuredAmount" aria-required="true" name="InsuredAmount" value={servicer.insuranceAmount} onChange={this.handleInputChange} thousandSeparator={true} prefix={'$'} decimalSeparator={'.'} decimalScale={2} maxLength={22} />
                                    {this.validator.message("insuredAmount", servicer.insuranceAmount, "required")}
                                </FormGroup>
                            </Col>
                            {isFloridaDealer ? (
                              <Col xs="12" md="4" className="col-5ths">
                                <FormGroup
                                  className={
                                    !this.validator.check(licenseNumber, [
                                      "required",
                                      { regex: "^([A-Z]{1}[0-9]{6})|([a-z]{1}[0-9]{6})$" },
                                    ]) && this.state.showContactValidationErrors
                                      ? "form-error"
                                      : ""
                                  }
                                >
                                  <label className="form-label" for="flLicenseNum">
                                    Florida License Number
                                  </label>
                                  <span aria-hidden="true" className="required-indicator">
                                    *
                                  </span>
                                  <input
                                    aria-labelledby="flLicenseNum"
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    id="flLicenseNum"
                                    aria-required="true"
                                    name="FloridaLicenseNumber"
                                    value={licenseNumber}
                                    onChange={this.handleInputChange}
                                    maxLength={7}
                                  />
                                  {this.validator.message("license Number", licenseNumber, [
                                    "required",
                                    { regex: "^([A-Z]{1}[0-9]{6})|([a-z]{1}[0-9]{6})$" },
                                  ]) && (
                                    <div className="errorMsg">Please enter a valid license number.</div>
                                  )}
                                </FormGroup>
                              </Col>
                              ) : (
                                <></>
                              )
                            }

                            <Col xs="12" md="4" className="col-5ths">
                                <FormGroup className={!this.validator.check(taxIdTypeId, "required") && this.state.showContactValidationErrors ? "form-error" : ""}>
                                    <label className="form-label" for="ownershipType">Ownership Type</label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    <DropDownAutoComplete
                                        name={'Ownership Type'}
                                        id={'ownershipType'}
                                        aria-required="true"
                                        options={taxIdTypes}
                                        onChangedValue={this.handleOwnershipChange}
                                        value={taxIdTypeId}
                                    />
                                    {this.validator.message("ownershipType", taxIdTypeId, "required")}
                                </FormGroup>
                            </Col>
                            <Col xs="12" md="4" className="col-5ths">
                                <FormGroup className={!this.validator.check(taxId, "required") && this.state.showContactValidationErrors ? "form-error" : ""}>
                                    <label className="form-label" for="taxID">Tax ID</label>
                                    <span aria-hidden="true" className="required-indicator">*</span>
                                    {/*<input aria-labelledby="taxID" className="form-control" type="text" placeholder="" id="taxID" aria-required="true" name="TaxID" value={taxId} onChange={this.handleInputChange} />*/}
                                    {countryId == 80 ?
                                        <>
                                            <input aria-labelledby="taxID" className="form-control" type="text" placeholder="" id="taxID" aria-required="true" name="TaxID" value={taxId} onChange={this.handleInputChange} />
                                            {this.validator.message("taxId", taxId, ["required", { regex: "^([0-9]{2}[A-Z]{1}[0-9]{6})|([0-9]{2}[a-z]{1}[0-9]{6})$" }], { messages: { required: "Tax ID is required." } })}
                                        </>
                                        :
                                        <>
                                            <NumberFormat aria-labelledby="taxID" className="form-control" type="text" placeholder="" id="taxID" aria-required="true" name="TaxID" value={taxId} onChange={this.handleInputChange} format={countryId == 1 ? '##-#######' : '#########-######'} />
                                            {this.validator.message("taxId", taxId, "required", { messages: { required: "Tax ID is required." } })}
                                        </>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr />
                        <h2 className="h4 subHead">Address Information</h2>
                        <AddressFieldRow
                            ref={this.physicalAddressRef}
                            addressType="Physical"
                            stateOptions={stateOptions.filter(c => c.countryId === countryId)}
                            countryId={countryId}
                            onInputChange={this.handleInputChange}
                            onOptionChange={this.onOptionChange}
                            addressLine1={address1}
                            addressLine2={address2}
                            city={city}
                            stateProvince={stateId}
                            zipPostal={zip}
                            showContactValidationErrors={this.state.showContactValidationErrors}
                        />
                        {this.state.usePhysicalAddress ?
                            <>
                                <label className="form-label" for="mailingAddress">Mailing Address</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                            </>
                            :
                            <>
                                <AddressFieldRow
                                    ref={this.mailingAddressRef}
                                    addressType="Mailing"
                                    stateOptions={stateOptions.filter(c => c.countryId === countryId)} 
                                    countryId={countryId}
                                    onInputChange={this.handleInputChange}
                                    onOptionChange={this.onOptionChange}
                                    addressLine1={maddress1}
                                    addressLine2={maddress2}
                                    city={mcity}
                                    stateProvince={mstateId}
                                    zipPostal={mzip}
                                    showContactValidationErrors={this.state.showContactValidationErrors}
                                />
                            </>
                        }
                        <div className="checkbox" id="mailingAddress">
                            <input className="mr-2" type="checkbox" id="mailingAddressCheck" name="billingAddress" onBlur={this.setIsPhysicalAddress} onChange={this.setIsPhysicalAddress} checked={this.state.usePhysicalAddress} />
                            <label for="mailingAddressCheck" className={`checkboxLabel ${this.context.accountName}`}>Same as Physical Address</label>
                        </div>
                        <hr />
                        <h2 className="h4 subHead">Contact Information</h2>
                        {contacts.map((contact, key) =>
                            <DealerContactInformation
                                contactModel={this.createCopy(contact)}
                                selectedTypes={contact.contactTypes}
                                contactTypeOptions={typeOptions}
                                key={key}
                                id={key}
                                ref={(el) => this.getContactRef(el, key)}
                                onDeleteContact={this.onDeleteContact}
                                contactRadio={primaryContactIndex === key}
                                onSelect={this.onSelect}
                                onContactUpdated={this.onContactUpdated}
                                showValidationErrors={this.state.showContactValidationErrors}
                                duplicatePhoneError={this.state.duplicatePhoneError}
                                duplicatePhoneId={this.state.duplicatePhoneId}
                                duplicatePhoneErrMsg={duplicatePhoneErrMsg}
                            />
                        )}
                        <button className={classNames("btn-secondary", styles.addContactBtn)} onClick={this.addContact} disabled={this.state.updatedModel.contacts.length >= maxContacts}>
                            <span className={styles.regularAdd}><AddIconSVG /></span>
                            Contact
                        </button>
                        {this.state.errorSaveUnsuccessful &&
                            <div className="validationSummary">There was an issue saving your profile. Please try again.</div>
                        }
                    </CardBody>
                </Card>
                <div className="btnWrapper">
                    <button className="btn-primary" onClick={this.save}>
                        <Spinner color="light" size="sm" animation={this.state.saveInProgress ? "border" : ""} />
                        {this.state.saveInProgress ? "" : "Continue"}
                    </button>
                </div>
            </Container>
        );
    }
}

EnrollmentForm.contextType = SessionContext;